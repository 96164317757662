
<div class="edit-faulty-equipment">
  <p-dialog (onHide)="closeFaultyEquipmentDetail()" [header]="popupHeader" [(visible)]="visible"
    [modal]="true" [style]="{width: '80vw'}" [maximizable]="true" [draggable]="false" [resizable]="false">
    <div *ngIf="loading" class="loading-indicator d-flex align-items-center justify-content-center"
      style="height: 200px;">
      <p-progressSpinner *ngIf="loading"></p-progressSpinner>
    </div>
    <form #formRef="ngForm">
      <ng-template #faultyEquipmentDetailRef>
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="tdCode">TD Code</label>
          <input id="tdCode" class="form-control" type="text" name="tdCode" [(ngModel)]="tdCode"
            [disabled]="!unlocked" (change)="validateTdCode()">
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="equipmentCategory">Equipment Category</label>
          <p-dropdown *ngIf="(openingMode == 'addNewFault') || unlocked" name="equipmentCategory" [options]="equipmentCategories" 
              [(ngModel)]="faultyEquipmentDetail.equipmentType" [filter]="true" filterBy="label" [showClear]="true" 
              placeholder="Select a category" (onChange)="onEquipmentCategoryChange()" appendTo="body" [required]=true>
            <ng-template pTemplate="selectedItem">
              <div *ngIf="faultyEquipmentDetail.equipmentType">
                <div>{{faultyEquipmentDetail.equipmentType}}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <input *ngIf="(openingMode != 'addNewFault') && !unlocked" id="equipmentCategory" class="form-control" type="text" 
              name="equipmentCategory" [(ngModel)]="faultyEquipmentDetail.equipmentType" [disabled]="!unlocked">
        </div>
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="equipmentBrand">Equipment</label>
          <p-dropdown *ngIf="(openingMode == 'addNewFault') || (unlocked && (faultyEquipmentDetail.reportingUser != 'XLS Import'))"
              name="equipmentBrand" [options]="equipmentBrandOptions" [(ngModel)]="faultyEquipmentDetail.equipmentBrand" 
              [filter]="true" filterBy="label" [showClear]="true" placeholder="Select equipment details" appendTo="body" [required]=true>
            <ng-template pTemplate="selectedItem">
              <div *ngIf="faultyEquipmentDetail.equipmentBrand">
                <div>{{faultyEquipmentDetail.equipmentBrand}}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <input *ngIf="(openingMode != 'addNewFault') && (!unlocked || (faultyEquipmentDetail.reportingUser == 'XLS Import'))"
              id="equipmentBrand" class="form-control" type="text" name="equipmentBrand"
              [(ngModel)]="faultyEquipmentDetail.equipmentBrand" [disabled]="!unlocked" />
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="reportedFault">Reported Fault</label>
          <p-dropdown *ngIf="(openingMode == 'addNewFault') || (unlocked && (faultyEquipmentDetail.reportingUser != 'XLS Import'))" 
              name="reportedFault" [options]="faultOptions" [(ngModel)]="faultyEquipmentDetail.reportedFault" 
              [filter]="true" filterBy="label" [showClear]="true" placeholder="Select fault" appendTo="body" [required]=true>
            <ng-template pTemplate="selectedItem">
              <div *ngIf="faultyEquipmentDetail.reportedFault">
                <div>{{faultyEquipmentDetail.reportedFault}}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <input *ngIf="(openingMode != 'addNewFault') && (!unlocked || (faultyEquipmentDetail.reportingUser == 'XLS Import'))" 
              id="reportedFault" class="form-control" type="text" name="reportedFault"
              [(ngModel)]="faultyEquipmentDetail.reportedFault" [disabled]="!unlocked" />
        </div>
      
        <div class="form-group col-12">
          <label for="additionaNote">Additional Note</label>
          <textarea id="additionalNote" class="form-control" name="additionalNote" rows="3"
            [(ngModel)]="faultyEquipmentDetail.additionalNote" [disabled]="(openingMode != 'addNewFault') && !unlocked">
          </textarea>
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="reportingUser">Reporting User</label>
          <input id="reportingUser" class="form-control" type="text" name="reportingUser"
            [(ngModel)]="faultyEquipmentDetail.reportingUser" [disabled]="(openingMode != 'addNewFault') && !unlocked">
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="reportedDate">Reported Date</label>
          <input id="reportedDate" type="date" class="form-control" name="reportedAt"
            [ngModel]="faultyEquipmentDetail.reportedAt | date:'yyyy-MM-dd'"
            (ngModelChange)="faultyEquipmentDetail.reportedAt = $event" [disabled]="(openingMode != 'addNewFault') && !unlocked">
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="serial">Serial Number</label>
          <input id="serial" class="form-control" type="text" name="serial" [(ngModel)]="faultyEquipmentDetail.serial">
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label>Faulty product</label>
          <p-dropdown [disabled]="openingMode == 'read-only'" id="faultyProduct" name="selectedFaultyProduct"
            [options]="faultyProductOptions" [(ngModel)]="faultyEquipmentDetail.faultyProduct" [filter]="true"
            filterBy="label" [showClear]="true" placeholder="Select faulty product" appendTo="body">
            <ng-template pTemplate="selectedItem">
              <div *ngIf="faultyEquipmentDetail.faultyProduct">
                <div>{{faultyEquipmentDetail.faultyProduct}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      
        <div class="form-group col-6">
          <label for="outcomeOfTest">Outcome Of Test</label>
          <textarea id="outcomeOfTest" class="form-control" name="outcomeOfTest" rows="3"
            [(ngModel)]="faultyEquipmentDetail.outcomeOfTest" (change)="outcomeUpdated()">
          </textarea>
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="dateTested">Date Tested</label>
          <input id="dateTested" class="form-control" type="date" name="dateTested"
            [ngModel]="faultyEquipmentDetail.dateTested | date:'yyyy-MM-dd'"
            (ngModelChange)="faultyEquipmentDetail.dateTested = $event">
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="tester">Tester</label>
          <input id="tester" class="form-control" type="text" name="tester" [(ngModel)]="faultyEquipmentDetail.tester">
        </div>

        <div class="form-group col-md-6 col-lg-3">
          <legend class="col-form-label pt-0">Troubleshooting Correct</legend>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="faultyRadio" id="faultyRadioYes" value="true"
              [checked]="faultyEquipmentDetail.troubleShootingCorrect == 'Yes'"
              (change)="faultyEquipmentDetail.troubleShootingCorrect = 'Yes'">
            <label class="form-check-label" for="faultyRadioYes">
              Yes
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="faultyRadio" id="faultyRadioNo" value="true"
              [checked]="faultyEquipmentDetail.troubleShootingCorrect == 'No'"
              (change)="faultyEquipmentDetail.troubleShootingCorrect = 'No'">
            <label class="form-check-label" for="faultyRadioNo">
              No
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="faultyRadio" id="faultyRadioNA" value="true"
              [checked]="faultyEquipmentDetail.troubleShootingCorrect == 'NA'"
              (change)="faultyEquipmentDetail.troubleShootingCorrect = 'NA'">
            <label class="form-check-label" for="faultyRadioNA">
              N/A
            </label>
          </div>
          <div class="form-check form-check-inline">
            <button type="button" title="Clear Troubleshooting Correct" class="btn" (click)="faultyEquipmentDetail.troubleShootingCorrect = ''">
              <i class="fa fa-times-circle"></i>&nbsp;Clear
            </button>
          </div>
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="dateSentToSupplier">Date Sent To Supplier</label>
          <input id="dateSentToSupplier" class="form-control" type="date" name="dateSentToSupplier"
            [ngModel]="faultyEquipmentDetail.dateSentToSupplier | date:'yyyy-MM-dd'"
            (ngModelChange)="faultyEquipmentDetail.dateSentToSupplier = $event">
        </div>
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="trackingId">Tracking Id</label>
          <input id="trackingId" class="form-control" type="text" name="trackingId"
            [(ngModel)]="faultyEquipmentDetail.trackingId">
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label for="dateReturnedFromSupplier">Date Returned From Supplier</label>
          <input id="dateReturnedFromSupplier" class="form-control" type="date" name="dateReturnedFromSupplier"
            [ngModel]="faultyEquipmentDetail.dateReturnedFromSupplier | date:'yyyy-MM-dd'"
            (ngModelChange)="faultyEquipmentDetail.dateReturnedFromSupplier = $event">
        </div>
      
        <div class="form-group col-12 col-md-6 col-lg-3">
          <legend class="col-form-label pt-0">Fixed</legend>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="fixedRadio" id="fixedRadioYes" value="true"
              [checked]="!!faultyEquipmentDetail.fixed" (change)="faultyEquipmentDetail.fixed = true; faultyEquipmentDetail.shelfLocation = null">
            <label class="form-check-label" for="fixedRadioYes">
              Yes
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="fixedRadio" id="fixedRadioNo" value="true"
              [checked]="(faultyEquipmentDetail.fixed != null) && (!faultyEquipmentDetail.fixed)"
              (change)="faultyEquipmentDetail.fixed = false">
            <label class="form-check-label" for="fixedRadioNo">
              No
            </label>
          </div>
          <div class="form-check form-check-inline">
            <button type="button" title="Clear Fixed" class="btn" (click)="faultyEquipmentDetail.fixed = null; faultyEquipmentDetail.shelfLocation = null">
              <i class="fa fa-times-circle"></i>&nbsp;Clear
            </button>
          </div>
        </div>
        <div class="form-group col-12 col-md-6 col-lg-3">
          <label>Shelf location</label>
          <p-dropdown name="selectedShelfLocation"
            [options]="shelfLocations" [(ngModel)]="faultyEquipmentDetail.shelfLocation" [filter]="true" filterBy="label"
            [showClear]="true" placeholder="Select Shelf Location" appendTo="body" [required]="faultyEquipmentDetail.fixed == false"
            [disabled]="(openingMode == 'read-only') || (faultyEquipmentDetail.fixed != false)"
            >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="faultyEquipmentDetail.shelfLocation">
                <div>{{faultyEquipmentDetail.shelfLocation}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="form-group col-12 col-md-6 col-lg-3">
          <label>Reason Not Fixed</label>
          <p-dropdown name="selectedShelfLocation"
            [options]="reasonNotFixedOptions" [(ngModel)]="faultyEquipmentDetail.reasonNotFixed" [filter]="true" filterBy="label"
            [showClear]="true" placeholder="Select not fixed reason" appendTo="body" [required]="faultyEquipmentDetail.fixed == false"
            [disabled]="(openingMode == 'read-only') || (faultyEquipmentDetail.fixed != false)"
            >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="faultyEquipmentDetail.reasonNotFixed">
                <div>{{faultyEquipmentDetail.reasonNotFixed}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="form-group col-12 col-md-6 col-lg-3">
          <legend class="col-form-label pt-0">Warranty</legend>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="warrantyRadio" id="warrantyRadioYes" value="true"
              [checked]="!!faultyEquipmentDetail.warranty" (change)="faultyEquipmentDetail.warranty = true">
            <label class="form-check-label" for="warrantyRadioYes">
              Yes
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="warrantyRadio" id="warrantyRadioNo" value="true"
              [checked]="(faultyEquipmentDetail.warranty != null) && (!faultyEquipmentDetail.warranty)"
              (change)="faultyEquipmentDetail.warranty = false">
            <label class="form-check-label" for="warrantyRadioNo">
              No
            </label>
          </div>
          <div class="form-check form-check-inline">
            <button type="button" title="Clear Fixed" class="btn" (click)="faultyEquipmentDetail.warranty = null">
              <i class="fa fa-times-circle"></i>&nbsp;Clear
            </button>
          </div>
        </div>
        
      </ng-template>
      <div *ngIf="!loading">
        <fieldset *ngIf="openingMode == 'read-only'" [disabled]="openingMode == 'read-only'">
          <div class="row">
            <ng-container *ngTemplateOutlet="faultyEquipmentDetailRef"></ng-container>
          </div>
        </fieldset>
        <div *ngIf="openingMode == 'update'" class="row">
          <ng-container *ngTemplateOutlet="faultyEquipmentDetailRef"></ng-container>
        </div>
        <div *ngIf="openingMode == 'addNewFault'" class="row">
          <ng-container *ngTemplateOutlet="faultyEquipmentDetailRef"></ng-container>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <button type="button" class="btn btn-warning" (click)="closeFaultyEquipmentDetail()">
        <span *ngIf="openingMode == 'read-only'">Close</span>
        <span *ngIf="openingMode != 'read-only'">Cancel</span>
      </button>
      <ng-container *ngIf="openingMode == 'addNewFault' || openingMode == 'update'">
        <button type="button" class="btn btn-primary mr-2" (click)="openingMode == 'update'?updateDefaultEquipment():addNewFault()"
            [disabled]="isSubmitButtonDisabled || !formRef.valid">
          Save
        </button>
      </ng-container>
      <ng-container *ngIf="(openingMode != 'addNewFault') && userCanAdministerFaults()">
        <button type="button" class="btn btn-danger mr-2" (click)="confirmDelete()" [disabled]="isSubmitButtonDisabled">
          Delete
        </button>
        <button *ngIf="openingMode != 'read-only'" type="button" class="btn btn-warning mr-2" (click)="unlocked = true" [disabled]="isSubmitButtonDisabled">
          Unlock
        </button>
      </ng-container>
    </ng-template>
  </p-dialog>
</div>
<p-confirmDialog></p-confirmDialog>