<div class="hardware-list">
  <div class="container">
    <div class="d-flex justify-content-center m-1">
      <p-dropdown [options]="brands" appendTo="body" [(ngModel)]="selectedBrand" placeholder="Brands" scrollHeight="600px">
        <ng-template let-Pbrand let-i="index" pTemplate="item">
          <span class="brandDropDown" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color': brandConfigs[Pbrand.value].color}">
            &nbsp;{{Pbrand.value}}
          </span>
        </ng-template>
      </p-dropdown>
      <div class="ml-2">
        <button type="button" class="btn btn-primary" pButton (click)="loadHardware()" label="Load Hardware" [disabled]="!selectedBrand">
        </button>
      </div>
      <div class="ml-2">
        <button type="button" class="btn btn-primary" pButton (click)="addHardware()" label="Add Hardware" [disabled]="!canAddHardware">
        </button>
      </div>
    </div>
  </div>
  <p-table #dt [value]="hardwareItems" [columns]="cols" [paginator]="true" [rows]="20" sortField="title" [sortOrder]="1" sortMode="single" 
      tableStyleClass="hardwareTable">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">
            <div *ngSwitchCase="'pricesExVat'">
              {{col.header}}<br/>
              <span class="info">Prices apply to orders created within the specified date range.</span>
            </div>
            <div *ngSwitchCase="'actions'">
              {{col.header}}
            </div>
            <div *ngSwitchCase="'planSymbol'" [pSortableColumn]="col.field">
              Plan
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
              Symbol
            </div>
            <div *ngSwitchDefault [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
              <ng-container *ngIf="col.field == 'title'">Must be unique within the brand</ng-container>
            </div>
          </ng-container>
        </th>
      </tr>
      <tr>
        <th *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">
            <div *ngSwitchCase="'pricesExVat'" class="info">
              Changes to prices and the additional/removal of prices will not be saved until you click the Update button.
            </div>
            <input *ngSwitchCase="'title'" pInputText type="search" (input)="applyFilter($event, col.field, 'contains')"
                [value]="getFilterValue('title')" style="width: 100%;">
            <input *ngSwitchCase="'planSymbol'" pInputText type="search" (input)="applyFilter($event, col.field, 'contains')"
                [value]="getFilterValue('planSymbol')" style="width: 100%;">
            <p-multiSelect *ngSwitchCase="'category'" [options]="hardwareCategories" appendTo="body" [(ngModel)]="selectedCategories"
                placeholder="Category" filterPlaceHolder="Category" [panelStyle]="{maxWidth:'135px'}" scrollHeight="600px"
                [maxSelectedLabels]=2 selectedItemsLabel="{0} selected" [style]="{'display':'flex'}"
                (onChange)="dt.filter($event.value, col.field, 'in')" >
            </p-multiSelect>
          </ng-container>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <ng-container *ngSwitchCase="'pricesExVat'">
            <ng-container *ngFor="let datedPrice of rowData[col.field]; let i = index">
              <div>
                <input name="fromDate_{{i}}" type="date" [(ngModel)]="rowData.pricesExVat[i].fromDate" min="2014-01-01" 
                    [ngClass]="{'empty' : !rowData.pricesExVat[i].fromDate}"/>
                <label for="toDate_{{i}}">to</label>
                <input name="toDate_{{i}}" type="date" [(ngModel)]="rowData.pricesExVat[i].toDate" min="2014-01-01" 
                    [ngClass]="{'empty' : !rowData.pricesExVat[i].toDate}"/>
                <label for="quarterly_{{i}}">Quarterly: </label>
                <input name="quarterly_{{i}}" pInputText type="number" [(ngModel)]="rowData.pricesExVat[i].quarterly"  
                    class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
                <label for="annual_{{i}}">Annual: </label>
                <input name="annual_{{i}}" pInputText type="number" [(ngModel)]="rowData.pricesExVat[i].annual"
                    class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
                <button type="button" class="btn icon-btn" (click)="deletePrice(rowData, i)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <hr />
            </ng-container>
            <div>
              <div><label>Add New Price:</label></div>
              <input name="newFromDate" type="date" [(ngModel)]="rowData['newFromDate']" min="2014-01-01"
                  [ngClass]="{'empty' : !rowData['newFromDate']}"/>
              <label for="newToDate">to</label>
              <input name="newToDate" type="date" [(ngModel)]="rowData['newToDate']" min="2014-01-01" 
                  [ngClass]="{'empty' : !rowData['newToDate']}"/>
              <label for="newDateQuarterly">Quarterly: </label>
              <input name="newDateQuarterly" pInputText type="number" [(ngModel)]="rowData['newQuarterly']"  
                  class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
              <label for="newDateAnnual">Annual: </label>
              <input name="newDateAnnual" pInputText type="number" [(ngModel)]="rowData['newAnnual']"
                  class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
              <button type="button" class="btn icon-btn" (click)="addPrice(rowData)">
                <i class="fas fa-plus-square"></i>
              </button>
            </div>
            <div>
              <div><label>Prices for orders under 1 year old. If not set will use appropriate dated price:</label></div>
              <div class="recentPrices">
                <label for="recentQuarterly">Quarterly: </label>
                <input name="recentQuarterly" pInputText type="number" [(ngModel)]="rowData.recentQuarterlyPrice"  
                    class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)"/>
                <label for="recentAnnual">Annual: </label>
                <input name="recentAnnual" pInputText type="number" [(ngModel)]="rowData.recentAnnualPrice"
                    class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)"/>
              </div>
            </div>
          </ng-container>
          <div *ngSwitchCase="'replacementPrice'">
            <input name="replacementPrice" pInputText type="number" [(ngModel)]="rowData['replacementPrice']"  
                class="font-weight-normal text-right" min="0" step="0.01" (keypress)="numberOnly($event, true)" />
          </div>
          <div *ngSwitchCase="'websiteId'">
            <span class="brand" [ngStyle]="{'background-color':rowData[col.field].background, 'color':rowData[col.field].color}">
              &nbsp;{{rowData[col.field].title}}
            </span>
          </div>
          <div *ngSwitchCase="'category'">
            <p-dropdown [options]="hardwareCategories" placeholder="Select a category" [(ngModel)]="rowData[col.field]" [required]=true>
            </p-dropdown>
            <ng-container *ngIf="rowData.category == 'Base Unit'">
              <div>Add Plan Codes for accessories where only some accessories of that types can be linked</div>
              <ng-container *ngFor="let linkableAccessory of rowData['linkableAccessories']; let accessoryIndex = index">
                <div>
                  <label class="planLabel" for="accessoryList{{accessoryIndex}}">Plan Code: {{linkableAccessory.planSymbol}}</label>
                  <p-multiSelect [options]="linkableAccessory.planSymbol | planCodeTypes:true" defaultLabel="Accepted accessories"
                    [(ngModel)]="linkableAccessory.accessoryTypes" [required]=true name="accessoryList{{accessoryIndex}}"
                    [maxSelectedLabels]=1 selectedItemsLabel="{0} selected" styleClass="planDropdown">
                  </p-multiSelect>
                  <button type="button" class="btn icon-btn" (click)="deleteLinkableAccessory(rowData, accessoryIndex)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </ng-container>
              <div>
                <div><label for="newAccessoryPlanCode">Add Plan Code:</label>
                  <input name="newAccessoryPlanCode" pInputText type="text" [(ngModel)]="rowData['newAccessoryPlanCode']"
                      (ngModelChange)="rowData['newAccessoryPlanCode'] = $event.toUpperCase()"
                      class="font-weight-normal planCode" required />
                  <button type="button" class="btn icon-btn" (click)="addAccessoryPlanCode(rowData)" 
                      [disabled]="!(rowData['newAccessoryPlanCode'] | planCodeHasTypes)">
                    <i class="fas fa-plus-square"></i>
                  </button>
                </div>
                <div *ngIf="rowData['newAccessoryPlanCode'] && !(rowData['newAccessoryPlanCode'] | planCodeHasTypes)" class="alert alert-danger">
                  Plan code {{rowData['newAccessoryPlanCode']}} does not have a list of accessory types defined.
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngSwitchCase="'updated'">
            By {{rowData['updatedBy']}} on {{rowData['updatedAt'] | date:'dd/MM/y \'at\' HH:mm'}}
          </div>
          <div *ngSwitchCase="'actions'">
            <button type="button" class="btn btn-primary" (click)="saveHardware(rowData)">
              <ng-container *ngIf="!!rowData['_id']">Update</ng-container>
              <ng-container *ngIf="!rowData['_id']">Save New</ng-container>
            </button>
          </div>
          <div *ngSwitchCase="'planSymbol'">
            <input name="{{col.field}}" pInputText type="text" [(ngModel)]="rowData[col.field]" class="font-weight-normal" />
          </div>
          <div *ngSwitchDefault>
            <input name="{{col.field}}" pInputText type="text" [(ngModel)]="rowData[col.field]" class="font-weight-normal" required/>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-toast></p-toast>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error"></p-confirmDialog>
<p-confirmDialog key="save"></p-confirmDialog>