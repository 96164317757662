import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {CcActionUpdateData} from '../models/socket-io/creditControlActionUpdateData.model';
import {GenericSocketService} from './generic-socket.service';

interface CcActionUpdServToCliEvs {
  'updateHomeOutboundAndFilteredPage': (actionUpdateData: CcActionUpdateData) => void;
  'updateHomeOutboundAndFilteredPageSettingCallback': (actionUpdateData: CcActionUpdateData) => void;
}

// Don't allow any client to server events
interface CcActionUpdCliToServEvs {}

@Injectable()
export class CreditControlActionUpdatesSocketService extends GenericSocketService<CcActionUpdServToCliEvs, CcActionUpdCliToServEvs> {

  constructor() {
    super({
      'url': environment.protocol + environment.IPAddress + '/actionUpdates', 
      'options': {
        'closeOnBeforeunload': false,
        'transports': ['websocket'],
        'path': '/socket.io/updates/',
      }
    });
  } 
}