<p-dialog header="Maintain Groups" (onHide)="hideDialog()" [(visible)]="display" [modal]="true" [draggable]=false
  [style]="{width:'94vw', height: 'auto'}" [position]="'center'" [minY]="20" [maximizable]="true" [baseZIndex]="10000" [blockScroll]="true">
<div class="maintain-group">
  <p-table #dt [value]="tableData" [columns]="cols" [paginator]="true" [rows]="5" class="maintain-table-block">
    <ng-template pTemplate="caption">
      <form [formGroup]="addGroupForm">
        <div class="row">
          <div class="col-md-4">
            <input type="text" class="form-control" name="groupName" formControlName="name"
              placeholder="Enter group name" required/>
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control" name="groupDescription" formControlName="description"
              placeholder="Enter group description" required/>
          </div>
          <div class="col-md-4 text-right">
            <button type="submit" class="btn btn-info" (click)="addGroup()" [disabled]="addGroupForm.invalid">Add New Group</button>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" class="px-2">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'name'">
            <input type="text" class="form-control" name="name" placeholder="Enter group name"
              [(ngModel)]="rowData[col.field]" #groupName (change)="doesUserExist(rowData, index)" />
          </div>
          <div *ngSwitchCase="'description'">
            <textarea class="form-control" [rows]="2" [cols]="30" pInputTextarea
              name="description" [(ngModel)]="rowData[col.field]" placeholder="Enter group description" >
            </textarea>
          </div>
          <div *ngSwitchCase="'createdAt'">
            <span>{{rowData[col.field] | date:'dd/MM/yyyy, HH:mm'}}</span>
          </div>
          <div *ngSwitchCase="'changedAt'">
            <span *ngIf="rowData[col.field]">{{rowData[col.field] | date:'dd/MM/yyyy, HH:mm'}}</span>
            <span *ngIf="!rowData[col.field]">Not changed yet</span>
          </div>
          <div *ngSwitchCase="'autoNotificationCount'">
            <span *ngIf="rowData[col.field]>0"><i class="pi pi-check"></i></span>
          </div>
          <div *ngSwitchCase="'actions'" class="main-btn-block">
            <span class="mx-1">
              <button type="button" class="btn btn-primary btn my-1" (click)="updateGroup(rowData,index)">Update</button>
            </span>
            <span class="mx-1">
              <button type="button" class="btn btn-primary btn my-1" (click)="maintainUsers(rowData)">Maintain Users</button>
            </span>
            <span class="mx-1">
              <button type="button" class="bg-danger my-1" class="group-delete" (click)="deleteGroup(rowData)">
                <i class="fas fa-trash"></i>
              </button>
            </span>
          </div>
          <div *ngSwitchDefault>
            {{rowData[col.field]}}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  </div>
  <p-toast [style]="{marginTop: '80px'}"></p-toast>
  <p-confirmDialog *ngIf="!groupDataToMaintainUsers"></p-confirmDialog>
</p-dialog>
<p-dialog class="maintain-users" header="Maintain Users" [(visible)]="groupDataToMaintainUsers" [modal]="true"
    [style]="{width: '60vw', minWidth: '200px'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000" [positionTop]='80'>
  <div class="container">
    <div class="row">
      <div class="col-md-12 form-group maintain-user-dialog-dropdown">
        <label>Select Users</label>
        <p-multiSelect [options]="users" [(ngModel)]="usersForMaintainUserDialog" [maxSelectedLabels]=10
          selectedItemsLabel="{0} users selected" [style]="{top:'0px'}" dropdownIcon="pi pi-user-plus"
          placeholder="0 users selected">
        </p-multiSelect>
      </div>
    </div>
    <div class="row text-right">
      <div class="col-12">
        <span>
          <button class="btn btn-primary" type="button" (click)="saveUsers()">Save</button>
        </span>
        <span>
          <button class="btn btn-warning ml-2" type="button" (click)="closeMaintainUsersDialog()">Cancel</button>
        </span>
      </div>
    </div>
  </div>
  <div class="popup-dialog">
    <p-confirmDialog *ngIf="groupDataToMaintainUsers"></p-confirmDialog>
  </div>
</p-dialog>
