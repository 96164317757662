import {PaymentAccount} from '../models/payments/paymentAccount.model';
import {StringIndexedObject} from '../models/utility/stringIndexedObject.model';

const SMART_DEBIT_ACCOUNTS: StringIndexedObject<StringIndexedObject<PaymentAccount>> = {
  'Lifeline24 Ltd': {
    'CK': {
      'accountId': 'carelink',
      'accountName': 'PRE 25-5-22 Carelink24',
    },
    'CL': {
      'accountId': 'careline',
      'accountName': 'PRE 25-5-22 Careline365',
    },
    'LL': {
      'accountId': 'lifeline',
      'accountName': 'PRE 25-5-22 Lifeline24 Ltd',
    },
    'TC': {
      'accountId': 'telecare',
      'accountName': 'PRE 25-5-22 Telecare Choice',
    },
  },
  'Careline365 Ltd': {
    'CK': {
      'accountId': 'carelink24',
      'accountName': 'Carelink24',
    },
    'CL': {
      'accountId': 'careline365',
      'accountName': 'Careline365 Limited',
    },
    'LL': {
      'accountId': 'lifeconnect',
      'accountName': 'LifeConnect24',
    },
    'TC': {
      'accountId': 'telecarechoice',
      'accountName': 'Telecare Choice',
    },
  }
}

export {
  SMART_DEBIT_ACCOUNTS,
};
