import * as moment from 'moment-timezone';
import {Equipment} from '../models/changelog/equipment.model';
import {VimOrNote} from '../models/changelog/vimOrNote.model';
import {ActionChange} from '../models/changelog/actionChange.model';
import {TreeNode} from 'primeng/api';
import {HardwareChange} from '../models/changelog/hardwareChange.model';
import {DiffHistory} from '../models/changelog/diffHistory.model';
import {HardwareSetsChange} from '../models/changelog/hardwareSetsChange.model';
import {HardwareId} from '../models/mongoose-populated-ids/hardwareId.model';
import {HardwareSetId} from '../models/mongoose-populated-ids/hardwareSetId.model';
import {KeySafeChange} from '../models/changelog/keySafeChange.model';
import {RenewalDiscountChange} from '../models/changelog/renewalDiscountChange.model';
import {ServiceChange} from '../models/changelog/serviceChange.model';
import {AccountServiceId} from '../models/mongoose-populated-ids/accountServiceId.model';

const ACTION_DATE_FIELDS: string[] = ['actionInitiatedDate', 'holdUntil'];
const ACTION_DATETIME_FIELDS: string[] = ['renewalDateTaken'];
const AFTER_UPDATE_KEY: RegExp = /^[0-9]+$/;
const BEFORE_UPDATE_KEY: RegExp = /^_[0-9]+$/;

function getTreeItem(user: string, reason: string, createdAt: string, field: string, 
    before: string, after: string): TreeNode {
  return  {
    'data': {
      'user': user? user.replace(/undefined/g, ''): '',
      'reason': reason? reason.replace(/undefined/g, ''): '',
      'date': createdAt,
      'field': field,
      'before': before,
      'after': after,
    },
    'expanded': true,
    'children': []
  };
}

/*
if a tag is removed old code was always saying it was the last tag as that removed 
so instead get the ids of all before and after tags and work out which aren't in both
as and individual tag cannot be edited no need to check for changes to a tag
*/
function getTagSubItemsFromDiff(user: string, reason: string, createdAt: string, tagsDiff: any): TreeNode[] {
  if (tagsDiff['_t'] !== 'a') {
    return undefined;
  }
  const subItems: TreeNode[] = [];
  interface TagDetails {tagName: string, expiryDate?: string};
  const tagsAfterUpdate: {[tagId: string]: TagDetails} = {};
  const tagsBeforeUpdate: {[tagId: string]: TagDetails} = {};
  for (const [index, value] of Object.entries<any>(tagsDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      const tag: any = value[0];
      if (!tag) {
        // Could be a removal and addition done in one update
        if (value.tagID && Array.isArray(value.tagID) && (value.tagID.length > 1)) {
          /*
            The tag from the database will have an object in tagID the new tag will have and ObjectId string
            If both ids are the same then it's not a change and just the differnt format the CRM sends Tags
            back to the server compared to what the API serves up
          */
          const oldTagId: string = value.tagID[0]._id;
          const newTagId: string|Object = value.tagID[1];
          if ((typeof(newTagId) !== 'string') || (oldTagId === newTagId)) {
            continue;
          }
          
          const oldTagName: string = value.tagID[0].tagName;
          let newTagName: string = undefined;
          // The new tag will have the name in the content field
          if (value.content && Array.isArray(value.content) && (value.content.length > 0)) {
            /*
              Really the after should be in the second entry, but it looks like if the before is undefined
              it is in the first entry
            */
            if (value.content.length === 2) {
              newTagName = value.content[1];
            } else {
              newTagName = value.content[0];
            }
          }
          if (oldTagName) {
            const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'removed', oldTagName, undefined);  
            subItems.push(subItem);
          }
          if (newTagName) {
            const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'added', undefined, newTagName);  
            subItems.push(subItem);
          }
        }
        continue;
      }
      const tagId: string = (typeof tag.tagID === 'string')? tag.tagID: tag.tagID._id;
      let tagName: string = (typeof tag.tagID === 'string')? tag.content: tag.tagID.tagName;
      if (!tagName) {
        tagName = 'Unknown tag';
      }
      const tagExpiry: string|undefined = tag.expiryDate? 
          moment.tz(tag.expiryDate, 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', undefined): undefined;
      const tagDetails: TagDetails = {
        'tagName': tagName,
        'expiryDate': tagExpiry,
      };
      if (AFTER_UPDATE_KEY.test(index)) {
        tagsAfterUpdate[tagId] = tagDetails;
      } else {
        tagsBeforeUpdate[tagId] = tagDetails;
      }
    } catch(err) {
      console.error('Error processing tag diffs. Error:', err);
    }
  }
  for (const [tagId, tag] of Object.entries<TagDetails>(tagsAfterUpdate)) {
    if (tagsBeforeUpdate[tagId]) {
      // Tag existed before and after, so ignore
      delete tagsBeforeUpdate[tagId];
    } else {
      // It was added
      const addedSubItem: TreeNode = getTreeItem(user, reason, createdAt, 'added', '', tag.tagName);
      if (tag.expiryDate) {
        const expiryDateDetails: TreeNode = getTreeItem(user, reason, createdAt, 'expiryDate', '', tag.expiryDate);
        addedSubItem.children.push(expiryDateDetails);
      }
      subItems.push(addedSubItem);
    }
  }
  for (const [_tagId, tag] of Object.entries<TagDetails>(tagsBeforeUpdate)) {
    // As we deleted the tags that existed afterwards these are all removed tags
    const removedSubItem: TreeNode = getTreeItem(user, reason, createdAt, 'removed', tag.tagName, '');
    if (tag.expiryDate) {
      const expiryDateDetails: TreeNode = getTreeItem(user, reason, createdAt, 'expiryDate', tag.expiryDate, '');
      removedSubItem.children.push(expiryDateDetails);
    }
    subItems.push(removedSubItem);
  }
  return subItems;
}

/* 
  if a piece of equipment other than the last is removed old code was showing a change to equipment from
  that position onwards 
  e.g. if you removed 1st of 3 it showed 1st changing to what was 2nd, and 2nd changing to what was 3rd and
  3rd being removed
*/
function getEquipmentSubItemsFromDiff(user: string, reason: string, createdAt: string, equipmentDiff: any): TreeNode[] {
  if (equipmentDiff['_t'] !== 'a') {
    return undefined;
  }
  const subItems: TreeNode[] = [];
  // Capture the important details for displaying on the change log

  const equipAfterUpdate: Equipment[] = [];
  const equipBeforeUpdate: Equipment[] = [];
  for (const [index, value] of Object.entries<any>(equipmentDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      const equipment: Equipment = value[0];
      if (!equipment) {
        // Could be a partial update
        let partialUpdateFound: boolean = false;
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, index, undefined, undefined);
        for (let [keyPartial, valuePartial] of Object.entries(value)) {
          if ((value['_t'] !== 'a') && Array.isArray(valuePartial)) {
            if ((valuePartial.length == 1) && (valuePartial[0] !== '') && (valuePartial[0] != null)) {
              let before: string = '';
              let after: string;
              if (keyPartial == 'added') {
                after = valuePartial[0]?
                    moment(valuePartial[0]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
              } else if (keyPartial == 'freeOfCharge') {
                after = valuePartial[0]? 'Yes': 'No';
              } else {
                // don't show if after is 0 or empty
                if (valuePartial[0]) {
                  after = valuePartial[0];
                }
              }
              if (!!after) {
                const partialUpdate: TreeNode = getTreeItem(user, reason, createdAt, keyPartial, before, after);
                subItem.children.push(partialUpdate);
                partialUpdateFound = true;
              }
            } else if (valuePartial.length == 2) {
              let before: string = valuePartial[0];
              let after: string = valuePartial[1];
              if (keyPartial == 'added') {
                before = valuePartial[0]?
                    moment(valuePartial[0]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
                after = valuePartial[1]?
                    moment(valuePartial[1]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
              } else if (keyPartial == 'freeOfCharge') {
                before = valuePartial[0]? 'Yes': 'No';
                after = valuePartial[1]? 'Yes': 'No';
              }

              // Only show if they differ and at least one has a value
              if ((before != after) && (valuePartial[0] || valuePartial[1])) {
                const partialUpdate: TreeNode = getTreeItem(user, reason, createdAt, keyPartial, before, after);
                subItem.children.push(partialUpdate);
                partialUpdateFound = true;    
              }
            }
          }
        }
        if (partialUpdateFound) {
          subItems.push(subItem);
        }
        continue;
      }
      if (AFTER_UPDATE_KEY.test(index)) {
        equipment.index = index;
        equipAfterUpdate.push(equipment);
      } else {
        equipment.index = index.replace('_', '');
        equipBeforeUpdate.push(equipment);
      }
    } catch(err) {
      console.error('Error processing equipment diffs. Error:', err);
    }
  }
  if (equipAfterUpdate.length === equipBeforeUpdate.length) {
    // A piece of equipment has a change - as positions won't have changed can use the same index in both
    equipAfterUpdate.forEach((after: Equipment, index: number) => {
      try {
        const changes: TreeNode[] = [];
        const before: Equipment = equipBeforeUpdate[index];
        // no field is going to be removed by the update (_id potentially only appears in the before, but we want to exclude that anyway)
        const fields: string[] = Object.getOwnPropertyNames(after);
        fields.forEach((field: string) => {
          if ((field === '_id') || (field === 'index')) {
            return;
          }
          let beforeValue: string = before[field];
          let afterValue: string = after[field];
          if (field == 'added') {
            beforeValue = beforeValue?
                moment(beforeValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
            afterValue = afterValue?
                moment(afterValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
          } else if (field == 'freeOfCharge') {
            beforeValue = beforeValue? 'Yes': 'No';
            afterValue = afterValue? 'Yes': 'No';
          }
          if ((beforeValue != afterValue) && (beforeValue || afterValue)) {
            const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, field, beforeValue, afterValue);
            changes.push(changeDetails);
          }
        });
        if (changes.length > 0) {
          const subItem: TreeNode = getTreeItem(user, reason, createdAt, before.index, undefined, undefined);
          subItem.children = changes;
          subItems.push(subItem);
        }
      } catch(err) {
        console.error('Error processing equipment change. Error:', err);
      }
    });
  } else {
    let largestEquipArray: Equipment[];
    let smallestEquipArray: Equipment[];
    let change: string;
    if (equipAfterUpdate.length > equipBeforeUpdate.length) {
      change = 'added';
      largestEquipArray = equipAfterUpdate;
      smallestEquipArray = equipBeforeUpdate;
    } else {
      change = 'removed';
      largestEquipArray = equipBeforeUpdate;
      smallestEquipArray = equipAfterUpdate;
    }
    largestEquipArray.forEach((equip1: Equipment) => {
      try {
        const matchingIndex: number = smallestEquipArray.findIndex(
          (equip2: Equipment) => ((equip1.equipment === equip2.equipment) && (equip1.serial === equip2.serial) && (equip1.status === equip2.status) &&
            (equip1.serialPendant === equip2.serialPendant) && (equip1.statusPendant === equip2.statusPendant)));
        if (matchingIndex < 0) {
          // No match, so record addition/removal
          const before: string = (change === 'added')? '': equip1.equipment;
          const after: string = (change === 'added')? equip1.equipment: '';
          const subItem: TreeNode = getTreeItem(user, reason, createdAt, change, before, after);
          // Show the key details of the before/after
          if (equip1.serial) {
            const serialBefore: string = (change === 'added')? '': equip1.serial;
            const serialAfter: string = (change === 'added')? equip1.serial: '';
            const serialDetails: TreeNode = getTreeItem(user, reason, createdAt, 'serial', serialBefore, serialAfter);
            subItem.children.push(serialDetails);
          }
          if (equip1.status) {
            const statusBefore: string = (change === 'added')? '': equip1.status;
            const statusAfter: string = (change === 'added')? equip1.status: '';
            const statusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', statusBefore, statusAfter);
            subItem.children.push(statusDetails);
          }
          if (equip1.serialPendant) {
            const serialBefore: string = (change === 'added')? '': equip1.serialPendant;
            const serialAfter: string = (change === 'added')? equip1.serialPendant: '';
            const serialDetails: TreeNode = getTreeItem(user, reason, createdAt, 'serialPendant', serialBefore, serialAfter);
            subItem.children.push(serialDetails);
          }
          if (equip1.statusPendant) {
            const statusBefore: string = (change === 'added')? '': equip1.status;
            const statusAfter: string = (change === 'added')? equip1.status: '';
            const statusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'statusPendant', statusBefore, statusAfter);
            subItem.children.push(statusDetails);
          }
          if (equip1.baseUnitYearMade) {
            const yearMadeBefore: string = (change === 'added')? '': `${equip1.baseUnitYearMade}`;
            const yearMadeAfter: string = (change === 'added')? `${equip1.baseUnitYearMade}`: '';
            const yearMadeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'baseUnitYearMade', yearMadeBefore, yearMadeAfter);
            subItem.children.push(yearMadeDetails);
          }
          if (equip1.pendantYearMade) {
            const yearMadeBefore: string = (change === 'added')? '': `${equip1.pendantYearMade}`;
            const yearMadeAfter: string = (change === 'added')? `${equip1.pendantYearMade}`: '';
            const yearMadeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'pendantYearMade', yearMadeBefore, yearMadeAfter);
            subItem.children.push(yearMadeDetails);
          }
          if (equip1.equipmentYearMade) {
            const yearMadeBefore: string = (change === 'added')? '': `${equip1.equipmentYearMade}`;
            const yearMadeAfter: string = (change === 'added')? `${equip1.equipmentYearMade}`: '';
            const yearMadeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'equipmentYearMade', yearMadeBefore, yearMadeAfter);
            subItem.children.push(yearMadeDetails);
          }
          if (equip1.added) {
            const addedDateString: string = moment(equip1.added).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
            const addedDateBefore: string = (change === 'added')? '': addedDateString;
            const addedDateAfter: string = (change === 'added')? addedDateString: '';
            const addedDateDetails: TreeNode = getTreeItem(user, reason, createdAt, 'added', addedDateBefore, addedDateAfter);
            subItem.children.push(addedDateDetails);
          }
          if (equip1.freeOfCharge != null) {
            const fOfCString: string = equip1.freeOfCharge? 'Yes': 'No';
            const fOfCBefore: string = (change === 'added')? '': fOfCString;
            const fOfCAfter: string = (change === 'added')? fOfCString: '';
            const fOfCDetails: TreeNode = getTreeItem(user, reason, createdAt, 'freeOfCharge', fOfCBefore, fOfCAfter);
            subItem.children.push(fOfCDetails);
          }
          subItems.push(subItem);
        } else {
          // Remove the match from the smallest array, so we don't match against the same record again.
          smallestEquipArray.splice(matchingIndex, 1);
        }
      } catch(err) {
        console.error('Error processing equipment addition/removal. Error:', err);
      }
    });
  }
  return subItems;
}

function getServiceSubItemsFromDiff(user: string, reason: string, createdAt: string, servicesDiff: any): TreeNode[] {
  if (servicesDiff['_t'] !== 'a') {
    return undefined;
  }
  let subItems: TreeNode[] = [];
  // Capture the important details for displaying on the change log
  const servicesAfterUpdate: ServiceChange[] = [];
  const servicesBeforeUpdate: ServiceChange[] = [];
  for (const [index, value] of Object.entries<any>(servicesDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      const serviceChange: ServiceChange = value[0];
      if (!serviceChange) {
        const afterChange: ServiceChange = {
          'index': index,
          'partial': true,
        };
        const beforeChange: ServiceChange = {
          'index': index,
          'partial': true,
        };
        for (let [keyPartial, valuePartial] of Object.entries(value)) {
          // Check for keys we are interested in
          if (!['serviceId', 'status', 'added', 'serviceName'].includes(keyPartial)) {
            continue;
          }
          if ((value['_t'] !== 'a') && Array.isArray(valuePartial)) {
            let before: any;
            // Exclude 0 from being shown
            let after: any;
            if ((valuePartial.length == 1) && !!valuePartial[0]) {
              // If only one entry in the array it's the after (really helpful as normally 2nd item is after!!)
              if (!!beforeChange[keyPartial]) {
                // we've already populated it
                before = beforeChange[keyPartial];
              } else {
                before = '';
              }
              after = valuePartial[0];
            } else if (valuePartial.length == 2) {
              before = !!valuePartial[0]? valuePartial[0]: '';
              after = !!valuePartial[1]? valuePartial[1]: '';
            }
            if (keyPartial == 'serviceId') {
              if (!!before && (typeof before != 'string')) {
                if (!beforeChange.serviceName) {
                  beforeChange.serviceName = before.title;
                }
                before = before._id;
              }
              if (!!after && (typeof after != 'string')) {
                after = after._id;
              }
            }
            beforeChange[keyPartial] = before;
            afterChange[keyPartial] = after;
          }
        }
        servicesBeforeUpdate.push(beforeChange);
        servicesAfterUpdate.push(afterChange);
        continue;
      }
      if (AFTER_UPDATE_KEY.test(index)) {
        serviceChange.index = index;
        servicesAfterUpdate.push(serviceChange);
      } else {
        serviceChange.index = index.replace('_', '');
        servicesBeforeUpdate.push(serviceChange);
      }
    } catch(err) {
      console.error('Error processing Services diffs. Error:', err);
    }
  }
  subItems = subItems.concat(getServiceChangesFromBeforeAndAfterArrays(user, reason, createdAt, servicesBeforeUpdate, servicesAfterUpdate));
  return subItems;
}

function getServiceChangesFromBeforeAndAfterArrays(user: string, reason: string, createdAt: string,
    servicesBeforeUpdate: ServiceChange[], servicesAfterUpdate: ServiceChange[]): TreeNode[] {
  const subItems: TreeNode[] = [];
  servicesAfterUpdate.forEach((after: ServiceChange, index: number) => {
    try {
      const changes: TreeNode[] = [];
      // Nothing on the discount is allowed to change, but discountExpiry might not be set
      const matchingIndex: number = servicesBeforeUpdate.findIndex((before: ServiceChange) => {
        const beforeId: string = (typeof before.serviceId == 'string')? before.serviceId: before.serviceId._id;
        const afterId: string = (typeof after.serviceId == 'string')? after.serviceId: after.serviceId._id;
        return afterId == beforeId;
      });
      if (matchingIndex < 0) {
        // No match, so record addition
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'added', '', after.index? after.index: index.toString(10));
        // Show the key details of the before/after
        if (!!after.serviceName) {
          const serviceNameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'serviceName', '', after.serviceName);
          subItem.children.push(serviceNameDetails);
        }
        if (!!after.status) {
          const serviceStatusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', '', after.status);
          subItem.children.push(serviceStatusDetails);
        }
        subItems.push(subItem);
      } else {
        const before: ServiceChange = servicesBeforeUpdate[matchingIndex];
        // no field is going to be removed by the update (_id potentially only appears in the before, but we want to exclude that anyway)
        const fields: string[] = Object.getOwnPropertyNames(after);
        let hasChange: boolean = false;
        fields.forEach((field: string) => {
          if (!['serviceId', 'status', 'added', 'serviceName'].includes(field)) {
            return;
          }
          let beforeValue: string = before[field];
          if ((field === 'serviceId') && (typeof beforeValue != 'string')) {
            beforeValue = (before.serviceId as AccountServiceId)._id;
          }
          let afterValue: string = after[field];
          if ((beforeValue == undefined) && before.partial) {
            // Field was not in the partial data, so unchanged
            beforeValue = afterValue;
          }
          if (field == 'added') {
            beforeValue = beforeValue?
                moment(beforeValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
            afterValue = afterValue?
                moment(afterValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
          }
          // Force the service name to always be included, if in the record
          if (field === 'serviceName') {
            // Service name only exists in after values, so use for both before and after
            const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, field, afterValue, afterValue);
            changes.push(changeDetails);
          } else if ((beforeValue != afterValue) && (beforeValue || afterValue)) {
            if (field !== 'index') {
              hasChange = true;
            }
            const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, field, beforeValue, afterValue);
            changes.push(changeDetails);
          }
        });
        if (hasChange) {
          const subItem: TreeNode = getTreeItem(user, reason, createdAt, before.index? before.index: index.toString(10), undefined, undefined);
          subItem.children = changes;
          subItems.push(subItem);
        }
        // Remove the match from the before array, so we don't match against the same record again.
        servicesBeforeUpdate.splice(matchingIndex, 1);
      }
    } catch(err) {
      console.error('Error processing Services after records. Error:', err);
    }
  });
  servicesBeforeUpdate.forEach((before: ServiceChange, index: number) => {
    try {
      const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'removed', before.index? before.index: index.toString(10), '');
      // Show the key details of the before/after
      if (!!before.serviceName) {
        const serviceNameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'serviceName', before.serviceName, '');
        subItem.children.push(serviceNameDetails);
      } else if (typeof before.serviceId != 'string') {
        const serviceNameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'serviceName', before.serviceId.title, '');
        subItem.children.push(serviceNameDetails);
      }
      if (!!before.status) {
        const serviceStatusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', before.status, '');
        subItem.children.push(serviceStatusDetails);
      } 
      subItems.push(subItem);
    } catch(err) {
      console.error('Error processing Services before records. Error:', err);
    }
  });
  return subItems;
}

function getKeySafeSubItemsFromDiff(user: string, reason: string, createdAt: string, keySafeDiff: any): TreeNode[] {
  if (keySafeDiff['_t'] !== 'a') {
    return undefined;
  }
  let subItems: TreeNode[] = [];
  // Capture the important details for displaying on the change log
  const keySafeAfterUpdate: KeySafeChange[] = [];
  const keySafeBeforeUpdate: KeySafeChange[] = [];
  for (const [index, value] of Object.entries<any>(keySafeDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      const keySafe: KeySafeChange = value[0];
      if (!keySafe) {
        // Could be a partial update
        let partialUpdateFound: boolean = false;
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, index, undefined, undefined);
        for (let [keyPartial, valuePartial] of Object.entries(value)) {
          if ((value['_t'] !== 'a') && Array.isArray(valuePartial)) {
            if ((valuePartial.length == 1) && (valuePartial[0] !== '') && (valuePartial[0] != null)) {
              let before: string = '';
              let after: string;
              if (keyPartial == 'added') {
                after = valuePartial[0]? 
                    moment.tz(valuePartial[0], 'Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
              } else {
                // don't show if after is 0 or empty
                if (valuePartial[0]) {
                  after = valuePartial[0];
                }
              }
              if (!!after) {
                const partialUpdate: TreeNode = getTreeItem(user, reason, createdAt, keyPartial, before, after);
                subItem.children.push(partialUpdate);
                partialUpdateFound = true;
              }
            } else if (valuePartial.length == 2) {
              let before: any = valuePartial[0];
              let after: any = valuePartial[1];
              if (keyPartial == 'added') {
                before = valuePartial[0]? 
                    moment.tz(valuePartial[0], 'Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
                after = valuePartial[1]? 
                    moment.tz(valuePartial[1], 'Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
              }
              // Only show if they differ and at least one has a value
              if ((before != after) && (valuePartial[0] || valuePartial[1])) {
                const partialUpdate: TreeNode = getTreeItem(user, reason, createdAt, keyPartial, before, after);
                subItem.children.push(partialUpdate);
                partialUpdateFound = true;    
              }
            }
          }
        }
        if (partialUpdateFound) {
          subItems.push(subItem);
        }
        continue;
      }
      if (AFTER_UPDATE_KEY.test(index)) {
        keySafe.index = index;
        keySafeAfterUpdate.push(keySafe);
      } else {
        keySafe.index = index.replace('_', '');
        keySafeBeforeUpdate.push(keySafe);
      }
    } catch(err) {
      console.error('Error processing Key Safe diffs. Error:', err);
    }
  }
  subItems = subItems.concat(getKeySafeChangesFromBeforeAndAfterArrays(user, reason, createdAt, keySafeBeforeUpdate, keySafeAfterUpdate));
  return subItems;
}

function getKeySafeChangesFromBeforeAndAfterArrays(user: string, reason: string, createdAt: string,
    beforeKeySafes: KeySafeChange[], afterKeySafes: KeySafeChange[]): TreeNode[] {
  const subItems: TreeNode[] = [];
  afterKeySafes.forEach((after: KeySafeChange, index: number) => {
    try {
      const changes: TreeNode[] = [];
      // The only things that can't have changed on key safes is equipment (name/description)
      const matchingIndex: number = beforeKeySafes.findIndex((before: KeySafeChange) => {
        return ((after.equipment === before.equipment));
      });
      if (matchingIndex < 0) {
        // No match, so record addition
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'added', '', after.index? after.index: index.toString(10));
        // Show the key details of the before/after
        if (after.equipment) {
          const nameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'equipment', '', after.equipment);
          subItem.children.push(nameDetails);
        }
        if (after.status) {
          const statusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', '', after.status);
          subItem.children.push(statusDetails);
        }
        if (after.added) {
          const addedDateString: string = moment(after.added).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
          const addedDetails: TreeNode = getTreeItem(user, reason, createdAt, 'added', '', addedDateString);
          subItem.children.push(addedDetails);
        }
        subItems.push(subItem);
      } else {
        const before: KeySafeChange = beforeKeySafes[matchingIndex];
        // no field is going to be removed by the update (_id potentially only appears in the before, but we want to exclude that anyway)
        const fields: string[] = Object.getOwnPropertyNames(after);
        let hasChange: boolean = false;
        fields.forEach((field: string) => {
          // Added cannot be changed, so no point checking
          if ((field === '_id') || (field === 'index')) {
            return;
          }
          let beforeValue: string = before[field];
          let afterValue: string = after[field];
          if (field == 'added') {
            beforeValue = beforeValue?
                moment(beforeValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
            afterValue = afterValue?
                moment(afterValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
          }
          // Force the equipment name to always be included, if in the record
          if (field === 'equipment') {
            const nameDetails: TreeNode = getTreeItem(user, reason, createdAt, field, beforeValue, afterValue);
            changes.push(nameDetails);
          } else if ((beforeValue != afterValue) && (beforeValue || afterValue)) {
            hasChange = true;
            const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, field, beforeValue, afterValue);
            changes.push(changeDetails);
          }
        });
        if (hasChange) {
          const subItem: TreeNode = getTreeItem(user, reason, createdAt, before.index? before.index: index.toString(10), undefined, undefined);
          subItem.children = changes;
          subItems.push(subItem);
        }
        // Remove the match from the before array, so we don't match against the same record again.
        beforeKeySafes.splice(matchingIndex, 1);
      }
    } catch(err) {
      console.error('Error processing Key Safe after records. Error:', err);
    }
  });
  beforeKeySafes.forEach((before: KeySafeChange, index: number) => {
    try {
      const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'removed', before.index? before.index: index.toString(10), '');
      // Show the key details of the before/after
      if (before.equipment) {
        const nameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'equipment', before.equipment, '');
        subItem.children.push(nameDetails);
      }
      if (before.status) {
        const statusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', before.status, '');
        subItem.children.push(statusDetails);
      }
      if (before.added) {
        const addedDateString: string = moment(before.added).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
        const addedDetails: TreeNode = getTreeItem(user, reason, createdAt, 'added', addedDateString, '');
        subItem.children.push(addedDetails);
      }
      subItems.push(subItem);
    } catch(err) {
      console.error('Error processing Key Safe before records. Error:', err);
    }
  });
  return subItems;
}

function getHardwareSubItemsFromDiff(user: string, reason: string, createdAt: string, hardwareDiff: any): TreeNode[] {
  if (hardwareDiff['_t'] !== 'a') {
    return undefined;
  }
  let subItems: TreeNode[] = [];
  // Capture the important details for displaying on the change log
  const hardwareAfterUpdate: HardwareChange[] = [];
  const hardwareBeforeUpdate: HardwareChange[] = [];
  for (const [index, value] of Object.entries<any>(hardwareDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      const hardware: HardwareChange = value[0];
      if (!hardware) {
        // Could be a partial update
        const afterChange: HardwareChange = {
          'index': index,
          'partial': true,
        };
        const beforeChange: HardwareChange = {
          'index': index,
          'partial': true,
        };
        for (let [keyPartial, valuePartial] of Object.entries(value)) {
          // Check for keys we are interested in
          if (!['hardwareId', 'serial', 'status', 'added', 'hardwareName'].includes(keyPartial)) {
            continue;
          }
          if ((value['_t'] !== 'a') && Array.isArray(valuePartial)) {
            let before: any;
            // Exclude 0 from being shown
            let after: any;
            if ((valuePartial.length == 1) && !!valuePartial[0]) {
              // If only one entry in the array it's the after (really helpful as normally 2nd item is after!!)
              if (!!beforeChange[keyPartial]) {
                // we've already populated it
                before = beforeChange[keyPartial];
              } else {
                before = '';
              }
              after = valuePartial[0];
            } else if (valuePartial.length == 2) {
              before = !!valuePartial[0]? valuePartial[0]: '';
              after = !!valuePartial[1]? valuePartial[1]: '';
            }
            if (keyPartial == 'hardwareId') {
              if (!!before && (typeof before != 'string')) {
                if (!beforeChange.hardwareName) {
                  beforeChange.hardwareName = before.title;
                }
                before = before._id;
              }
              if (!!after && (typeof after != 'string')) {
                after = after._id;
              }
            }
            beforeChange[keyPartial] = before;
            afterChange[keyPartial] = after;
          }
        }
        hardwareBeforeUpdate.push(beforeChange);
        hardwareAfterUpdate.push(afterChange);
        continue;
      }
      if (AFTER_UPDATE_KEY.test(index)) {
        hardware.index = index;
        hardwareAfterUpdate.push(hardware);
      } else {
        hardware.index = index.replace('_', '');
        hardwareBeforeUpdate.push(hardware);
      }
    } catch(err) {
      console.error('Error processing hardware diffs. Error:', err);
    }
  }
  subItems = subItems.concat(getHardwareChangesFromBeforeAndAfterArrays(user, reason, createdAt, hardwareBeforeUpdate, hardwareAfterUpdate));
  return subItems;
}

function getHardwareChangesFromBeforeAndAfterArrays(user: string, reason: string, createdAt: string,
    beforeHardware: HardwareChange[], afterHardware: HardwareChange[]): TreeNode[] {
  const subItems: TreeNode[] = [];
  afterHardware.forEach((after: HardwareChange, index: number) => {
    try {
      const changes: TreeNode[] = [];
      // The only things that can't have changed on hardware is the id and when it was added
      const matchingIndex: number = beforeHardware.findIndex((before: HardwareChange) => {
        // The id is the Hardware record, if populated by mongoose
        const beforeId: string = (typeof before.hardwareId == 'string')? before.hardwareId: before.hardwareId._id;
        return ((after.hardwareId === beforeId) && (after.added === before.added));
      });
      if (matchingIndex < 0) {
        // No match, so record addition
        // After images should always have a name
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'added', '', after.index? after.index: index.toString(10));
        // Show the key details of the before/after
        if (after.hardwareName) {
          const nameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareName', '', after.hardwareName);
          subItem.children.push(nameDetails);
        }
        if (after.hardwareId) {
          let afterId: string;
          if (typeof after.hardwareId == 'string') {
            afterId = after.hardwareId;
          } else {
            afterId = after.hardwareId._id;
            const hardwareNameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareName', after.hardwareId.title, '');
            subItem.children.push(hardwareNameDetails);
          }
          const hardwareIdDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareId', '', afterId);
          subItem.children.push(hardwareIdDetails);
        }
        if (after.serial) {
          const serialDetails: TreeNode = getTreeItem(user, reason, createdAt, 'serial', '', after.serial);
          subItem.children.push(serialDetails);
        }
        if (after.status) {
          const statusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', '', after.status);
          subItem.children.push(statusDetails);
        }
        if (after.added) {
          const addedDateString: string = moment(after.added).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
          const addedDetails: TreeNode = getTreeItem(user, reason, createdAt, 'added', '', addedDateString);
          subItem.children.push(addedDetails);
        }
        subItems.push(subItem);
      } else {
        const before: HardwareChange = beforeHardware[matchingIndex];
        // no field is going to be removed by the update (_id potentially only appears in the before, but we want to exclude that anyway)
        const fields: string[] = Object.getOwnPropertyNames(after);
        let hasChange: boolean = false;
        fields.forEach((field: string) => {
          // Only include the fields we're interested in (excludes, and partial)
          if (!['hardwareId', 'serial', 'status', 'hardwareName', 'added'].includes(field)) {
            return;
          }
          let beforeValue: string = before[field];
          if ((field === 'hardwareId') && (typeof beforeValue != 'string')) {
            beforeValue = (before.hardwareId as HardwareId)._id;
          }
          let afterValue: string = after[field];
          if ((beforeValue == undefined) && before.partial) {
            // Field was not in the partial data, so unchanged
            beforeValue = afterValue;
          }
          if (field == 'added') {
            beforeValue = beforeValue?
                moment(beforeValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
            afterValue = afterValue?
                moment(afterValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
          }
          // Force the hardware name to always be included, if in the record
          if (field === 'hardwareName') {
            // Hardware name only exists in after values, so use for both before and after
            const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, field, afterValue, afterValue);
            changes.push(changeDetails);
          } else if ((beforeValue != afterValue) && (beforeValue || afterValue)) {
            if (field !== 'index') {
              hasChange = true;
            }
            const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, field, beforeValue, afterValue);
            changes.push(changeDetails);
          }
        });
        if (hasChange) {
          const subItem: TreeNode = getTreeItem(user, reason, createdAt, before.index? before.index: index.toString(10), undefined, undefined);
          subItem.children = changes;
          subItems.push(subItem);
        }
        // Remove the match from the before array, so we don't match against the same record again.
        beforeHardware.splice(matchingIndex, 1);
      }
    } catch(err) {
      console.error('Error processing hardware after records. Error:', err);
    }
  });
  beforeHardware.forEach((before: HardwareChange, index: number) => {
    try {
      const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'removed', before.index? before.index: index.toString(10), '');
      // Show the key details of the before/after
      if (before.hardwareId) {
        let beforeId: string;
        if (typeof before.hardwareId == 'string') {
          beforeId = before.hardwareId;
        } else {
          beforeId = before.hardwareId._id;
          const hardwareNameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareName', before.hardwareId.title, '');
          subItem.children.push(hardwareNameDetails);
        }
        const hardwareIdDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareId', beforeId, '');
        subItem.children.push(hardwareIdDetails);
      }
      if (before.hardwareName) {
        const hardwareNameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareName', before.hardwareName, '');
        subItem.children.push(hardwareNameDetails);
      }
      if (before.serial) {
        const serialDetails: TreeNode = getTreeItem(user, reason, createdAt, 'serial', before.serial, '');
        subItem.children.push(serialDetails);
      }
      if (before.status) {
        const statusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', before.status, '');
        subItem.children.push(statusDetails);
      }
      if (before.added) {
        const addedDateString: string = moment(before.added).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
        const addedDetails: TreeNode = getTreeItem(user, reason, createdAt, 'added', addedDateString, '');
        subItem.children.push(addedDetails);
      }
      subItems.push(subItem);
    } catch(err) {
      console.error('Error processing hardware before records. Error:', err);
    }
  });
  return subItems;
}

function getHardwareChangesOnSet(user: string, reason: string, createdAt: string,
    beforeHardware: HardwareChange[], afterHardware: HardwareChange[]): TreeNode {
  const hardwareNode: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareInSet', '', '');
  hardwareNode.children = getHardwareChangesFromBeforeAndAfterArrays(user, reason, createdAt, beforeHardware, afterHardware);
  return hardwareNode;
}

function getHardwareSetSubItemsFromDiff(user: string, reason: string, createdAt: string, hardwareSetsDiff: any): TreeNode[] {
  if (hardwareSetsDiff['_t'] !== 'a') {
    return undefined;
  }
  const subItems: TreeNode[] = [];
  // Capture the important details for displaying on the change log
  const hardwareSetsBeforeUpdate: HardwareSetsChange[] = [];
  const hardwareSetsAfterUpdate: HardwareSetsChange[] = [];
  for (const [index, value] of Object.entries<any>(hardwareSetsDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      const hardwareSet: HardwareSetsChange = value[0];
      if (!hardwareSet) {
        // Could be a partial update
        let partialUpdateFound: boolean = false;
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, index, undefined, undefined);
        for (let [keyPartial, valuePartial] of Object.entries(value)) {
          // if (keyPartial == 'category') {
          //   continue;
          // }
          if (keyPartial == 'hardwareInSet') {
            const hardwareInSetChanges: TreeNode[] = getHardwareSubItemsFromDiff(user, reason, createdAt, valuePartial);
            if (hardwareInSetChanges && (hardwareInSetChanges.length > 0)) {
              const hardwareNode: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareInSet', '', '');
              hardwareNode.children = hardwareInSetChanges;
              subItem.children.push(hardwareNode);
              partialUpdateFound = true;
            }
          } else if ((value['_t'] !== 'a') && Array.isArray(valuePartial)) {
            if ((valuePartial.length == 1) && (valuePartial[0] !== '') && (valuePartial[0] != null)) {
              let before: string = '';
              let after: string;
              // don't show if after is 0 or empty
              if (valuePartial[0]) {
                after = valuePartial[0];
              }
              if (!!after) {
                if (keyPartial != 'hardwareSetName') {
                  partialUpdateFound = true;
                } else {
                  // Partial update means the set is there before and after, so include the name on both
                  before = after;
                }
                const partialUpdate: TreeNode = getTreeItem(user, reason, createdAt, keyPartial, before, after);
                subItem.children.push(partialUpdate);
              }
            } else if (valuePartial.length == 2) {
              let before: any = valuePartial[0];
              let after: any = valuePartial[1];
              if (keyPartial == 'hardwareSetId') {
                if (typeof before != 'string') {
                  before = before._id;
                }
                if (typeof after != 'string') {
                  after = after._id;
                }
              } 
              // Only show if they differ and at least one has a value
              if ((before != after) && (valuePartial[0] || valuePartial[1])) {
                // Don't show on changelog if only change in hardwareSetName (which only exists on after, so always a change)
                if (keyPartial != 'hardwareSetName') {
                  partialUpdateFound = true;
                } else {
                  // Partial update means the set is there before and after, so include the name on both
                  before = after;
                }
                const partialUpdate: TreeNode = getTreeItem(user, reason, createdAt, keyPartial, before, after);
                subItem.children.push(partialUpdate);
              }
            }
          }
        }
        if (partialUpdateFound) {
          subItems.push(subItem);
        }

        continue;
      }
      if (AFTER_UPDATE_KEY.test(index)) {
        hardwareSet.index = index;
        hardwareSetsAfterUpdate.push(hardwareSet);
      } else {
        hardwareSet.index = index.replace('_', '');
        hardwareSetsBeforeUpdate.push(hardwareSet);
      }
    } catch(err) {
      console.error('Error processing hardware set diffs. Error:', err);
    }
  }
  hardwareSetsAfterUpdate.forEach((after: HardwareSetsChange) => {
    try {
      const changes: TreeNode[] = [];
      // The only thing that can't have changed on hardware set is the id
      const matchingIndex: number = hardwareSetsBeforeUpdate.findIndex((before: HardwareSetsChange) => {
        // The id is the Hardware record, if populated by mongoose
        const beforeId: string = (typeof before.hardwareSetId == 'string')? before.hardwareSetId: before.hardwareSetId._id;
        return (after.hardwareSetId === beforeId);
      });
      if (matchingIndex < 0) {
        // No match, so record addition
        // After images should always have a name
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'added', '', after.hardwareSetName);
        after.hardwareInSet.forEach((hardwareChange: HardwareChange) => {
          // Show the key details of the before/after
          if (hardwareChange.hardwareName) {
            const nameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareName', '', hardwareChange.hardwareName);
            subItem.children.push(nameDetails);
          }
          if (hardwareChange.serial) {
            const serialDetails: TreeNode = getTreeItem(user, reason, createdAt, 'serial', '', hardwareChange.serial);
            subItem.children.push(serialDetails);
          }
          if (hardwareChange.status) {
            const statusDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', '', hardwareChange.status);
            subItem.children.push(statusDetails);
          }
        });
        subItems.push(subItem);
      } else {
        const before: HardwareSetsChange = hardwareSetsBeforeUpdate[matchingIndex];
        // no field is going to be removed by the update (_id potentially only appears in the before, but we want to exclude that anyway)
        const fields: string[] = Object.getOwnPropertyNames(after);
        let hasChange: boolean = false;
        fields.forEach((field: string) => {
          // Added cannot be changed, so no point checking
          if ((field === '_id') || (field === 'added') || (field === 'index')) {
            return;
          }
          let beforeValue: string = before[field];
          if ((field === 'hardwareSetId') && (typeof beforeValue != 'string')) {
            beforeValue = (before.hardwareSetId as HardwareSetId)._id;
          }
          const afterValue: string = after[field];
          // Force the hardware name to always be included, if in the record
          if (field === 'hardwareSetName') {
            // Hardware name only exists in after values, so use for both before and after
            const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, field, afterValue, afterValue);
            changes.push(changeDetails);
          } else if (field === 'hardwareInSet') {
            const changeDetails: TreeNode = getHardwareChangesOnSet(user, reason, createdAt, before.hardwareInSet, after.hardwareInSet);
            if (changeDetails.children && (changeDetails.children.length > 0)) {
              hasChange = true;
              changes.push(changeDetails);
            }
          }
        });
        if (hasChange) {
          const subItem: TreeNode = getTreeItem(user, reason, createdAt, before.index, undefined, undefined);
          subItem.children = changes;
          subItems.push(subItem);
        }
        // Remove the match from the before array, so we don't match against the same record again.
        hardwareSetsBeforeUpdate.splice(matchingIndex, 1);
      }
    } catch(err) {
      console.error('Error processing hardware set after records. Error:', err);
    }
  });
  hardwareSetsBeforeUpdate.forEach((before: HardwareSetsChange) => {
    try {
      const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'removed', before.index, '');
      // Show the key details of the before/after
      if (before.hardwareSetId) {
        let beforeId: string;
        if (typeof before.hardwareSetId == 'string') {
          beforeId = before.hardwareSetId;
        } else {
          beforeId = before.hardwareSetId._id;
          const hardwareNameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareSetName', before.hardwareSetId.title, '');
          subItem.children.push(hardwareNameDetails);
        }
        const hardwareSetIdDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareSetId', beforeId, '');
        subItem.children.push(hardwareSetIdDetails);
      }
      if (before.hardwareSetName) {
        const hardwareSetNameDetails: TreeNode = getTreeItem(user, reason, createdAt, 'hardwareSetName', before.hardwareSetName, '');
        subItem.children.push(hardwareSetNameDetails);
      }
      subItems.push(subItem);
    } catch(err) {
      console.error('Error processing hardware set before records. Error:', err);
    }
  });
  return subItems;
}

function getRenewalDiscountSubItemsFromDiff(user: string, reason: string, createdAt: string, renewalDiscountDiff: any): TreeNode[] {
  if (renewalDiscountDiff['_t'] !== 'a') {
    return undefined;
  }
  let subItems: TreeNode[] = [];
  // Capture the important details for displaying on the change log
  const renewalDiscountAfterUpdate: RenewalDiscountChange[] = [];
  const renewalDiscountBeforeUpdate: RenewalDiscountChange[] = [];
  for (const [index, value] of Object.entries<any>(renewalDiscountDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      const renewalDiscount: RenewalDiscountChange = value[0];
      if (!renewalDiscount) {
        // nothing can change, so shouldn't have a partial update
        continue;
      }
      if (AFTER_UPDATE_KEY.test(index)) {
        renewalDiscount.index = index;
        renewalDiscountAfterUpdate.push(renewalDiscount);
      } else {
        renewalDiscount.index = index.replace('_', '');
        renewalDiscountBeforeUpdate.push(renewalDiscount);
      }
    } catch(err) {
      console.error('Error processing Renewal Discount diffs. Error:', err);
    }
  }
  subItems = subItems.concat(getRenewalDiscountChangesFromBeforeAndAfterArrays(user, reason, createdAt, renewalDiscountBeforeUpdate, renewalDiscountAfterUpdate));
  return subItems;
}

function getRenewalDiscountChangesFromBeforeAndAfterArrays(user: string, reason: string, createdAt: string,
    beforeRenewalDiscounts: RenewalDiscountChange[], afterRenewalDiscounts: RenewalDiscountChange[]): TreeNode[] {
  const subItems: TreeNode[] = [];
  afterRenewalDiscounts.forEach((after: RenewalDiscountChange, index: number) => {
    try {
      const changes: TreeNode[] = [];
      // Nothing on the discount is allowed to change, but discountExpiry might not be set
      const matchingIndex: number = beforeRenewalDiscounts.findIndex((before: RenewalDiscountChange) => {
        return ((after.discount === before.discount) && (after.discountExpiry === before.discountExpiry) &&
          (after.reason === before.reason) && (after.otherReasonText === before.otherReasonText));
      });
      if (matchingIndex < 0) {
        // No match, so record addition
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'added', '', after.index? after.index: index.toString(10));
        // Show the key details of the before/after
        if (!!after.discount) {
          const discountDetails: TreeNode = getTreeItem(user, reason, createdAt, 'discount', '', after.discount.toFixed(2));
          subItem.children.push(discountDetails);
        }
        if (!!after.discountExpiry) {
          const discountExpiryDetails: TreeNode = getTreeItem(user, reason, createdAt, 'discountExpiry', '', after.discountExpiry);
          subItem.children.push(discountExpiryDetails);
        }
        if (!!after.reason) {
          const reasonDetails: TreeNode = getTreeItem(user, reason, createdAt, 'reason', '', after.reason);
          subItem.children.push(reasonDetails);
        }
        if (!!after.otherReasonText) {
          const otherReasonTextDetails: TreeNode = getTreeItem(user, reason, createdAt, 'otherReasonText', '', after.otherReasonText);
          subItem.children.push(otherReasonTextDetails);
        }
        if (!!after.addedBy) {
          const otherReasonTextDetails: TreeNode = getTreeItem(user, reason, createdAt, 'addedBy', '', after.addedBy);
          subItem.children.push(otherReasonTextDetails);
        }
        
        subItems.push(subItem);
      } else {
        // Nothing can change, so just remove it from future matches.
        beforeRenewalDiscounts.splice(matchingIndex, 1);
      }
    } catch(err) {
      console.error('Error processing Renewal Discount after records. Error:', err);
    }
  });
  beforeRenewalDiscounts.forEach((before: RenewalDiscountChange, index: number) => {
    try {
      const subItem: TreeNode = getTreeItem(user, reason, createdAt, 'removed', before.index? before.index: index.toString(10), '');
      // Show the key details of the before/after
      if (!!before.discount) {
        const discountDetails: TreeNode = getTreeItem(user, reason, createdAt, 'discount', before.discount.toFixed(2), '');
        subItem.children.push(discountDetails);
      }
      if (!!before.discountExpiry) {
        const discountExpiryDetails: TreeNode = getTreeItem(user, reason, createdAt, 'discountExpiry', before.discountExpiry, '');
        subItem.children.push(discountExpiryDetails);
      }
      if (!!before.reason) {
        const reasonDetails: TreeNode = getTreeItem(user, reason, createdAt, 'reason', before.reason, '');
        subItem.children.push(reasonDetails);
      }
      if (!!before.otherReasonText) {
        const otherReasonTextDetails: TreeNode = getTreeItem(user, reason, createdAt, 'otherReasonText', before.otherReasonText, '');
        subItem.children.push(otherReasonTextDetails);
      }
      if (!!before.addedBy) {
        const otherReasonTextDetails: TreeNode = getTreeItem(user, reason, createdAt, 'addedBy', before.addedBy, '');
        subItem.children.push(otherReasonTextDetails);
      }
      subItems.push(subItem);
    } catch(err) {
      console.error('Error processing Renewal Discount before records. Error:', err);
    }
  });
  return subItems;
}

/*
  if a VIM or Note is removed old code was always saying it was the last one that was removed 
  and showing all of the ones after the removed one as having shuffled up
*/
function getVimOrNoteSubItemsFromDiff(user: string, reason: string, createdAt: string, vimOrNoteDiff: any): TreeNode[] {
  if (vimOrNoteDiff['_t'] !== 'a') {
    return undefined;
  }
  const subItems: TreeNode[] = [];
  const messagesAfterUpdate: VimOrNote[] = [];
  const messagesBeforeUpdate: VimOrNote[] = [];
  for (const [index, value] of Object.entries<any>(vimOrNoteDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      if (Array.isArray(value)) {
        const message: VimOrNote = value[0];
        if (!message) {
          continue;
        }
        if (AFTER_UPDATE_KEY.test(index)) {
          messagesAfterUpdate.push(message);
        } else {
          messagesBeforeUpdate.push(message);
        }
      } else {
        let beforeNoteParts: VimOrNote;
        let afterNoteParts: VimOrNote;
        if (!value['categories'] || Array.isArray(value['categories'])) {
          // Note/VIM edit
          beforeNoteParts = {
            'content': value['content']? value['content'][0] : '',
            'categories': value['categories']? value['categories'][0] : [],
          };
          afterNoteParts = {
            'content': value['content']? value['content'][1] : '',
            'categories': value['categories']? value['categories'][1] : [],
          };
        } else {
          // Note/VIM edit
          const beforeCategories: string[] = [];
          const afterCategories: string[] = [];
          for (const [catIndex, catValue] of Object.entries<any>(value['categories'])) {
            if (AFTER_UPDATE_KEY.test(catIndex)) {
              afterCategories.push(catValue[0]);
            } else if (BEFORE_UPDATE_KEY.test(catIndex)) {
              beforeCategories.push(catValue[0]);
            }
          }
          beforeNoteParts = {
            'content': value['content']? value['content'][0] : '',
            'categories': beforeCategories,
          };
          afterNoteParts = {
            'content': value['content']? value['content'][1] : '',
            'categories': afterCategories,
          };
        }
        if ((beforeNoteParts.content != afterNoteParts.content) ||
            (beforeNoteParts.categories.join('; ') != afterNoteParts.categories.join('; '))) {
          messagesAfterUpdate.push(afterNoteParts);
          messagesBeforeUpdate.push(beforeNoteParts);
        }
      }
    } catch(err) {
      console.error('Error processing VIM or Note diffs. Error:', err);
    }
  }
  if (messagesAfterUpdate.length === messagesBeforeUpdate.length) {
    // A note has a change - as positions won't have changed can use the same index in both
    messagesAfterUpdate.forEach((after: VimOrNote, index: number) => {
      try {
        const before: VimOrNote = messagesBeforeUpdate[index];
        const categoriesBefore: string = before.categories? before.categories.join('; '): '';
        const categoriesAfter: string = after.categories? after.categories.join('; '): '';
        if (before.content !== after.content) {
          const subItem: TreeNode = 
              getTreeItem(user, reason, createdAt, 'updated', before.content, after.content);
          subItems.push(subItem);
        } else if (categoriesBefore !== categoriesAfter) {
          let changeType: string = 'replaced';
          if (!categoriesBefore) {
            changeType = 'added';
          } else if (!categoriesAfter) {
            changeType = 'deleted';
          }
          const subItem: TreeNode = 
              getTreeItem(user, reason, createdAt, changeType, before.content, after.content);
          const categoriesDetails: TreeNode = getTreeItem(user, reason, createdAt, 'categories', categoriesBefore, categoriesAfter);
          subItem.children.push(categoriesDetails);
          subItems.push(subItem);
        }
      } catch(err) {
        console.error('Error processing note change. Error:', err);
      }
    });
  } else {
    let largestMessagepArray: VimOrNote[];
    let smallestMessageArray: VimOrNote[];
    let change: string;
    if (messagesAfterUpdate.length > messagesBeforeUpdate.length) {
      change = 'added';
      largestMessagepArray = messagesAfterUpdate;
      smallestMessageArray = messagesBeforeUpdate;
    } else {
      change = 'removed';
      largestMessagepArray = messagesBeforeUpdate;
      smallestMessageArray = messagesAfterUpdate;
    }
    largestMessagepArray.forEach((message1: VimOrNote) => {
      try {
        const matchingIndex: number = smallestMessageArray.findIndex(
          (message2: VimOrNote) => ((message1.content === message2.content) && (message1.date === message2.date) && (message1.userName === message2.userName))
        );
        if (matchingIndex < 0) {
          // No match, so record addition/removal
          const before: string = (change === 'added')? '': message1.content;
          const after: string = (change === 'added')? message1.content: '';
          const subItem: TreeNode = getTreeItem(user, reason, createdAt, change, before, after);
          // Show the key details of the before/after
          if (message1.categories && (message1.categories.length > 0)) {
            const categoriesBefore: string = (change === 'added')? '': message1.categories.join('; ');
            const categoriesAfter: string = (change === 'added')? message1.categories.join('; '): '';
            const categoriesDetails: TreeNode = getTreeItem(user, reason, createdAt, 'categories', categoriesBefore, categoriesAfter);
            subItem.children.push(categoriesDetails);
          }
          subItems.push(subItem);
        } else {
          // Remove the match from the smallest array, so we don't match against the same record again.
          smallestMessageArray.splice(matchingIndex, 1);
        }
      } catch(err) {
        console.error('Error processing VIM or Note addition/removal. Error:', err);
      }
    });
  }
  return subItems;
}

function getActionSubItemForAdditionOrRemoval(user: string, reason: string, createdAt: string, action: ActionChange, change: string): TreeNode {
  const before: string = (change === 'added')? '': action.outstandingName;
  const after: string = (change === 'added')? action.outstandingName: '';
  const subItem: TreeNode = getTreeItem(user, reason, createdAt, change, before, after);
  // Show the key details of the before/after
  if (action.actionInitiatedDate) {
    action.actionInitiatedDate = action.actionInitiatedDate? 
        moment(action.actionInitiatedDate).tz('Europe/London').format('DD/MM/YYYY').replace('Invalid date', ''): undefined;
    const beforeValue: string = (change === 'added')? '': action.actionInitiatedDate;
    const afterValue: string = (change === 'added')? action.actionInitiatedDate: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'actionInitiatedDate', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.renewalDateTaken) {
    action.renewalDateTaken = action.renewalDateTaken?
        moment(action.renewalDateTaken).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
    const beforeValue: string = (change === 'added')? '': action.renewalDateTaken;
    const afterValue: string = (change === 'added')? action.renewalDateTaken: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'actionDueDate', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.holdUntil) {
    action.holdUntil = action.holdUntil?
        moment.tz(action.holdUntil, 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', ''): undefined;
    const beforeValue: string = (change === 'added')? '': action.holdUntil
    const afterValue: string = (change === 'added')? action.holdUntil: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'holdUntil', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.personReturning) {
    const beforeValue: string = (change === 'added')? '': action.personReturning
    const afterValue: string = (change === 'added')? action.personReturning: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'personReturning', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.cancellationEmail) {
    const beforeValue: string = (change === 'added')? '': action.cancellationEmail
    const afterValue: string = (change === 'added')? action.cancellationEmail: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'cancellationEmail', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.contactNumber) {
    const beforeValue: string = (change === 'added')? '': action.contactNumber
    const afterValue: string = (change === 'added')? action.contactNumber: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'contactNumber', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.responsiblePersonName) {
    const beforeValue: string = (change === 'added')? '': action.responsiblePersonName
    const afterValue: string = (change === 'added')? action.responsiblePersonName: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'responsiblePersonName', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.emailAddress) {
    const beforeValue: string = (change === 'added')? '': action.emailAddress
    const afterValue: string = (change === 'added')? action.emailAddress: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'emailAddress', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.returnedEquip) {
    const beforeValue: string = (change === 'added')? '': action.returnedEquip
    const afterValue: string = (change === 'added')? action.returnedEquip: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'returnedEquip', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.returnedEquipTwo) {
    const beforeValue: string = (change === 'added')? '': action.returnedEquipTwo
    const afterValue: string = (change === 'added')? action.returnedEquipTwo: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'returnedEquipTwo', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.returnedEquipThree) {
    const beforeValue: string = (change === 'added')? '': action.returnedEquipThree
    const afterValue: string = (change === 'added')? action.returnedEquipThree: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'returnedEquipThree', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.owedPayment) {
    const beforeValue: string = (change === 'added')? '': action.owedPayment;
    const afterValue: string = (change === 'added')? action.owedPayment: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'owedPayment', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.status) {
    const beforeValue: string = (change === 'added')? '': action.status;
    const afterValue: string = (change === 'added')? action.status: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'status', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.date) {
    const beforeValue: string = (change === 'added')? '': action.date;
    const afterValue: string = (change === 'added')? action.date: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'date', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.returnLabelNumbers) {
    const beforeValue: string = (change === 'added')? '': action.returnLabelNumbers;
    const afterValue: string = (change === 'added')? action.returnLabelNumbers: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'returnLabelNumbers', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.note) {
    const beforeValue: string = (change === 'added')? '': action.note;
    const afterValue: string = (change === 'added')? action.note: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'note', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.count) {
    const beforeValue: string = (change === 'added')? '': action.count;
    const afterValue: string = (change === 'added')? action.count: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'count', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.reason) {
    const beforeValue: string = (change === 'added')? '': action.reason
    const afterValue: string = (change === 'added')? action.reason: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'reason', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  if (action.reasonOther) {
    const beforeValue: string = (change === 'added')? '': action.reasonOther
    const afterValue: string = (change === 'added')? action.reasonOther: '';
    const changeDetails: TreeNode = getTreeItem(user, reason, createdAt, 'reasonOther', beforeValue, afterValue);
    subItem.children.push(changeDetails);
  }
  return subItem;
}

/* 
  if an action other than the last is removed old code was showing a change to actions from
  that position onwards 
  e.g. if you removed 1st of 3 it showed 1st changing to what was 2nd, and 2nd changing to what was 3rd and
  3rd being removed
*/
function getActionSubItemsFromDiff(user: string, reason: string, createdAt: string, actionsDiff: any): TreeNode[] {
  if (actionsDiff['_t'] !== 'a') {
    return undefined;
  }
  const subItems: TreeNode[] = [];
  // Capture the important details for displaying on the change log

  const actionAfterUpdate: ActionChange[] = [];
  const actionBeforeUpdate: ActionChange[] = [];
  for (const [index, value] of Object.entries<any>(actionsDiff)) {
    try {
      if (!AFTER_UPDATE_KEY.test(index) && !BEFORE_UPDATE_KEY.test(index)) {
        continue;
      }
      const actionChange: ActionChange = value[0];
      if (!actionChange) {
        // Could be a partial update due to updates applied on server side
        let partialUpdateFound: boolean = false;
        const subItem: TreeNode = getTreeItem(user, reason, createdAt, index, undefined, undefined);
        for (let [keyPartial, valuePartial] of Object.entries(value)) {
          if ((value['_t'] !== 'a') && Array.isArray(valuePartial)) {
            if ((valuePartial.length == 1) && (valuePartial[0] !== '') && (valuePartial[0] != null)) {
              let before: string = '';
              let after: string;
              if (ACTION_DATE_FIELDS.includes(keyPartial)) {
                after = valuePartial[0]? 
                    moment.tz(valuePartial[0], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', ''): undefined;
              } else if (ACTION_DATETIME_FIELDS.includes(keyPartial)) {
                after = valuePartial[0]? 
                    moment(valuePartial[0]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
              } else {
                // don't show if after is 0 or empty
                if (valuePartial[0]) {
                  after = valuePartial[0];
                }
              }
              if (!!after) {
                const partialUpdate: TreeNode =
                  getTreeItem(user, reason, createdAt, (keyPartial === 'renewalDateTaken'? 'actionDueDate': keyPartial), before, after);
                subItem.children.push(partialUpdate);
                partialUpdateFound = true;
              }
            } else if (valuePartial.length == 2) {
              let before: string = valuePartial[0];
              let after: string = valuePartial[1];
              if (ACTION_DATE_FIELDS.includes(keyPartial)) {
                before = valuePartial[0]? 
                    moment.tz(valuePartial[0], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', ''): undefined;
                after = valuePartial[1]? 
                    moment.tz(valuePartial[1], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', ''): undefined;
              } else if (ACTION_DATETIME_FIELDS.includes(keyPartial)) {
                before = valuePartial[0]? 
                    moment(valuePartial[0]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
                after = valuePartial[1]? 
                    moment(valuePartial[1]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
              }
              // Only show if they differ and at least one has a value
              if ((before != after) && (valuePartial[0] || valuePartial[1])) {
                const partialUpdate: TreeNode =
                  getTreeItem(user, reason, createdAt, (keyPartial === 'renewalDateTaken'? 'actionDueDate': keyPartial), before, after);
                subItem.children.push(partialUpdate);
                partialUpdateFound = true;    
              }
            }
          }
        }
        if (partialUpdateFound) {
          subItems.push(subItem);
        }
        continue;
      }
      if (AFTER_UPDATE_KEY.test(index)) {
        actionChange.index = index;
        actionAfterUpdate.push(actionChange);
      } else {
        actionChange.index = index.replace('_', '');
        actionBeforeUpdate.push(actionChange);
      }
    } catch(err) {
      console.error('Error processing actions diffs. Error:', err);
    }
  }
  actionAfterUpdate.forEach((after: ActionChange) => {
    try {
      const changes: TreeNode[] = [];
      const matchingIndex: number = actionBeforeUpdate.findIndex(
        (before: ActionChange) => ((after.outstandingName === before.outstandingName) && 
          (after.actionInitiatedDate == before.actionInitiatedDate)));
      if (matchingIndex < 0) {
        subItems.push(getActionSubItemForAdditionOrRemoval(user, reason, createdAt, after, 'added'));
      } else {
        const before: ActionChange = actionBeforeUpdate[matchingIndex];
        // no field is going to be removed by the update (_id potentially only appears in the before, but we want to exclude that anyway)
        const fields: string[] = Object.getOwnPropertyNames(after);
        let hasChange: boolean = false;
        fields.forEach((field: string) => {
          if ((field === '_id') || (field === 'index')) {
            return;
          }
          let beforeValue: string = before[field];
          let afterValue: string = after[field];
          if (ACTION_DATE_FIELDS.includes(field)) {
            beforeValue = beforeValue?
                moment.tz(beforeValue, 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', ''): undefined;
            afterValue = afterValue?
                moment.tz(afterValue, 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', ''): undefined;
          } else if (ACTION_DATETIME_FIELDS.includes(field)) {
            beforeValue = beforeValue?
                moment(beforeValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
            afterValue = afterValue?
                moment(afterValue).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', ''): undefined;
          }
          // Force the action name to always be included, if in the record
          if ((field === 'outstandingName') || ((beforeValue != afterValue) && (beforeValue || afterValue))) {
            if ((beforeValue != afterValue) && (beforeValue || afterValue)) {
              hasChange = true;
            }
            const changeDetails: TreeNode = 
              getTreeItem(user, reason, createdAt, (field === 'renewalDateTaken'? 'actionDueDate': field), beforeValue, afterValue);
            changes.push(changeDetails);
          }
        });
        if (hasChange) {
          const subItem: TreeNode = getTreeItem(user, reason, createdAt, before.index, undefined, undefined);
          subItem.children = changes;
          subItems.push(subItem);
        }
        // Remove the match from the before action array, so we don't match against the same record again.
        actionBeforeUpdate.splice(matchingIndex, 1);
      }
    } catch(err) {
      console.error('Error processing action change. Error:', err);
    }
  });
  actionBeforeUpdate.forEach((before: ActionChange, _index: number) => {
    try {
      subItems.push(getActionSubItemForAdditionOrRemoval(user, reason, createdAt, before, 'removed'));
    } catch(err) {
      console.error('Error processing action change. Error:', err);
    }
  });
  return subItems;
}

function getTree(histories: DiffHistory[]) {
  const tree: TreeNode[] = [];
  for (let i in histories) {
    const history = histories[i]['diff'];
    const user = histories[i]['user'];
    const reason = histories[i]['reason'];
    const createdAt = moment(histories[i]['createdAt']).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
    delete history.website;
    delete history.created;
    delete history.$pull;
    delete history.$push;
    delete history.items;
    delete history.changelog;
    delete history.alarms;
    delete history.accountContact;
    if (history.alarmUserDetails && history.alarmUserDetails.users) {
      history.additionalUser = history.alarmUserDetails.users;
      delete history.alarmUserDetails.users;
    }

    for (let [key, value] of Object.entries(history)) {
      const item = {
        'data': {
          'user': user? user.replace(/undefined/g, ''): '',
          'reason': reason? reason.replace(/undefined/g, ''): '',
          'field': key,
          'date': createdAt
        },
        'expanded': true,
        'children': []
      };
      const meanKey = key;
      if (meanKey === 'tags') {
        const tagSubItems: TreeNode[] = getTagSubItemsFromDiff(user, reason, createdAt, value);
        if (tagSubItems && tagSubItems.length > 0) {
          item.children = tagSubItems;
        }
      } else if ((meanKey === 'replacementEquipment') || (meanKey === 'additionalEquipment') || (meanKey === 'plans')) {
        const equipmentSubItems: TreeNode[] = getEquipmentSubItemsFromDiff(user, reason, createdAt, value);
        if (equipmentSubItems && equipmentSubItems.length > 0) {
          item.children = equipmentSubItems;
        }
      } else if (meanKey == 'services') {
        const servicesSubItems: TreeNode[] = getServiceSubItemsFromDiff(user, reason, createdAt, value);
        if (servicesSubItems && servicesSubItems.length > 0) {
          item.children = servicesSubItems;
        }
      } else if (meanKey === 'keySafes') {
        // Key Safes can now change order, so separate out from old style equipment
        const keySafeSubItems: TreeNode[] = getKeySafeSubItemsFromDiff(user, reason, createdAt, value);
        if (keySafeSubItems && keySafeSubItems.length > 0) {
          item.children = keySafeSubItems;
        }
      } else if (meanKey === 'hardware') {
        const hardwareSubItems: TreeNode[] = getHardwareSubItemsFromDiff(user, reason, createdAt, value);
        if (hardwareSubItems && hardwareSubItems.length > 0) {
          item.children = hardwareSubItems;
        }
      } else if (meanKey === 'hardwareSets') {
        const hardwareSetSubItems: TreeNode[] = getHardwareSetSubItemsFromDiff(user, reason, createdAt, value);
        if (hardwareSetSubItems && hardwareSetSubItems.length > 0) {
          item.children = hardwareSetSubItems;
        }
      } else if (meanKey === 'renewalDiscounts') {
        const renewalDiscountSubItems: TreeNode[] = getRenewalDiscountSubItemsFromDiff(user, reason, createdAt, value);
        if (renewalDiscountSubItems && renewalDiscountSubItems.length > 0) {
          item.children = renewalDiscountSubItems;
        }
      } else if ((meanKey === 'notes') || (meanKey === 'vim')) {
        const vimOrNoteSubItems: TreeNode[] = getVimOrNoteSubItemsFromDiff(user, reason, createdAt, value);
        if (vimOrNoteSubItems && vimOrNoteSubItems.length > 0) {
          item.children = vimOrNoteSubItems;
        }
      } else if (meanKey === 'outstandingActions') {
        const actionSubItems: TreeNode[] = getActionSubItemsFromDiff(user, reason, createdAt, value);
        if (actionSubItems && actionSubItems.length > 0) {
          item.children = actionSubItems;
        }
      } else if (Array.isArray(value)) {
        if (value.length == 1) {
          item.data['before'] = '';
          item.data['after'] = value[0];
        } else if (value.length == 2) {
          item.data['before'] = value[0];
          item.data['after'] = value[1];
        }
      } else {
        for (let [keySub1, valueSub1] of Object.entries(value)) {
          const subItem = {
            'data': {
              'user': user? user.replace(/undefined/g, ''): '',
              'reason': reason? reason.replace(/undefined/g, ''): '',
              'field': keySub1,
              'date': createdAt
            },
            'expanded': true,
            'children': []
          }

          let subValueChanged: boolean = false;
          // This is the indicator in a jsonDiff that the key represents an array on the object being diff'ed 
          if (value['_t'] === 'a') {
            // There is a before and after image
            if (Object.entries(value).some(i => '_' + keySub1 == i[0]) && (keySub1.match(/^[0-9]+$/) != null)) {
              let newSubvalue;
              if (value[keySub1][0]) {
                if (typeof value[keySub1][0] == 'string') {
                  newSubvalue = [];
                  newSubvalue.push(value['_' + keySub1][0]); //before
                  newSubvalue.push(value[keySub1][0]); // after
                } else {
                  newSubvalue = {};
                  for (let [subKey, subvValue] of Object.entries(value[keySub1][0])) {
                    if (value['_' + keySub1][0][subKey] != value[keySub1][0][subKey]) {
                      newSubvalue[subKey] = [value['_' + keySub1][0][subKey], value[keySub1][0][subKey]];
                    }
                  }
                }
              } else {
                newSubvalue = {};
              }
              valueSub1 = newSubvalue;
              subValueChanged = true;
            }
          }

          if ((subValueChanged || (value['_t'] !== 'a')) && Array.isArray(valueSub1)) {
            // Changed to allow us to capture additions too
            if ((valueSub1.length == 1) && (valueSub1[0] !== '') && (valueSub1[0] != null)) {
              const lowerCaseKey: string = keySub1.toLowerCase();
              subItem.data['before'] = '';
              if (lowerCaseKey.includes('date')) {
                const after: string = moment.tz(valueSub1[0], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                subItem.data['after'] = after;
              } else if ((lowerCaseKey == 'backgroundautotestcall') || (lowerCaseKey == 'lastmainsfail') || lowerCaseKey.includes('activation')) {
                let after: string = moment(valueSub1[0]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
                subItem.data['after'] = after;
              } else {
                // don't show if after is 0 or empty
                if (valueSub1[0]) {                    
                  subItem.data['after'] = valueSub1[0];
                }
              }
            } else if (valueSub1.length == 2) {
              const lowerCaseKey: string = keySub1.toLowerCase();
              if (lowerCaseKey.includes('date')) {
                const before = moment.tz(valueSub1[0], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                const after = moment.tz(valueSub1[1], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                if (before != after) {
                  subItem.data['before'] = before;
                  subItem.data['after'] = after;
                }
              } else if ((lowerCaseKey == 'backgroundautotestcall') || (lowerCaseKey == 'lastmainsfail') || lowerCaseKey.includes('activation')) {
                let before: string = moment(valueSub1[0]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
                let after: string = moment(valueSub1[1]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
                if (before != after) {
                  subItem.data['before'] = before;
                  subItem.data['after'] = after;
                }
              } else {
                // don't show if before and after are both 0 or empty
                if (valueSub1[0] || valueSub1[1]) {
                  subItem.data['before'] = valueSub1[0];
                  subItem.data['after'] = valueSub1[1];
                }
              }
            }

          } else {
            if (!Object.entries(value).some(i => '_' + keySub1 == i[0]) && (keySub1.match(/^[0-9]+$/) != null)) {
              let partialUpdateFound: boolean = false;
              // Check for partial array entries done server side which stops the whole entry before and after being included
              for (let [keyPartial, valuePartial] of Object.entries(valueSub1)) {
                if ((valueSub1['_t'] !== 'a') && Array.isArray(valuePartial)) {
                  const partialUpdate: TreeNode = {
                    'data': {
                      'user': user? user.replace(/undefined/g, ''): '',
                      'reason': reason? reason.replace(/undefined/g, ''): '',
                      'field': keyPartial,
                      'date': createdAt
                    },
                    'expanded': true,
                    'children': []
                  };
                  if ((valuePartial.length == 1) && (valuePartial[0] !== '') && (valuePartial[0] != null)) {
                    const lowerCaseKey: string = keyPartial.toLowerCase();
                    partialUpdate.data['before'] =  '';
                    if (lowerCaseKey.includes('date')) {
                      const after: string = moment.tz(valuePartial[0], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                      partialUpdate.data['after'] = after;
                    } else if ((lowerCaseKey == 'backgroundautotestcall') || (lowerCaseKey == 'lastmainsfail') || lowerCaseKey.includes('activation')) {
                      let after: string = moment(valueSub1[0]).tz('Europe/London').format('DD/MM/YYYY HH:mm').replace('Invalid date', '');
                      partialUpdate.data['after'] = after;
                    } else {
                      // don't show if after is 0 or empty
                      if (valuePartial[0]) {
                        partialUpdate.data['after'] = valuePartial[0];
                      }
                    }
                    subItem.children.push(partialUpdate);
                    partialUpdateFound = true;
                  } else if (valuePartial.length == 2) {
                    if (keyPartial.toLowerCase().includes('date')) {
                      const before = moment.tz(valuePartial[0], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                      const after = moment.tz(valuePartial[1], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                      if (before != after) {
                        partialUpdate.data['before'] = before;
                        partialUpdate.data['after'] = after;
                        subItem.children.push(partialUpdate);
                      }
                    } else {
                      // don't show if before and after are both 0 or empty
                      if (valuePartial[0] || valuePartial[1]) {
                        partialUpdate.data['before'] = valuePartial[0];
                        partialUpdate.data['after'] = valuePartial[1];
                        subItem.children.push(partialUpdate);
                      }
                    }
                    partialUpdateFound = true;
                  }
                // Uncomment if we want the stripeCustomerUpdateStatus changes showing up 
                // } else if ((meanKey == 'initialOrderDetails') && Array.isArray(value) && (value.length == 1)) {
                //   const partialUpdate: TreeNode = {
                //     'data': {
                //       'user': user? user.replace(/undefined/g, ''): '',
                //       'reason': reason? reason.replace(/undefined/g, ''): '',
                //       'field': keyPartial,
                //       'date': createdAt,
                //       'before': '',
                //       'after': valuePartial,
                //     },
                //     'expanded': true,
                //     'children': []
                //   };
                //   item.children.push(partialUpdate);
                //   partialUpdateFound = true;
                }
              }
              if (!partialUpdateFound) {
                subItem.data['field'] = 'added';
                subItem.data['before'] = '';
                if ((meanKey == 'additionalUser') && valueSub1[0]) {
                  subItem.data['after'] = valueSub1[0].firstName + ' ' + valueSub1[0].lastName;
                } else if ((meanKey == 'contactAttempts') && valueSub1[0]) {
                  subItem.data['after'] = valueSub1[0].phoneNumber;
                  let field = undefined;
                  let counter = 0;
                  if (valueSub1[0].thumbsUp > 0) {
                    field = 'thumbsUp';
                    counter = valueSub1[0].thumbsUp;
                  } else if (valueSub1[0].thumbsDown > 0) {
                    field = 'thumbsDown';
                    counter = valueSub1[0].thumbsDown;
                  } else if (valueSub1[0].successfulPayments > 0) {
                    field = 'successfulPayments';
                    counter = valueSub1[0].successfulPayments;
                  }
                  if (field) {
                    const counterDetails = {
                      'data': {
                        'user': user? user.replace(/undefined/g, ''): '',
                        'reason': reason? reason.replace(/undefined/g, ''): '',
                        'field': field,
                        'date': createdAt,
                        'after': counter,
                      },
                      'children': []
                    }
                    subItem.children.push(counterDetails);
                  }
                } else if ((meanKey == 'serviceUsers') && valueSub1[0]) {
                  subItem.data['after'] = '';
                  for (let [srvUsrKey, srvUsrValue] of Object.entries(valueSub1[0])) {
                    const fieldDetails = {
                      'data': {
                        'user': user? user.replace(/undefined/g, ''): '',
                        'reason': reason? reason.replace(/undefined/g, ''): '',
                        'field': srvUsrKey,
                        'date': createdAt,
                        'after': srvUsrValue,
                      },
                      'children': []
                    }
                    subItem.children.push(fieldDetails);
                  }
                } else if (typeof valueSub1[0] == 'string') {
                  subItem.data['after'] = valueSub1[0];
                }
              }
            } else if ((keySub1.startsWith('_') && !Object.entries(value).some(i => keySub1.replace('_', '') == i[0])) &&
                (keySub1.replace('_', '').match(/^[0-9]+$/) != null)) {
              subItem.data['field'] = 'removed';
              subItem.data['after'] = '';
              if ((meanKey == 'additionalUser') && valueSub1[0]) {
                subItem.data['before'] = valueSub1[0].firstName + ' ' + valueSub1[0].lastName;
              } else if ((meanKey == 'serviceUsers') && valueSub1[0]) {
                subItem.data['before'] = '';
                for (let [srvUsrKey, srvUsrValue] of Object.entries(valueSub1[0])) {
                  const fieldDetails = {
                    'data': {
                      'user': user? user.replace(/undefined/g, ''): '',
                      'reason': reason? reason.replace(/undefined/g, ''): '',
                      'field': srvUsrKey,
                      'date': createdAt,
                      'before': srvUsrValue,
                    },
                    'children': []
                  }
                  subItem.children.push(fieldDetails);
                }
              } else if (typeof valueSub1[0] == 'string') {
                subItem.data['before'] = valueSub1[0];
              }
            } else {
              for (let [keySub2, valueSub2] of Object.entries(valueSub1)) {

                const subsubItem = {
                  'data': {
                    'user': user? user.replace(/undefined/g, ''): '',
                    'reason': reason? reason.replace(/undefined/g, ''): '',
                    'field': keySub2,
                    'date': createdAt
                  },
                  'children': []
                }

                if (Array.isArray(valueSub2)) {
                  if (valueSub2.length == 2) {
                    if (keySub2.toLowerCase().includes('date')) {
                      const before = moment.tz(valueSub2[0], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                      const after = moment.tz(valueSub2[1], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                      if (before != after) {
                        subsubItem.data['before'] = before;
                        subsubItem.data['after'] = after;
                        subItem.children.push(subsubItem);
                      }
                    } else if (keySub2 != '_id') {
                      // don't show if before and after are both 0 or empty
                      if (valueSub2[0] || valueSub2[1]) {
                        subsubItem.data['before'] = valueSub2[0];
                        subsubItem.data['after'] = valueSub2[1];
                        subItem.children.push(subsubItem);
                      }
                    }
                  } else if ((valueSub2.length == 1) && (valueSub2[0] !== '') && (valueSub2[0] != null)) {
                    if (keySub2.toLowerCase().includes('date')) {
                      const before = '';
                      const after = moment.tz(valueSub2[0], 'Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
                      if (before != after) {
                        subsubItem.data['before'] = before;
                        subsubItem.data['after'] = after;
                        subItem.children.push(subsubItem);
                      }
                    } else if (keySub2 != '_id') {
                      if (valueSub2[0]) {
                        subsubItem.data['before'] = '';
                        subsubItem.data['after'] = valueSub2[0];
                        subItem.children.push(subsubItem);
                      }
                    }
                  }
                } 
              }
            }
            // -------
          }
          if ((subItem.data['before'] != undefined && subItem.data['before'] != '') || (subItem.data['after'] != '' && subItem.data['after'] != undefined) || subItem.children.length > 0) {
            item.children.push(subItem);
          }
        }

      }
      if (item.data['before'] != undefined || item.children.length > 0) {
        tree.unshift(item)
      }
    }
  }
  return tree;
}

export {
  getTree,
}
