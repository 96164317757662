import {SelectItem} from 'primeng/api';
import {MessageTransmissionConfig, MessageTypeConfig, WebsiteMsgConfig} from '../models/messageTypeConfig.model';

interface MessageTypesByWebsite {
  [websiteId: string]: SelectItem<string>[]
}
interface MessageTypeConfigByName {
  [messageType: string]: MessageTypeConfig
}

let manualSendEmailMessageTypesByWebsite: MessageTypesByWebsite;
let manualSendLetterMessageTypesByWebsite: MessageTypesByWebsite;
let manualSendSmsMessageTypesByWebsite: MessageTypesByWebsite;
let messageTypeConfigsByMessageType: MessageTypeConfigByName;

function initialiseVariables(): void {
  const manualSendMessageTypes: MessageTypeConfig[] = JSON.parse(localStorage.getItem('manualSendMessageTypes')) || [];
  manualSendEmailMessageTypesByWebsite = {};
  manualSendLetterMessageTypesByWebsite = {};
  manualSendSmsMessageTypesByWebsite = {};
  messageTypeConfigsByMessageType = {};

  manualSendMessageTypes.forEach((msgTypeCfg: MessageTypeConfig) => {
    messageTypeConfigsByMessageType[msgTypeCfg.messageType] = msgTypeCfg;
    msgTypeCfg.websites.forEach((websiteMsgCfg: WebsiteMsgConfig) => {
      if (!manualSendEmailMessageTypesByWebsite[websiteMsgCfg.websiteId]) {
        manualSendEmailMessageTypesByWebsite[websiteMsgCfg.websiteId] = [];
      }
      if (!manualSendLetterMessageTypesByWebsite[websiteMsgCfg.websiteId]) {
        manualSendLetterMessageTypesByWebsite[websiteMsgCfg.websiteId] = [];
      }
      if (!manualSendSmsMessageTypesByWebsite[websiteMsgCfg.websiteId]) {
        manualSendSmsMessageTypesByWebsite[websiteMsgCfg.websiteId] = [];
      }
      websiteMsgCfg.sendUsing.forEach((msgTxConfig: MessageTransmissionConfig) => {
        if (msgTxConfig.enabled && (msgTxConfig.method == 'email')) {
          manualSendEmailMessageTypesByWebsite[websiteMsgCfg.websiteId].push({
            'label': msgTypeCfg.messageType,
            'value': msgTypeCfg.messageType,
          });
        } else if (msgTxConfig.enabled && (msgTxConfig.method == 'letter')) {
          manualSendLetterMessageTypesByWebsite[websiteMsgCfg.websiteId].push({
            'label': msgTypeCfg.messageType,
            'value': msgTypeCfg.messageType,
          });
        } else if (msgTxConfig.enabled && (msgTxConfig.method == 'text')) {
          manualSendSmsMessageTypesByWebsite[websiteMsgCfg.websiteId].push({
            'label': msgTypeCfg.messageType,
            'value': msgTypeCfg.messageType,
          });
        }
      });
    });
  });
}

function getManualSendEmailTypesByWebsite(): MessageTypesByWebsite {
  if (!manualSendEmailMessageTypesByWebsite) {
    if (!localStorage.getItem('manualSendMessageTypes')) {
      return {};
    }
    initialiseVariables();
  }
  return manualSendEmailMessageTypesByWebsite;
}

function getManualSendLetterTypesByWebsite(): MessageTypesByWebsite {
  if (!manualSendLetterMessageTypesByWebsite) {
    if (!localStorage.getItem('manualSendMessageTypes')) {
      return {};
    }
    initialiseVariables();
  }
  return manualSendLetterMessageTypesByWebsite;
}

function getManualSendSmsTypesByWebsite(): MessageTypesByWebsite {
  if (!manualSendSmsMessageTypesByWebsite) {
    if (!localStorage.getItem('manualSendMessageTypes')) {
      return {};
    }
    initialiseVariables();
  }
  return manualSendSmsMessageTypesByWebsite;
}

function getMessageConfig(messageType: string): MessageTypeConfig {
  if (!messageTypeConfigsByMessageType) {
    if (!localStorage.getItem('manualSendMessageTypes')) {
      return undefined;
    }
    initialiseVariables();
  }
  return messageTypeConfigsByMessageType[messageType];
}

function getManualSendEmailTypesForWebsite(websiteId: string): SelectItem<string>[] {
  const msgTypes: MessageTypesByWebsite = getManualSendEmailTypesByWebsite();
  return msgTypes[websiteId]? msgTypes[websiteId]: [];
}

function getManualSendLetterTypesForWebsite(websiteId: string): SelectItem<string>[] {
  const msgTypes: MessageTypesByWebsite = getManualSendLetterTypesByWebsite();
  return msgTypes[websiteId]? msgTypes[websiteId]: [];
}

function getManualSendSmsTypesForWebsite(websiteId: string): SelectItem<string>[] {
  const msgTypes: MessageTypesByWebsite = getManualSendSmsTypesByWebsite();
  return msgTypes[websiteId]? msgTypes[websiteId]: [];
}

export {
  getManualSendEmailTypesForWebsite,
  getManualSendLetterTypesForWebsite,
  getManualSendSmsTypesForWebsite,
  getMessageConfig,
}