import {SelectItem} from 'primeng/api';

let expiryChangeReasons: SelectItem<string>[];

function getExpiryChangeReasons(): SelectItem<string>[] {
  if (!expiryChangeReasons) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('expiry change: change reasons')) {
      return [];
    }
    expiryChangeReasons = JSON.parse(localStorage.getItem('expiry change: change reasons')) || [];
  }
  return expiryChangeReasons;
}

let paymentInOutOptions: SelectItem<string>[];

function getExpiryPaymentInOutOptions(): SelectItem<string>[] {
  if (!paymentInOutOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('expiry change: in/out payment options')) {
      return [];
    }
    paymentInOutOptions = JSON.parse(localStorage.getItem('expiry change: in/out payment options')) || [];
  }
  return paymentInOutOptions;
}

let independentPaymentOptions: SelectItem<string>[];

function getExpiryIndependentPaymentOptions(): SelectItem<string>[] {
  if (!independentPaymentOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('expiry change: independent payment options')) {
      return [];
    }
    independentPaymentOptions = JSON.parse(localStorage.getItem('expiry change: independent payment options')) || [];
  }
  return independentPaymentOptions;
}

let holdReasons: SelectItem<string>[];

function getExpiryHoldReasons(): SelectItem<string>[] {
  if (!holdReasons) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('expiry change: reasons for hold')) {
      return [];
    }
    holdReasons = JSON.parse(localStorage.getItem('expiry change: reasons for hold')) || [];
  }
  return holdReasons;
}

export {
  getExpiryChangeReasons,
  getExpiryPaymentInOutOptions,
  getExpiryIndependentPaymentOptions,
  getExpiryHoldReasons,
}