<div class="container">
  <div class="row mt-4" [formGroup]="manualUploadForm">
    <div class="col-12">
      <h2>Equipment Manual Upload</h2>
    </div>
    <div *ngIf="resultMessage" class="col-12 mb-2">
      <div class="alert" [ngClass]="resultSuccess? 'alert-success' : 'alert-danger'">
        {{resultMessage}}
      </div>
    </div>
    <div class="col-12 mb-2">
      To upload a replacement for a manual please select the equipment the manual is for then select the file to upload.
    </div>
    <div class="col-4">
      <label for="manualFileName">Equipment to upload new manual for</label>
      <p-dropdown [style]="{width:'100%'}" [options]="manualLookups" formControlName="manualFileName" 
        [required]="true" name="manualFileName" [panelStyle]="{minWidth:'12em'}" placeholder="Select Equipment" appendTo="body">
      </p-dropdown>
    </div>
    <div class="col-6">
      <label for="replacementFile" class="mb-0">New Manual</label>
      <input type="file" placeholder="Upload file" accept=".pdf" name="replacementFile" (change)="onFileChange($event)">
    </div>
    <div class="col-2">
      <label></label>
      <button type="button" class="btn btn-primary" [disabled]="!isOkToUpload()" (click)="uploadManualFile()">
        Upload
      </button>
    </div>
  </div>
</div>