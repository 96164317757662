import {OrderService} from './../order.service';
import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {OrderUpdateResponse} from '../../models/responses/orderUpdateResponse.model';
import {DomHandler} from 'primeng/dom';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {getPriceChangeComplaintReasons, getPriceChangeDiscountOptions, getPriceChangeReasons} from '../../lookups/priceChange';
import {Order} from '../../models/order.model';

// TODO this is to be removed and replaced with frozen-price when CRM is changed to use hardware
@Component({
  selector: 'app-renewal-price[orderData][newRenewalPrice][closeDialog][updateOrderOnRenewalPriceChange]',
  templateUrl: './renewal-price.component.html',
  styleUrls: ['./renewal-price.component.scss']
})
export class RenewalPriceComponent implements OnInit {

  constructor(
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
  ) { }
  display: boolean = true;
  @Input() orderData: Order;
  @Input() newRenewalPrice: number = 0;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateOrderOnRenewalPriceChange: EventEmitter<Order> = new EventEmitter<Order>();
  priceChangeReasons: SelectItem<string>[];
  selectedPriceChangeReason: string;
  otherPriceChangeReason: string = '';
  complaintReasons: SelectItem<string>[];
  selectedComplaintReason: string;
  otherComplaintReason: string = '';
  discountOptions: SelectItem<string>[];
  selectedDiscountOption: string;
  otherDiscountOption: string = '';
  isSubmitButtonDisabled: boolean = false;
  additionalNote: string = '';

  ngOnInit(): void {
    this.priceChangeReasons = getPriceChangeReasons();
    this.complaintReasons = getPriceChangeComplaintReasons();
    this.discountOptions = getPriceChangeDiscountOptions();
  }

  onHideDialog() {
    this.closeDialog.emit();
  }

  submitData() {
    this.isSubmitButtonDisabled = true;
    const params = {
      orderId: this.orderData._id,
      selectedPriceChangeReason: this.selectedPriceChangeReason,
      otherPriceChangeReason: this.otherPriceChangeReason,
      selectedComplaintReason: this.selectedComplaintReason,
      otherComplaintReason: this.otherComplaintReason,
      selectedDiscountOption: this.selectedDiscountOption,
      otherDiscountOption: this.otherDiscountOption,
      renewalPrice: this.newRenewalPrice,
      userName: localStorage.getItem('userName'),
      additionalNote:this.additionalNote,
    };
    this.orderService.updateOrderRenewalPrice(params).subscribe((response: OrderUpdateResponse) => {
      if (!response.success) {
        this.showErrorPopUp('Error Updating Renewal Price', `There was an error updating the renewal price. ${response.message}`);
        this.isSubmitButtonDisabled = false;
      } else {
        this.updateOrderOnRenewalPriceChange.emit(response.order);
      }
      
    }, (err: Error) => {
      this.showErrorPopUp('Error Updating Renewal Price',
        `There was an error updating the renewal price. Error: ${err? err.message: 'uknown error'}`
      );
      this.isSubmitButtonDisabled = false;
    });
  }

  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('aboveBlockMessage', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
