import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable ,  throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Params, Router} from "@angular/router";
import {DataService} from "./data.service";
import {environment} from '../environments/environment';
import {CRM_CLIENT_VERSION} from './models/environment.model';
import {clearLocalStorage} from './helpers/helperFunctions';

@Injectable( )
export class AuthenticationService implements HttpInterceptor {
  constructor(
    private router: Router,
    private data: DataService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only add the auth token to requests to the CRM
    if (!req.url.startsWith(environment.protocol+environment.IPAddress)) {
      return next.handle(req);
    }

    const token = localStorage.getItem('token');
    if (!token) {
      return next.handle(req);
    }

    const req1 = req.clone({
      headers: req.headers.set('Authorization', `${token}`)
        .set('crm-client-version', CRM_CLIENT_VERSION),
    });

    return next.handle(req1).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          if ([401, 418].includes(response.status)) {
            clearLocalStorage();
            const errorMsg: string = response['error']['message'];
            if (!this.router.routerState.snapshot.url.startsWith('/login')) {
              const loginParams: Params = {
                'redirectURL': this.router.routerState.snapshot.url,
              };
              this.router.navigate(['/login'], {queryParams: loginParams, skipLocationChange: true})
                .then((_success: boolean) => {
                  this.data.error(errorMsg);
                });
            } else {
              this.data.error(errorMsg);
            }
          } else if (response.status == 403) {
            this.router.navigate(['/permission', {skipLocationChange: true}]);
          }
        }
        return throwError(response);
      })
    )
  }
}
