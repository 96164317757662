import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {isValidObjectId} from '../../helpers/helperFunctions';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Review} from '../../models/review.model';
import {ReviewsService} from '../../services/reviews.service';
import {PostOrderSection} from '../post-order-section';

@Component({
  selector: 'app-reviews-section[pageSection][minimizeSectionMethod][orderId]',
  templateUrl: './reviews-section.component.html',
  styleUrls: ['../post-order-sections.scss', './reviews-section.component.scss']
})
export class ReviewsSectionComponent extends PostOrderSection implements OnInit, OnDestroy {
  @Input() orderId: string;

  orderReviews: Review[];

  constructor(
    private reviewsService: ReviewsService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
  ) {
    super(confirmationService, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.orderReviews = [];
    if (isValidObjectId(this.orderId)) {
      this.reviewsService.getReviewsForOrder(this.orderId).subscribe((response: MultiRecordResponse<Review>) => {
        if (response.success) {
          this.orderReviews = response.data;
        } else {
          console.error('Error getting Reviews', response.message);
        }
      });
    }
  }
  
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
