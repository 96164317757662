import {Component, Host, OnInit, OnDestroy} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PostOrderSection} from '../post-order-section';
import {CancellationCategorySelectItem, CancellationReasonSelectItem, cancellationCategoriesRequiringPrompt, getCancellationReasonCategories, getCancellationReasonsForCatgory} from '../../lookups/cancellationReasons';
import {getExternalEmailValidator} from '../../validators/email.validator';
import {phoneNumberValidator} from '../../validators/phone-number.validator';
import {CancellationDetails} from '../../models/order.model';
import {PostOrderService} from '../post-order.service';
import {Subscription} from 'rxjs';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';

@Component({
  selector: 'app-cancellation-section[pageSection][minimizeSectionMethod][addNoteFromSection][saveOrderFromSection]',
  templateUrl: './cancellation-section.component.html',
  styleUrls: ['../post-order-sections.scss', './cancellation-section.component.scss']
})
export class CancellationSectionComponent extends PostOrderSection implements OnInit, OnDestroy {
  cancellationDetailsSubscription: Subscription;
  originalCancellationReason: string;
  cancellationCategoriesSelectItems: CancellationCategorySelectItem[] = [];
  cancellationReasonsSelectItems: CancellationReasonSelectItem[] = [];
  otherReasonRequired: boolean;

  constructor(
    @Host() private parentFormDirective: FormGroupDirective,
    private formBuilder: FormBuilder,
    private postOrderService: PostOrderService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
  ) {
    super(confirmationService, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.otherReasonRequired = false;
    this.prepareForm();
    this.cancellationCategoriesSelectItems = getCancellationReasonCategories(false);
    this.originalCancellationReason = '';
    this.cancellationDetailsSubscription = this.postOrderService.cancellationDetailsSubject.subscribe((cancellationDetails: CancellationDetails) => {
      // Have to prepare the form again here as post-order recreates the form when it gets updates
      this.prepareForm();
      this.orderForm.patchValue({
        'cancellation': cancellationDetails,
      });
      this.originalCancellationReason = this.cancellationReason;
      this.updateFormForCancellationCategory(this.cancellationReason);
    });
  }

  ngOnDestroy(): void {
    this.cancellationDetailsSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  get orderForm(): FormGroup {
    return this.parentFormDirective.form;
  }

  get formLoaded(): boolean {
    return !!this.orderForm.get('cancellation');
  }

  prepareForm(): void {
    if (!this.formLoaded) {
      this.orderForm.addControl('cancellation',
        this.formBuilder.group({
          'cancellationDate': [null],
          'cancellationReason': ['', Validators.required],
          'detailedCancellationReason': ['', Validators.required],
          'otherReason': ['', Validators.required],
          'personReturning': ['', Validators.required],
          'cancellationEmail': ['', [getExternalEmailValidator(false)]],
          'telephoneNumber': ['', [phoneNumberValidator, Validators.required]],
          'returnDate': [''],
          'setToCancellingBy': [''],
        })
      );
    }
  }

  get cancellationReason(): string {
    return this.safeGetFormField('cancellationReason');
  }

  get detailedCancellationReason(): string {
    return this.safeGetFormField('detailedCancellationReason');
  }

  get cancellationData(): AbstractControl {
    return this.orderForm.get('cancellation');
  }

  safeGetFormField(fieldName: string): string {
    if (!this.orderForm.get('cancellation') || !this.orderForm.get('cancellation').get(fieldName)) {
      return '';
    }
    return this.orderForm.get('cancellation').get(fieldName).value;
  }

  updateFormForCancellationCategory(newCategory: string): void {
    if (!this.formLoaded) {
      return;
    }
    if (!newCategory) {
      this.cancellationReasonsSelectItems = [];
    } else {
      this.cancellationReasonsSelectItems = getCancellationReasonsForCatgory(newCategory);
    }
    this.changeCancellationReason(false);
  }

  changeCancellationReason(saveOrder: boolean): void {
    if (!this.formLoaded) {
      return;
    }
    const categorySelect: CancellationCategorySelectItem = this.cancellationCategoriesSelectItems.find((catSelect: CancellationCategorySelectItem) => 
      catSelect.value == this.cancellationReason
    );
    const reasonSelect: CancellationReasonSelectItem = this.cancellationReasonsSelectItems.find((reasonSelect: CancellationReasonSelectItem) => 
      reasonSelect.value == this.detailedCancellationReason
    );
    if (this.cancellationReasonsSelectItems.length > 0) {
      this.cancellationData.get('detailedCancellationReason').setValidators(Validators.required);
    } else {
      this.cancellationData.get('detailedCancellationReason').clearValidators();
    }
    this.cancellationData.get('detailedCancellationReason').updateValueAndValidity();
    if ((categorySelect && categorySelect.requiresFreeText) || (reasonSelect && reasonSelect.requiresFreeText)) {
      this.cancellationData.get('otherReason').setValidators(Validators.required);
      this.otherReasonRequired = true;
    } else {
      this.cancellationData.get('otherReason').setValue('');
      this.cancellationData.get('otherReason').clearValidators();
      this.otherReasonRequired = false;
    }
    this.cancellationData.get('otherReason').updateValueAndValidity();
    if (!categorySelect || !reasonSelect || reasonSelect.requiresContactDetails) {
      this.cancellationData.get('personReturning').setValidators(Validators.required);
      this.cancellationData.get('telephoneNumber').setValidators(Validators.required);
    } else {
      this.cancellationData.get('personReturning').clearValidators();
      this.cancellationData.get('telephoneNumber').clearValidators();
    }
    this.cancellationData.get('personReturning').updateValueAndValidity();
    this.cancellationData.get('telephoneNumber').updateValueAndValidity();
    if (saveOrder) {
      this.saveOrder();
    }
  }

  changeCancellationCategory(): void {
    this.cancellationData.get('detailedCancellationReason').setValue('');
    this.updateFormForCancellationCategory(this.cancellationReason);
    const sendArcCancellingEmail: () => void = () => {
      this.postOrderService.sendArcCancellingEmail(this.cancellationReason, this.username).then((result: SimpleResponse) => {
        this.addNote({
          'content': result.message,
          'categories': ['ARC'],
        });
        if (result.success) {
          this.showSuccess('Email Sent', 'Email successfully sent to ARC');
        } else {
          this.showErrorPopUp('Error sending email', result.error);
        }
      });
    }
    if (!cancellationCategoriesRequiringPrompt.includes(this.cancellationReason)) {
      sendArcCancellingEmail();
    } else {
      this.showConfirmationPopup('ARC Notification Email',
        `You are about to change the cancellation reason to ${this.cancellationReason}. Are you sure you want to make this change?`,
        sendArcCancellingEmail, () => {
          this.orderForm.get('cancellation').patchValue({
            cancellationReason: this.originalCancellationReason
          });
          this.orderForm.get('cancellation').get('cancellationReason').markAsPristine();
        }
      );
    }
  }
}
