import {FormGroup} from '@angular/forms';
import {FormArray} from '@angular/forms';
import {Lead, LeadAlarmUserDetails, LeadCallNote, LeadComment, MatchedOrder} from './../../models/lead.model';
import {LeadsService} from './../leads.service';
import {contactDetailsRequiredValidator} from '../../validators/contact-details-required.validator';
import {phoneNumberValidator, mobileNumberValidator} from './../../validators/phone-number.validator';
import {emailValidator} from './../../validators/email.validator';
import {AbstractControl, Validators} from '@angular/forms';
import {FormBuilder} from '@angular/forms';
import {Component, Input, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import * as moment from 'moment-timezone';
import {SelectItem, ConfirmationService} from 'primeng/api';
import {getDateAndType, getLeadNoCallbackOptions, CALL_DATE_SHIFTS, LeadType, LEAD_STATUSES, getLeadTypes } from '../../lookups/leads';
import {LocksSocketService} from '../../sockets/locks-socket.service';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {User} from '../../models/user.model';
import {filteredUsersByRole, sortByLabel} from '../../helpers/helperFunctions';
import {UsersAndRolesResponse} from '../../models/responses/usersAndRolesResponse.model';
import {UsersService} from '../../setup/users/users.service';
import {PostSheet} from '../../models/postSheet.model';
import {environment} from '../../../environments/environment';
import getAddressClient, {FindFailed, FindSuccess, Result} from 'getaddress-api';
import {Address} from '../../models/address.model';
import {getLookupFromGetAddressResult, validateAddress} from '../../helpers/getAddressHelper';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {LeadLockData} from '../../models/socket-io/leadLockData.model';
import {ProposedMessageService} from '../../messages-list/proposed-message.service';
import {ProposedMessage} from '../../models/proposedMessage.model';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';
import {Column} from '../../models/column.model';
import { OrderLockData } from '../../models/socket-io/orderLockData.model';
import {getUserAddress} from '../../helpers/getUserAddress';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-add-lead[openMode][leadId][closeModal]',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss'],
  providers: [ConfirmationService],
})
export class AddLeadComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private leadsService: LeadsService,
    private confirmationService: ConfirmationService,
    private locksSocket: LocksSocketService,
    private userService: UsersService,
    private proposedMessageService: ProposedMessageService,
    private clipboard: Clipboard,
  ) { }
  
  @Input() openMode: string = null;
  @Input() leadId: string = null;
  @Output() closeModal = new EventEmitter<SingleRecordResponse<Lead>>();
  loading: boolean = true;
  displayModal: boolean = true;
  brandConfigs: BrandCfg = getBrandConfigs();
  isSubmitDisabled: boolean = false;
  brands: SelectItem<string>[] = getBrandSelectItems();
  leadTypes: LeadType[];
  callDateType: string = "datetime-local";
  today: Date = moment.tz('Europe/London').startOf('day').toDate();
  callDateShifts: SelectItem<string>[] = CALL_DATE_SHIFTS;
  leadDeclinedOptions:  SelectItem<string>[] = getLeadNoCallbackOptions();
  socketId: string;
  leadEditor: LeadLockData;
  documentBlocked: boolean = false;
  closing: boolean = false;
  user: string;
  ownerNames: SelectItem<string>[] = [];
  leadDetail: Lead;
  leadForm: FormGroup;
  addCallNoteForm: FormGroup = this.formBuilder.group({
    content: ['',[Validators.required]]
  });
  addCommentForm: FormGroup = this.formBuilder.group({
    comments: ['']
  });
  salesContactNeeded: boolean;
  numberOnly = numberOnly;
  getLeadAddress = getUserAddress;
  showPostSheetAdd: boolean = false;
  getAddrClient: getAddressClient;
  leadAddressSearchPostCode: string;
  allowAddressManualEntry: boolean;
  leadSearchError: string;
  leadAddressResults: SelectItem<Address>[];
  emailInfoPackDisabled: boolean;
  resultMessage: string;
  resultSuccess: boolean;
  leadsToCheckColumns: Column[] = [
    {field: 'orderChecked', header: 'orderChecked'},
    {field: 'name', header: 'Name'},
    {field: 'orderCreationDate', header: 'Order Created Date'},
    {field: 'tdCode', header: 'Order Link'},
  ];
  orderLockList: OrderLockData[] = [];
  whoReferredOptions: SelectItem[] = [
    {label:'Self', value: 'Self'},  
    {label:'Third Party', value: 'Third Party'},  
    {label:'Professional', value: 'Professional'},  
  ];
  statusSelects: SelectItem<string>[] = LEAD_STATUSES;
  
  ngOnInit(): void {
    this.user = localStorage.getItem('userName');
    this.getUsers();
    this.leadDeclinedOptions;
    this.emailInfoPackDisabled = false;
    this.allowAddressManualEntry = false;
    this.leadTypes = getLeadTypes();
    this.leadForm = this.formBuilder.group({
      leadType: [{value: null, disabled: (this.openMode == 'view')}, [Validators.required]],
      whoReferred: [{value: null, disabled: (this.openMode == 'view')}, [Validators.required]],
      brand: [{value: null, disabled: (this.openMode == 'view')}, Validators.required],
      referralDate: [moment.tz('Europe/London').format('YYYY-MM-DD'),Validators.required],
      alarmUserDetails: this.formBuilder.group({
        firstName: ['', [Validators.required, Validators.pattern(/^\S{2}.*$/)]],
        otherName: [''],
        lastName: ['', [Validators.required, Validators.pattern(/^\S{2}.*$/)]],
        preferredName: [''],
        email: ['', ],
        telephone: ['', [phoneNumberValidator]],
        mobile: ['', [mobileNumberValidator]],
        salesContact: [null],
        userAddress: this.formBuilder.group({
          addressOne: [''],
          addressTwo: [''],
          city: [''],
          county: [''],
          postcode: [''],
          dob: [null],
          validated: [false],
        })
      },{'validators': contactDetailsRequiredValidator('Alarm User', 'telephone', 'mobile')}),
      referrerDetails: this.formBuilder.group({
        firstName: [''],
        otherName:[''],
        lastName: [''],
        email: ['', ],
        relationship: [''],
        telephone: [''],
        mobile: [''],
        salesContact: [null],
        accountContactForCRM: [null],
        userAwareOfReferral: [null],
        referredBy: [null],
      }),
      salesContactDetails: this.formBuilder.group({
        firstName: ['', Validators.required],
        otherName: [''],
        lastName: ['', Validators.required],
        email: ['', [emailValidator]],
        relationship: [''],
        telephone: ['', [phoneNumberValidator]],
        mobile: ['', [mobileNumberValidator]],
        accountContactForCRM:[null],
      },{'validators': contactDetailsRequiredValidator('Sales Contact','telephone','mobile')}),
      callNotes: this.formBuilder.array([]),
      comments: this.formBuilder.array([]),
      callDate: [{value: null, disabled: (this.openMode == 'view')}, [Validators.required]],
      callDateShift: [{value: null, disabled: (this.openMode == 'view')}, [Validators.required]],
      ownerName: [{value: '', disabled: (this.openMode == 'view')}],
      addedBy: this.user,
      matchedOrders: this.formBuilder.array([]),
      askedForInfoPack: '',
      status: [{value: 'Active', disabled: (this.openMode == 'view')}, [Validators.required]],
      leadDeclinedReason: [{value: '', disabled: (this.openMode == 'view')}],
      leadDeclinedReason2: [{value: '', disabled: (this.openMode == 'view')}],
      paymentRef: '',
    });
    if (this.openMode != 'add') {
      this.getLead();
    } else {
      this.changeSalesContact();
      this.changeLeadType();
      this.loading = false;
    }
    if (this.openMode == 'edit') {
      this.locksSocket.emit('lockingLead', {
        leadId: this.leadId, user: localStorage.getItem('userName')
      }, (lockedLead: LeadLockData) => {
        if (lockedLead.added) {
          this.documentBlocked = false;
          this.socketId = lockedLead.socketId;
          this.leadEditor = lockedLead;
        } else {
          this.documentBlocked = true;
          this.leadEditor = lockedLead;
          this.socketId = '';
        }
      });
      this.locksSocket.on('lockedLead', this.processLeadLock);
      
      this.locksSocket.on('leadLockList', this.processLeadLockList);
    }
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);
  }

  get userAddressFormGroup(): FormGroup {
    const alarmUserFormFroup: FormGroup = this.leadForm.controls['alarmUserDetails'] as FormGroup;
    return (alarmUserFormFroup.controls['userAddress'] as FormGroup);
  }

  leadAddressSearch() {
    if (!this.leadAddressSearchPostCode) {
      return;
    }
    this.leadAddressResults = [];
    this.allowAddressManualEntry = false;
    this.leadSearchError = '';
    this.getAddrClient.find(this.leadAddressSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.leadAddressResults = getLookupFromGetAddressResult(this.leadAddressSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.leadSearchError = addressResult.toFailed().message;
        console.error('Lead Address search failed. Error:', this.leadSearchError);
      } else if (this.leadAddressResults.length <= 2) {
        this.leadSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Lead Address search failed. Error:', error);
    })
  }

  setLeadAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowAddressManualEntry = true;
      const existingAddress: Address = this.userAddressFormGroup.value;
      let postcodeToUse: string = existingAddress.postcode;
      if (!existingAddress.addressOne && !existingAddress.postcode) {
        postcodeToUse = this.leadAddressSearchPostCode;
      }
      this.userAddressFormGroup.patchValue({
        'postcode': postcodeToUse,
        'validated': false,
      });
      return;
    }
    this.allowAddressManualEntry = false;
    // Don't want to copy Role
    this.userAddressFormGroup.patchValue({
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    });
  }

  processLeadLock: (lockedLead: LeadLockData) => void = (leadLock: LeadLockData) => {
    if (leadLock.socketId === this.socketId) {
      this.leadEditor = leadLock;
      this.documentBlocked = true;
    }
  };

  processLeadLockList: (leadLockList: LeadLockData[]) => void = (leadLockList: LeadLockData[]) => {
    if (this.closing) {
      return;
    }
    if (this.documentBlocked && leadLockList.filter((e: LeadLockData) => e.leadId == this.leadId).length == 0) {
      this.locksSocket.emit('lockingLead', { leadId: this.leadId, user: this.user },
        (currentLead: LeadLockData) => {
          if (currentLead.added) {
            this.socketId == currentLead.socketId;
            this.getLead();
            this.documentBlocked = false;
          }
        });
    }
  };

  submit(): void {
    // Should be one or other, but just to be safe
    if ((this.openMode != 'edit') && (this.openMode != 'add')) {
      return;
    }
    this.isSubmitDisabled = true;
    const lead: Lead = this.leadForm.value;
    if (!!this.callNote.value) {
      lead.callNotes.push({
        content: this.callNote.value,
        createdBy: localStorage.getItem('userName'),
        createdAt: moment.tz('Europe/London').toISOString(),
      });
    }
    if (!!this.comments.value) {
      lead.comments.push({
        comments: this.comments.value,
        createdBy: localStorage.getItem('userName'),
        createdAt: moment.tz('Europe/London').toISOString(),
      });
    }
    
    if ((this.status.value == 'Declined') && (this.leadDeclinedReason.value != 'Other')) {
      lead.leadDeclinedReason2 = '';
    }
    if (this.openMode == 'edit') {    
      lead._id = this.leadDetail._id;
      this.leadsService.updateLead(lead).subscribe((response: SingleRecordResponse<Lead>) => {
        this.displayModal = false;
        this.closeModal.emit(response);
      }, (err: Error) => {
        console.log("Error on updating lead from add lead cmp", err);
        this.displayModal = false;
        this.closeModal.emit({ 
          'success': false,
          'message': `Error updating lead. Error: ${err.message}`
        });
      });
    } else if (this.openMode == 'add') {
      if (['Info Pack - by post', 'Info Pack - by email'].includes(this.selectedLeadType.value)) {
        lead.askedForInfoPack = 'Yes';
      }
      if (lead.ownerName == '') {
        lead.ownerName = localStorage.getItem('userName');
      }
      this.leadsService.addLead(lead).subscribe((response: SingleRecordResponse<Lead>) => {
        this.displayModal = false;
        this.closeModal.emit(response);
      }, (err: Error) => {
        console.log("Error while adding a lead");
        this.displayModal = false;
        this.closeModal.emit({ 
          'success': false,
          'message': `Error adding lead. Error: ${err.message}`
        });
      });
    }
  }

  getLead(): void {
    this.leadsService.getLead(this.leadId).subscribe((response: SingleRecordResponse<Lead>) => {
      this.loading = false;
      this.leadDetail = response.data;
      this.setFormValue();
    }, (err: Error) => {
      console.log("Error while getting lead by id :: ", err.message);
      this.loading = false;
    })
  };
  
  setFormValue(): void {
    const { callDateType, callDate } = getDateAndType(this.leadDetail.callDateShift, this.leadDetail.callDate);
    this.leadForm.patchValue({
      leadType: this.leadDetail.leadType,
      whoReferred: this.leadDetail.whoReferred,
      brand: this.leadDetail.brand,
      referralDate: (this.leadDetail.referralDate ?
        moment.tz(this.leadDetail.referralDate, 'Europe/London').format('YYYY-MM-DD') :
        null),
      alarmUserDetails: this.leadDetail.alarmUserDetails,
      referrerDetails: this.leadDetail.referrerDetails,
      salesContactDetails: this.leadDetail.salesContactDetails,
      callDate: callDate,
      callDateShift: this.leadDetail.callDateShift,
      ownerName: this.leadDetail.ownerName,
      addedBy: this.leadDetail.addedBy,
      askedForInfoPack: this.leadDetail.askedForInfoPack,
      status: this.leadDetail.status,
      leadDeclinedReason: this.leadDetail.leadDeclinedReason,
      leadDeclinedReason2: this.leadDetail.leadDeclinedReason2,
      paymentRef: this.leadDetail.paymentRef,
    });
    this.leadDetail.matchedOrders.forEach((matchedOrder: MatchedOrder) => {
      this.matchedOrderForms.push(
        this.formBuilder.group({
          orderChecked: [matchedOrder.orderChecked],
          date: [matchedOrder.date],
          orderId: [matchedOrder.orderId],
          tdCode: [matchedOrder.tdCode],
          alarmUserName: [matchedOrder.alarmUserName],
          orderCreationDate: [matchedOrder.orderCreationDate],
        }),
      )}
    )
    this.callDateType = callDateType;
    this.leadDetail.callNotes.forEach((note: LeadCallNote) => {
      this.callNotesForms.push(
        this.formBuilder.group({
          _id: [note._id],
          content: [note.content],
          createdBy: [note.createdBy],
          createdAt: [note.createdAt],
        })
      );
    });
    this.leadDetail.comments.forEach((note: LeadComment) => {
      this.commentForms.push(
        this.formBuilder.group({
          _id: [note._id],
          comments: [note.comments],
          createdBy: [note.createdBy],
          createdAt: [note.createdAt],
        })
      );
    });
    this.statusChange();
    this.changeSalesContact();
    this.changeLeadType();
  }

  unlock(): void {
    this.confirmationService.confirm({
      message: `${this.leadEditor.user} is curently editing this lead, do you want to have full access permission? Warning: any changes made by ${this.leadEditor.user} will be lost`,
      header: 'Warning',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.locksSocket.emit('unlockingLead-F', { leadId: this.leadId, user: this.user }, (data) => {
          if (data.added) {
            this.socketId = data.socketId;
            this.getLead();
            this.documentBlocked = false;
          }
        })
      },
      reject: () => {
        
      }
    });
  }

  closeDialog(): void {
    this.displayModal = false;
    this.closeModal.emit({
      'success': true,
      'message': 'dialog closed'
    });
    this.closing = true;
    if (!!this.leadId && !this.documentBlocked) {
      this.locksSocket.emit('unlockingLead', { 'leadId': this.leadId, 'user': this.user });
    }
  }

  ngOnDestroy(): void {
    // Don't want to remove all listeners here as the parent component is listening on this socket too
    this.locksSocket.removeListener('lockedLead', this.processLeadLock);
    this.locksSocket.removeListener('leadLockList', this.processLeadLockList);
    this.closing = true;
    if (!!this.leadId && !this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingLead', { 'leadId': this.leadId, 'user': this.user });
    }
  }

  unload(): void {
    this.closing = true;
    if (!!this.leadId && !this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingLead', { 'leadId': this.leadId, 'user': this.user });
    }
  }

  get callNotesForms(): FormArray {
    return this.leadForm.get('callNotes') as FormArray;
  }

  get commentForms(): FormArray {
    return this.leadForm.get('comments') as FormArray;
  }

  get matchedOrderForms(): FormArray {
    return this.leadForm.get('matchedOrders') as FormArray;
  }

  get alarmUserDetails(): AbstractControl {
    return this.leadForm.get('alarmUserDetails');
  }

  get referrerDetails(): AbstractControl {
    return this.leadForm.get('referrerDetails');
  }

  get salesContactDetails(): AbstractControl {
    return this.leadForm.get('salesContactDetails');
  }

  get selectedBrand(): AbstractControl {
    return this.leadForm.get('brand');
  }

  get selectedLeadType(): AbstractControl {
    return this.leadForm.get('leadType');
  }

  get callDate(): AbstractControl {
    return this.leadForm.get('callDate')
  }

  get callDateShift(): AbstractControl {
    return this.leadForm.get('callDateShift');
  }

  get dateType(): string {
    return this.callDateType;
  }

  get callNote(): AbstractControl {
    return this.addCallNoteForm.get('content');
  }

  get comments(): AbstractControl {
    return this.addCommentForm.get('comments');
  }
  get whoReferred(): AbstractControl {
    return this.leadForm.get('whoReferred');
  }

  get isCallLogRequired(): boolean {
    if (!this.selectedLeadType.value) {
      return false
    } 
    return (this.openMode == 'add') && this.selectedLeadType.value.callLogRequired;
  }

  get isEmailRequired(): boolean {
    if (!this.selectedLeadType.value) {
      return false
    } 
    return this.selectedLeadType.value.emailRequired;
  }
  
  get isProfessionalReferral(): boolean {
    return (this.selectedLeadType.value && this.selectedLeadType.value.includes('Professional'));
  }

  get whoReferredRequired(): boolean {
    if (!this.selectedLeadType.value) {
      return false
    } 
    return this.leadTypes.find((leadtype: LeadType) => this.selectedLeadType.value == leadtype.value).whoReferredRequired;
  }

  get isOkToSubmit(): boolean {
    return !this.leadForm.invalid && !this.isSubmitDisabled && !(this.isCallLogRequired && this.addCallNoteForm.invalid);
  }

  get askedForInfoPack(): AbstractControl {
    return this.leadForm.get('askedForInfoPack');
  }

  get leadDeclinedReason(): AbstractControl {
    return this.leadForm.get('leadDeclinedReason');
  }

  get leadDeclinedReason2(): AbstractControl {
    return this.leadForm.get('leadDeclinedReason2');
  }

  get status(): AbstractControl {
    return this.leadForm.get('status');
  }

  shiftChange(event: DropDownChangeEvent<string>): void {
    if (event.value == 'Specific Time') {
      this.callDateType = 'datetime-local'
    } else {
      this.callDateType = 'date'
    }
  }

  getPaymentRef(): void {
    this.leadsService.getNextNumber('Leads Payment Ref').subscribe((refResponse: SingleRecordResponse<number>) => {
      if (!refResponse.success || !refResponse.data) {
        this.confirmationService.confirm({
          'header': 'Error Getting Payment Ref',
          'message':
            `Error getting unique reference for payment, click the Get Ref button to try again. Reason: ${refResponse.message}`,
          'rejectVisible': false,
          'acceptLabel': 'OK',
          'icon': 'pi pi-exclamation-triangle',
        });
      } else {
        this.leadForm.get('paymentRef').setValue(`Lead-${refResponse.data}`);
      }
    });
  }

  statusChange(): void {
    if ((this.status.value == 'Pending Payment') && !this.leadForm.get('paymentRef').value) {
      this.getPaymentRef();
    }

    if (this.status.value != 'Declined') {
      this.leadDeclinedReason.setValue('');
      this.leadDeclinedReason2.setValue('');
    }

    if (['Active', 'Pending Payment', 'Considering', 'Unable to Contact'].includes(this.status.value)) {
      this.callDate.setValidators(Validators.required);
      this.callDateShift.setValidators(Validators.required);
    } else {
      this.callDate.clearValidators();
      this.callDateShift.clearValidators();
      this.callDate.setValue(null);
      this.callDateShift.setValue(null);
    }
    this.leadForm.updateValueAndValidity();
  }

  changeSalesContact(): void {
    if (!this.isProfessionalReferral) {
      this.clearSalesContactValidators();
    } else if (this.isProfessionalReferral &&
        ((this.alarmUserDetails.value.salesContact == 'Yes') || (this.referrerDetails.value.salesContact == 'Yes'))) {
      this.clearSalesContactValidators()
    } else {
      this.setSalesContactValidators();
    }
    this.leadForm.updateValueAndValidity();
  }

  changeLeadType() {
    if (!this.whoReferredRequired || (this.openMode == 'view')) {
      this.whoReferred.disable();
    } else {
      this.whoReferred.enable();
    }
    if(!this.isProfessionalReferral) {
      this.clearSalesContactValidators();
    } else if (this.isProfessionalReferral &&
        ((this.alarmUserDetails.value.salesContact == 'Yes') || (this.referrerDetails.value.salesContact == 'Yes'))) {
      this.clearSalesContactValidators()
    } else {
      this.setSalesContactValidators();
    }
    if (this.isEmailRequired) {
      this.setEmailValidators();
    } else {
      this.clearEmailValidators();
    }
    this.leadForm.updateValueAndValidity();
  }

  setSalesContactValidators() {
    this.salesContactNeeded = true;
    if (this.openMode != 'view') {
      this.salesContactDetails.enable();
    }
    this.salesContactDetails.get('telephone').setValidators(phoneNumberValidator);
    this.salesContactDetails.get('mobile').setValidators(mobileNumberValidator);
    this.salesContactDetails.get('firstName').setValidators(Validators.required);
    this.salesContactDetails.get('lastName').setValidators(Validators.required);
    this.salesContactDetails.get('email').setValidators(emailValidator);
    this.salesContactDetails.setValidators(contactDetailsRequiredValidator('Sales Contact','telephone','mobile'));
  }

  clearSalesContactValidators() {
    this.salesContactNeeded = false;
    this.salesContactDetails.disable();
    this.salesContactDetails.patchValue({
      'firstName': '',
      'otherName': '',
      'lastName': '',
      'email': '',
      'relationship': '',
      'telephone': '',
      'mobile': '',
      'accountContactForCRM': null
    });
    this.salesContactDetails.get('telephone').clearValidators();
    this.salesContactDetails.get('mobile').clearValidators();
    this.salesContactDetails.get('firstName').clearValidators();
    this.salesContactDetails.get('lastName').clearValidators();
    this.salesContactDetails.get('email').clearValidators();
    this.salesContactDetails.clearValidators();
  }

  setEmailValidators() {
    this.alarmUserDetails.get('email').setValidators(emailValidator);
    this.alarmUserDetails.get('telephone').clearValidators();
    this.alarmUserDetails.get('mobile').clearValidators();
    this.alarmUserDetails.clearValidators();
  }

  clearEmailValidators() {
    this.alarmUserDetails.get('email').clearValidators();
    this.alarmUserDetails.get('telephone').setValidators(phoneNumberValidator);
    this.alarmUserDetails.get('mobile').setValidators(mobileNumberValidator);
    this.alarmUserDetails.setValidators(contactDetailsRequiredValidator('Alarm User','telephone','mobile'));
  }

  getUsers() {
    this.userService.getUsers().subscribe((response: UsersAndRolesResponse) => {
      if (response.success) {
        response.users = filteredUsersByRole(response.users);
        this.ownerNames = response.users.map((user: User) => {
          return {
            value: user.name,
            label: user.name
          }
        });
        this.ownerNames = sortByLabel(this.ownerNames);
      }
    }, (err: Error) => {
      this.showErrorPopUp('Error getting users', `Error getting users. Error: ${err.message}`);
      console.log('Error while getting users in leads group component :: ',);
    })
  }

  showAddPost() {
    this.showPostSheetAdd = true;
  }
// TODO add in info pack emails
  addAutomatedNote(content: string): void {
    const newNote: FormGroup = this.formBuilder.group({
      content: content,
      createdBy: localStorage.getItem('userName'),
      createdAt: moment.tz('Europe/London').toDate(),
    });
    this.callNotesForms.push(newNote);
  }

  emailInfoPack() {
    if (this.leadForm.value.alarmUserDetails.email && this.leadForm.value.brand) {
      const lead: Lead = this.leadForm.value;
      const params: ProposedMessage = {
        brand: lead.brand,
        messageType: 'Info Pack',
        methods: ['email'],
        status: 'Ready to Send',
        contactDetails: {
          'source': 'Lead',
          'firstName': lead.alarmUserDetails.firstName,
          'lastName': lead.alarmUserDetails.lastName,
          'email': lead.alarmUserDetails.email,
          'mobile': '',
          'address': [],
        },
      };
      this.proposedMessageService.addMessage(params).subscribe((response: SingleRecordResponse<ProposedMessage>) => {
        this.emailInfoPackDisabled = false;
        if (response.success) {
          let noteContent: string;
          noteContent = `Info pack email sent to ${lead.alarmUserDetails.firstName + ' ' + lead.alarmUserDetails.lastName} at ${lead.alarmUserDetails.email}.`
          this.addAutomatedNote(noteContent); 
        } else {
          // TODO display error
          this.resultMessage = `Error queuing email for sending ${response.message}`;
          this.resultSuccess = false;
        }
        
      }, (err: Error) => {
        this.emailInfoPackDisabled = false;
        // TODO display error
        this.resultMessage = `Error queuing email for sending ${err.message}`;
        this.resultSuccess = false;
        console.log('Response :: ', err);
      });
    } else {
      this.showErrorPopUp('Cannot email info pack.', 'Cannot email info pack as missing email address or brand');
    }
    
  }

  closeAddPostSheet(event: PostSheet|string|undefined) {
    if (typeof event == 'string') {
      this.showErrorPopUp('Error adding Post Sheet entry.', `Error saving new post sheet entry. Error ${event}`);
    }
    this.showPostSheetAdd = false;
  }

  isOrderLocked(_id: string) {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id).length > 0;
  }

  orderLockedBy(_id: string) {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id)[0].user;
  }

  validateAndCopyToClipBoard(userDetail: LeadAlarmUserDetails): void {
    this.allowAddressManualEntry = false;
    this.clipboard.copy(getUserAddress(userDetail));
    if (userDetail.userAddress?.validated) {
      return;
    }
    validateAddress(this.getAddrClient, userDetail.userAddress).then(
      (addressValResponse: MultiRecordResponse<SelectItem<Address>>) => {
        if (addressValResponse.success) {
          this.alarmUserDetails.get('userAddress').patchValue({
            'validated': true,
          })
          return;
        }
        this.leadSearchError = addressValResponse.message;
        this.leadAddressSearchPostCode = userDetail.userAddress? userDetail.userAddress.postcode: '';
        if (!addressValResponse.data) {
          this.confirmationService.confirm({
            'header': 'Error Validating Address',
            'message': `Error trying to validate the existing address. Please check it to make sure it is correct. Reason: ${addressValResponse.message}`,
            'rejectVisible': false,
            'acceptLabel': 'OK',
            'icon': 'pi pi-exclamation-triangle',
          });
        } else {
          this.leadAddressResults = addressValResponse.data;
          this.confirmationService.confirm({
            'header': 'Possible Invalid Address',
            'message': `The existing address could not be validated please check it to make sure it is correct. Reason: ${addressValResponse.message}`,
            'rejectVisible': false,
            'acceptLabel': 'OK',
            'icon': 'pi pi-info-circle',
          });
        }
      }
    );
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}