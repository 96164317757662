import {Injectable} from '@angular/core';
import {GenericSocketService} from './generic-socket.service';
import {environment} from '../../environments/environment';
import {ConfirmationCallback} from '../models/socket-io/confirmationCallback';
import {OrderUpdate} from '../models/socket-io/orderUpdate.model';

interface OrderUpdServToCliEvs {
  'updateOrders': (orderUpdateData: OrderUpdate) => void;
}

interface OrderUpdCliToServEvs {
  'broadcastUpdate': (orderUpdateData: OrderUpdate, fn?: ConfirmationCallback) => void;
}

@Injectable()
export class OrderUpdatesSocketService extends GenericSocketService<OrderUpdServToCliEvs, OrderUpdCliToServEvs> {
  constructor() {
    super({
      'url': environment.protocol + environment.IPAddress + '/orderUpdates', 
      'options': {
        'closeOnBeforeunload': false,
        'transports': ['websocket'],
        'path': '/socket.io/updates/',
      }
    });
  }
}
