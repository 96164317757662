import {TooltipService} from './tooltip.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {Component, OnInit, ViewChild} from '@angular/core';
import {pageOptions, orderPageSections} from '../../lookups/tooltips';
import {Table} from 'primeng/table';
import {UsersService} from '../../setup/users/users.service';
import {Column} from '../../models/column.model';
import {Title} from '@angular/platform-browser';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Tooltip} from '../../models/tooltip.model';

@Component({
  selector: 'app-tooltips',
  templateUrl: './tooltips.component.html',
  styleUrls: ['./tooltips.component.scss'],
  providers:[ConfirmationService, MessageService]
})
export class TooltipsComponent implements OnInit {

  constructor(
    private tooltipService: TooltipService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private usersService: UsersService,
    private title: Title
  ) { }
  @ViewChild('dt', {static: false})
  table: Table;
  cols: Column[] = [
    { field: 'page', header: 'Page' },
    { field: 'section', header: 'Section' },
    { field: 'label', header: 'Label' },
    { field: 'tooltip', header: 'Tooltip' },
    { field: 'action', header: 'Action' },
  ];
  tooltips: Tooltip[] = [];
  searchableFields: string[] = this.cols.map(e => e.field);
  pages = pageOptions;
  selectedPage: string = null;
  sections: {[page: string]: SelectItem<string>[]} = {
    'Order Page': orderPageSections,
  }
  sectionOptions: SelectItem<string>[] = [];

  ngOnInit(): void {
    this.getTooltips();
    this.title.setTitle('CRM Tooltips');
  }

  userCanEditTooltips(): boolean {
    return this.usersService.userHasPermission('Maintain Tooltips');
  }

  getTooltips(): void {
    this.tooltipService.getTooltips().subscribe((response: MultiRecordResponse<Tooltip>) => {
      if (response.success) {
        this.tooltips = response.data;
      } else {
        this.showErrorPopUp('Error', response.message || 'Something went wrong try again.');
      }
    }, (err: Error) => {
      console.log('ERROR while getting tooltips :: ', err);
      this.showErrorPopUp('Error', 'Something went wrong try again.');
    });
  }

  onSelectPage(event) {
    this.sectionOptions = this.sections[this.selectedPage];
  }

  updateTooltip(tooltipData: Tooltip): void {
    if (!tooltipData) {
      return;
    }
    console.log('Tooltip data ::: ', tooltipData);
    const params = {
      id: tooltipData._id,
      tooltip: tooltipData.tooltip
    }
    this.tooltipService.updateTooltip(params).subscribe((response: SimpleResponse) => {
      if (response.success) {
        this.showSuccess('Changes Applied!');
        this.getTooltips();
      } else {
        this.showErrorPopUp('Error', 'Something went wrong try again');
      }
    }, (err: Error) => {
      console.log(err)
    })
  }

  showSuccess(detail: string): void {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail,
    });
  }

  globalFilter($event: Event, filterType: string): void {
    this.table.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
