import {FormBuilder, FormGroup} from '@angular/forms';
import {RenewalLinkService} from './renewal-link.service';
import {SelectItem} from 'primeng/api';
import {Component, OnInit} from '@angular/core';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {RenewalLink} from '../../models/renewalLink.model';
import {MessageService} from 'primeng/api';
import {Title} from '@angular/platform-browser';
import {Column} from '../../models/column.model';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';

@Component({
  selector: 'app-renewal-link',
  templateUrl: './renewal-link.component.html',
  styleUrls: ['./renewal-link.component.scss'],
  providers: [MessageService],
})
export class RenewalLinkComponent implements OnInit {

  constructor(
    private renewalLinkService: RenewalLinkService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private title: Title,
  ) { }
  numberOnly = numberOnly;
  cols: Column[];
  brands: SelectItem<string>[];
  selectedBrand: string;
  brandConfigs: BrandCfg;
  renewalLinks: RenewalLink[] = [];
  canAddRenewalLink: boolean = false;
  loading: boolean = false;
  canRenewalLinkUpdate: boolean = true;
  canDeleteRenewalLink: boolean = true;
  addRenewalLinkForm: FormGroup = this.fb.group({
    renewalPrice: [],
    priceIncVat: [],
    link: []
  });

  ngOnInit(): void {
    this.initializeVariables();
    this.title.setTitle('CRM Renewal Links');
  }

  addRenewalLink() {
    this.canAddRenewalLink = false;
    // renewalPrice is stored as a string server side and needs to be correctly formatted
    const params: RenewalLink = {
      'brand': this.addRenewalLinkForm.value.brand,
      'renewalPrice': this.addRenewalLinkForm.value.renewalPrice.toFixed(2),
      'priceIncVat': this.addRenewalLinkForm.value.priceIncVat,
      'link': this.addRenewalLinkForm.value.link
    };
    params.renewalPrice = 
    params.brand = this.selectedBrand;
    console.log("Selected brand :: ", this.selectedBrand);
    this.renewalLinkService.addRenewalLink(params).subscribe(response => {
      this.canAddRenewalLink = true;
      console.log("Response on adding renewal link :: ", response);
      if (response.success) {
        this.getRenewalLinks()
        this.addRenewalLinkForm.reset();
        this.showSuccess('Renewal link has been added successfuly');
      } else {
        this.showError('Something went wrong try again');
      }
    }, (err: Error) => {
      this.canAddRenewalLink = true;
      console.log("Error while adding new renewal link :: ", err);
      this.showError('Something went wrong try again');
    })
  }

  updateRenewalLink(rowData: RenewalLink) {
    // renewalPrice is stored as a string server side and needs to be correctly formatted
    let renewalPriceFormatted: string;
    if (typeof rowData.renewalPrice == 'string') {
      if (/^\d*\.?\d*$/.test(rowData.renewalPrice)) {
        renewalPriceFormatted = Number(rowData.renewalPrice).toFixed(2);
      }
    } else {
      renewalPriceFormatted = rowData.renewalPrice.toFixed(2);
    }
    this.canRenewalLinkUpdate = false;
    const params: RenewalLink = {
      '_id': rowData._id,
      'brand': rowData.brand,
      'renewalPrice': renewalPriceFormatted,
      'priceIncVat': rowData.priceIncVat,
      'link': rowData.link,
    };
    this.renewalLinkService.updateRenewalLink(params).subscribe((response: SimpleResponse) => {
      this.canRenewalLinkUpdate = true;
      console.log("Response on update renewal link :: ", response);
      if (response.success) {
        this.getRenewalLinks();
        this.showSuccess(
          'Record updated successfully'
        );
      } else
        this.showError('Something went wrong try again');
    }, (err: Error) => {
      this.canRenewalLinkUpdate = true;
      console.log("Error on updating updateRenewalLink :: ", err);
      this.showError(
        err.message
      );
    });
  }

  deleteRenewalLink(_id: string) {
    this.canDeleteRenewalLink = false;
    this.renewalLinkService.deleteRenewalLink(_id).subscribe((response) => {
      this.canDeleteRenewalLink = true;
      if (response.success) {
        this.getRenewalLinks();
        this.showSuccess('Record is deleted successfully');
      } else
        this.showError('Something went wrong try again');
    }, (err: Error) => {
      this.canDeleteRenewalLink = true;
      this.showError('Something went wrong try again');
    })
  }

  initializeVariables() {
    this.cols = [
      { field: 'brand', header: 'Brand' },
      { field: 'renewalPrice', header: 'Renewal Price' },
      { field: 'priceIncVat', header: 'Price Inc Vat' },
      { field: 'link', header: 'Link' },
      { field: 'actions', header: 'Actions' },
    ];
    this.brands = getBrandSelectItems();
    this.brandConfigs = getBrandConfigs();
  }

  loadRenewalLinks() {
    if (!this.selectedBrand || !this.brandConfigs[this.selectedBrand])
      return;
    this.getRenewalLinks();
  }

  getRenewalLinks() {
    this.canAddRenewalLink = false;
    this.loading = true;
    this.renewalLinkService.getRenewalLinks(this.selectedBrand).subscribe(response => {
      if (response.success) {
        this.canAddRenewalLink = true;
        this.renewalLinks = response.data;
      } else {
        this.showError(
          'Something ent wrong try again'
        );
      }
      this.loading = false;
    }, (err: Error) => {
      console.log("Error on getRenewalLinks", err);
      this.loading = false;
      this.showError(err.message);
    })
  }

  showError(message:string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 300000
    });
  }

  showSuccess(message:string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
      life: 1000
    });
  }

}
