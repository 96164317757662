import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PopulatedProduct, RawProduct} from '../../models/product.model';
import {environment} from '../../../environments/environment';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {PopulatedVariation} from '../../models/variation.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private API_URL = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getAllProductsForSite(websiteId: string): Observable<MultiRecordResponse<PopulatedProduct>> {
    return this.http.get<MultiRecordResponse<PopulatedProduct>>(`${this.API_URL}/products/${websiteId}/all`);
  }

  getProductsForCsesPage(websiteId: string): Observable<MultiRecordResponse<RawProduct>> {
    return this.http.get<MultiRecordResponse<RawProduct>>(`${this.API_URL}/products/${websiteId}/cses`);
  }
  
  // TODO remove for hardware version
  getProductsForRecordsPage(websiteId: string): Observable<MultiRecordResponse<PopulatedProduct>> {
    return this.http.get<MultiRecordResponse<PopulatedProduct>>(`${this.API_URL}/products/${websiteId}/records`);
  }

  getKeySafesForRecordsPage(websiteId: string): Observable<MultiRecordResponse<PopulatedProduct>> {
    return this.http.get<MultiRecordResponse<PopulatedProduct>>(`${this.API_URL}/products/${websiteId}/keySafes`);
  }

  updateProduct(id: string, productParams: {'product': PopulatedProduct}): Observable<SingleRecordResponse<PopulatedProduct>> {
    return this.http.put<SingleRecordResponse<PopulatedProduct>>(`${this.API_URL}/products/${id}`, productParams);
  }

  updateVariation(id: number, variationParams: {'variation': PopulatedVariation}): Observable<SingleRecordResponse<PopulatedVariation>> {
    return this.http.put<SingleRecordResponse<PopulatedVariation>>(`${this.API_URL}/variations/${id}`, variationParams);
  }
}

