import {Column} from '../../models/column.model';

const asAtOrderCols: Column[] = [
  {field: 'fromDate', header: 'Effective From Date', order: 10, hide: true},
  {field: 'toDate', header: 'Effective To Date', order: 20, hide: true},
  {field: 'deleted', header: 'Order Deleted', order: 30, hide: true},
  {field: 'orderId', header: 'Order Id', order: 40, hide: true},
  {field: 'brand', header: 'Brand', order: 50, hide: false},
  {field: 'tdCode', header: 'Td Code', order: 60, hide: false},
  {field: 'websiteOrderId', header: 'Website Order Id', order: 70, hide: true},
  {field: 'orderStatus', header: 'Status', order: 80, hide: false},
  {field: 'userPostcode', header: 'Postcode', order: 90, hide: false},
  {field: 'numberOfAdditionalUsers', header: 'Additional Users', order: 100, hide: true},
  {field: 'planCode', header: 'Plan', order: 110, hide: false},
  {field: 'planType', header: 'Plan Type', order: 120, hide: false},
  {field: 'vatStatus', header: 'VAT', order: 130, hide: false},
  {field: 'renewalDate', header: 'Renewal Date', order: 140, hide: false},
  {field: 'paymentDueDate', header: 'Payment Due Date', order: 150, hide: false},
  {field: 'renewalPrice', header: 'Renewal Price', order: 160, hide: false},
  {field: 'renewalType', header: 'Renewal Type', order: 170, hide: false},
  {field: 'freeMonths', header: 'Free Months', order: 180, hide: false},
  {field: 'orderDate', header: 'Order Date', order: 190, hide: false},
  {field: 'recordCreated', header: 'Order Created Date', order: 200, hide: true},
  {field: 'tags', header: 'Tags', order: 210, hide: false},
  {field: 'actions', header: 'Action Title', order: 220, hide: false},
  {field: 'cancellingDate', header: 'Cancellation Date', order: 230, hide: false},
  {field: 'cancellationReason', header: 'Cancellation Reason', order: 240, hide: false},
  {field: 'detailedCancellationReason', header: 'Detailed Cancellation Reason', order: 250, hide: false},
  {field: 'otherCancellationReason', header: 'Cancellation Other Reason', order: 260, hide: false},
  {field: 'cancelledDate', header: 'Return Date', order: 270, hide: false},
  {field: 'legalCompany', header: 'Legal Company', order: 280, hide: false},
];

export {
  asAtOrderCols,
}
