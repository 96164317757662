<p-dialog class="create-notification" header="Create Notification Message" (onHide)="onHideDialog()"
  [(visible)]="display" [modal]="true" [style]="{width: '75vw', minWidth: '200px'}" [minY]="70"
  [maximizable]="true" [baseZIndex]="10000">
  <div class="container">
    <form>
      <div class="row">
        <div class="form-group" style="width: 100%;">
          <label for="messageSummary">Notification Summary</label>
          <input type="text" class="form-control" name="messageSummary" [(ngModel)]="notificationSummary"
            [ngClass]="notificationSummaryRef.errors?.required?'notificationRequired':'notificationProvided'"
            #notificationSummaryRef="ngModel" #summaryInput required
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group" style="width: 100%;">
          <label for="notificationText">Notification Message Text</label>
          <textarea class="form-control" class="form-control" [rows]="7" cols="30" pInputTextarea
            name="notificationText" [(ngModel)]="notificationText" #notificationTextRef></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          Send To:
        </div>
        <div class="col-md-6">
          <div class="create-notification-multiselect row no-gutters px-1"
              [ngClass]="isAllUsers || selectedUsers?.length || selectedGroups.length?'notificationProvided':'notificationRequired'">
            <div class="col-12 mb-2">
              <p-checkbox name="allUsers" label="All Users" [(ngModel)]="isAllUsers" [binary]="true" [required]=true>
              </p-checkbox>
            </div>
            <div class="col-12 mb-2">
              <p-multiSelect name="selectedGroup" [options]="groups" [(ngModel)]="selectedGroups" [panelStyle]="{minWidth:'12em'}"
                placeholder="Select Groups" [disabled]="isAllUsers">
              </p-multiSelect>
            </div>
            <div class="col-12">
              <p-multiSelect name="selectedUsers" [options]="users" [(ngModel)]="selectedUsers" [panelStyle]="{minWidth:'12em'}"
                placeholder="Select Users" [disabled]="isAllUsers">
              </p-multiSelect>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <button type="button" class="btn btn-primary" 
            [disabled]="!(notificationSummary != '' && (isAllUsers || selectedGroups.length>0 || selectedUsers.length>0))"
            (click)="createNotification()"
          >
            Create
          </button>
        </div>
      </div>
    </form>
  </div>
  <p-toast [style]="{marginTop: '80px'}"></p-toast>
</p-dialog>

<p-dialog class="create-notification-view" header="Notification Summary" (onHide)="onHideNotificationSummaryDialog()"
    [(visible)]="notificationSummaryDisplay" [modal]="true"
    [style]="{width: '75vw', minWidth: '200px'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
  <div class="container">
    <div class="row">
      <div class="form-group" style="width: 100%;">
        <label for="messageSummary">Notification Summary</label>
        <input type="text" class="form-control" name="messageSummary" [(ngModel)]="notificationSummary"
          #notificationSummaryRef="ngModel" readonly />
      </div>
    </div>
    <div class="row">
      <div class="form-group" style="width: 100%;">
        <label for="notificationText">Notification Message Text</label>
        <textarea class="form-control" class="form-control" [rows]="5" cols="30" pInputTextarea
          name="notificationText" [(ngModel)]="notificationText" #notificationTextRef readonly></textarea>
      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-2">
        Send To:
      </div>
      <div class="ui-g-12 ui-md-4">
        <p-scrollPanel [style]="{width: '100%', height: '160px'}" styleClass="custombar1">
          <div style="padding:1em;line-height:1.5">
            <span *ngFor="let item of sendToForMessageSummary" style="font-size: 15px;font-weight: 15px;" class="badge badge-secondary mt-2 ml-2">{{item}} </span>
          </div>
        </p-scrollPanel>
      </div>
      <div class="ui-g-12 ui-md-4">
        <div class="ui-g-12"></div>
        <div class="ui-g-12"></div>
        <div class="ui-g-12"></div>
        <div class="ui-g-12"></div>
        <div class="ui-g-12"></div>
        <div class="ui-g-12"></div>
        <div class="ui-g-12"></div>
        <div class="ui-g-12"></div>
        <button type="button" class="btn btn-primary" (click)="finalSubmit()" [disabled]="isConfirmDisabled">Confirm</button>
        <button type="button" class="btn btn-warning ml-2" (click)="onHideNotificationSummaryDialog()">Cancel</button>
      </div>
    </div>
  </div>
</p-dialog>