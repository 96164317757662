import {Observable} from 'rxjs';
import {environment} from './../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActionListResponse} from '../models/responses/actionListResponse.model';
import {BoardSummaryResponse} from '../models/responses/boardSummaryResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {ActionCompetionSummary} from '../models/actionCompetionSummary.model';

@Injectable({
  providedIn: 'root'
})
export class HomeOutboundService {
  private API_URL: string = environment.protocol + environment.IPAddress + '/api';
  constructor(
    private http: HttpClient
  ) { }

  getYesterdaysReport(board: string): Observable<SingleRecordResponse<ActionCompetionSummary>> {
    return this.http.get<SingleRecordResponse<ActionCompetionSummary>>(this.API_URL + `/boards/yesterday/completions/${board}`);
  }

  getDataForActionPerBox(board: string): Observable<BoardSummaryResponse> {
    return this.http.get<BoardSummaryResponse>(this.API_URL + `/boards/grouped/by-actions/${board}`);
  }

  getDataByActionAndShift(data: { actionType: string, shift: string }): Observable<ActionListResponse> {
    return this.http.post<ActionListResponse>(this.API_URL + '/boards/by-action/by-shift/', data);
  }

  getOutstandingActionOnSameOrder(data: { orderId: string, actionName: string }) {
    return this.http.post(`${this.API_URL}/boards/same-order/outsanding-actions`, data);
  }
}
