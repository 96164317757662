import {Component, OnInit} from '@angular/core';
import {Tag} from '../../models/tag.model';
import {TagsService} from './tags.service';
import {TagsResponse} from '../../models/responses/tagsResponse.model';
import {TagResponse} from '../../models/responses/tagResponse.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  newTag: Tag;
  tags: Tag[];
  archivedTags: Tag[];

  constructor(private tagsService: TagsService,
              private title: Title,
  ) {
    this.newTag = {tagName: '', color: '', allowExpiry: false};
  }

  ngOnInit() {
    this.tagsService.getAllTags().subscribe((tagsResponse: TagsResponse) => {
      this.newTag = {tagName: '', color: '', allowExpiry: false};
      this.tags = tagsResponse['tags'].filter((tag: Tag) => !tag['archived']);
      this.archivedTags = tagsResponse['tags'].filter((tag: Tag) => tag['archived']);
    }, err => {
      //console.log(err);
    });
    this.title.setTitle('CRM Tags');
  }

  save() {
    //console.log(this.tag.tagName);
    // //console.log(this.tags.tagName!=undefined && this.tags.tagName != '' && this.tags.tagName != ' ');
    if (this.newTag.tagName && this.newTag.tagName != '' && this.newTag.tagName != ' ') {
      this.tagsService.createTag({'tag': this.newTag}).subscribe((tagResponse: TagResponse) => {
        this.tags.push(tagResponse['tag']);
        this.newTag = {tagName: '', color: '', allowExpiry: false};
        //console.log(tag['tag']);
      }, err => {
        //console.log(err);

      });
    }

  }

  updateTag(tag: Tag) {
    //console.log(tag);
    this.tagsService.updateTag(tag._id, {'tag': tag}).subscribe((_tagResponse: TagResponse) => {
      //console.log();
    }, err => {
      //console.log(err);

    });
  }

  tagActivation(tag, i)  {
    //console.log(tag);
    tag['archived'] = false;
    this.tagsService.updateTag(tag._id, {'tag': tag}).subscribe((_tag: TagResponse) => {
      //console.log();
    }, err => {
      //console.log(err);
    });
    this.archivedTags.splice(i, 1);
    this.tags.push(tag);

  }

  deleteTag(tag, i) {
    //console.log(tag);
    this.tagsService.deleteTag(tag._id).subscribe((_tag: TagResponse) => {
      //console.log();
    }, err => {
      //console.log(err);
    });
    this.tags.splice(i, 1);
    this.archivedTags.push(tag);
  }
}
