<p-dialog (onHide)="closeDialog()" header="Post Spreadsheet" [(visible)]="display" [style]="{width: '60vw'}"
    [baseZIndex]="3100" [blockScroll]="true">
  <div class="row">
    <div class="col-md-12">
      <h6>Name & Address</h6>
      <textarea class="form-control" cols="30" pInputTextarea autoResize="autoResize" style="width: 100%;"
        [(ngModel)]="userInformation" appAutoResizeTextarea>
      </textarea>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="closeDialog()" label="Close" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>