<div class="add-new-action">
  <p-dialog header="Add New Action" (onHide)="closeDialog()" [(visible)]="displayModal" [modal]="true"
      [style]="{width: '90vw'}" [draggable]="false" [resizable]="false">
    <form [formGroup]="addActionForm" #form="ngForm">
      <ng-template #addActionFormRef>
        <div class="row" *ngIf="duplicateActionError">
          <div class="form-group col-12">
            <div class="alert alert-danger">{{duplicateActionError}}</div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            Be aware that regardless of a field's label the changelog will show the field name from the
            database, so it is best to use fields for a similar purpose as the name above the input indicates.
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Action Name</label>
            <input type="text" name="actionName" class="form-control" formControlName="actionName"
              placeholder="Enter action name" (change)="onActionNameChange()" [readonly]="openMode == 'edit'" required>
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <div class="row">
              <div class="col-6">
                <label>Background</label>
                <input type="color" style="height: 33px;" name="background" class="form-control" formControlName="background" required>
              </div>
              <div class="col-6">
                <label>Text Colour</label>
                <input type="color" style="height: 33px;" name="color" class="form-control" formControlName="color" required>
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <div class="row">
              <div class="col-6">
                <label>Default Due In Days</label>
                <input type="text" name="defaultDueInDays" class="form-control" (keypress)="numberOnly($event, false)"
                  formControlName="defaultDueInDays" placeholder="Enter default due in days">
              </div>
              <div class="col-6">
                <p-checkbox class="center-checkbox" name="usersCanAdd" [binary]="true" label="Users Can Add?" formControlName="usersCanAdd">
                </p-checkbox>
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Returned Equip Label (equip dropdown)</label>
            <input type="text" name="returnedEquipLabel" class="form-control" formControlName="returnedEquipLabel"
              placeholder="Enter returned equip label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Returned Equip Two Label (equip dropdown)</label>
            <input type="text" name="returnedEquipTwoLabel" class="form-control" formControlName="returnedEquipTwoLabel"
              placeholder="Enter returned equip two label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Returned Equip Three Label (equip dropdown)</label>
            <input type="text" name="returnedEquipThreeLabel" class="form-control"
              formControlName="returnedEquipThreeLabel" placeholder="Enter returned equip three label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Person Returning Label (single line text)</label>
            <input type="text" name="personReturningLabel" class="form-control" formControlName="personReturningLabel"
              placeholder="Enter person returning label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Return Label Numbers Label (multi-line text)</label>
            <input type="text" name="returnLabelNumbersLabel" class="form-control"
              formControlName="returnLabelNumbersLabel" placeholder="Enter return label numbers label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Owed Payment Label (decimal number)</label>
            <input type="text" name="owedPaymentLabel" class="form-control" formControlName="owedPaymentLabel"
              placeholder="Enter owed payment label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Cancellation Email Label (single line text)</label>
            <input type="text" name="cancellationEmailLabel" class="form-control"
              formControlName="cancellationEmailLabel" placeholder="Enter cancellation email label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Hold Until Label (date, min 01/01/2014)</label>
            <input type="text" name="holdUntilLabel" class="form-control" formControlName="holdUntilLabel"
              placeholder="Enter hold until label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Status Label (single line text)</label>
            <input type="text" name="statusLabel" class="form-control" formControlName="statusLabel"
              placeholder="Enter status label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Date Label (date and time, min today)</label>
            <input type="text" name="dateLabel" class="form-control" formControlName="dateLabel"
              placeholder="Enter date label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Note Label (multi-line text)</label>
            <input type="text" name="noteLabel" class="form-control" formControlName="noteLabel"
              placeholder="Enter note label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Count Label (integer number)</label>
            <input type="text" name="countLabel" class="form-control" formControlName="countLabel"
              placeholder="Enter count label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Responsible Person Label (single line text)</label>
            <input type="text" name="responsiblePersonNameLabel" class="form-control" formControlName="responsiblePersonNameLabel"
              placeholder="Responsible Person Label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Contact No. Label (with valid/invalid indicator)</label>
            <input type="text" name="contactNumberLabel" class="form-control" formControlName="contactNumberLabel" placeholder="Contact Number Label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Email Label (with valid/invalid indicator)</label>
            <input type="text" name="emailAddressLabel" class="form-control" formControlName="emailAddressLabel" placeholder="Email Address Label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <label>Reason Label (text or dropdown, if configured)</label>
            <input type="text" name="reasonLabel" class="form-control" formControlName="reasonLabel" placeholder="Reason Label">
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <div class="row">
              <div class="col-6">
                <label>Board Name</label>
                <input type="string" name="board" class="form-control" formControlName="board" placeholder="Enter board name">
              </div>
              <div class="col-6">
                <label>Board Sort Order</label>
                <input type="number" name="boardSortOrder" class="form-control" (keypress)="numberOnly($event, false)" formControlName="boardSortOrder"
                  placeholder="Enter board sort order">
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-md-6 col-xl-4">
            <p-checkbox class="center-checkbox" name="allowDueDateFilterOnCC" [binary]="true" label="Due Date Filter Allowed on CC?" formControlName="allowDueDateFilterOnCC">
            </p-checkbox>
          </div>
          <!-- CRM not yet changed to use this, so avoid confusion
          <div class="form-group col-3">
            <p-checkbox class="center-checkbox" name="attention" [binary]="true" label="Attention"
                formControlName="attention">
            </p-checkbox>
          </div> -->
        </div>
      </ng-template>
      <div *ngIf="openMode != 'view'">
        <ng-container *ngTemplateOutlet="addActionFormRef"></ng-container>
      </div>
      <div *ngIf="openMode == 'view'">
        <fieldset [disabled]="true">
          <ng-container *ngTemplateOutlet="addActionFormRef"></ng-container>
        </fieldset>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <button type="button" class="btn btn-warning" (click)="closeDialog()">Cancel</button>
      <button *ngIf="openMode !='view'" type="submit" class="btn btn-primary mr-2" [disabled]="form.invalid"
        (click)="openMode == 'add'?addAction():updateAction();">
        Submit
      </button>
    </ng-template>
  </p-dialog>
</div>