import {getExternalEmailValidator} from './../../validators/email.validator';
import {PostSheet} from './../../models/postSheet.model';
import {OrderService} from './../../post-order/order.service';
import {FormBuilder, Validators, FormGroup, FormControl, AbstractControl} from '@angular/forms';
import {SelectItem, MessageService} from 'primeng/api';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {PostSheetService} from '../post-sheet.service';
import {getSendReasonOptions, getWhatToSendOptions, SendReason, WhatToSend} from '../../lookups/postSheet';
import * as moment from 'moment-timezone';
import {phoneNumberValidator} from '../../validators/phone-number.validator';
import {Order} from '../../models/order.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {Address, AddressWithName} from '../../models/address.model';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {Lead} from '../../models/lead.model';
import {environment} from '../../../environments/environment';
import getAddressClient,{FindFailed, FindSuccess, Result} from 'getaddress-api';
import {getLookupFromGetAddressResult} from '../../helpers/getAddressHelper';
import {Observable} from 'rxjs';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';

@Component({
  selector: 'app-add-post-sheet[openMode][closeAddPostSheet]',
  templateUrl: './add-post-sheet.component.html',
  styleUrls: ['./add-post-sheet.component.scss'],
  providers: [MessageService]
})

export class AddPostSheetComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private orderService: OrderService,
    private postSheetService: PostSheetService,
    
  ) { }

  @Output() closeAddPostSheet: EventEmitter<PostSheet|string|undefined> = new EventEmitter<PostSheet|string|undefined>();
  @Input() openMode: string;
  @Input() postSheetDetail: PostSheet;
  @Input() order: Order;
  @Input() lead: Lead;
  displayModal = true;
  todayDate = moment.tz('Europe/London').startOf('day').format('YYYY-MM-DD');
  brands: SelectItem<string>[] = getBrandSelectItems();
  brandConfigs: BrandCfg = getBrandConfigs();
  whatToSendOptions: WhatToSend[] = getWhatToSendOptions();
  sendReasonOptions: SendReason[] = getSendReasonOptions();
  orderId: string;
  userName: string;
  isSubmitDisabled: boolean = false;
  header: string = "Add Post Sheet"
  postSheetTypes: SelectItem<string>[];
  vatOrNotOptions: SelectItem<string>[];
  paymentMethodOptions: SelectItem<string>[];
  infoPackAndOrderFormOptions: WhatToSend[];
  chasersWhatToSendOptions: WhatToSend[];
  addPostSheetForm: FormGroup = new FormGroup({});
  loading: boolean = true;
  availableContactDetails: SelectItem<AddressWithName>[] = [];
  selectedContactDetails: AddressWithName;
  numberOnly = numberOnly;
  numberPlusDecimal = numberPlusDecimal;
  reasonsThatMustBePaidFor: string[] = ['Lost BY CUSTOMER', 'Additional', 'Upgrade', 'Damaged'];
  getAddressKey: string = environment.getAddressDomainToken;
  addPostSheetAddressSearchPostCode: string;
  addPostSheetAddressResults: SelectItem<Address>[];
  getAddrClient: getAddressClient;
  postSheetSearchError: string;

  ngOnInit(): void {
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);
    if (this.openMode == 'add') {
      this.initializeVariables({'validated': false});
      this.disableControls(['postedOn','postedBy']);
      this.loading = false;
    } else if (this.openMode == 'addFromOrder') {
      this.orderId = this.order._id;
      this.initializeVariables({
        'orderId': {
          'alarmUserDetails': {
            'tdCode': this.order.alarmUserDetails.tdCode,
            'emailMarketing': '',
            'emailTwoMarketing': '',
            'mobileMarketing': '',
          }
        },
        'websiteId': this.order.website,
        'validated': false,
      });
      this.disableControls(['postedOn','postedBy']);
      // We don't need mobile or address for emailing
      if (this.order.alarmUserDetails.correspondenceDetails?.firstName || this.order.alarmUserDetails.correspondenceDetails?.lastName) {
        this.availableContactDetails.push({
          label: `Correspondent: ${this.order.alarmUserDetails.correspondenceDetails.firstName} ${this.order.alarmUserDetails.correspondenceDetails.lastName}`,
          value: {
            'name': `${this.order.alarmUserDetails.correspondenceDetails.firstName} ${this.order.alarmUserDetails.correspondenceDetails.lastName}`,
            'addressOne': this.order.alarmUserDetails.correspondenceDetails.addressOne,
            'addressTwo': this.order.alarmUserDetails.correspondenceDetails.addressTwo,
            'city': this.order.alarmUserDetails.correspondenceDetails.city,
            'county': this.order.alarmUserDetails.correspondenceDetails.county,
            'postcode': this.order.alarmUserDetails.correspondenceDetails.postcode,
            'validated': this.order.alarmUserDetails.correspondenceDetails.validated,
          }
        });
      }
      this.availableContactDetails.push({
        label: `Alarm User: ${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}`,
        value: {
          'name': `${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}`,
          'addressOne': this.order.alarmUserDetails.userAddress.addressOne,
          'addressTwo': this.order.alarmUserDetails.userAddress.addressTwo,
          'city': this.order.alarmUserDetails.userAddress.city,
          'county': this.order.alarmUserDetails.userAddress.county,
          'postcode': this.order.alarmUserDetails.userAddress.postcode,
          'validated': this.order.alarmUserDetails.userAddress.validated,
        }
      });
      let userNumber: number = 1;
      this.order.alarmUserDetails.users.forEach(user => {
        this.availableContactDetails.push({
          label: `Additional User ${userNumber++}: ${user.firstName} ${user.lastName}`,
          value: {
            'name': `${user.firstName} ${user.lastName}`,
            'addressOne': this.order.alarmUserDetails.userAddress.addressOne,
            'addressTwo': this.order.alarmUserDetails.userAddress.addressTwo,
            'city': this.order.alarmUserDetails.userAddress.city,
            'county': this.order.alarmUserDetails.userAddress.county,
            'postcode': this.order.alarmUserDetails.userAddress.postcode,
            'validated': this.order.alarmUserDetails.userAddress.validated,
          }
        });
      });
      let contactNumber: number = 1;
      this.order.accountContacts.forEach(accountContact => {
        this.availableContactDetails.push({
          label: `Account Contact ${contactNumber++}: ${accountContact.accFirstName} ${accountContact.accLastName}`,
          value: {
            'name': `${accountContact.accFirstName} ${accountContact.accLastName}`,
            'addressOne': '',
            'addressTwo': '',
            'city': '',
            'county': '',
            'postcode': '',
            'validated': false,
          }
        });
      });
      this.loading = false;
    } else if (this.openMode == 'addFromLead') {
      this.initializeVariables({
        'websiteId': getBrandConfigs()[this.lead.brand],
        'phone':  this.lead.alarmUserDetails.telephone || this.lead.alarmUserDetails.mobile,
        'email': this.lead.alarmUserDetails.email,
        'name': `${this.lead.alarmUserDetails.firstName} ${this.lead.alarmUserDetails.lastName}`,
        'addressLineOne': this.lead.alarmUserDetails.userAddress.addressOne,
        'addressLineTwo': this.lead.alarmUserDetails.userAddress.addressTwo,
        'city': this.lead.alarmUserDetails.userAddress.city,
        'county': this.lead.alarmUserDetails.userAddress.county,
        'postcode': this.lead.alarmUserDetails.userAddress.postcode,
        'validated': this.lead.alarmUserDetails.userAddress.validated,
      });
      this.disableControls(['postedOn','postedBy']);
      this.loading = false;
    } else if (this.openMode == 'view') {
      this.getPostSheet();
      this.header = 'Post Sheet Detail';
    } else if (this.openMode == 'update') {
      this.header = 'Update Post Sheet';
      this.getPostSheet();
    }
  }

  addPostSheetAddressSearch() {
    if (!this.addPostSheetAddressSearchPostCode) {
      return;
    }
    this.addPostSheetAddressResults = [];
    this.postSheetSearchError = '';
    this.getAddrClient.find(this.addPostSheetAddressSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.addPostSheetAddressResults = getLookupFromGetAddressResult(this.addPostSheetAddressSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.postSheetSearchError = addressResult.toFailed().message;
        console.error('Post sheet Address search failed. Error:', this.postSheetSearchError);
      } else if (this.addPostSheetAddressResults.length <= 2) {
        this.postSheetSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Post sheet Address search failed. Error:', error);
    })
  }

  setPostSheetAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.addPostSheetForm.patchValue({
        'postcode': selectedAddress?.postcode,
        'validated': false,
      });
      return;
    }
    // Don't want to copy Role
    this.addPostSheetForm.patchValue({
      'addressLineOne': selectedAddress.addressOne,
      'addressLineTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    });
  }

  initializeVariables(postSheet: Partial<PostSheet>) {
    this.addPostSheetForm = this.fb.group({
      websiteId: [postSheet?.websiteId?.title],
      whatToSend: [postSheet?.whatToSend, Validators.required],
      reason: [postSheet?.reason, Validators.required],
      comments: [postSheet?.comments],
      tdCode: [postSheet?.orderId?.alarmUserDetails?.tdCode],
      addedBy: [(postSheet?.addedBy || localStorage.getItem('userName')), Validators.required],
      class: [postSheet?.class, Validators.required],
      paidAndMethod: [postSheet?.paidAndMethod, (Validators.required, null)],
      paidAmount: [postSheet?.paidAmount, (Validators.required, null)],
      vatOrNot: [postSheet?.vatOrNot, (Validators.required, null)],
      paymentMethod:  [postSheet?.paymentMethod, (Validators.required, null)],
      postedBy: [postSheet?.postedBy],
      postedOn:[postSheet?.postedOn? moment.tz(postSheet?.postedOn,'Europe/London').format('YYYY-MM-DD'): ''],
      orderedByOrderedFor: [postSheet?.orderedByOrderedFor, Validators.required],
      phone: [postSheet?.phone, phoneNumberValidator],
      email: [postSheet?.email, getExternalEmailValidator(false)],
      callbackDate: [postSheet?.callbackDate? moment.tz(postSheet?.postedOn,'Europe/London').format('YYYY-MM-DD'): ''],
      labelCreated: [postSheet?.labelCreated],
      name: [postSheet?.name, Validators.required],
      addressLineOne: [postSheet?.addressLineOne, Validators.required],
      addressLineTwo: [postSheet?.addressLineTwo],
      city: [postSheet?.city, Validators.required],
      county: [postSheet?.county],
      postcode: [postSheet?.postcode, Validators.required],
      dateSent: [postSheet?.dateSent? moment.tz(postSheet?.dateSent,'Europe/London'): null],
      validated: [postSheet?.validated],
    });
    let postSheetTypeControl: AbstractControl;
    if (this.openMode == 'addFromOrder') {
      postSheetTypeControl = this.fb.control('Item');
    } else if (this.openMode == 'addFromLead') {
      postSheetTypeControl = this.fb.control('Info Pack');
    } else {
      postSheetTypeControl = this.fb.control(postSheet?.postSheetType, [Validators.required]);
    }
    this.addPostSheetForm.addControl('postSheetType', postSheetTypeControl);
    this.postSheetTypes = [
      { label: 'Item', value: 'Item' },
      { label: 'Info Pack', value: 'Info Pack' },
      { label: 'Wrong Provider Calls', value: 'Wrong Provider Calls' },
      { label: 'Chasers to be sent', value: 'Chasers to be sent' }
    ];
    this.chasersWhatToSendOptions = [
      { label: 'Postcard (SIGNUP45)', value: 'Postcard (SIGNUP45)' },
      { label: '1 yr flyer (INFO35)', value: '1 yr flyer (INFO35)' },
      { label: 'Wrong Provider (35SWITCH)', value: 'Wrong Provider (35SWITCH)' }
    ];
    //this.infoPackAndOrderFormOptions = this.whatToSendOptions.filter(value: this.infoPackOptions)

    this.infoPackAndOrderFormOptions = [
      { label: 'Info pack and order form', value: 'info pack and order form'},
      { label: '30 leaflets', value: '30 leaflets' },
      { label: '100 leaflets', value: '100 leaflets' },
      { label: '200+ leaflets', value: '200+ leaflets' }
    ];
    this.vatOrNotOptions = [
      { label: 'Not exempt', value: 'Not exempt' },
      { label: 'Exempt', value: 'Exempt' },
    ];
    this.paymentMethodOptions = [
      { label: 'Stripe', value: 'Stripe' },
      { label: 'DD', value: 'DD' },
      { label: 'Cheque', value: 'Cheque' },
      { label: 'BACS', value: 'BACS' },
      { label: 'Go Cardless', value: 'Go Cardless' },
      { label: 'Free Of Charge', value: 'Free Of Charge' },
    ];
    this.changeItem(true);
  }
  get reasonBeingSent(): string {
    return this.addPostSheetForm.get('reason').value;
  }

  changeItem(duringInitialisation: boolean) {
    const postSheetType: string = this.addPostSheetForm.get('postSheetType').value;
    if (postSheetType == 'Chasers to be sent') {
      this.whatToSendOptions = this.chasersWhatToSendOptions;
      this.disableControls(['websiteId', 'reason', 'comments', 'paidAmount', 'vatOrNot', 'paymentMethod', 'phone', 'email', 'orderedByOrderedFor', 'labelCreated', 'dateSent', 'callbackDate']);
      this.enableControls(['whatToSend', 'class', 'name', 'addressLineOne', 'addressLineTwo', 'city', 'county', 'postcode']);
    } else if (postSheetType == 'Item') {
      this.whatToSendOptions = getWhatToSendOptions();
      this.disableControls([ 'phone', 'email', 'orderedByOrderedFor', 'labelCreated', 'dateSent', 'callbackDate']);
      this.enableControls(['websiteId', 'reason', 'comments', 'paidAmount', 'vatOrNot', 'paymentMethod', 'whatToSend', 'class', 'name', 'addressLineOne', 'addressLineTwo', 'city', 'county', 'postcode']);
    } else if (postSheetType == 'Info Pack') {
      this.whatToSendOptions = this.infoPackAndOrderFormOptions;
      this.disableControls(['labelCreated', 'dateSent', 'reason', 'paidAmount', 'vatOrNot', 'paymentMethod', 'class']);
      this.enableControls(['websiteId', 'phone', 'email', 'orderedByOrderedFor', 'comments', 'whatToSend', 'name', 'addressLineOne', 'addressLineTwo', 'city', 'county', 'postcode', 'callbackDate']);
    } else  if (postSheetType == 'Wrong Provider Calls') {
      this.disableControls(['reason', 'comments', 'paidAmount', 'vatOrNot', 'paymentMethod', 'phone', 'email', 'orderedByOrderedFor', 'whatToSend', 'class', 'callbackDate']);
      this.enableControls(['websiteId', 'labelCreated', 'dateSent', 'name', 'addressLineOne', 'addressLineTwo', 'city', 'county', 'postcode']);
    }
    if (!duringInitialisation) {
      this.addPostSheetForm.get('whatToSend').setValue(undefined);
    }
    
  }

  disableControls(controls: string[]) {
    controls.forEach((control: string) => {
      this.addPostSheetForm.get(control)?.disable();
    });
  }

  enableControls(controls: string[]) {
    controls.forEach((control: string) => {
      this.addPostSheetForm.get(control)?.enable();
    });
  }

  changeWhatToSend(event: DropDownChangeEvent<string>) {
    if (!event.value) {
      this.addPostSheetForm.get('class').patchValue(undefined);
    } else {
      const selectedOption: WhatToSend = this.whatToSendOptions.find((option: WhatToSend) =>
        option.value == event.value
      );
      if (selectedOption) {
        this.addPostSheetForm.get('class').patchValue(selectedOption.class);
      } else {
        this.addPostSheetForm.get('class').patchValue(undefined);
      }
    }
  }

  changeWhySend(event: DropDownChangeEvent<string>) {
    if (this.reasonsThatMustBePaidFor.includes(event.value)) {
      //paidAndMethod required
      this.addPostSheetForm.get('paidAmount' && 'vatOrNot' && 'paymentMethod').setValidators(Validators.required);
    } else {
      //paidAndMethod not required
      this.addPostSheetForm.get('paidAmount' && 'vatOrNot' && 'paymentMethod').clearValidators();
    }
    this.addPostSheetForm.get('paidAmount' && 'vatOrNot' && 'paymentMethod').updateValueAndValidity();
  }

  addPostSheet() {
    this.isSubmitDisabled = true;
    console.log("Values on addPostSheet :: ", this.addPostSheetForm.value);
    let params = { ...this.addPostSheetForm.value };
    if (this.tdCode?.value && this.orderId) {
      params.orderId = this.orderId;
    }
    if (this.openMode.startsWith('add')) {
      params['addedBy'] = localStorage.getItem('userName');
    }
    params.websiteId = this.brandConfigs[this.addPostSheetForm.value?.websiteId]?._id;
    this.postData(params).subscribe((response: SingleRecordResponse<PostSheet>) => {
      this.isSubmitDisabled = false;
      this.displayModal = false;
      console.log("Response on adding post-sheet :: ", response);
      if (response.success) {
        this.closeAddPostSheet.emit(response.data)
      } else {
        this.closeAddPostSheet.emit(response.message);
      }
    }, (err: Error) => {
      this.isSubmitDisabled = false;
      this.closeAddPostSheet.emit(err.message);
      this.displayModal = false;
      console.error("Error on adding post-sheet :: ", err);
    });
  }

  postPostSheet() {
    this.isSubmitDisabled = true;
    let params = { ...this.addPostSheetForm.value };
    if (this.tdCode?.value && this.orderId) {
      params.orderId = this.orderId;
    }
    if (!params['postedOn']) {
      params['postedOn'] = moment.tz('Europe/London').toDate();
    }
    if (!params['postedBy']) {
      params['postedBy'] = localStorage.getItem('userName');
    }
    params['addedBy'] = this.postSheetDetail.addedBy;
    params['_id'] = this.postSheetDetail._id;
    if (this.brandConfigs[this.addPostSheetForm.value?.websiteId]) {
      params.websiteId = this.brandConfigs[this.addPostSheetForm.value?.websiteId]._id;
    }
    this.postSheetService.postPostSheet(params).subscribe((response: SingleRecordResponse<PostSheet>) => {
      this.isSubmitDisabled = false;
      this.displayModal = false;
      console.log("Response on posting post-sheet :: ", response);
      if (response.success) {
        this.closeAddPostSheet.emit(response.data)
      } else {
        this.closeAddPostSheet.emit(response.message);
      }
    }, (err: Error) => {
      this.isSubmitDisabled = false;
      this.closeAddPostSheet.emit(err.message);
      this.displayModal = false;
      console.error("Error on adding post-sheet :: ", err);
    });
  }

  postData(params: PostSheet): Observable<SingleRecordResponse<PostSheet>> {
    if (params['postedBy'] && !params['postedOn']) {
      params['postedOn'] = moment.tz('Europe/London').toDate();
    }
    if (params['postedOn'] && !params['postedBy']) {
      params['postedBy'] = localStorage.getItem('userName');
    }

    if (params['postedBy']) {
      // Need to call patch so record gets moved into post archive
      if (this.openMode == 'update') {
        params['_id'] = this.postSheetDetail._id;
      }
      return this.postSheetService.postPostSheet(params);
    } else {
      if (['add', 'addFromOrder', 'addFromLead'].includes(this.openMode)) {
        return this.postSheetService.addPostSheet(params);
      } else {
        params['_id'] = this.postSheetDetail._id;
        return this.postSheetService.updatePostSheet(params);
      }
    }
  }

  getPostSheet() {
    this.postSheetService.getPostSheetById(this.postSheetDetail?._id).subscribe((response) => {
      if (response.success) {
        this.initializeVariables(response.data || {'validated': false});
      } else {
        this.showError('Something went wrong try again');
        this.initializeVariables({'validated': false});
      }
      this.loading = false;
    }, (err) => {
      this.showError('Something went wrong try again');
    })
  }

  validateTdCode(): void {
    if (!this.tdCode?.value) {
      return;
    }
    this.orderService.findOrder({
      'alarmUserDetails.tdCode': this.tdCode?.value,
      'deleted': false
    }).subscribe((response) => {
      console.log("Response :: ", response);
      if (!response.success || !response.orders) {
        this.showError(
          'Error looking up order id from tdCode. Please check the code and try again'
        );
      } else if (response.orders.length != 1) {
        this.showError(
          `Error looking up order id from tdCode. There are ${response.orders.length} matches`
        );
      } else {
        this.orderId = response.orders[0]._id;
      }
    }, (err: Error) => {
      console.log("Error on validateTdCode :: ", err);
    })
  }

  get postSheetType(): AbstractControl {
    return this.addPostSheetForm.get('postSheetType');
  }

  get whatToSend(): AbstractControl {
    return this.addPostSheetForm.get('whatToSend');
  }

  get tdCode(): AbstractControl {
    return this.addPostSheetForm.get('tdCode');
  }

  get isAlreadyPosted() {
    return (this.postSheetDetail?.postedOn && this.postSheetDetail?.postedBy);
  }

  get formPostedOn(): AbstractControl {
    return this.addPostSheetForm.get('postedOn');
  }

  get formPostedBy(): AbstractControl {
    return this.addPostSheetForm.get('postedBy');
  }

  showSuccess(message: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
      life: 1000
    });
  }

  showError(error: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: error,
      life: 300000
    });
  }

  closeDialog() {
    this.displayModal = false;
    this.closeAddPostSheet.emit(undefined);
  }

  selectedContactChange(): void {
    if (this.selectedContactDetails) {
      this.addPostSheetForm.patchValue({
        'name': this.selectedContactDetails.name,
        'addressLineOne': this.selectedContactDetails.addressOne,
        'addressLineTwo': this.selectedContactDetails.addressTwo,
        'city': this.selectedContactDetails.city,
        'county': this.selectedContactDetails.county,
        'postcode': this.selectedContactDetails.postcode,
        'validated': this.selectedContactDetails.validated,
      });
    } else {
      this.addPostSheetForm.patchValue({
        'name': '',
        'addressLineOne': '',
        'addressLineTwo': '',
        'city': '',
        'county': '',
        'postcode': '',
        'validated': false,
      });
    }
  }

  get isFormInvalid(): boolean {
    return this.addPostSheetForm.invalid;
  }

}

const DIGITS: string[] = [
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9','.'
  ];
function numberPlusDecimal(event: KeyboardEvent, allowDecimals: boolean):boolean {
  if (!DIGITS.includes(event.key) && !(allowDecimals && (event.key == '.'))) {
    return false;
  }
  return true;
}
