<div class="row m-0" [class.hideDuringPrinting]="printMailingLabelsService.isPrinting">
    <div class="col-1 pt-2">
      <button type="button" class="btn btn-primary m-2" (click)="canAddPostSheet = true;postSheetOpenMode='add'">Add Post Sheet</button>
    </div>
    <div class="col-2 pt-3">
      <p-multiSelect
        class="inline "
        [options]="cols"
        [(ngModel)]="selectedPostSheetColumns"
        optionLabel="header"
        selectedItemsLabel="{0} selected columns"
        [style]="{minWidth: '200px'}"
        placeholder="Filter Columns"
        scrollHeight="600px"
        (onChange)="onPostSheetColumnChange($event, dt)" >
      </p-multiSelect>
    </div>
    <div class="col-3 pt-2">
      <div ngbDropdown class="d-inline-flex">
        <button type="button" id="Print Labels" ngbDropdownToggle class="btn btn-primary m-2">Print Labels
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem type="button" (click)="printSelectedSheets()">Print Selected Labels
          </button>
          <button ngbDropdownItem type="button" (click)="printClassSheets('1st class letter', '1st class letters')">Print First Class
          </button>
          <button ngbDropdownItem type="button" (click)="printClassSheets('1st large letter', '1st large letters')">Print First Large
          </button>
          <button ngbDropdownItem type="button" (click)="printClassSheets('2nd class letter', '2nd class letters')">Print Second Class 
          </button>
          <button ngbDropdownItem type="button" (click)="printClassSheets('Tracked', 'Tracked post')">Print Tracked
          </button>
          <button ngbDropdownItem type="button" (click)="printClassSheets('CRL', 'CRL post')">Print CRL
          </button>
        </div>
      </div>
      <div ngbDropdown class="d-inline-flex">
        <button type="button" id="Export" ngbDropdownToggle class="btn btn-primary m-2"
            aria-controls="exportCustomColumnControl">Export
        </button>
        <div ngbDropdownMenu>
            <button ngbDropdownItem type="button" (click)="exportTrackedPostSheet('Click and Drop')">Click and Drop</button>
            <button ngbDropdownItem type="button" (click)="exportSelectedPostSheet('Selected Post Sheets')">Selected Post Sheets</button>
            <button ngbDropdownItem type="button" (click)="promptForAccountingDates()">Accounting Post Sheets</button>
        </div>
      </div>
      <button type="button" class="btn btn-primary m-2" (click)="createLeadsFromPost()" [disabled]="selectedSheets.length == 0">Create Leads</button>
    </div>
    <div class="col-6 text-right pt-2">
      <div class="inputs mx-2 text-left">
        <p-dropdown [options]="dataLoadOptions" [(ngModel)]="selectedDataLoadOption" class="m-2" placeholder="Select an option"
            [showClear]="true" ></p-dropdown>
      </div>
      <ng-container [ngSwitch]="selectedDataLoadOption">
        <ng-container *ngSwitchCase="'posted'">
          <div class="dateInputs" id="dateInputs">
            <label class="load-logs" for="startDate">Posted Between:</label>
            <input id="startDate" type="date" autocomplete="off" #startDate max="{{maxDateStr}}" value="{{defaultStartDateStr}}" class="mx-2"/>
            <label class="load-logs" for="endDate">and:</label>
            <input id="endDate" type="date" autocomplete="off" #endDate max="{{maxDateStr}}" value="{{maxDateStr}}" class="mx-2"/>
          </div>
          <ng-container *ngIf="!loading else loadingBlock">
            <button type="button" (click)="loadData(startDate.value, endDate.value)" class="btn btn-primary m-2" [disabled]="!selectedDataLoadOption">Load Data</button>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="!loading else loadingBlock">
            <button type="button" (click)="loadData()" class="btn btn-primary m-2" [disabled]="!selectedDataLoadOption">Load Data</button>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #loadingBlock>
        <div class="m-2 spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </ng-template>
</div>
<div class="post-sheets" [class.hideDuringPrinting]="printMailingLabelsService.isPrinting">
    <p-table #dt [value]="postSheets" [columns]="selectedPostSheetColumns" [paginator]="true" [rows]="10" [sortOrder]="1" sortMode="single" [(selection)]="selectedSheets" 
    [showCurrentPageReport]="true" currentPageReportTemplate="PAGE {currentPage} OF {totalPages} - POSTSHEETS {first} TO {last} OF {totalRecords}" >
        <ng-template pTemplate="header" let-columns>
            <tr>
              <th><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                <ng-container *ngFor="let col of columns">
                  <th [ngSwitch]='col.field' id="{{col.field.replaceAll('.', '-')}}">
                    <div *ngSwitchDefault [pSortableColumn]="col.field">{{col.header}}</div>
                  </th>
                </ng-container>
              <th>Actions</th>
            </tr>
            <tr>
              <th><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
              <ng-container *ngFor="let col of columns">
                <th [ngSwitch]='col.field'>
                  <div>
                    <p-columnFilter  *ngSwitchCase="'addedBy'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <p-calendar *ngSwitchCase="'dateAdded'" [(ngModel)]="dateAddedFilters" appendTo="body"selectionMode="range"
                      [readonlyInput]="true" dateFormat="dd/mm/yy" (onSelect)="dt.filter($event, col.field, 'dateAdded')"
                      [monthNavigator]=true [yearNavigator]=true [showButtonBar]=true [keepInvalid]=true
                      [yearRange]="filterYearRange" [numberOfMonths]="1" [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true
                      [inputStyle]="{maxWidth:'100px'}" [hideTransitionOptions]="'0ms'"
                      (onClearClick)="clearTestDateFilters()">
                    </p-calendar>
                    <p-columnFilter *ngSwitchCase="'orderId.alarmUserDetails.tdCode'" type="text" [matchMode]="col.filterMatchMode" 
                      [matchModeOptions]="tdCodeMatchModeOptions" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <div *ngSwitchCase="'websiteId'">
                      <p-multiSelect [options]="brands" placeholder="Brand" styleClass="brandcss" [panelStyle]="{minWidth:'12em'}"
                          scrollHeight="600px" [maxSelectedLabels]=0 selectedItemsLabel="{0} selected"
                          (onChange)="dt.filter($event.value,col.field,'in')" [style]="{'display':'flex'}">
                          <ng-template let-Pbrand let-i="index" pTemplate="item">
                              <span class="brandBar"
                                  [ngStyle]="{'background-color': getBrandBackgroundColour(Pbrand.label), 'color': getBrandColour(Pbrand.label)}">
                                  &nbsp;{{Pbrand.label}}
                              </span>
                          </ng-template>
                      </p-multiSelect>
                    </div>
                    <p-columnFilter  *ngSwitchCase="'postSheetType'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <p-columnFilter  *ngSwitchCase="'name'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <p-columnFilter *ngSwitchCase="'address'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <div>
                      <p-multiSelect *ngSwitchCase="'whatToSend'" [options]="whatToSendOptions" placeholder="What to send" [maxSelectedLabels]=0 selectedItemsLabel="{0} items selected" 
                        [filter]=true [resetFilterOnHide]="true" (onChange)="dt.filter($event.value, col.field, 'in')">
                      </p-multiSelect>
                    </div>
                    <div>
                      <p-multiSelect *ngSwitchCase="'reason'" [options]="sendReasonOptions" placeholder="Reason" [maxSelectedLabels]=0 selectedItemsLabel="{0} items selected" 
                          [filter]=true [resetFilterOnHide]="true" (onChange)="dt.filter($event.value, col.field, 'in')">
                          <ng-template let-sendReason let-i="index" pTemplate="item">
                              <span data-toggle="tooltip" title="{{sendReason.tooltip}}">{{sendReason.label}}</span>
                          </ng-template>
                      </p-multiSelect>
                    </div>
                    <p-columnFilter  *ngSwitchCase="'comments'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <p-columnFilter  *ngSwitchCase="'paidAndMethod'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <p-columnFilter  *ngSwitchCase="'paidAmount'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <p-columnFilter  *ngSwitchCase="'class'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                    <p-columnFilter  *ngSwitchCase="'postedBy'" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                  </div>
                </th>
              </ng-container>
              <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <td><p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <div *ngSwitchCase="'orderId.alarmUserDetails.tdCode'">
                      <a routerLink="/order/{{rowData.orderId?._id}}" target="_blank">
                        {{rowData.orderId?.alarmUserDetails.tdCode}}
                        <div *ngIf="isOrderLocked(rowData.orderId?._id)">
                          {{orderLockedBy(rowData.orderId?._id)}}
                        </div>
                      </a>
                    </div>
                    <div *ngSwitchCase="'websiteId'">
                        <div class="brandBar"
                          [ngStyle]="{'background-color': getBrandBackgroundColour(rowData['websiteId']?.title), 'color': getBrandColour(rowData['websiteId']?.title)}">
                          {{rowData['websiteId']?.title}}
                        </div>
                    </div>
                    <div *ngSwitchCase="'dateAdded'">
                      {{rowData[col.field] | date:'dd/MM/y'}}
                    </div>
                    <div *ngSwitchCase="'address'">
                      {{rowData['addressLineOne']}} {{rowData['addressLineTwo']}} <br/>{{rowData['city']}}  
                      <br/>{{rowData['county']}} <br/> {{rowData['postcode']}} 
                    </div>
                    <div *ngSwitchDefault>
                        {{rowData[col.field]}}
                    </div>
                </td>
                <td>
                  <div>
                    <button type="button" class="btn btn-info" (click)="openPostSheetDetail(rowData,'view')" 
                      style="width: 70px; margin-left: 7px; margin-bottom: 1px;">View</button>
                  </div>
                  <div>
                    <button type="button" class="btn btn-primary ml-2" (click)="openPostSheetDetail(rowData,'update')" [disabled]="rowData['postedOn']" 
                      style="width: 70px; margin-bottom: 1px;">update</button>
                  </div>
                  <div [hidden]="rowData['postedOn']">
                    <button type="button" class="btn btn-success ml-2" (click)="postUnpostedSheet(rowData)" style="width: 70px; margin-bottom: 1px;">
                      Post
                    </button>
                  </div>
                  <div [hidden]="!rowData['postedOn']">
                    <button type="button" class="btn btn-danger ml-2" (click)="unpostPostedSheet(rowData)"  style="width: 70px; margin-bottom: 1px;">
                      Unpost
                    </button>
                  </div>
                  <div>
                    <button type="button" class="btn btn-danger ml-2" (click)="deletePostSheet(rowData)" 
                      style="width: 70px;">Delete</button>
                  </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-dialog header="Enter date range" (onHide)="closeDateEntryModal()" [(visible)]="displayDateEntryModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
  <form>
    <div class="row">
      <div class="form-group col-6">
        <label for="roleName">From Date</label>
        <input type="date" id="fromDate" class="form-control" name="fromDate" [(ngModel)]="fromDate" required max="{{maxDateStr}}">
      </div>
      <div class="form-group col-6">
        <label for="roleName">From To</label>
        <input type="date" id="toDate" class="form-control" name="toDate" [(ngModel)]="toDate" required max="{{maxDateStr}}">
      </div>
      <div class="col-12">
        <button type="button" class="btn btn-warning" (click)="closeDateEntryModal()">Cancel</button>
        <button type="button" class="btn btn-primary ml-2" [disabled]="!fromDate || !toDate" (click)="exportAccountingPostSheet()">Submit</button>
      </div>
    </div>
  </form>
</p-dialog>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<app-add-post-sheet *ngIf="canAddPostSheet" [openMode]="postSheetOpenMode" [postSheetDetail]="postSheetDetail" (closeAddPostSheet)="closeAddPostSheet($event)"
  [class.showDuringPrinting]="printMailingLabelsService.isPrinting">
</app-add-post-sheet>
<router-outlet name="print"></router-outlet>