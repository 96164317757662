import getAddressClient, {AutocompleteAddress, FindSuccess, FindFailed, Result} from 'getaddress-api';
import {SelectItem} from 'primeng/api';
import {Address, getFormattedAddress} from './getUserAddress';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';

function addDefaultOptions(postcode: string, lookups: SelectItem<Address>[]): void {
  lookups.unshift({
    'label': '--Please Select an Address--',
    'value': undefined,
    'disabled': true
  });
  lookups.push({
    'label': 'Enter Address Manually',
    'value': {
      'postcode': postcode,
      'validated': false,
    },
    'disabled': false
  });
}

function getLookupFromGetAddressResult(postcode: string, addressResult: Result<FindSuccess,FindFailed>): SelectItem<Address>[] {
  const lookups: SelectItem<Address>[] = [];
  if (addressResult.isSuccess) {
    const findSuccess: FindSuccess = addressResult.toSuccess();
    if (findSuccess.addresses.postcode) {
      postcode = findSuccess.addresses.postcode;
    }
    findSuccess.addresses.addresses.forEach((autoCompleteAddress: AutocompleteAddress) => {
      const address: Address = {
        'addressOne': autoCompleteAddress.line_1,
        'addressTwo': autoCompleteAddress.line_2,
        'city': autoCompleteAddress.town_or_city,
        'county': autoCompleteAddress.county,
        'postcode': postcode,
        'role': autoCompleteAddress.building_number,
        'validated': true,
      };
      lookups.push({
        'label': getFormattedAddress(address).replace(/\n/g, ', '),
        'value': address,
      });
    });
    lookups.sort((selectItemA: SelectItem<Address>, selectItemB: SelectItem<Address>) => {
      const aHasNumber: boolean = /^\d+$/.test(selectItemA.value.role);
      const bHasNumber: boolean = /^\d+$/.test(selectItemB.value.role);
      if (aHasNumber && !bHasNumber) {
        return -1;
      }
      if (!aHasNumber && bHasNumber) {
        return 1;
      }
      if (!aHasNumber && !bHasNumber) {
        return selectItemA.value.addressOne.localeCompare(selectItemB.value.addressOne);
      }
      const aNumber: Number = Number.parseInt(selectItemA.value.role);
      const bNumber: Number = Number.parseInt(selectItemB.value.role);
      if (aNumber < bNumber) {
        return -1;
      }
      if (aNumber > bNumber) {
        return 1;
      }
      return selectItemA.value.addressOne.localeCompare(selectItemB.value.addressOne);
    });
  
  }
  addDefaultOptions(postcode, lookups);
  return lookups;
}

async function validateAddress(getAddrClient: getAddressClient, address: Address): Promise<MultiRecordResponse<SelectItem<Address>>> {
  if (!address || !address.postcode || !address.postcode.trim()) {
    return {
      'success': false,
      'message': 'Postcode missing from existing address.',
    };
  }
  const defaultLookups: SelectItem<Address>[] = [];
  addDefaultOptions(address.postcode.trim(), defaultLookups);
  try {
    const addressResult: Result<FindSuccess,FindFailed> = await getAddrClient.find(address.postcode.trim());
    if (!addressResult.isSuccess) {
      return {
        'success': false,
        'message': `Error returned by postcode lookup. Error ${addressResult.toFailed().message}`,
      };
    }
    const findSuccess: FindSuccess = addressResult.toSuccess();
    if (findSuccess.addresses.addresses.length <= 0) {
      return {
        'success': false,
        'message': 'No matches found for postcode.',
        'data': defaultLookups,
      };
    }
    const addressToMatch: Address = {
      'addressOne': address.addressOne? address.addressOne.toLocaleLowerCase().trim(): '',
      'addressTwo': address.addressTwo? address.addressTwo: '',
      'city': address.city? address.city: '',
      'county': address.county? address.county: '',
      'postcode': address.postcode,
      'validated': address.validated,
    };
    const matchingAddress: AutocompleteAddress = (findSuccess.addresses.addresses.find((autoCompleteAddress: AutocompleteAddress) => 
      (addressToMatch.addressOne == autoCompleteAddress.line_1.toLocaleLowerCase().trim())
    ) as AutocompleteAddress);
    if (matchingAddress) {
      const matchedAddress: Address = {
        'addressOne': matchingAddress.line_1,
        'addressTwo': matchingAddress.line_2,
        'city': matchingAddress.town_or_city,
        'county': matchingAddress.county,
        'postcode': address.postcode.trim(),
        'role': matchingAddress.building_number,
        'validated': true,
      };
      return {
        'success': true,
        'message': 'Match found.',
        'data': [{
          'label': getFormattedAddress(matchedAddress).replace(/\n/g, ', '),
          'value': matchedAddress,
        }],
      }
    }
    return {
      'success': false,
      'message': 'Postcode lookup did not find an address with a matching address line 1 for this postcode.',
      'data': getLookupFromGetAddressResult(address.postcode.trim(), addressResult),
    }
  } catch(error: any) {
    return {
      'success': false,
      'message': `Error calling postcode lookup. Error: ${error.message}`,
    }
  };
}

export {
  getLookupFromGetAddressResult,
  validateAddress,
};
