import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {TagResponse} from '../../models/responses/tagResponse.model';
import {TagsResponse} from '../../models/responses/tagsResponse.model';
import {TagRequest} from '../../models/requests/tagRequest.model';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private API_URL: string = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getActiveTags(): Observable<TagsResponse> {
    return this.http.get<TagsResponse>(`${this.API_URL}/tags/`);
  }

  getAllTags(): Observable<TagsResponse> {
    return this.http.get<TagsResponse>(`${this.API_URL}/tags/all`);
  }
  
  createTag(TagsParams: TagRequest): Observable<TagResponse> {
    return this.http.post<TagResponse>(`${this.API_URL}/tags`, TagsParams);
  }

  updateTag(id: string,TagsParams: TagRequest): Observable<TagResponse> {
    return this.http.put<TagResponse>(`${this.API_URL}/tags/${id}`, TagsParams);
  }

  deleteTag(id: string): Observable<TagResponse> {
    return this.http.delete<TagResponse>(`${this.API_URL}/tags/${id}`);
  }
}

