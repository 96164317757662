import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'legalCompanyToClass'
})
export class LegalCompanyToClassPipe implements PipeTransform {

  transform(legalCompany: string): string {
    if (!legalCompany || (typeof legalCompany != 'string')) {
      return '';
    }
    return legalCompany.split(/\s/)[0].toLocaleLowerCase();
  }

}
