import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {getActionColour, getActionBackgroundColour} from '../../lookups/actions';
import {BoardSummary} from '../../models/responses/boardSummaryResponse.model';
import {CcActionUpdateData} from '../../models/socket-io/creditControlActionUpdateData.model';
import {CreditControlActionUpdatesSocketService} from '../../sockets/credit-control-action-updates-socket.service';

@Component({
  selector: 'app-outstanding-action[actionData]',
  templateUrl: './outstanding-action.component.html',
  styleUrls: ['./outstanding-action.component.scss']
})
export class OutstandingActionComponent implements OnInit, OnDestroy {
  getActionColour = getActionColour;
  getActionBackgroundColour = getActionBackgroundColour;

  constructor(
    private ccActionUpdatesSocket: CreditControlActionUpdatesSocketService
  ) { }
  @Input() actionData: BoardSummary = {
    '_id': '',
    'total': 0,
    'morning': 0,
    'afternoon': 0,
    'evening': 0,
    'overdue': 0,
    'today': 0,
    'backgroundColor': '#ffffff',
  };

  ngOnInit() {
    this.ccActionUpdatesSocket.on('updateHomeOutboundAndFilteredPage', this.updateTotalsWhenActionCompleted);
    this.ccActionUpdatesSocket.on('updateHomeOutboundAndFilteredPageSettingCallback', this.updatePageWhenActionCallbackSet);
  }
  
  ngOnDestroy() {
    this.ccActionUpdatesSocket.removeAllListeners();
  }

  updateTotalsWhenActionCompleted: (actionUpdateData: CcActionUpdateData) => void = (data: CcActionUpdateData) => {
    if (data.actionName == this.actionData._id) {
      // console.log("DATA ON updateHomeOutboundFilteredPage", data);
      if (data.actionWasOverdue) {
        this.actionData.overdue = this.actionData.overdue > 0 ? this.actionData.overdue - 1 : 0;
        this.actionData.total = this.actionData.total > 0 ? this.actionData.total - 1 : 0;
      } else {
        this.updateTodaysShiftFigures(data);
        this.actionData.total = this.actionData.total > 0 ? this.actionData.total - 1 : 0;
      }
    }
  }

  updatePageWhenActionCallbackSet: (actionUpdateData: CcActionUpdateData) => void = (data: CcActionUpdateData) => {
    if (data.actionName == this.actionData._id) {
      this.updateTodaysShiftFigures(data);
    }
  }

  updateTodaysShiftFigures(data: CcActionUpdateData) {
    switch (data.shift) {
      case 'morning':
        if (this.actionData.morning > 0) {
          this.actionData.morning--;
        }
        break;
      case 'afternoon':
        if (this.actionData.afternoon > 0) {
          this.actionData.afternoon--;
        }
        break;
      case 'evening':
        if (this.actionData.evening > 0) {
          this.actionData.evening--;
        }
        break;        
      default:
        break;
    }
    this.actionData.today = this.actionData.morning + this.actionData.afternoon + this.actionData.evening;
  }

}
