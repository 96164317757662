<div class="mb-xl-2"></div>
<div class="container-fluid" id="hardwareAuditTable">
  <p-table #announcementTable [value]="announcements" [paginator]="true" [rows]="30"
      [sortField]="'createdAt'" [sortOrder]=-1 [sortMode]="'single'">
    <ng-template pTemplate="caption">
      <div>
        <label for="newMsg">New Announcement:</label>
        <input id="newMsg" type="text" [(ngModel)]="newMsg" pInputText class="mx-3"/>
        <button type="button" class="btn btn-primary" (click)="updateAnnouncement()">Update Announcement</button>
      </div>
      <div>
        Leave the input empty and click Update Annoucement if you just want to to clear the current announcement.
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of cols">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" 
              ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-announcement>
      <tr>
        <td>{{announcement.createdAt | date:'dd/MM/yyyy HH:mm'}}</td>
        <td>{{announcement.createdBy}}</td>
        <td>{{announcement.message}}</td>
        <td>{{announcement.removedBy}}</td>
        <td>{{announcement.removedAt | date:'dd/MM/yyyy HH:mm'}}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      There are {{announcements.length}} announcement
    </ng-template>

  </p-table>
</div>
<p-confirmDialog key="general"></p-confirmDialog>