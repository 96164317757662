import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Subject, Subscription} from 'rxjs';
import {JontekContactRecord} from '../../models/jontekContactRecord.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {PostOrderSection} from '../post-order-section';
import {PostOrderService} from '../post-order.service';
import {JontekService} from './jontek.service';

@Component({
  selector: 'app-jontek-contacts-section[pageSection][minimizeSectionMethod]',
  templateUrl: './jontek-contacts-section.component.html',
  styleUrls: ['../post-order-sections.scss', './jontek-contacts-section.component.scss']
})
export class JontekContactsSectionComponent extends PostOrderSection implements OnInit, OnDestroy {
  jontekCodesSubscription: Subscription;
  jontekContactRecords: JontekContactRecord[];

  constructor(
    private jontekService: JontekService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private postOrderService: PostOrderService,
  ) {
    super(confirmationService, messageService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.jontekContactRecords = [];
    this.jontekCodesSubscription =
      this.postOrderService.jontekCodesSubject.subscribe((jontekCodes: string[]) => {
        this.loadJontekContacts(jontekCodes);
      });
  }

  ngOnDestroy(): void {
    this.jontekCodesSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  loadJontekContacts(jontekCodes: string[]): void {
    this.jontekContactRecords = [];
    if (jontekCodes && jontekCodes.length > 0) {
      this.jontekService.getJontekContactsForJontekCodes(jontekCodes)
        .subscribe((response: MultiRecordResponse<JontekContactRecord>) => {
          if (response.success) {
            this.jontekContactRecords = response.data;
          } else {
            console.error('Error getting Jontek Contacts', response.message);
          }
        });
    }
  }
}
