<div class="container">
  <br>
  <div>
    <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid '+color[websitetitle]  :''}">
      Step 1 - Brand
    </div>
    <div class="row">
      <ng-container *ngFor="let website of websites">
        <div class="col-2" *ngIf="stripes[website.title]">
          <img (click)="selectBrand(website);" src="assets/img/{{website.title}}.png"
              class="img-thumbnail" [ngStyle]="{'border': website._id==websiteSelected ? '6px solid '+color[website.title]  :''}">
          {{website.title}}
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="websiteSelected">
    <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid '+color[websitetitle]  :''}">
      Step 2 - Order Type
    </div>
    <div class="row">
      <div class="col-3">
        <label>Order Type</label>
      </div>
      <div class="col-9">
        <p-dropdown [ngClass]="{'error': validationErrorExists && !selectedOrderType}" [options]="orderTypes" placeholder="Select an Order Type"
            [(ngModel)]="selectedOrderType" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" (onChange)="changeOrderType()">
        </p-dropdown>
      </div>
      <div class="col-12">
        <label>Note</label><br/>If you change the order type the basket will be cleared as item prices are different for different types of order.
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isExistingCustomer()">
    <div class="row">
      <div class="col-3">
        <label>Add to existing CRM Account?</label>
      </div>
      <div class="col-3">
        <input [ngClass]="{'error': !allowReplacementWithoutTd && !tdCodeFound}" type="text" placeholder="Search CRM - TD Code"
          [(ngModel)]="searchTDCode" (change)="search()" [readonly]="!showPlaceOrder">
        <p *ngIf="tdCodeSearched && !tdCodeFound" class="alert alert-danger">TD Code Not Found</p>
      </div>
      <ng-container *ngIf="isReplacementWithoutTdAllowed()">
        <div class="col-4">
          <label>No existing CRM Account</label><br/>
          e.g. replacement on keysafe only order
        </div>
        <div class="col-2">
          <input type="checkbox" [(ngModel)]="allowReplacementWithoutTd" style="width: auto;">
        </div>
      </ng-container>
    </div>
    <div class="row">
      <ng-container *ngIf="tdCodeFound">
        <div class="col-6">
          <label>Customer Name</label><br/>
          {{order.alarmUserDetails.firstName}} {{order.alarmUserDetails.lastName}}
        </div>
        <div class="col-2">
          <label>Order Date</label><br/>
          {{order.created | date:'dd/MM/y'}}
        </div>
        <div class="col-2">
          <label>Free Months</label><br/>
          {{order.renewalInformation.freemonths}}
        </div>
        <div class="col-2">
          <label>Plan Type</label><br/>
          {{planType}}
        </div>
        <div class="col-3">
          <label>Current Renewal Price</label><br/>
          {{order.renewalInformation.renewalPrice}}
        </div>
        <div class="col-3">
          <label>CRM Renewal Date</label><br/>
          {{crmRenewalDate}}
        </div>
        <div class="col-3">
          <label>Next Renewal Date</label><br/>
          {{nextRenewalDate}}
        </div>
        <div class="col-3">
          <label>Pro-rata Charge to Date</label><br/>
          {{proRataToDate}}
        </div>
        <div class="col-12">
          <label>Notes</label><br/>
          Pro-rata charges are only relevant to Additional Equipment and Upgrade orders. Overdue past renewals will not be included.<br/>
          If the next renewal date is too close and the payment date is passed or too close to change the payment
          additional renewal periods will be included in the pro-rata cost calculation.<br/>
        </div>
      </ng-container>
      <ng-container *ngIf="isReplacementDueToFault()">
        <div class="col-3">
          <label>What is the fault?</label>
        </div>
        <div class="col-3">
          <p-dropdown [ngClass]="{'error': validationErrorExists && !fault}" [options]="faultOptions" placeholder="Select a fault"
              [(ngModel)]="fault" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder">
          </p-dropdown>
        </div>
        <div *ngIf="fault == 'Other'" class="col-6">
          <input [ngClass]="{'error': validationErrorExists && !otherFault}" type="text" placeholder="Fault details"
            [(ngModel)]="otherFault" [readonly]="!showPlaceOrder">
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="orderDetails.type == 'Phone Order'">
    <div class="row">
      <div class="col-3">
        <label>Number customer called from</label> 
      </div>
      <div class="col-4">
        <input [ngClass]="{'error' : validationErrorExists && !customerCalledFrom && !withheldNumber}" (keypress)="numberOnly($event, false)"
          [(ngModel)]="customerCalledFrom" type="text" [readonly]="!showPlaceOrder || withheldNumber" autocomplete="off">
      </div>
      <div class="col-3" style="padding-left: 60px">
        <label>Customer withheld number</label>
      </div>
      <div class="col-1">
        <input type="checkbox" [(ngModel)]="withheldNumber" style="width: auto;" [disabled]="!showPlaceOrder">
      </div>
    </div>
  </ng-container>
  <ng-template #vimMessages>
    <div *ngFor="let vim of vims; let vimIndex=index">
      <span class="vimmessage"> {{vim.content}}</span>
      <span class="vimby"> By {{vim.userName}}&#64; {{vim.date | date:'dd/MM/y HH:mm'}}</span>
    </div>
  </ng-template>
  <p-dialog id="vimPopup" [(visible)]="vimPopupOpen" header="" [modal]="true"  [style]="{width: '80%'}" (onHide)="closeVimPopup()"
    [transitionOptions]="'0ms'">
    <p-header>
      <div class="d-flex justify-content-between">
        <span>Very Important Message</span>
      </div>
    </p-header>
    <ng-container *ngTemplateOutlet="vimMessages"></ng-container>
    <p-footer>
      <button type="button" class="btn btn-primary" (click)="closeVimPopup()">
        <span aria-hidden="true" class="pi pi-check"></span>OK
      </button>
    </p-footer>
  </p-dialog>
  <ng-container *ngIf="selectedOrderType && (!isExistingCustomer() || tdCodeFound || allowReplacementWithoutTd)">
    <div>
      <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid '+color[websitetitle]  :''}">
        Step 3 - Product Selection
      </div>
      <div class="row">
        <div class="col-2">
          <p-selectButton [options]="vatOptions" [(ngModel)]="vatSelected" optionLabel="label" optionValue="value"
              (click)="setVat(); category=null;" [disabled]="!showPlaceOrder">
          </p-selectButton>
        </div>
        <div class="col-3" >
          <p-dropdown *ngIf="vatSelected" [options]="categories" placeholder="Select a category" [showClear]="true"
              [(ngModel)]="category" [style]="{'width':'100%'}" (onChange)="setCategory()" [readonly]="!showPlaceOrder">
          </p-dropdown>
        </div>
        <div class="col-3" >
          <p-dropdown *ngIf="category" [options]="productsFiltered" placeholder="Select a product" optionLabel="crmTitle"
              [(ngModel)]="selectedProduct" [style]="{'width':'100%'}" [showClear]="true" [filter]="true"
              (onChange)="onOptionsSelected()" [readonly]="!showPlaceOrder">
          </p-dropdown>
        </div>
        <div class="col-3">
          <div *ngIf="(variations).length > 0">
            <p-dropdown [options]="variations" placeholder="Select a product" optionLabel="title"
                [(ngModel)]="selectedVariation" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder">
            </p-dropdown>
          </div>
        </div>
        <div class='col-1'>
          <button pButton type="button" label="+" class="ui-button-rounded addprod" (click)="addToInvoice()"
              [disabled]="!showPlaceOrder || !selectedProduct || (((variations).length > 0) && !selectedVariation) ">
          </button>
        </div>
        <div *ngIf="(vatSelected == 'exempt') && (orderVatStatus == OrderVatStatusEnum.VATABLE)" class='col-12'>
          <div class="alert alert-danger">
            You have "Not Exempt" items, that are not key safes, in the basket. 
            You cannot mix "Not Exempt" items, other than keysafes, with and "Exempt" items,
            please remove the non keysafe items to add "Exempt" items.
          </div>
        </div>
      </div>

      <div class="row">
        <table class="table">
          <tr>
            <th>Order</th>
            <th>Quantity</th>
            <td></td>
            <th>Total</th>
            <th></th>
          </tr>
          <tbody *ngFor="let item of items; let i = index">
            <tr>
              <td> {{item.crmTitle}} : {{item.sku}} </td>
              <td>
                <input *ngIf="!item.selectedVariation" type="number" min='1' (keypress)="numberOnly($event, false)"
                    [(ngModel)]="item.quantity" (change)="priceupdate()" [readonly]="!showPlaceOrder">
              </td>
              <td></td>
              <td>
                <div *ngIf="(item.quantity > 0) && (item.regularPrice > 0) && !item.selectedVariation">
                  {{item.quantity * item.regularPrice | currency :currencyCode:'symbol':'1.2-2' }}
                </div>
              </td>
              <td>
                <ng-container *ngIf="!item.sku.includes('SETUP')">
                  <button class="btn btn-danger btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top"
                      title="Delete" (click)="delete(i)" [disabled]="!showPlaceOrder">
                    <i class="fa fa-trash"></i>
                  </button>
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="!!item.selectedVariation">
              <td>{{item.selectedVariation.title}} : {{item.selectedVariation.sku}}</td>
              <td>
                <input type="number" min='1' (keypress)="numberOnly($event, false)" [(ngModel)]="item.quantity" (change)="priceupdate()" [readonly]="!showPlaceOrder">
              </td>
              <td></td>
              <td>{{item.quantity * item.selectedVariation.regular_price  | currency :currencyCode:'symbol':'1.2-2' }}</td>
              <td>
                <button class="btn btn-danger btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top"
                    title="Delete" (click)="delete(i)" [disabled]="!showPlaceOrder">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="needsCustomerHeight(item) || needsWearingOption(item)">
              <td>
                <div class="row">
                  <ng-container *ngIf="needsCustomerHeight(item)">
                    <div class="col-3">
                      Customer Height :
                    </div>
                    <div class="col-3">
                      <select [(ngModel)]="item.customerHeight" [disabled]="!showPlaceOrder">
                        <option value="Over 5'2"> Over 5'2"</option>
                        <option value="Under 5'2"> Under 5'2"</option>
                      </select>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="needsWearingOption(item)">
                    <div class="col-3">
                      Wearing Option :
                    </div>
                    <div class="col-3">
                      <select [(ngModel)]="item.wearingOption" [disabled]="!showPlaceOrder">
                        <option value="Neck">Neck</option>
                        <option value="Wrist">Wrist</option>
                      </select>
                    </div>
                  </ng-container>
                </div>
              </td>
              <td colspan='4'></td>
            </tr>
          </tbody>
          <tbody *ngIf="calculationError">
            <tr>
              <th colspan="5">
                <p class="alert alert-danger">{{calculationError}}</p>
              </th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>VAT</th>
              <th colspan='2'></th>
              <th>{{vat | currency :currencyCode:'symbol':'1.2-2'}}</th>
              <th></th>
            </tr>
            <ng-container *ngIf="!isExistingCustomer()">
              <tr>
                <th colspan="5">Coupon code</th>
              </tr>
              <tr>
                <th>
                  <input type="text" placeholder="Please enter Coupon code" (change)="searchCoupon()" [(ngModel)]='couponCode'
                    [readonly]="!showPlaceOrder || !websiteSelected">
                </th>
                <th>
                  <p *ngIf="coupon">{{coupon.description}}</p>
                  <p *ngIf="couponError" class="alert alert-danger">{{couponError}}</p>
                </th>
                <th></th>
                <th *ngIf="coupon && !couponError && (coupon.type=='percent' || coupon.type=='percent_product')"> - {{coupon.amount}}% </th>
                <th *ngIf="coupon && !couponError && (coupon.type=='fixed_cart' || coupon.type=='fixed_product')"> - {{currencySymbol}}{{coupon.amount}} </th>
                <th *ngIf="!coupon || couponError"></th><!-- make the column count correct -->
                <th></th>
              </tr>
            </ng-container>
            <ng-container *ngIf="(proRataCurrentPrice > 0) && selectedOrderType && (selectedOrderType.calculationMethod == 'Difference')">
              <th>Pro-Rata existing Plan Price (subtracted from new price)</th>
              <th colspan='2'></th>
              <th>-{{proRataCurrentPrice | currency :currencyCode:'symbol':'1.2-2'}}</th>
              <th></th>
            </ng-container>
            <tr>
              <th>Order Total</th>
              <th colspan='2'></th>
              <th>{{TTorder  | currency :currencyCode:'symbol':'1.2-2'}}</th>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <div>
      <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid '+color[websitetitle]  :''}">
        Step 4 - Customer Details
      </div>
      <div class="row">
        <h3 style="padding-left: 14px;">Billing Details</h3>
      </div>
      <div class="row">
        <div class="col-3"><label>First Name</label></div>
        <div class="col-3">
          <input [ngClass]="{'error' : validationErrorExists && infoBilling.firstName.trim()=='' }" type="text"
              [(ngModel)]="infoBilling.firstName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" autocomplete="off">
        </div>

        <div class="col-3"><label>Last Name</label></div>
        <div class="col-3">
          <input [ngClass]="{'error' : validationErrorExists && infoBilling.lastName.trim()=='' }" type="text"
              [(ngModel)]="infoBilling.lastName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" autocomplete="off">
        </div>

        <div class="col-3">
          <label>Email</label><br/>
          Dummy used if left blank
        </div>
        <div class="col-3">
          <input type="text" [(ngModel)]="infoBilling.email" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" (blur)="emailBlur()">
        </div>

        <div class="col-3"><label>Phone</label></div>
        <div class="col-3">
          <input [ngClass]="{'error' : validationErrorExists && infoBilling.mobile.trim()=='' }" type="text"
              [(ngModel)]="infoBilling.mobile" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" maxlength="20"
              autocomplete="off" (keypress)="numberOnly($event, false)">
        </div>
        <div class="col-3"><label>Address</label></div>
        <div class="form-group col-9" id="postcode_lookup_billing">
          <input type="text" id="getaddress_input_billing" autocomplete="off" [(ngModel)]="billingSearchPostCode">
          <button type="button" id="getaddress_button_billing" (click)="billingAddressSearch()" [disabled]="!billingSearchPostCode">
            Find Postcode
          </button>
        </div>
        <div *ngIf="billingSearchError" class="form-group col-9 offset-3">
          <p class="alert alert-danger">{{billingSearchError}}</p>
        </div>
        <div *ngIf="billingAddressResults && (billingAddressResults.length > 0)" class="form-group col-9 offset-3">
          <p-dropdown [options]="billingAddressResults" [style]="{'width':'100%'}" (onChange)="setBillingAddress($event)"
              [readonly]="!showPlaceOrder">
          </p-dropdown>
        </div>
        <div class="col-9 offset-3">
          <input [ngClass]="{'error' : validationErrorExists && infoBilling.userAddress.addressOne=='' }"
              type="text" placeholder="Address Line 1" [(ngModel)]="infoBilling.userAddress.addressOne" 
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry"
              autocomplete="off">
        </div>
        <div class="col-9 offset-3">
          <input type="text" placeholder="Address Line 2" [(ngModel)]="infoBilling.userAddress.addressTwo"
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-9 offset-3">
          <input [ngClass]="{'error' : validationErrorExists && infoBilling.userAddress.city.trim()=='' }"
              type="text" placeholder="Town / City" [(ngModel)]="infoBilling.userAddress.city" 
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-9 offset-3">
          <input type="text" placeholder="County" [(ngModel)]="infoBilling.userAddress.county"
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-3"><label>Postcode</label></div>
        <div class="col-9">
          <input [ngClass]="{'error' : validationErrorExists && infoBilling.userAddress.postcode.trim()=='' }" type="text"
              [(ngModel)]="infoBilling.userAddress.postcode" [style]="{'width':'100%'}"
              [readonly]="!showPlaceOrder || !allowBillingAddressManualEntry" autocomplete="off">
        </div>
      </div>
      <div class="row">
        <div class="col-3"><label>Deliver to a different Address?</label></div>
        <div class="col-1">
          <input type="checkbox" [(ngModel)]="differentDeliver" style="width: auto;" [disabled]="!showPlaceOrder">
        </div>
      </div>
      <div class="row" *ngIf="differentDeliver">
        <div class="col-3"><label>First Name</label></div>
        <div class="col-3">
          <input [ngClass]="{'error' : validationErrorExists && infoDeliver.firstName.trim()==''}"
              type="text" [(ngModel)]="infoDeliver.firstName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder"
              autocomplete="off">
        </div>

        <div class="col-3"><label>Last Name</label></div>
        <div class="col-3">
          <input [ngClass]="{'error' : validationErrorExists && infoDeliver.lastName.trim()==''}"
              type="text" [(ngModel)]="infoDeliver.lastName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder"
              autocomplete="off">
        </div>

        <div class="col-3"><label>Address</label></div>
        <div class="form-group col-9" id="delivery_postcode_lookup">
          <input type="text" id="getaddress_input_delivery" autocomplete="off" [(ngModel)]="deliverySearchPostCode">
          <button type="button" id="getaddress_button_delivery" (click)="deliveryAddressSearch()" [disabled]="!deliverySearchPostCode">
            Find Postcode
          </button>
        </div>
        <div *ngIf="deliverySearchError" class="form-group col-9 offset-3">
          <p class="alert alert-danger">{{deliverySearchError}}</p>
        </div>
        <div *ngIf="deliveryAddressResults && (deliveryAddressResults.length > 0)" class="form-group col-9 offset-3">
          <p-dropdown [options]="deliveryAddressResults" [style]="{'width':'100%'}" (onChange)="setDeliveryAddress($event)"
              [readonly]="!showPlaceOrder">
          </p-dropdown>
        </div>
        <div class="col-9 offset-3">
          <input [ngClass]="{'error' : validationErrorExists && infoDeliver.userAddress.addressOne.trim()==''}"
              type="text" placeholder="Address Line 1" [(ngModel)]="infoDeliver.userAddress.addressOne" 
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry" autocomplete="off">   
        </div>
        <div class="col-9 offset-3">
          <input type="text" placeholder="Address Line 2" [(ngModel)]="infoDeliver.userAddress.addressTwo"
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-9 offset-3">
          <input [ngClass]="{'error' : validationErrorExists && infoDeliver.userAddress.city.trim()==''}"
              type="text" placeholder="Town / City" [(ngModel)]="infoDeliver.userAddress.city" 
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-9 offset-3">
          <input type="text" placeholder="County" [(ngModel)]="infoDeliver.userAddress.county" 
              [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry" autocomplete="off">
        </div>
        <div class="col-3"><label>PostCode</label></div>
        <div class="col-9">
          <input [ngClass]="{'error' : validationErrorExists && infoDeliver.userAddress.postcode.trim()==''}"
              type="text" [(ngModel)]="infoDeliver.userAddress.postcode" [style]="{'width':'100%'}" autocomplete="off"
              [readonly]="!showPlaceOrder || !allowDeliveryAddressManualEntry">
        </div>
      </div>
      <br>
    </div>

    <div>
      <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid '+color[websitetitle]  :''}">
        Step 5 - Order Details
      </div>
      <div class="row Order">
        <div class="col-3"><label>Order Notes</label></div>
        <div class="col-9">
          <input [(ngModel)]="orderDetails.notes" type="text" [readonly]="!showPlaceOrder" autocomplete="off">
        </div>
        <ng-container *ngIf="!isExistingCustomer()">
          <div class="col-3"><label>Alarm User's Name</label></div>
          <div class="col-9">
            <select [ngClass]="{'error' : validationErrorExists && !alarmUserNameOption}"
                [style]="{'width':'100%'}" [(ngModel)]="alarmUserNameOption" [disabled]="!showPlaceOrder">
              <option value=""></option>
              <option value="billing">Same as Billing Address Name</option>
              <option *ngIf="differentDeliver" value="delivery">Same as Delivery Address Name</option>
              <option value="other">Other</option>
            </select>
          </div>
          <ng-container *ngIf="alarmUserNameOption == 'other'">
            <div class="col-3"><label>Alarm User's First Name</label></div>
            <div class="col-3">
              <input [ngClass]="{'error' : validationErrorExists && !orderDetails.alarmUserFirstName}" type="text" 
                  [(ngModel)]="orderDetails.alarmUserFirstName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder"
                  autocomplete="off">
            </div>
            <div class="col-3"><label>Alarm User's Last Name</label></div>
            <div class="col-3">
              <input [ngClass]="{'error' : validationErrorExists && !orderDetails.alarmUserLastName}" type="text" 
                  [(ngModel)]="orderDetails.alarmUserLastName" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder"
                  autocomplete="off">
            </div>
          </ng-container>
          <div class="col-3"><label>Alarm User's Phone</label></div>
          <div class="col-9">
            <select [ngClass]="{'error' : validationErrorExists && !alarmUserPhoneOption}"
                [style]="{'width':'100%'}" [(ngModel)]="alarmUserPhoneOption" [disabled]="!showPlaceOrder">
              <option value=""></option>
              <option value="billing">Same as Billing Phone</option>
              <option value="other">Other</option>
              <option value="unknown">Unknown</option>
            </select>
          </div>
          <ng-container *ngIf="alarmUserPhoneOption == 'other'">
            <div class="col-3"><label>Alarm User's Phone</label></div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && (!orderDetails.alarmUserMobile && !orderDetails.alarmUserPhone)}" type="text" 
                  [(ngModel)]="orderDetails.alarmUserPhone" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" (keypress)="numberOnly($event, false)"
                  autocomplete="off">
            </div>
            <div class="col-3"><label>Alarm User's Mobile</label></div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && (!orderDetails.alarmUserMobile && !orderDetails.alarmUserPhone)}" type="text" 
                  [(ngModel)]="orderDetails.alarmUserMobile" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" (keypress)="numberOnly($event, false)"
                  autocomplete="off">
            </div>
          </ng-container>
          <div class="col-3"><label>Alarm User's Address</label></div>
          <div class="col-9">
            <select [ngClass]="{'error' : validationErrorExists && !alarmUserAddressOption}"
                [style]="{'width':'100%'}" [(ngModel)]="alarmUserAddressOption" [disabled]="!showPlaceOrder">
              <option value=""></option>
              <option value="billing">Same as Billing Address</option>
              <option *ngIf="differentDeliver" value="delivery">Same as Delivery Address</option>
              <option value="other">Other</option>
              <option value="unknown">Unknown</option>
            </select>
          </div>
          <ng-container *ngIf="alarmUserAddressOption == 'other'">
            <div class="col-3"><label>Address</label></div>
            <div class="form-group col-9" id="postcode_lookup_alarm_user">
              <input type="text" id="getaddress_input_alarm_user" autocomplete="off" [(ngModel)]="alarmUserSearchPostCode">
              <button type="button" id="getaddress_button_alarm_user" (click)="alarmUserAddressSearch()" [disabled]="!alarmUserSearchPostCode">
                Find Postcode
              </button>
            </div>
            <div *ngIf="alarmUserSearchError" class="form-group col-9 offset-3">
              <p class="alert alert-danger">{{alarmUserSearchError}}</p>
            </div>
            <div *ngIf="alarmUserAddressResults && (alarmUserAddressResults.length > 0)" class="form-group col-9 offset-3">
              <p-dropdown [options]="alarmUserAddressResults" [style]="{'width':'100%'}" (onChange)="setAlarmUserAddress($event)"
                  [readonly]="!showPlaceOrder">
              </p-dropdown>
            </div>
            <div class="col-9 offset-3">
              <input [ngClass]="{'error' : validationErrorExists && alarmUserAddress.addressOne.trim()==''}"
                  type="text" placeholder="Address Line 1" [(ngModel)]="alarmUserAddress.addressOne" 
                  [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry" autocomplete="off">
            </div>
            <div class="col-9 offset-3">
              <input type="text" placeholder="Address Line 2" [(ngModel)]="alarmUserAddress.addressTwo"
                  [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry" autocomplete="off">
            </div>
            <div class="col-9 offset-3">
              <input [ngClass]="{'error' : validationErrorExists && alarmUserAddress.city.trim()==''}"
                  type="text" placeholder="Town / City" [(ngModel)]="alarmUserAddress.city" 
                  [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry" autocomplete="off">
            </div>
            <div class="col-9 offset-3">
              <input type="text" placeholder="County" [(ngModel)]="alarmUserAddress.county" 
                  [style]="{'width':'100%'}" [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry" autocomplete="off">
            </div>
            <div class="col-3"><label>PostCode</label></div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && alarmUserAddress.postcode.trim()==''}"
                  type="text" [(ngModel)]="alarmUserAddress.postcode" [style]="{'width':'100%'}" autocomplete="off"
                  [readonly]="!showPlaceOrder || !allowAlarmUserAddressManualEntry">
            </div>
          </ng-container>
          <div class="col-3">
            <label>Renewal Method</label>
          </div>
          <div class="col-9">
            <select [ngClass]="{'error' : validationErrorExists && !orderDetails.renewalMethod}"
                [(ngModel)]="orderDetails.renewalMethod" (change)="addAddress()" [disabled]="!showPlaceOrder">
              <option value=""></option>
              <option value="None">None</option>
              <option *ngIf="websitetitle != 'LLIE'" value="directDebit">Direct Debit</option>
              <option *ngIf="websitetitle == 'LLIE'" value="goCardless">Go Cardless</option>
              <option value="recurringBilling">Recurring Billing</option>
            </select>
          </div>
          <div class="row" *ngIf="orderDetails.renewalMethod=='directDebit'">
            <div class="col-3 text-center">
              <label>Sort Code</label>
            </div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && !directDebit.sortCode}" (keypress)="numberOnly($event, false)"
                  [(ngModel)]="directDebit.sortCode" type="text" [readonly]="!showPlaceOrder" autocomplete="off"
                  maxlength="6">
            </div>

            <div class="col-3 text-center">
              <label>Account Number</label>
            </div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && !directDebit.accountNumber}" (keypress)="numberOnly($event, false)"
                  [(ngModel)]="directDebit.accountNumber" type="text" [readonly]="!showPlaceOrder" autocomplete="off"
                  maxlength="8">
            </div>

            <div class="col-3 text-center">
              <label>Account Holder Name</label>
            </div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && !directDebit.accountHolderName}"
                  [(ngModel)]="directDebit.accountHolderName" type="text" [readonly]="!showPlaceOrder" autocomplete="off">
            </div>
            <div class="col-3 text-center">
              <label>DD Address Line 1</label>
            </div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && !directDebit.addressLineOne}"
                  [(ngModel)]="directDebit.addressLineOne" type="text" [readonly]="!showPlaceOrder" autocomplete="off">
            </div>
            <div class="col-3 text-center">
              <label>DD Address Line 2</label>
            </div>
            <div class="col-9">
              <input [(ngModel)]="directDebit.addressLineTwo" type="text" [readonly]="!showPlaceOrder" autocomplete="off">
            </div>
            <div class="col-3 text-center">
              <label>DD City</label>
            </div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && !directDebit.city}"
                  [(ngModel)]="directDebit.city" type="text" [readonly]="!showPlaceOrder" autocomplete="off">
            </div>

            <div class="col-3  text-center">
              <label>DD Postcode</label>
            </div>
            <div class="col-9">
              <input [ngClass]="{'error' : validationErrorExists && !directDebit.postcode}"
                  [(ngModel)]="directDebit.postcode" type="text" [readonly]="!showPlaceOrder" autocomplete="off">
            </div>
          </div>

          <div class="col-3">
            <label>Order Contact Name</label>
          </div>
          <div class="col-9">
            <input [ngClass]="{'error' : validationErrorExists && !orderDetails.contactName}"
              [(ngModel)]="orderDetails.contactName" type="text" [readonly]="!showPlaceOrder" autocomplete="off">
          </div>

          <ng-container *ngIf="['directDebit', 'goCardless', 'recurringBilling'].includes(orderDetails.renewalMethod)">
            <div class="col-3"><label>Renewal Frequency</label></div>
            <div class="col-9">
              <select [ngClass]="{'error' : validationErrorExists && !orderDetails.renewalFrequency}"
                  [(ngModel)]="orderDetails.renewalFrequency" [disabled]="!showPlaceOrder">
                <option value="">Please Select</option>
                <option value="Annually">Annually</option>
                <option value="Quarterly">Quarterly</option>
              </select>
            </div>
          </ng-container>

          <div class="col-3"><label>Paper NCF Required?</label></div>
          <div class="col-9">
            <select [ngClass]="{'error' : validationErrorExists && !orderDetails.NCFRequired}"
                [(ngModel)]="orderDetails.NCFRequired" [disabled]="!showPlaceOrder">
              <option value="">Please Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </ng-container>
      </div>
    </div>

    <div >
      <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid '+color[websitetitle]  :''}">
        Step 6 - Basket
      </div>
      <div class="row">
        <table class="table">
          <tr>
            <th>Order</th>
            <th>Quantity</th>
            <th></th>
            <th>Total</th>
          </tr>
          <tbody *ngFor="let item of items; let i = index">
            <tr>
              <td>{{item.crmTitle}}</td>
              <td>
                <input *ngIf="!item.selectedVariation" type="number" min='1' (keypress)="numberOnly($event, false)" [(ngModel)]="item.quantity"
                    (change)="priceupdate()" [readonly]="!showPlaceOrder">
              </td>
              <td>
                <div *ngIf="overrideTotal && !item.selectedVariation">
                  <input type="number" step="0.01" (keypress)="numberOnly($event, true)" [(ngModel)]="item.regularPrice" (change)="priceupdate()" [readonly]="!showPlaceOrder">
                </div>
              </td>
              <td>
                <ng-container  *ngIf="!item.selectedVariation">
                  {{item.quantity * item.regularPrice | currency :currencyCode:'symbol':'1.2-2' }}
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="!!item.selectedVariation">
              <td>{{item.selectedVariation.title}}</td>
              <td>
                <input type="number" min='1' (keypress)="numberOnly($event, false)" [(ngModel)]="item.quantity" (change)="priceupdate()" [readonly]="!showPlaceOrder">
              </td>
              <td>
                <div *ngIf="overrideTotal">
                  <input type="number" step="0.01" (keypress)="numberOnly($event, true)" [(ngModel)]="item.selectedVariation.regular_price" (change)="priceupdate()"
                      [readonly]="!showPlaceOrder">
                </div>
              </td>
              <td>
                <div>
                  {{item.quantity * item.selectedVariation.regular_price | currency :currencyCode:'symbol':'1.2-2'}}
                </div>
              </td>
            </tr>
          </tbody>
          <tr *ngIf="!isExistingCustomer() && coupon">
            <th>
              Coupon
            </th>
            <th>
              <p>{{coupon.description}}</p>
              <p *ngIf="couponError" class="alert alert-danger">{{couponError}}</p>
            </th>
            <th></th>
            <th *ngIf="!couponError && (coupon.type=='percent' || coupon.type=='percent_product')"> - {{coupon.amount}}% </th>
            <th *ngIf="!couponError && (coupon.type=='fixed_cart' || coupon.type=='fixed_product')"> - {{currencySymbol}}{{coupon.amount}} </th>
            <th *ngIf="couponError"></th><!-- make the column count correct -->
          </tr>
          <ng-container *ngIf="(proRataCurrentPrice > 0) && selectedOrderType && (selectedOrderType.calculationMethod == 'Difference')">
            <th>Pro-Rata existing Plan Price (subtracted from new price)</th>
            <th colspan='2'></th>
            <th>-{{proRataCurrentPrice | currency :currencyCode:'symbol':'1.2-2'}}</th>
            <th></th>
          </ng-container>
          <tr>
            <th>Order Total</th>
            <th></th>
            <th></th>
            <th>{{TTorder | currency :currencyCode:'symbol':'1.2-2'}}</th>
          </tr>
          <tr>
            <th>
              Override Total?
              <div class="totalOverride"><input type="checkbox" [(ngModel)]="overrideTotal" [disabled]="!showPlaceOrder"></div>
              <span *ngIf="overrideTotal">Why are you overriding the total?</span>
            </th>
            <th>
              <select *ngIf="overrideTotal" [(ngModel)]="overrideReason" [disabled]="!showPlaceOrder">
                <option value="">Please Select</option>
                <option value="Complaint">Complaint</option>
                <option value="CSE given Discount">CSE given Discount</option>
                <option value="Downgrade">Downgrade</option>
                <option value="Honouring Price">Honouring Price</option>
                <option value="Price Frozen">Price Frozen</option>
                <option value="Partner Discount">Partner Discount</option>
                <option value="Switched Plans">Switched Plans</option>
                <option value="TH Digital">TH Digital</option>
                <option value="Upgrade">Upgrade</option>
                <option value="Wrong Price Recorded">Wrong Price Recorded</option>
                <option value="Other">Other</option>
              </select>
              <div class="alert alert-danger" *ngIf="overrideTotal && !overrideReason">You must select a reason for the override.</div>
            </th>
            <th colspan="2">
              <div *ngIf="overrideTotal && overrideReason == 'Other'">
                <input [(ngModel)]="otherOverrideReason" type="text" [readonly]="!showPlaceOrder" autocomplete="off" name="otherOverrideReason" style="width: 100%;" >
              </div>
            </th> 
          </tr>
        </table>
      </div>

    </div>
    <div>
      <div class="alert alert-primary" role="alert" [ngStyle]="{'border':true? '3px solid '+color[websitetitle]  :''}">
        Step 7 - Checkout
      </div>
      <div class="row Checkout">
        <div class="col-3">
          <label>Payment Method</label>
        </div>
        <div *ngIf="TTorder == 0" class="col-9" style="margin-bottom: 20px;">
          <input type="text" readonly [(ngModel)]="orderDetails.paymentMethod"/>
        </div>
        <div *ngIf="TTorder > 0" class="col-9" style="margin-bottom: 20px;">
          <select [ngClass]="{'error' : validationErrorExists && !orderDetails.paymentMethod}"
              [(ngModel)]="orderDetails.paymentMethod" [disabled]="!showPlaceOrder">
            <option value="">Please Select</option>
            <option value="Secured Debit/Credit Card">Secured Debit/Credit Card</option>
            <option value="Cheque Payment">Cheque Payment</option>
            <option *ngIf="websitetitle != 'LLIE'" value="Direct Debit">Direct Debit</option>
            <option value="Payment Link">Payment Link</option>
            <option value="Referral – Customer will pay later">Referral – Customer will pay later</option>
            <option value="Manual Stripe Payment">Manual Stripe Payment</option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="!isExistingCustomer()">
        <div class="row checkout">
          <div class="col-3">
            <label>How did you hear about us?</label>
          </div>
          <div class="col-3">
            <p-dropdown [options]="howHeardOptions" placeholder="How the customer heard about us"
                [(ngModel)]="howHeard" [style]="{'width':'100%'}" [showClear]="true" [filter]="true"
                [readonly]="!showPlaceOrder" (onChange)="changeHowHeard()">
            </p-dropdown>
          </div>
          <ng-container *ngIf="(howHeard == 'Partnership') && (partnerships.length > 0)">
            <div class="col-2">
              <label>Which Partnership?</label>
            </div>
            <div class="col-4">
              <p-dropdown [options]="partnerships" placeholder="Select which partnership"
                  [(ngModel)]="selectedPartnership" [style]="{'width':'100%'}" [showClear]="true" [filter]="true"
                  [readonly]="!showPlaceOrder" (onChange)="changePartnership()">
              </p-dropdown>
            </div>
          </ng-container>
        </div>
        <div class="row marketing">
          <div class="col-12 mb-2">
            Please remember to offer a reviews feedback invite.<br/>
            Please remember to offer a referral to friends or family if they are happy with our service.
          </div>
          <div class="col-12">
            As part of our life saving service we will occasionally send you updates, recomendations & offers via email and phone/SMS,
            can you please confirm if are you happy to receive these communications?
          </div>
          <div class="col-3">
            By Email:
          </div>
          <div class="col-9">
            <p-dropdown [options]="marketingOptInSelects" [(ngModel)]="emailMarketing">
            </p-dropdown>
          </div>
          <div class="col-3">
            By Phone:
          </div>
          <div class="col-9">
            <p-dropdown [options]="marketingOptInSelects" [(ngModel)]="phoneMarketing">
            </p-dropdown>
          </div>
        </div>
      </ng-container>
      <div *ngIf="showProgressBar">
        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
        <br>
        <br>
        <br>
      </div>
      <div *ngIf="validationErrors.length > 0" class="alert alert-danger" role="alert">
        Validation Errors:
        <ul>
          <li *ngFor="let error of validationErrors" >{{error}}</li>
        </ul>
      </div>
      <div *ngIf="!showPlaceOrder && !!currentStep" class="alert alert-success" role="alert">
        Completed Steps:
        <ul>
          <ng-container *ngFor="let stepName of Object.keys(processingSteps)">
            <li *ngIf="processingSteps[stepName].required && processingSteps[stepName].completed">
              {{stepName}}
            </li>
          </ng-container>
        </ul>
      </div>
      <div *ngIf="!!currentStep" class="alert alert-info" role="alert">
        Currently Processing Step: {{currentStep}}
      </div>
      <div *ngIf="!!processingError" class="alert alert-danger" role="alert">
        <div class="processingMsg">
          Error on Step: {{currentStep}}
          <br/>
          <span class="font-weight-bold">Error:</span>{{processingError.message}}
          <ng-container *ngIf="!!processingError.code">
            <span class="font-weight-bold">Error code:</span>{{processingError.code}}
          </ng-container>
          <button (click)="displayErrorDetails = !displayErrorDetails" type="button" class="btn minMax">
            <i *ngIf="displayErrorDetails" class="pi pi-minus"></i>
            <i *ngIf="!displayErrorDetails" class="pi pi-plus"></i>
          </button>
        </div>
        <div [ngStyle]="{'display': displayErrorDetails? 'block': 'none'}">
          <div>
            <ng-container *ngIf="processingSteps[currentStep].allowToBeSkipped">
              {{processingSteps[currentStep].messageToDisplayIfCanSkip}}
            </ng-container>
            <ng-container *ngIf="!processingSteps[currentStep].allowToBeSkipped">
              {{processingSteps[currentStep].messageToDisplay}}
            </ng-container>
          </div>
          <ng-container [ngSwitch]="currentStep">
            <ng-container *ngSwitchCase="['Creating Payment Intent on Stripe', 'Checking User Supplied Payment Reference'].includes(currentStep)? currentStep: ''">
              <ng-container *ngIf="processingSteps[currentStep].allowToBeSkipped">
                Payment Reference (this is the reference for the payment on Stripe that starts pi_):
                <input type="text" [(ngModel)]="paymentIntentRef" class="paymentRef" class="retryId" />
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Creating Direct Debit Form'">
              <input type="checkbox" (change)="manuallyCompleteStep($event)" />
            </ng-container>
            <ng-container *ngSwitchCase="[ORDER_CREATION_STEP, 'Checking User Supplied OrderId'].includes(currentStep)? currentStep: ''">
              Order Id (this is the number beside the # in the site's admin area. Do not include the #):
              <input type="number" [(ngModel)]="websiteOrderId" class="retryId" (keypress)="numberOnly($event, false)" />
            </ng-container>
            <ng-container *ngSwitchCase="'Importing into CRM'">
              <input type="checkbox" (change)="manuallyCompleteStep($event)"/>
            </ng-container>
          </ng-container>
          <div *ngIf="(currentStep != ORDER_STATUS_UPDATE_STEP) || processingSteps['Importing into CRM'].required">
            Clicking RETRY will continue from the first uncompleted step.<br/>
            Steps still to do:
            <ul>
              <ng-container *ngFor="let stepName of Object.keys(processingSteps)">
                <li *ngIf="processingSteps[stepName].required && !processingSteps[stepName].completed && 
                    (stepName != ORDER_STATUS_UPDATE_STEP)">
                  {{stepName}}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div *ngIf="(currentStep != ORDER_STATUS_UPDATE_STEP) || processingSteps['Importing into CRM'].required">
          <button pButton type="button" label="RETRY" class="ui-button-rounded" (click)="retryOrder()">
          </button>
        </div>
      </div>
      <div *ngIf="!showProgressBar && processingSteps[ORDER_CREATION_STEP].completed">
        <div class="alert alert-success" role="alert">
          Order Received <br>
          <a *ngIf="!!orderLink" href="{{orderLink}}" target="_blank" class="alert-link">View this order in the admin area </a> 
          <ng-container *ngIf="success">
            <ng-container *ngIf="!!crmOrderLink">
              | <a routerLink="{{crmOrderLink}}" class="alert-link">View order on CRM</a>
            </ng-container>
            | <a (click)="refresh()" class="alert-link">Add new manual order</a>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="orderDetails.paymentMethod != 'Secured Debit/Credit Card'">
      <div class="col-3 offset-9" *ngIf="showPlaceOrder">
        <button [disabled]="isPlaceOrderDisabled" pButton type="button" label="PLACE ORDER"
            class="ui-button-rounded" (click)="createOrder()">
        </button>
      </div>
    </div>
  </ng-container>
  
  <div class="container-fluid" [ngStyle]="{'display': orderDetails.paymentMethod == 'Secured Debit/Credit Card'? 'initial' : 'none'}">
    <div class="form-row">
      <div class="col-3">
        <label>Name on Card</label>
      </div>
      <div class="col-9">
        <input type="text" [(ngModel)]="nameOnCard" [readonly]="!showPlaceOrder" autocomplete="off"/>
      </div>
    </div>
    <form (ngSubmit)="createCardPaidOrder()" class="checkout" >
      <div class="form-row">
        <div [ngStyle]="{'display':  (stripeAccount == 'LL')? 'initial' : 'none' }" id="card-info" #cardInfoLL ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'CK')? 'initial' : 'none' }" id="card-info" #cardInfoCK ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'TC')? 'initial' : 'none' }" id="card-info" #cardInfoTC ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'CL')? 'initial' : 'none' }" id="card-info" #cardInfoCL ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'LLIE')? 'initial' : 'none' }" id="card-info" #cardInfoLLIE ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'LL_OLD')? 'initial' : 'none' }" id="card-info" #cardInfoLLOld ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'CK_OLD')? 'initial' : 'none' }" id="card-info" #cardInfoCKOld ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'TC_OLD')? 'initial' : 'none' }" id="card-info" #cardInfoTCOld ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'CL_OLD')? 'initial' : 'none' }" id="card-info" #cardInfoCLOld ></div>
        <div [ngStyle]="{'display':  (stripeAccount == 'LLIE_OLD')? 'initial' : 'none' }" id="card-info" #cardInfoLLIEOld ></div>
        <div id="card-errors" role="alert" *ngIf="error"><p>{{ error }}</p></div>
      </div>
      <button type="submit" *ngIf="showPlaceOrder" [disabled]="isPlaceOrderDisabled">
        PLACE ORDER
      </button>
    </form>
  </div>
</div>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error"></p-confirmDialog>