import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PageCountResponse} from '../../models/responses/pageCountResponse.model';
import {Log} from '../../models/log.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SaveLogRequest} from '../../models/requests/saveLogRequest.model';
import {SaveLogsRequest} from '../../models/requests/saveLogsRequest.model';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  private API_URL: string = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getLogPageCount(source: string, startDate: string, endDate: string): Observable<PageCountResponse> {
    return this.http.get<PageCountResponse>(`${this.API_URL}/logs/pagecount/${source}/${startDate}/${endDate}`);
  }

  getLogPage(source: string, startDate: string, endDate: string, page: number): Observable<MultiRecordResponse<Log>> {
    return this.http.get<MultiRecordResponse<Log>>(`${this.API_URL}/logs/page/${source}/${startDate}/${endDate}/${page}`);
  }

  getLog(id: string): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/logs/${id}`);
  }

  createLog(logParams: SaveLogRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/logs`, logParams);
  }

  createLogs(logsParams: SaveLogsRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/logs/multiple`, logsParams);
  }

}
