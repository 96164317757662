import { Component, Input, OnInit } from '@angular/core';
import {OrderService} from '../order.service';
import {AlarmActivation} from '../../models/alarmActivation.model';
import {isValidObjectId} from '../../helpers/helperFunctions';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Column} from '../../models/column.model';

@Component({
  selector: 'app-alarm-activation-section[orderId]',
  templateUrl: './alarm-activation-section.component.html',
  styleUrls: ['../post-order-sections.scss', './alarm-activation-section.component.scss']
})
export class AlarmActivationSectionComponent implements OnInit {
  @Input() orderId: string;
  alarmActivations: AlarmActivation[] = [];
  cols: Column[] = [
    { field: 'activationType', header: 'Type' },
    { field: 'deviceIdent', header: 'Device Ident' },
    { field: 'activated', header: 'Last Occurred' },
    { field: 'notifications', header: 'Notifications Sent'},
    { field: 'incident', header: 'Incident Details' },
  ];

  constructor(
    private orderService: OrderService,
  ) { }

  ngOnInit(): void {
    if (!isValidObjectId(this.orderId)) {
      return;
    }
    this.orderService.getAlarmActivationsForOrderAndType(this.orderId, 'Alarm Unit Low Battery')
      .subscribe((response: MultiRecordResponse<AlarmActivation>) => {
        if (response.success) {
          this.alarmActivations = response.data;
        } else {
          console.error('Error getting Alarm Activations', response.message);
        }
      });
  }

}
