import {environment} from './../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {ActionConfig} from '../../lookups/actions';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  API_URL: string = `${environment.protocol}${environment.IPAddress}/api`;

  constructor(
    private http: HttpClient,
  ) { }

  addAction(params): Observable<SingleRecordResponse<ActionConfig>> {
    return this.http.post<SingleRecordResponse<ActionConfig>>(`${this.API_URL}/actions`, params);
  }

  getActions(): Observable<MultiRecordResponse<ActionConfig>> {
    return this.http.get<MultiRecordResponse<ActionConfig>>(`${this.API_URL}/actions`);
  }

  updateAction(params): Observable<SimpleResponse> {
    return this.http.patch<SimpleResponse>(`${this.API_URL}/actions`, params);
  }
}
