<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [baseZIndex]=1100></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}"></p-toast>

<p-dialog  [(visible)]="showColumSetDialog" [modal]="true" [style]="{width: '300px', height: '200px' , minWidth: '300px'}" [minY]="700"
           [maximizable]="true" [baseZIndex]=1000>
  <p-header>Please Provide the Name</p-header>
  <label>New Set Name:</label><input type="text" [(ngModel)]="columnSetName" pInputText class="w-100"/>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="closeColumnSetDialog()" label="OK"></button>
  </p-footer>
</p-dialog>

<p-dialog  [(visible)]="showImportDialog" [modal]="true" [style]="{width: '850px', height: '800px' , minWidth: '600px'}" [minY]="700"
           [maximizable]="true" [baseZIndex]="1000">
  <p-header>
    <h3 *ngIf="progress>-1">Import in progress...</h3>
    <h3 *ngIf="progress==-1" style="font-weight:normal">Import Order Records</h3>
  </p-header>
  <div class="container" *ngIf="progress>-1" >
    <p-progressBar [value]="progressVal"></p-progressBar>
    <div class="row">
      <div class="col-md-6">
        <p-scrollPanel [style]="{width: '100%', height: '200px'}" styleClass="custombar1">
          <div style="padding:1em;line-height:1.5">
            <div *ngFor="let log of logs"  [ngClass]="log.status"  >
              <p *ngIf="log.status!='alert-danger' && log.status!='alert-warning'" > {{log.tdCode}} :  {{log.msg}} </p>
            </div>
          </div>
        </p-scrollPanel>
      </div>
      <div class="col-md-6">
        <p-scrollPanel [style]="{width: '100%', height: '200px'}" styleClass="custombar1">
          <div style="padding:1em;line-height:1.5">
            <div *ngFor="let log of logs"  [ngClass]="log.status">
              <p *ngIf="log.status=='alert-danger' || log.status=='alert-warning'" > {{log.tdCode}} :  {{log.msg}} </p>
            </div>
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
  <div class="import" *ngIf="progress==-1">
    <div class="row no-gutters">
      <div class="col-4">
        <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file"
          [accept]="(!!selectedImportType && selectedImportType.appName == 'create review')? '.csv': '.xlsx'">
      </div>
      <div class="col-6">
        <select name="selectedImportType" [(ngModel)]="selectedImportType" class="ml-2">
          <ng-container *ngFor="let importType of importTypes; let i = index">
            <option [ngValue]="importType">{{importType.label}}</option>
          </ng-container>
        </select>
        <ng-container *ngIf="selectedImportType && selectedImportType.orderCreation === 'Yes, if option ticked'">
          <label for="allowOrderCreation">Allow order creation</label>
          <input type="checkbox" [(ngModel)]="allowOrderCreation" name="allowOrderCreation" id="allowOrderCreation" />
        </ng-container>
        <ng-container *ngIf="selectedImportType && selectedImportType.appName == 'create review'">
          <select name="selectedBrandForReview" [(ngModel)]="selectedBrandForReview" class="ml-2">
            <option value="Select Brand" selected disabled>Select Brand</option>
            <ng-container *ngFor="let brand of brands; let i = index">
              <option [ngValue]="brand.value">{{brand.label}}</option>
            </ng-container>
          </select>
        </ng-container>
      </div>
      <div class="col-2">
        <button type="button" class="btn btn-info" (click)="upload()" [disabled]="!file" >Upload</button>
        <button type="button" class="btn btn-warning" (click)="showImportDialog = false" >Cancel</button>
      </div>
    </div>
    <div *ngIf="selectedImportType">
      <div class="my-2">
        <label class="mb-0">General Note:</label> The Brand column must contain the brand code, not the name.
        This is visible in the brand colour bars within the CRM e.g. in the Brand filter dropdown on the homepage.
      </div>
      <div class="mb-2">
        <label class="mb-0">Purpose:</label> {{selectedImportType.purpose}}<br/>
      </div>
      <div class="mb-2">
        <label class="mb-0">Will create order the if it does not exist:</label> {{selectedImportType.orderCreation}}<br/>
      </div>
      <div class="mb-2">
        <label class="mb-0">Required Columns:</label> {{selectedImportType.requiredColumns}}<br/>
      </div>
      <div class="mb-2">
        <label class="mb-0">Notes:</label> {{selectedImportType.notes}}<br/>
      </div>
    </div>
  </div>
  <div class="container importResults" *ngIf="progress == -2">
    <ng-container *ngIf="logs.length === 0">
      <div>
        Submitting request please wait... 
      </div>
      <div>
        <i class="fa fa-spinner fa-spin"></i>
      </div>
    </ng-container>
    <ng-container *ngIf="logs.length > 0">
      <div class="importResultsLog">
        <div *ngFor="let log of logs" [ngClass]="log.status">
          {{log.msg}}
        </div>
      </div>
      <button type="button" class="btn btn-warning" (click)="closeUpload()">Close</button>
    </ng-container>
  </div>
  <p-footer>
    <ng-container *ngIf="maxToProcess==progress">
      <button type="button" pButton icon="pi pi-check" (click)="refresh()" label="Reload page"></button>
    </ng-container>
  </p-footer>
</p-dialog>
<div class="mb-5"></div>
<!-- anchor must be a passive link -->
<div class="container-fluid" id="custom-table-mt">
  <!--Export-->
  <!--End Export-->
  <p-dialog class="vim-modal" header="&nbsp;"
            [(visible)]="vimModalDisplay"
            [modal]="true"
            [style]="{'font-weight': '500'}"
            [showHeader]="true"
            [draggable]="true"
            [closeOnEscape]="true"
            [dismissableMask]="true">
    <p-header>
      VIM: {{titleDialog}}
    </p-header>
    <div class="vim-modal-box" *ngFor="let vim of vimsForDialog; let i = index">
      {{i+1}}) {{vim}}
    </div>
  </p-dialog>
  <p-table #dt
           (sortFunction)="customSort($event)" [customSort]="true"
           (onFilter)="customFilter($event)"
           [columns]="selectedColumns"
           [value]="data"
           [paginator]=true
           [rows]="15"
           [rowsPerPageOptions]="[15,25,35,45, All]"
           sortField="created"
           [sortOrder]="-1"
           sortMode="single"
           [(selection)]="selectedOrders"
           dataKey="_id"
           [totalRecords]="totalRecords"
           loadingIcon="fa-cog"
           [globalFilterFields]="bigSearch"
           exportFilename="CRM-Export @ {{curDate | date:'dd-MM-y'}}"
           [autoLayout]="true"
           [showCurrentPageReport]="true"
           currentPageReportTemplate="PAGE {currentPage} OF {totalPages} - CUSTOMERS {first} TO {last} OF {totalRecords}">
    <ng-template pTemplate="caption">
      <div id="table-bar-holder">
        <p-multiSelect
             [options]="cols"
             [(ngModel)]="selectedColumns"
             optionLabel="header"
             [maxSelectedLabels]=2
             selectedItemsLabel="{0} selected columns will be exported"
             [style]="{minWidth: '200px'}"
             placeholder="Filter Columns"
             scrollHeight="600px"
             (onChange)="onColumnChange($event, dt)" >
        </p-multiSelect>
        <div ngbDropdown class="d-inline-flex">
          <button *ngIf="userCanExportOrders()" type="button" id="customExport" ngbDropdownToggle
              class="ml-2 p-button" aria-controls="exportCustomColumnControl" [disabled]="selectedOrders.length == 0">
            Custom Export
          </button>
          <div ngbDropdownMenu aria-labelledby="customExport" id="customExportOptions">
            <ng-container *ngFor="let option of orderExportOptions">
              <button ngbDropdownItem type="button" (click)="customExport(option)">{{option}}</button>
            </ng-container>
          </div>
        </div>
        <div ngbDropdown class="d-inline-flex">
          <button type="button" id="loadLayout" ngbDropdownToggle class="ml-2 p-button" aria-controls="loadLayoutControl">
            Load Columns
          </button>
          <div ngbDropdownMenu aria-labelledby="loadLayout" id="loadLayoutOptions">
            <button ngbDropdownItem type="button" (click)="loadColumnSet(defaultColumns)">
              Default
            </button>
            <ng-container *ngFor="let columnSet of savedColumnSets">
              <button ngbDropdownItem type="button" (click)="loadColumnSet(columnSet.value)">{{columnSet.label}}</button>
            </ng-container>
          </div>
        </div>
        <div ngbDropdown class="d-inline-flex">
          <button type="button" id="saveLayout" ngbDropdownToggle class="ml-2 p-button" aria-controls="saveLayoutControl" [disabled]="isSaveColumnDisabled">
            Save Columns
          </button>
          <div ngbDropdownMenu aria-labelledby="saveLayout" id="saveLayoutOptions">
            <button ngbDropdownItem type="button" (click)="saveColumns('Default', false)">
              Default
            </button>
            <ng-container *ngFor="let columnSet of savedColumnSets">
              <button ngbDropdownItem type="button" (click)="saveColumns(columnSet.label, false)">
                {{columnSet.label}}
              </button>
            </ng-container>
            <button ngbDropdownItem type="button" (click)="promptForSetName()">
              <i>Save new set</i>
            </button>
          </div>
        </div>
        <input type="search" class="ml-2 px-1 searchclass" style="min-width: 200px;"
               pInputText size="100 " [(ngModel)]="findField" placeholder="Global Search"
               (input)="globalFilter($event, 'contains')">
        <button type="button" pButton label="Reset Filters" (click)="onClick()" class="ml-2 p-0">
        </button>
        <button type="button" pButton label="Import" (click)="showImportDialog=true" class="ml-2 p-0"
            *ngIf="userHasImportPermission()">
        </button>
        <div ngbDropdown class="d-inline-flex">
          <button *ngIf="userCanExportOrders()" type="button" id="exportAll" ngbDropdownToggle
              class="ml-2 p-button" aria-controls="exportCustomColumnControl" [disabled]="selectedOrders.length == 0">
            Export All Columns
          </button>
          <div ngbDropdownMenu aria-labelledby="exportAll" id="exportAllOptions">
            <ng-container *ngFor="let option of orderExportOptions">
              <button ngbDropdownItem type="button" (click)="export(option)">{{option}}</button>
            </ng-container>
          </div>
        </div>
      </div>
      <!--Export-->
      <div class="ui-helper-clearfix">
      </div>
      <!--End Export-->
    </ng-template>
    <!-- HEADER-->
    <ng-template pTemplate="header" let-columns>
      <tr style="border-bottom: 2px solid #fff;">
        <th style="width: 3%;max-width: 3%;">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let col of columns">
          <!-- If the Tags column is visible tag expiry dates will be embedded in it, so hide the tag expiry date column -->
          <ng-container *ngIf="!((col.field == 'tagExpiryDates') && isColumnVisible('Tags'))">
            <th [ngStyle]="{'display': col.display}">
              <div *ngIf="col.field != 'outstandingActionsRenewalDateTaken'" (click)="mark=0" [pSortableColumn]="col.field"  >
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
              </div>
              <div *ngIf="col.field== 'outstandingActionsRenewalDateTaken'" [pSortableColumn]="col.field"  >
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order">
                </p-sortIcon>
              </div>
            </th>
          </ng-container>
        </ng-container>
      </tr>
      <tr style="border-bottom: 7px solid #eaeaea;">
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let col of columns">
          <!-- If the Tags column is visible tag expiry dates will be embedded in it, so hide the tag expiry date column -->
          <ng-container *ngIf="!((col.field == 'tagExpiryDates') && isColumnVisible('Tags'))">
            <th style="padding: 10px 5px;" [ngSwitch]="col.field" [ngStyle]="{'display': col.display}">
              <p-multiSelect *ngSwitchCase="'title'" [options]="brands" appendTo="body"
                  [(ngModel)]="selectedBrand"
                  placeholder="Brands" filterPlaceHolder="brand" styleClass="brandcss"
                  [panelStyle]="{maxWidth:'135px'}" scrollHeight="600px" [maxSelectedLabels]=0
                  selectedItemsLabel="{0} selected" [style]="{'display':'flex'}"
                  (onChange)="dt.filter($event.value, col.field, 'in')" >
                <ng-template let-Pbrand let-i="index" pTemplate="item">
                  <span class="brandBar" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color':brandConfigs[Pbrand.value].color}">
                    &nbsp;{{Pbrand.value}}
                  </span>
                </ng-template>
              </p-multiSelect>

              <p-multiSelect *ngSwitchCase="'renewalType'" [options]="renewaltypes" appendTo="body"
                  [(ngModel)]="selectedrenewalType" placeholder="renewaltypes" filterPlaceHolder="renewal Type"
                  styleClass="brandcss" [panelStyle]="{maxWidth:'135px'}" scrollHeight="600px"
                  [maxSelectedLabels]=0 selectedItemsLabel="{0} selected"
                  [style]="{'display':'flex'}" (onChange)="dt.filter($event.value, col.field, 'in')" >
              </p-multiSelect>

              <div class=" ui-md-3">
                <p-calendar
                  [(ngModel)]="dateFilters"
                  appendTo="body"
                  *ngSwitchCase="'created'"
                  selectionMode="range"
                  [readonlyInput]="true"
                  dateFormat="dd/mm/yy"
                  (onSelect)="dt.filter($event, col.field, 'inCreatedCollection')"
                  [monthNavigator]=true [yearNavigator]=true [yearRange]="filterYearRange"
                  [showButtonBar]=true
                  [keepInvalid]=true
                  [numberOfMonths]="1"
                  [showOtherMonths]=true
                  [selectOtherMonths]=true
                  [showIcon]=true
                  [inputStyle]="{maxWidth:'100px'}"
                  [hideTransitionOptions]="'0ms'"
                  (onClearClick)="clearDateFilters('created')">
                  

                  <p-footer >
                    <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" >
                      <button type="button" (click)="createdDateCustomFilter($event,-7); dt.filter($event, col.field, 'inCreatedCollection')">
                        <span class="ui-button-text ui-clickable">Last Week</span> </button>
                    </div>
                    <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                      <button type="button" (click)="createdDateCustomFilter($event,-15); dt.filter($event, col.field, 'inCreatedCollection')">
                        <span class="ui-button-text ui-clickable">Last 2 Weeks</span></button>
                    </div>
                    <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                      <button type="button" (click)="createdDateCustomFilter($event,-30); dt.filter($event, col.field, 'inCreatedCollection')">
                        <span class="ui-button-text ui-clickable">Last 30 Days</span></button>
                    </div>
                    <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                      <button type="button" (click)="lastCalenderMonth($event,1, 1); dt.filter($event, col.field, 'inCreatedCollection')">
                        <span class="ui-button-text ui-clickable">Last Calendar Month</span></button>
                    </div>
                  </p-footer>
                </p-calendar>
              </div>

              <div class=" ui-md-3">
                <p-calendar
                  [(ngModel)]="renewalDateFilters"
                  appendTo="body"
                  *ngSwitchCase="'renewalDate'"
                  selectionMode="range"
                  [readonlyInput]="true"
                  dateFormat="dd/mm/yy"
                  (onSelect)="dt.filter($event, col.field, 'inrenewalDateFilters')"
                  [monthNavigator]=true [yearNavigator]=true [yearRange]="filterYearRange" 
                  [showButtonBar]=true
                  [keepInvalid]=true
                  [numberOfMonths]="1"
                  [showOtherMonths]=true
                  [selectOtherMonths]=true
                  [showIcon]=true
                  [inputStyle]="{maxWidth:'100px'}"

                  [hideTransitionOptions]="'0ms'"
                  (onClearClick)="clearDateFilters('renewalDate')" >
                </p-calendar>
              </div>

              <p-dropdown *ngSwitchCase="'outstandingActionsName'" [options]="actionSelectItems"
                          placeholder="Actions" appendTo="body"
                          [ngModel]="getFilterValue(col.field)"
                          (onChange)="dt.filter($event.value, col.field, 'array-contains-value');selectedAction = $event.value; "
                          [filter]=true [showClear]="true"
                          [style]="{'width':'100%'}">
                <ng-template let-Paction let-i="index" pTemplate="item">
                  <span class="actionSelection" [style.background-color]="actions[Paction.value].background"
                      [style.color]="actions[Paction.value].color">
                    {{Paction.label}}
                  </span>
                </ng-template>
              </p-dropdown>

              <div class=" ui-md-3">
                <p-calendar
                  [(ngModel)]="actionDateFilters"
                  appendTo="body"
                  *ngSwitchCase="'outstandingActionsRenewalDateTaken'"
                  selectionMode="range"
                  [readonlyInput]=true
                  dateFormat="dd/mm/yy"
                  (onSelect)="dt.filter($event, col.field, 'inCollection')"
                  [monthNavigator]=true [yearNavigator]=true [yearRange]="filterYearRange"
                  [showButtonBar]=true
                  [keepInvalid]=true
                  [numberOfMonths]="1"
                  [inputStyle]="{maxWidth:'100px'}"
                  [showOtherMonths]=true
                  [selectOtherMonths]=true
                  [showIcon]=true
                  (onClearClick)="clearDateFilters('outstandingActionsRenewalDateTaken')">
                  <p-footer >
                    <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                      <button type="button" (click)="actionDateCustomFilter($event,1); dt.filter($event, col.field, 'inCollection')"><span class="ui-button-text ui-clickable">Tomorrow</span></button>
                    </div>
                    <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" >
                      <button type="button" (click)="actionDateCustomFilter($event,7); dt.filter($event, col.field, 'inCollection')"><span class="ui-button-text ui-clickable">Next Week</span> </button>
                    </div>
                    <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                      <button type="button" (click)="actionDateCustomFilter($event,30); dt.filter($event, col.field, 'inCollection')"><span class="ui-button-text ui-clickable">Next 30 days</span></button>
                    </div>
                      <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                        <button type="button" (click)="actionDateCustomFilter($event,-30); dt.filter($event, col.field, 'inCollection')"><span class="ui-button-text ui-clickable">Last 30 days</span></button>
                    </div>
                    <div class="ng-tns-c6-9 ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                        <button type="button" (click)="dt.filter($event, col.field, 'NoDate')"><span class="ui-button-text ui-clickable">No date</span></button>
                    </div>
                  </p-footer>
                </p-calendar>
              </div>

              <div class=" ui-md-3">
                <p-calendar
                  [(ngModel)]="actionsInitiatedDateFilters"
                  appendTo="body"
                  *ngSwitchCase="'outstandingActionsInitiatedDate'"
                  selectionMode="range"
                  [readonlyInput]=true
                  dateFormat="dd/mm/yy"
                  (onSelect)="dt.filter($event, col.field, 'inCollectionActionsInitiatedDateFilter')"
                  [monthNavigator]=true [yearNavigator]=true [yearRange]="filterYearRange"
                  [showButtonBar]=true
                  [keepInvalid]=true
                  [numberOfMonths]="1"
                  [inputStyle]="{maxWidth:'100px'}"
                  [showOtherMonths]=true
                  [selectOtherMonths]=true
                  [showIcon]=true
                  (onClearClick)="clearDateFilters('outstandingActionsInitiatedDate')">
                </p-calendar>
              </div>

              <div *ngSwitchCase="'notesDate'">
                <p-calendar
                  [(ngModel)]="notesDateDateFilters"
                  appendTo="body"
                  selectionMode="range"
                  [readonlyInput]=true
                  dateFormat="dd/mm/yy"
                  (onSelect)="dt.filter($event, 'noteTime', 'inCollectionNoteDate')"
                  [monthNavigator]=true [yearNavigator]=true [yearRange]="filterYearRange"
                  [showButtonBar]=true
                  [keepInvalid]=true
                  [numberOfMonths]="1"
                  [inputStyle]="{maxWidth:'100px'}"
                  [showOtherMonths]=true
                  [selectOtherMonths]=true
                  [showIcon]=true>
                </p-calendar>
              </div>

              <p-calendar
                [(ngModel)]="cancellationDateFilters"
                appendTo="body"
                *ngSwitchCase="'cancellationDate'"
                selectionMode="range"
                [readonlyInput]=true
                dateFormat="dd/mm/yy"
                (onSelect)="dt.filter($event, col.field, 'inCollectionCancellationDate')"
                [monthNavigator]=true [yearNavigator]=true [yearRange]="filterYearRange"
                [showButtonBar]=true
                [keepInvalid]=true
                [numberOfMonths]="1"
                [inputStyle]="{maxWidth:'100px'}"
                [showOtherMonths]=true
                [selectOtherMonths]=true
                [showIcon]=true
                (onClearClick)="clearDateFilters('cancellationDate')">
              </p-calendar>

              <p-calendar
                [(ngModel)]="returnDateDateFilters"
                appendTo="body"
                *ngSwitchCase="'returnDate'"
                selectionMode="range"
                [readonlyInput]=true
                dateFormat="dd/mm/yy"
                (onSelect)="dt.filter($event, col.field, 'inCollectionReturnDate')"
                [monthNavigator]=true [yearNavigator]=true [yearRange]="filterYearRange"
                [showButtonBar]=true
                [keepInvalid]=true
                [numberOfMonths]="1"
                [inputStyle]="{maxWidth:'100px'}"
                [showOtherMonths]=true
                [selectOtherMonths]=true
                [showIcon]=true
                (onClearClick)="clearDateFilters('returnDate')">
              </p-calendar>

              <p-multiSelect *ngSwitchCase="'status'" appendTo="body"
                            [panelStyle]="{maxWidth:'135px'}"
                            [(ngModel)]="selectedstatus"
                            [options]="statusType" placeholder="Status"
                            (onChange)="dt.filter($event.value, col.field, 'in')"
                            scrollHeight="600px"
                            selectedItemsLabel="{0} selected"
                            [maxSelectedLabels]=2
                            [style]="{'display':'flex'}" >
                <ng-template let-Pstatu let-i="index" pTemplate="item">
                  <span [style.background-color]="statusConfigs[Pstatu.value].background" [style.color]="statusConfigs[Pstatu.value].color"
                      class="statusSelection">
                    {{Pstatu.label}}
                  </span>
                </ng-template>
              </p-multiSelect>

              <p-multiSelect *ngSwitchCase="'freemonths'" appendTo="body"
                            [panelStyle]="{maxWidth:'135px'}"
                            [(ngModel)]="selectedMonths"
                            [options]="freeMonthsType" placeholder="Free Months"
                            (onChange)="dt.filter($event.value, col.field, 'in')"
                            scrollHeight="600px"
                            selectedItemsLabel="{0} selected"
                            [maxSelectedLabels]=2
                            [style]="{'display':'flex'}" >
                <ng-template let-Pstatu let-i="index" pTemplate="item">
                  <span class="statusSelection" [ngStyle]="{ 'color':'black', 'padding':'5px','font-weight': '900' , 'font-size':'11px'}">
                    {{Pstatu.label}}
                  </span>
                </ng-template>
              </p-multiSelect>

              <p-multiSelect *ngSwitchCase="'monitoring'" appendTo="body"
                            [panelStyle]="{maxWidth:'135px'}"
                            [(ngModel)]="selectedmonitoring"
                            [options]="monitoringOptions" placeholder="Monitoring"
                            (onChange)="dt.filter($event.value, col.field, 'in')"
                            scrollHeight="600px"
                            selectedItemsLabel="{0} selected"
                            [maxSelectedLabels]=2
                            [style]="{'display':'flex'}" >
              </p-multiSelect>

              <!-- TODO start remove for hardware version -->
              <p-dropdown *ngSwitchCase="'planStatus'" [options]="pStatus"
                          placeholder="Status" appendTo="body"
                          (onChange)="dt.filter($event.value, col.field, 'contains')"
                          [filter]=true [showClear]="true"
                          [ngModel]="getFilterValue(col.field)"
                          [style]="{'width':'120px'}" >
              </p-dropdown>

              <p-dropdown *ngSwitchCase="'pendantStatus'" [options]="pStatus"
                          placeholder="Status" appendTo="body"
                          (onChange)="dt.filter($event.value, col.field, 'contains')"
                          [ngModel]="getFilterValue(col.field)"
                          [filter]=true [showClear]="true"
                          [style]="{'width':'120px'}"  >
              </p-dropdown>

              <p-dropdown *ngSwitchCase="'ReplacementEquipmentStatus'" [options]="pStatus"
                    placeholder="Status" appendTo="body"
                    (onChange)="dt.filter($event.value, col.field, 'contains')"
                    [filter]=true [showClear]="true"
                    [style]="{'width':'120px'}"
                    [ngModel]="getFilterValue(col.field)" >
              </p-dropdown>

              <p-dropdown *ngSwitchCase="'AdditionalEquipmentStatus'" [options]="pStatus"
                          placeholder="Status" appendTo="body"
                          (onChange)="dt.filter($event.value, col.field, 'contains')"
                          [filter]=true [showClear]="true"
                          [style]="{'width':'120px'}"
                          [ngModel]="getFilterValue(col.field)" >
              </p-dropdown>
              <!-- TODO end remove for hardware version -->

              <p-dropdown *ngSwitchCase="'hardwareStatus'" [options]="pStatus"
                          placeholder="Status" appendTo="body"
                          (onChange)="dt.filter($event.value, col.field, 'contains')"
                          [filter]=true [showClear]="true"
                          [ngModel]="getFilterValue(col.field)"
                          [style]="{'width':'120px'}" >
              </p-dropdown>

              <p-dropdown *ngSwitchCase="'cancellationReason'" [options]="cancellationReasons"
                          placeholder="Reason" appendTo="body" [showClear]="true"
                          (onChange)="dt.filter($event.value, col.field, 'blank-or-equals')"
                          [filter]=true [style]="{'width':'120px'}" styleClass="cancelFilter"
                          [ngModel]="getFilterValue(col.field)">
                <ng-template let-reason let-i="index" pTemplate="item">
                  <span class="cancelFilterItem">
                    {{reason.label}}&nbsp;
                  </span>
                </ng-template>
              </p-dropdown>

              <p-columnFilter *ngSwitchCase="'tdCode'" type="text" [matchMode]="col.filterMatchMode" 
                  [matchModeOptions]="tdCodeMatchModeOptions" field="{{col.field}}" class="p-ml-auto">
              </p-columnFilter>

              <p-multiSelect *ngSwitchCase="'Tags'" [options]="tagSelectItems" appendTo="body"
                  (onChange)="dt.filter($event.value, col.field, 'array-contains-array')"
                  placeholder="Tags" [filter]=true [style]="{'width':'100%'}" 
                  [ngModel]="getFilterValue(col.field)">
                <ng-template let-tag let-tagIndex="index" pTemplate="item">
                  <span class="tagSelection" [style.background-color]="tagsColor[tag.value]">
                    {{tag.label}}
                  </span>
                </ng-template>  
              </p-multiSelect>

              <div *ngSwitchCase="'notesWithDate'" class="p-d-flex p-jc-between p-ai-center">
                <input pInputText type="search" (input)="applyFilter($event, 'notesContent', col.filterMatchMode)"
                  [value]="getFilterValue('notesContent')" style="width: 100%;">
              </div>

              <!-- 
                Selectable search types only actually make sense for some fields.
                In particular any array item has to have old style filter as it's a array of items
                and new filter options don't work correctly
              -->
              <div *ngSwitchDefault class="p-d-flex p-jc-between p-ai-center">
                <p-columnFilter *ngIf="col.usePColumnFilter" type="text" [matchMode]="col.filterMatchMode" field="{{col.field}}" class="p-ml-auto">
                </p-columnFilter>
                <input *ngIf="!col.usePColumnFilter" pInputText type="search" (input)="applyFilter($event, col.field, col.filterMatchMode)"
                  [value]="getFilterValue(col.field)" style="width: 100%;">
              </div>
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <!--END HEADER-->
    <!--BODY-->
    <ng-template pTemplate="body" let-rowData let-i="tdCode" let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td>
          <p-tableCheckbox  [value]="rowData"></p-tableCheckbox>
        </td>
        <ng-container *ngFor="let col of columns">
          <!-- If the Tags column is visible tag expiry dates will be embedded in it, so hide the tag expiry date column -->
          <ng-container *ngIf="!((col.field == 'tagExpiryDates') && isColumnVisible('Tags'))">
            <td [ngSwitch]="true" [ngStyle]="{'display': col.display}">
              <div *ngSwitchCase="col.field === 'title'"
                  [ngStyle]="{'background-color': getBrandBackgroundColour(rowData[col.field]), 'color': getBrandColour(rowData[col.field]) }">
                {{rowData[col.field]}}
              </div>
              <ng-container *ngSwitchCase="col.field === 'Tags'" >
                <div *ngFor="let name of rowData[col.field] ; let i = index "
                    [ngStyle]="{'background-color': tagsColor[name], 'font-weight':'bold'}">
                  <p class="tag"> * {{name}}</p>
                  <span *ngIf="isColumnVisible('tagExpiryDates') && (rowData['tagExpiryDates'][i] !== 'NO EXPIRY') && (rowData['tagExpiryDates'][i] !== 'NO DATE')">
                    <p class="dateTakenp">Expires: {{rowData['tagExpiryDates'][i]}}</p>
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'tagExpiryDates'">
                <div *ngFor="let expiryDate of rowData[col.field]  ; let i = index">
                  <p class="dateTakenp">{{expiryDate}}</p>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'tdCode'">
                <a routerLink="/order/{{rowData._id}}" target="_blank" >
                  {{rowData[col.field]}}
                  <div *ngIf="isOrderLocked(rowData._id)">
                    {{ orderLockedBy(rowData._id) }}
                  </div>
                </a>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'returnDate'">{{rowData[col.field] | date:'dd/MM/y'}}</ng-container>
              <ng-container *ngSwitchCase="col.field === 'statusDate'">{{rowData[col.field] | date:'dd/MM/y'}}</ng-container>
              <ng-container *ngSwitchCase="col.field === 'renewalDate'">{{rowData[col.field]}}</ng-container>
              <ng-container *ngSwitchCase="col.field === 'created'">{{rowData[col.field] | date:'dd/MM/y'}}</ng-container>
              <ng-container *ngSwitchCase="col.field === 'vim'">

                <div class="vim-table-box" *ngIf="0< rowData[col.field]?.length" (click)="showDialog(rowData)">
                  {{ rowData[col.field][0] | slice:sliceOptions.start:sliceOptions.end}}
                  <span *ngIf="rowData[col.field][0]?.length >= sliceOptions.default">...</span><span><i class="pi pi-plus"></i></span>
                </div>
              </ng-container>

              <div *ngSwitchCase="col.field === 'status'" class="status"
                    [style.background-color]="getStatusBackgroundColour(rowData[col.field])" [style.color]="getStatusColour(rowData[col.field])">
                {{rowData[col.field]}}
              </div>

              <ng-container *ngSwitchCase="col.field === 'outstandingActionsName'">
                <div *ngFor="let name of rowData[col.field] ; let i = index"
                    [style.background-color]="getActionBackgroundColour(name)" 
                    [style.color]="getActionColour(name)">
                  <p class="actiontitlep">{{name}}</p>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'outstandingActionsRenewalDateTaken'">
                <div *ngFor="let dateTaken of rowData[col.field]  ; let i = index ">
                  <p class="dateTakenp" *ngIf=" !(dateTaken > minDAte) ">No date</p>
                  <p class="dateTakenp" *ngIf="dateTaken > minDAte">{{dateTaken | date:'dd/MM/y'}} </p>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'outstandingActionsInitiatedDate'">
                <div *ngFor="let dateTaken of rowData[col.field]  ; let i = index ">
                  <p class="dateTakenp" *ngIf=" !(dateTaken > minDAte) ">No date</p>
                  <p class="dateTakenp" *ngIf="dateTaken > minDAte">{{dateTaken | date:'dd/MM/y'}} </p>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'notesDate'">
                <div *ngFor="let noteDate of rowData['noteDateFormatted']">
                  {{noteDate}}
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'notesContent'">
                {{rowData | homepageNotesToField:'notesContent'}}
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'noteCategory'">
                {{rowData | homepageNotesToField:'noteCategory'}}
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'notesUserName'">
                {{rowData | homepageNotesToField:'notesUserName'}}
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'notesWithDate'">
                {{rowData | homepageNotesToField:'notesWithDate'}}
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'cancellationDate'">
                  <p *ngIf=" !(rowData[col.field]  > minDAte) "> </p>
                  <p *ngIf="rowData[col.field]  > minDAte">{{rowData[col.field]  | date:'dd/MM/y'}} </p>
              </ng-container>
              <ng-container
                  *ngSwitchCase="['backgroundAutoTestCall', 'lastBoxActivation', 'pendantOneLastActivation', 'pendantTwoLastActivation', 'lastMainsFail', 'lastSmokeAlarmActivation', 'lastCODetectorActivation', 'lastHighUseDate', 'lastGpsActivation', 'lastRadioTestMissing', 'lastEpilepsyAlert'].includes(col.field)">
                <p>{{rowData[col.field] | date:'dd/MM/y'}} </p>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'renewalErrors'">
                <div *ngFor="let error of rowData[col.field]" class="errors">
                  {{error}}
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="col.field === 'discounts'" >
                <div *ngFor="let discount of rowData[col.field] ; let i = index" class="discount">
                  {{discount}}
                  <hr *ngIf="i < rowData[col.field]?.length - 1">
                </div>
              </ng-container>
              <div *ngSwitchCase="col.field === 'legalCompany'" [ngClass]="rowData[col.field] | legalCompanyToClass">
                {{rowData[col.field]}}
              </div>
              <ng-container *ngSwitchDefault>{{rowData[col.field]}}</ng-container>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <!--BODY-->
    <ng-template class="table-stats-holder" pTemplate="summary">
      <table class="table-stats">
        <tbody>
        <tr>
          <td><span>CRM TOTAL: {{data?.length}}</span></td>
          <td><span>TOTAL LIVE: <span>{{ dt.totalRecords }} </span></span></td>
          <td><span>TOTAL ROW SELECTED: {{selectedOrders?.length}}</span></td>
        </tr>
        </tbody>
      </table>
    </ng-template>
  </p-table>
  <div class="container-fluid px-0">
    <div class="row mx-0 table-page" style="background-color: #eaeaea;padding: 0;margin-bottom: 10px;">
      <div class="col-md-8" >
        <div id="load" *ngIf="paginationLoadingProg > 0">
              <div>G</div>
              <div>N</div>
              <div>I</div>
              <div>D</div>
              <div>A</div>
              <div>O</div>
              <div>L</div>
        </div>
      </div>
    </div>
  </div>
</div>
