<section  id="loginPage">
  <div class="container p-5">
    <div class="row mt-5">
      <div class="col-lg-6 col-xl-4 offset-lg-4">
        <app-message></app-message>
        <div class="card bg-light">
          <div class="card-body">
            <h4 class="text-center">Login</h4>
            <hr>
            <form>
              <div class="form-group">
                <label for='email'>Email</label>
                <input type="email" name="email" id="email" class="form-control" [(ngModel)]="email" autocomplete='email'>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" name="password" id='password' class="form-control" [(ngModel)]="password" autocomplete='current-password'>
              </div>
              <hr>
              <button type="button" class="btn btn-primary btn-block" (click)="login()" [disabled]="btnDisabled">
                Login
              </button>
              <div class="version">CRM Client version: {{crmClientVersion}}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <p-confirmDialog key="general" [baseZIndex]=3000></p-confirmDialog>
    <p-dialog [(visible)]="show2fa" header="Enter Authentication Code" [style]="{width: '300px', height: '200px' , minWidth: '300px'}" [minY]="700"
        [maximizable]="true" [baseZIndex]=1000 (onHide)="closeDialog()">
      <ng-template pTemplate="content">
        <label>Authentication Code</label>
        <input type="text" name="authCode" class="form-control" [(ngModel)]="authCode" (keypress)="numberOnly($event, false)">
      </ng-template>
      <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="validate2fa()" label="OK"></button>
        <button type="button" pButton icon="pi pi-times" (click)="closeDialog()" label="Cancel"></button>
      </p-footer>
    </p-dialog>
  </div>
</section>
