<p-dialog (onHide)="closeDialog()" header="Send SMS" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '90vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <div id="smsDialogBody">
    <form [formGroup]="contactForm">
      <div class="row">
        <div *ngIf="resultMessage" class="col-12 form-group">
          <div class="alert" [ngClass]="resultSuccess? 'alert-success' : 'alert-danger'">
            {{resultMessage}}
          </div>
        </div>
        <div *ngIf="hasBlockAlarmComms" class="col-12 form-group">
          <div class="alert alert-warning">
            This order has a "Block Alarm Correspondence" tag, which might indicate the customer is currently away.
          </div>
        </div>
        <div *ngIf="isRenewalWithNoPrice" class="col-12 form-group">
          <div class="alert alert-danger">
            This order has no renewal price, you must set a renewal price before you can send a renewal message.
          </div>
        </div>
        <div class="col-12 form-group">
          This form is for sending SMS messages using one of the predefined templates, it cannot be used for general SMS communications.
        </div>
        <div class="col-6 form-group">
          <ng-container *ngIf="smsTypes.length == 1">
            <label for="smsType">SMS Type:</label>
            <input type="text" class="form-control" name="smsType" id="smsType" formControlName="smsTypeToSend" readonly/>
          </ng-container>
          <ng-container *ngIf="smsTypes.length > 1">
            <label for="smsType">SMS Type:</label>
            <p-dropdown [options]="smsTypes" name="smsType" id="smsType" formControlName="smsTypeToSend" 
              placeholder="Select an SMS type to send" [required]="true" (onChange)="selectedTypeChange()"
              appendTo="body" [baseZIndex]="3200" [filter]="true">
            </p-dropdown>
          </ng-container>
        </div>
        <div class="col-6 form-group">
          <label for="contactDetails">Select contact to send SMS to, or enter details manually below</label>
          <p-dropdown [options]="contactDetailsToPickFrom" name="contactDetails" id="contactDetails" 
              [(ngModel)]="selectedContactDetails" [showClear]="true" appendTo="body" [baseZIndex]="3200"
              (onChange)="selectedContactChange()" [ngModelOptions]="{standalone: true}" [filter]="true"
              placeholder="Optionally select an existing contact">
          </p-dropdown>
        </div>
        <div class="col-12 form-group">
          If you have selected an existing contact you can override some of their details below -
          this will not update the order and is just the details to use for this SMS.
        </div>
        <div class="col-6 form-group">
          <label for="firstName">First Name</label>
          <input type="text" class="form-control" name="firstName" id="firstName" formControlName="firstName" required/>
        </div>
        <div class="col-6 form-group">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" name="lastName" id="lastName" formControlName="lastName" required/>
        </div>
        <ng-container *ngIf="areRegardingFieldsAllowed">
          <div class="col-6 form-group">
            <label for="reFirstName">Regarding First Name</label>
            <input type="text" class="form-control" name="reFirstName" id="reFirstName" formControlName="reFirstName"
              [required]="areRegardingFieldsRequired || hasRegardingLastName" />
          </div>
          <div class="col-6 form-group">
            <label for="reLastName">Regarding Last Name</label>
            <input type="text" class="form-control" name="reLastName" id="reLastName" formControlName="reLastName"
              [required]="areRegardingFieldsRequired || hasRegardingFirstName" />
          </div>
        </ng-container>
        <div class="col-12 form-group">
          <label for="mobile">Mobile Number</label>
          <input type="mobile" class="form-control" name="mobile" id="mobile" formControlName="mobile" required/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group text-right">
          <button type="button" class="btn btn-primary" (click)="sendSms()" [disabled]="!isOkToSend()">Send</button>
          <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Close</button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>