import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BusinessGrowthCustomer} from '../models/businessGrowthCustomer';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessGrowthService {

  constructor(
    private http: HttpClient
  ) { }
  
  API_URL: string = environment.protocol + environment.IPAddress + '/api/business-growth';

  addBusinessGrowthCustomer(body: BusinessGrowthCustomer) {
    return this.http.post<SingleRecordResponse<BusinessGrowthCustomer>>(`${this.API_URL}`, body);
  }

  getBusinessGrowthCustomers() {
    return this.http.get<MultiRecordResponse<BusinessGrowthCustomer>>(this.API_URL);
  }

  getBusinessGrowthCustomer(bgcId: string) {
    return this.http.get<SingleRecordResponse<BusinessGrowthCustomer>>(`${this.API_URL}/${bgcId}`);
  }

  updateBusinessGrowthCustomer(bgcId: string, body: BusinessGrowthCustomer) {
    return this.http.patch<SingleRecordResponse<BusinessGrowthCustomer>>(`${this.API_URL}/${bgcId}`, body);
  }

  deleteBusinessGrowthCustomer(bgcId: string) {
    return this.http.delete<SimpleResponse>(`${this.API_URL}/${bgcId}`);
  }
}
