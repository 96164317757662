import {Injectable} from '@angular/core';
import {GenericSocketService} from './generic-socket.service';
import {environment} from '../../environments/environment';
import {CustomerFeedback} from '../models/customerFeedback.model';

interface CustFeedbackUpdServToCliEvs {
  'updateCustFeedback': (updatedCustFeedback: CustomerFeedback) => void;
  'deletedCustFeedback': (orderId: string, feedbackId: string) => void;
}

// Don't allow any client to server events
interface CustFeedbackUpdCliToServEvs {}

@Injectable()
export class CustomerFeedbackSocketService extends GenericSocketService<CustFeedbackUpdServToCliEvs, CustFeedbackUpdCliToServEvs> {

  constructor() {
    super({
      'url': environment.protocol + environment.IPAddress + '/customerFeedbackUpdates', 
      'options': {
        'closeOnBeforeunload': false,
        'transports': ['websocket'],
        'path': '/socket.io/updates/',
      }
    });
   }
}
