import {Component, OnInit} from '@angular/core';
import {MessageService, SelectItem} from 'primeng/api';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {PopulatedProduct} from '../../models/product.model';
import {ProductsService} from './products.service';
import {PopulatedVariation} from '../../models/variation.model';
import {vatStatuses} from '../../lookups/vatStatuses';
import {Hardware} from '../../models/hardware.model';
import {HardwareService} from '../hardware.service';
import {HardwareSet} from '../../models/hardwareSet.model';
import {sortByLabel} from '../../helpers/helperFunctions';
import {Title} from '@angular/platform-browser';
import {AccountService} from '../../models/accountService.model';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [MessageService]
})
export class ProductsComponent implements OnInit {
  processing: boolean = false;
  progressVal: number;
  progress: number;
  products: PopulatedProduct[] = [];
  display: boolean = false;
  brandConfigs: BrandCfg;
  brands: SelectItem<string>[];
  selectedBrand: string;
  vatStatuses: SelectItem<string>[] = vatStatuses;
  hardwareList: SelectItem<Hardware>[] = [];
  hardwareSetList: SelectItem<HardwareSet>[] = [];
  accountServicesList: SelectItem<string>[] = [];
  selectedStatus: string;
  statuses: SelectItem<string>[] = [{
    'label': 'Not Configured',
    'value': 'not configured'
  }, {
    'label': 'Configured',
    'value': 'configured'
  }, {
    'label': 'Archived',
    'value': 'archived'
  }];
  selectedStatuses: string[] = [];

  constructor(
    private productsService: ProductsService,
    private messageService: MessageService,
    private hardwareService: HardwareService,
    private title: Title,
  ) {
  }

  allUpdate() {
    this.progress = 0;
    this.progressVal = 0;
    this.display = true;
    this.products.map((product: PopulatedProduct) => {
      this.saveProd(product._id, product);
      product.variations.map((variation: PopulatedVariation) => {
        this.saveVariation(variation._id, variation);
      });
    });
  };

  saveProd(id: string, product: PopulatedProduct) {
    this.productsService.updateProduct(id, {'product': product})
      .subscribe((response: SingleRecordResponse<PopulatedProduct>) => {
        this.progress += 1 / this.products.length;
        this.progressVal = Math.floor(this.progress * 100)+1;
        if (!response.success) {
          this.showError(`${response.message}: ${response.error?.message}`);
        }
      }, err => {
        this.showError(err.message);
      });
  }

  saveVariation(id: number, variation: PopulatedVariation) {
    this.productsService.updateVariation(id, {'variation': variation})
      .subscribe((response: SingleRecordResponse<PopulatedVariation>) => {
        if (!response.success) {
          this.showError(`${response.message}: ${response.error?.message}`);
        }
      }, err => {
        this.showError(err.message);
      });
  }

  ngOnInit() {
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
    this.progress = -1;
    this.selectedStatuses.push('not configured');
    this.selectedStatuses.push('configured');
    this.title.setTitle('CRM Products');
  }

  loadProducts() {
    this.display = false;
    this.progress = -1;
    if (!this.selectedBrand || !this.brandConfigs[this.selectedBrand]) {
      return;
    }
    const websiteId: string = this.brandConfigs[this.selectedBrand]._id;
    if (!websiteId) {
      return;
    }
    this.productsService
      .getAllProductsForSite(websiteId)
      .subscribe((response: MultiRecordResponse<PopulatedProduct>) => {
        if (!response.success) {
          this.showError(`Error getting products for brand. Error: ${response.message}`);
          return;
        }
        this.products = response.data;
        const temp: PopulatedProduct[] = [];
        // Make sure sub products come after their parent
        for (let prod of this.products) {
          if (prod.subProducts.length > 0) {
            temp.push(prod);
            for (let id of prod.subProducts) {
              let p: PopulatedProduct = this.products.find((p: PopulatedProduct) => (id == p.productWebID));
              if ((p !== undefined) && (p !== null)) {
                p.isSub = true;
                temp.push(p);
              }
            }
          }
        }
        // Add any remaining products not already added
        for (let prod of this.products) {
          if (!temp.includes(prod)) {
            temp.push(prod);
          }
        }
        this.products = temp;
      }, err => {
        this.showError(`Error getting products for brand. Error: ${err.message}`);
      });
    this.hardwareService.getHardwareForSite(websiteId)
      .subscribe((response: MultiRecordResponse<Hardware>) => {
        if (response.success) {
          this.hardwareList = response.data.map((hardware: Hardware) => {
            return {
              'label': hardware.title,
              'value': hardware,
            }
          });
          sortByLabel(this.hardwareList);
        } else {
          this.showError(response.message || 'Something went wrong try again.');
        }
      }, (err: Error) => {
        console.log('ERROR while getting hardware: ', err);
        this.showError('Something went wrong try again.');
      });
    this.hardwareService.getHardwareSetsForSite(websiteId)
      .subscribe((response: MultiRecordResponse<HardwareSet>) => {
        if (response.success) {
          this.hardwareSetList = response.data.map((hardwareSet: HardwareSet) => {
            return {
              'label': hardwareSet.title,
              'value': hardwareSet,
            }
          });
          sortByLabel(this.hardwareSetList);
        } else {
          this.showError(response.message || 'Something went wrong try again.');
        }
      }, (err: Error) => {
        console.log('ERROR while getting hardware sets: ', err);
        this.showError('Something went wrong try again.');
      });
    this.hardwareService.getServicesForSite(websiteId)
      .subscribe((response: MultiRecordResponse<AccountService>) => {
        if (response.success) {
          this.accountServicesList = response.data.map((accountService: AccountService) => {
            return {
              'label': accountService.title,
              'value': accountService._id,
            }
          });
          sortByLabel(this.accountServicesList);
        } else {
          this.showError(response.message || 'Something went wrong try again.');
        }
      }, (err: Error) => {
        console.log('ERROR while getting account services: ', err);
        this.showError('Something went wrong try again.');
      });
  }

  showError(message: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Something went wrong!',
      detail: message
    });
  }

  showSuccess(message: string) {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: message,
    });
  }

  deleteHardwareSet(item: PopulatedProduct|PopulatedVariation, index: number): void {
    item.hardwareSets.splice(index, 1);
  }

  addHardwareSet(item: PopulatedProduct|PopulatedVariation): void {
    if (!item.newHardwareSet) {
      return;
    }
    item.hardwareSets.push({
      '_id': item.newHardwareSet._id,
      'title': item.newHardwareSet.title,
    });
    delete item.newHardware;
  }

  deleteHardware(item: PopulatedProduct|PopulatedVariation, index: number): void {
    item.hardware.splice(index, 1);
  }

  addHardware(item: PopulatedProduct|PopulatedVariation): void {
    if (!item.newHardware) {
      return;
    }
    item.hardware.push({
      '_id': item.newHardware._id,
      'title': item.newHardware.title,
    });
    delete item.newHardware;
  }

  setCategory(product: PopulatedProduct) {
    if (product.category == 'keySafe') {
      product.vat = 'not exempt';
      product.renewalPrice = 0;
      product.planOrEquipment = '';
      product.renewalAfterDate = 0;
      product.variations.forEach((variation: PopulatedVariation) => {
        variation.vat = 'not exempt';
        variation.renewalPrice = 0;
        variation.symbol = '';
        variation.renewalAfterDate = 0;
        variation.hardware = [];
        variation.hardwareSets = [];
      });
      product.hardware = [];
      product.hardwareSets = [];
    }
    if (product.category == 'services') {
      product.hardware = [];
      product.hardwareSets = [];
      product.variations.forEach((variation: PopulatedVariation) => {
        variation.hardware = [];
        variation.hardwareSets = [];
      });
    } else {
      product.serviceId = null;
    }
  }
}
