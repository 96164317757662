import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {SelectItem} from 'primeng/api';
import {getBase64EncodedFileContents} from '../../helpers/helperFunctions';
import {getManualLookups} from '../../lookups/equipmentSerial';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {EquipmentManualsService} from './equipment-manuals.service';

@Component({
  selector: 'app-equipment-manuals',
  templateUrl: './equipment-manuals.component.html',
  styleUrls: ['./equipment-manuals.component.scss']
})
export class EquipmentManualsComponent implements OnInit {
  manualLookups: SelectItem<string>[];
  manualUploadForm: FormGroup;
  uploadDisabled: boolean;
  resultMessage: string;
  resultSuccess: boolean;
  manualFile: File;

  constructor(
    private fb: FormBuilder,
    private title: Title,
    private equipmentManualsService: EquipmentManualsService,
  ) { }

  ngOnInit(): void {
    this.resultMessage = '';
    this.title.setTitle('CRM Manual Upload');
    this.manualLookups = getManualLookups();
    this.manualUploadForm = this.fb.group({
      'manualFileName': ['', Validators.required],
      'replacementFile': [null, Validators.required],
    });
    this.uploadDisabled = false;
  }

  isOkToUpload(): boolean {
    return this.manualUploadForm.valid && !this.uploadDisabled;
  }

  onFileChange(event: Event) {
    this.manualFile = (event.target as HTMLInputElement).files[0];
    // so form validator picks up whether file selected
    this.manualUploadForm.get('replacementFile').setValue(this.manualFile? this.manualFile.name: '');
  }

  async uploadManualFile(): Promise<void> {
    this.resultMessage = '';
    this.uploadDisabled = true;
    const fileContents: string = await getBase64EncodedFileContents(this.manualFile);
    this.equipmentManualsService.updateEquipmentManual(this.manualUploadForm.get('manualFileName').value, fileContents)
      .subscribe((response: SimpleResponse) => {
        this.uploadDisabled = false;
        if (response.success) {
          this.resultMessage = 'Replacement manual successfully uploaded';
          this.resultSuccess = true;
        } else {
          this.resultMessage = response.message;
          this.resultSuccess = false;
        }
      }, (err: Error) => {
        this.resultMessage = `Error replacing manual. Error: ${err.message}`;
        this.resultSuccess = false;
        this.uploadDisabled = false;
      });
  }

}
