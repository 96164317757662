import {Injectable} from '@angular/core';
import {GenericSocketService} from './generic-socket.service';
import {environment} from '../../environments/environment';
import {ProposedMessage} from '../models/proposedMessage.model';

interface MsgUpdServToCliEvs {
  'updateMessage': (updatedMessage: ProposedMessage) => void;
}

// Don't allow any client to server events
interface MsgUpdCliToServEvs {}

@Injectable()
export class MessageUpdatesSocketService extends GenericSocketService<MsgUpdServToCliEvs, MsgUpdCliToServEvs> {
  constructor() {
    super({
      'url': environment.protocol + environment.IPAddress + '/messageUpdates', 
      'options': {
        'closeOnBeforeunload': false,
        'transports': ['websocket'],
        'path': '/socket.io/updates/',
      }
    });
  }
}