import {SelectItem} from 'primeng/api';

let priceChangeReasons: SelectItem<string>[];

function getPriceChangeReasons(): SelectItem<string>[] {
  if (!priceChangeReasons) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('price change: change reasons')) {
      return [];
    }
    priceChangeReasons = JSON.parse(localStorage.getItem('price change: change reasons')) || [];
  }
  return priceChangeReasons;
}

let complaintReasons: SelectItem<string>[];

function getPriceChangeComplaintReasons(): SelectItem<string>[] {
  if (!complaintReasons) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('price change: complaint reasons')) {
      return [];
    }
    complaintReasons = JSON.parse(localStorage.getItem('price change: complaint reasons')) || [];
  }
  return complaintReasons;
}

let discountOptions: SelectItem<string>[];

function getPriceChangeDiscountOptions(): SelectItem<string>[] {
  if (!discountOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('price change: discount options')) {
      return [];
    }
    discountOptions = JSON.parse(localStorage.getItem('price change: discount options')) || [];
  }
  return discountOptions;
}

export {
  getPriceChangeReasons,
  getPriceChangeComplaintReasons,
  getPriceChangeDiscountOptions,
}