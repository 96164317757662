import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FriendsAndFamilyContentService} from '../friends-and-family-content.service';
import {SbrApplicationContent} from '../../../models/responses/sbr/SbrApplicationContent.model';
import {phoneNumberValidator} from '../../../validators/phone-number.validator';
import {SingleRecordResponse} from '../../../models/responses/singleRecordResponse.model';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {contentTypes} from '../../../lookups/sbrTypes';
import {numberOnly} from '../../../helpers/keyboardHelpers';

@Component({
  selector: 'app-maintain-f-and-f-content[closeMaintainContent][fAndFContent][fAndFCategories]',
  templateUrl: './maintain-f-and-f-content.component.html',
  styleUrls: ['./maintain-f-and-f-content.component.scss'],
  providers: [ConfirmationService]
})
export class MaintainFAndFContentComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private contentService: FriendsAndFamilyContentService,
    private confirmationService: ConfirmationService,
  ) { }

  @Output() closeMaintainContent = new EventEmitter<SbrApplicationContent>();
  @Input() fAndFContent: SbrApplicationContent = null;
  @Input() fAndFCategories: SelectItem<string>[] = [];
  numberOnly = numberOnly;
  dialogVisible: boolean = true;
  saveDisabled: boolean = false;
  contentForm: FormGroup;
  contentTypeSelects: SelectItem<number>[] = contentTypes;

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.contentForm = this.formBuilder.group({
      'sbr_applicationcontentid': [this.fAndFContent?.sbr_applicationcontentid],
      'sbr_name': [this.fAndFContent?.sbr_name, [Validators.required]],
      'sbr_title': [this.fAndFContent?.sbr_title, [Validators.required]],
      'sbr_phonenumber': [this.fAndFContent?.sbr_phonenumber, [Validators.required, phoneNumberValidator]],
      'sbr_description': [this.fAndFContent?.sbr_description, [Validators.required]],
      'sbr_contenttype': [this.fAndFContent?.sbr_contenttype, [Validators.required]],
      'sbr_targeturl': [this.fAndFContent?.sbr_targeturl, [Validators.required]],
      'sbr_imageurl': [this.fAndFContent?.sbr_imageurl, [Validators.required]],
      'sbr_sortorder': [this.fAndFContent?.sbr_sortorder, [Validators.required]],
      '_sbr_applicationcategoryid_value': [this.fAndFContent?._sbr_applicationcategoryid_value, [Validators.required]],
    });
    this.changeContentType(this.fAndFContent?.sbr_contenttype);
  }

  changeContentType(newType: number) {
    switch (newType) {
      case 858810000: // Ad
        this.contentForm.get('sbr_phonenumber').enable();
        this.contentForm.get('sbr_targeturl').disable();
        break;
      case 858810001: // Article
        this.contentForm.get('sbr_phonenumber').disable();
        this.contentForm.get('sbr_targeturl').enable();
        this.contentForm.get('sbr_description').setValue('Dummy text to stop app error.');
        break;
      default:
        this.contentForm.get('sbr_phonenumber').disable();
        this.contentForm.get('sbr_targeturl').disable();
        break;
    }
  }

  saveContent() {
    const content: SbrApplicationContent = this.contentForm.value;
    this.saveDisabled = true;
    if (content.sbr_applicationcontentid) {
      this.updateContent(content);
    } else {
      delete content.sbr_applicationcontentid;
      this.addContent(content);
    }
  }

  addContent(content: SbrApplicationContent) {
    this.contentService.addContent(content).subscribe((updateResponse: SingleRecordResponse<SbrApplicationContent>) => {
      if (updateResponse.success) {
        this.dialogVisible = false;
        this.closeMaintainContent.emit(updateResponse.data);
      } else if (!updateResponse.success) {
        this.showErrorPopUp('Error Adding Content', 'There was an error trying to add the content, please try again.');
        this.saveDisabled = false;
      }
    }, (err: Error) => {
      this.showErrorPopUp('Error Adding Content', `There was an error trying to add the content, please try again. Error: ${err.message}`);
      this.saveDisabled = false;
    });
  }

  updateContent(content: SbrApplicationContent) {
    this.contentService.updateContent(content).subscribe((updateResponse: SingleRecordResponse<SbrApplicationContent>) => {
      if (updateResponse.success) {
        this.dialogVisible = false;
        this.closeMaintainContent.emit(updateResponse.data);
      } else if (!updateResponse.success) {
        this.showErrorPopUp('Error Updating Content', 'There was an error trying to update the content, please try again.');
        this.saveDisabled = false;
      }
    }, (err: Error) => {
      this.showErrorPopUp('Error Updating Content', `There was an error trying to update the content, please try again. Error: ${err.message}`);
      this.saveDisabled = false;
    });
  }

  get isOkToSave(): boolean {
    return this.contentForm.valid && !this.saveDisabled;
  }

  closeDialog() {
    this.closeMaintainContent.emit();
    this.dialogVisible = false;
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
