import {Component, OnInit} from '@angular/core';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import {FeedbacksService} from './feedbacks.service';
import {SelectItem} from 'primeng/api';
import {Feedback} from './feedback.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  submitted: boolean;
  categories: SelectItem<string>[];
  feedbacks: Feedback[];
  userName: string;
  constructor(
    private fb: FormBuilder,
    private feedbacksService: FeedbacksService,
    private title: Title,
  ) { }

  ngOnInit() {
    this.feedbacksService.getFeedbacks()
      .subscribe((rsp: MultiRecordResponse<Feedback>) => {
        if (rsp.success && !!rsp.data) {
          this.feedbacks = rsp.data;
          this.feedbacks.sort((a, b) => {
            return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
          });
        }
      }, _err => {
      });

    this.userName = localStorage.getItem('userName');

    this.feedbackForm = this.fb.group({
      'category': new FormControl('', Validators.required),
      'title': new FormControl('', Validators.required),
      'body': new FormControl('', Validators.required)
    });

    this.categories = [];
    this.categories.push({label:'Select Bug Category', value:''});
    this.categories.push({label:'Home Page Bug', value:'homepage'});
    this.categories.push({label:'Order Page Bug', value:'orderpage'});
    this.categories.push({label:'Others', value:'others'});

    this.title.setTitle('CRM Feedback');
  }

  onSubmit(){
    this.submitted = true;
    const feedback = this.feedbackForm.value;
    feedback.createdBy = this.userName;
    feedback.createdAt = new Date();
    this.feedbacksService.createFeedback({feedback:feedback})
      .subscribe((_rsp: SingleRecordResponse<Feedback>) => {
        this.ngOnInit();
      }, _err => {
      });
   }


  addrep(feedback: Feedback){
      feedback.responses.push({
      body: feedback.newRep,
      createdBy: this.userName,
      createdAt: new Date()
    } );

    //console.log(feedback);
    this.feedbacksService.updateFeedback(feedback._id ,{feedback:feedback})
      .subscribe((_rsp: SimpleResponse) => {
        this.ngOnInit();
      }, _err => {
      });
  }

  checkedFeedback(feedback: Feedback){
    //console.log(feedback);
    this.feedbacksService.updateFeedback(feedback._id ,{feedback:feedback})
      .subscribe((_rsp: SimpleResponse) => {
        this.ngOnInit();
      }, _err => {
      });
  }
}
