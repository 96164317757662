import {BusinessGrowthService} from './business-growth.service';
import {BusinessGrowthCustomer} from '../models/businessGrowthCustomer';
import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Title} from '@angular/platform-browser';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {Column} from '../models/column.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';

@Component({
  selector: 'app-business-growth-customers',
  templateUrl: './business-growth-customers.component.html',
  styleUrls: ['./business-growth-customers.component.scss'],
  providers: [MessageService, ConfirmationService]
})

export class BusinessGrowthCustomersComponent implements OnInit {

  constructor(
    private messageService: MessageService,
    private businessGrowthService: BusinessGrowthService,
    private title: Title,
    private confirmationService: ConfirmationService,
  ) { }

  bgCustomerOpenMode: string;
  businessGrowthCustomers: BusinessGrowthCustomer[] = [];
  columns: Column[] = [];
  businessGrowthCustomer: BusinessGrowthCustomer;

  ngOnInit(): void {
    this.title.setTitle('CRM Business Growth');
    this.getBGCustomers();
    this.initializeVariables();
  }

  getBGCustomers() {
    this.businessGrowthService.getBusinessGrowthCustomers().subscribe((response: MultiRecordResponse<BusinessGrowthCustomer>) => {
      this.businessGrowthCustomers = response.data;
      // console.log("Response on getBGCustomers :: ", this.businessGrowthCustomers);
    }, (err: Error) => {
      this.showErrorPopUp('Error getting customers', `Error getting customers. Error: ${err.message}`);
    });
  }

  initializeVariables(){
    this.columns = [
      { field: 'bgcName', header: 'Name' },
      { field: 'bgcIdentifyingTag', header: 'Tag' },
      { field: 'addressOne', header: 'Address One' },
      { field: 'addressTwo', header: 'Address Two' },
      { field: 'city', header: 'City' },
      { field: 'county', header: 'County' },
      { field: 'postcode', header: 'Postcode' },
      { field: 'actions', header: 'Actions' }
    ];
  }

  closeBGCustomer(event) {
    if (event == 'success') {
      this.getBGCustomers();
      this.showSuccess();
    } else if(event == 'error'){
      this.showErrorPopUp('Error updating customers', 'Error updating customer.');
    }
    this.bgCustomerOpenMode = null;
    this.businessGrowthCustomer = null;
  }

  openBGCustomer(openMode: string, businessGrowthCustomer: BusinessGrowthCustomer) {
    this.bgCustomerOpenMode = openMode;
    this.businessGrowthCustomer = businessGrowthCustomer;
  }

  confirmDeleteBGCustomer(bgCustomerToDelete: BusinessGrowthCustomer) {
    this.showConfirmationPopup('Confirm Deletion', 
      `You are sure you wish to delete the Customer ${bgCustomerToDelete.bgcName}?`,
      () => {
        this.businessGrowthService.deleteBusinessGrowthCustomer(bgCustomerToDelete._id).subscribe((response: SimpleResponse) => {
          if (response.success) {
            this.businessGrowthCustomers = this.businessGrowthCustomers.filter((businessGrowthCustomer: BusinessGrowthCustomer) => 
              businessGrowthCustomer._id != bgCustomerToDelete._id
            );
          } else {
            this.showErrorPopUp('Error deleting customer', `Error deleting the customer. ${response.message}`);
          }
        });
      }
    );
  }

  showErrorPopUp(header: string, message: string): void {
    this.showConfirmationPopup(header, message);
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success!',
      detail:'Operation success!'
    })
  }

  showConfirmationPopup(header: string, message: string, acceptCallback?: () => void, rejectCallback?: () => void,
      isRejectLabelVisible: boolean = true) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      key: 'general',
      header: header,
      message: message,
      rejectVisible: isRejectLabelVisible,
      accept: () => {
        acceptClicked = true;
        if (acceptCallback) {
          acceptCallback();
        }
      },
      reject: () => {
        if (rejectCallback && !acceptClicked) {
          rejectCallback();
        }
      }
    });
  }
}
