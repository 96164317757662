import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {NoteTemplate, getNoteTemplates} from '../../lookups/noteTemplate';
import {NoteCategory, getNoteCategories} from '../../lookups/noteCategory';
import {AddNoteParams} from '../../models/addNoteParams.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';

@Component({
  selector: 'app-add-note[noteContent]',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {
  @Input() noteContent: string;
  @Output() closeModal: EventEmitter<AddNoteParams> = new EventEmitter<AddNoteParams>();
  dialogVisible: boolean;
  noteForm: FormGroup;
  saveDisabled: boolean;
  defaultNoteCategories: string[] = [];
  noteTemplates: NoteTemplate[];
  categoriesToAddNote: NoteCategory[];

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.categoriesToAddNote = getNoteCategories(true);
    this.noteTemplates = getNoteTemplates();
    this.defaultNoteCategories = JSON.parse(localStorage.getItem('defaultNoteCategories'));
    this.noteForm = this.formBuilder.group({
      'content': [this.noteContent, Validators.required],
      'categories': [this.defaultNoteCategories, Validators.required],
    });
    this.saveDisabled = false;
    this.dialogVisible = true;
  }

  isOkToSave(): boolean {
    return this.noteForm.valid && !this.saveDisabled;
  }

  save(): void {
    this.closeModal.emit(this.noteForm.value);
  }

  closeDialog(): void {
    this.closeModal.emit();
  }

  changeNoteTemplate(event: DropDownChangeEvent<NoteTemplate>) {
    if (event.value) {
      this.noteForm.patchValue({
        content: `${this.noteContent}\n${event.value.value}`,
        categories: event.value.noteCategories,
      });
    }
  }
}
