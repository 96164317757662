import {SelectItem} from 'primeng/api';

let businessGrowthAutomatedMessageOptions: SelectItem<string>[];

function getBusinessGrowthAutomatedMessageOptions(): SelectItem<string>[] {
  if (!businessGrowthAutomatedMessageOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('Business Growth: Automated Messages')) {
      return [];
    }
    businessGrowthAutomatedMessageOptions = JSON.parse(localStorage.getItem('Business Growth: Automated Messages')) || [];
  }
  return businessGrowthAutomatedMessageOptions;
}

export {
  getBusinessGrowthAutomatedMessageOptions,
}