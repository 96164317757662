import {SelectItem} from 'primeng/api';

interface StatusCfg {
  [status: string]: {background: string, color: string}
}

const statusConfigs: StatusCfg = {
  'active': {background: "#70cd38", color: "#000000"},
  'no ncf': {background: "#e9c80d", color: "#000000"},
  'cancelling': {background: "#e0291f", color: "#000000"},
  'cancelled': { background: "#58585a", color: "#FFFFFF" },
  // 'One Off Purchase': { background: '#CC9933', color: '#000000' }
};

// This is for primeng dropdowns
const statusSelectItems: SelectItem<string>[] = [];

const statuses: string[] = Object.keys(statusConfigs);
statuses.forEach((status: string) => {
  statusSelectItems.push({label: status, value: status});
});

function getStatusColour(status: string, defaultColour: string = '#000000'): string {
  const config = statusConfigs[status];
  if (config) {
    return config.color;
  }
  return defaultColour;
}

function getStatusBackgroundColour(status: string, defaultColour: string = '#FFFFFF'): string {
  const config = statusConfigs[status];
  if (config) {
    return config.background;
  }
  return defaultColour;
}

interface EquipStatusCfg {
  [equipStatus: string]: {
    'active': boolean;
    'includeInRenewal': boolean;
    'validInSet': boolean;
    'displaySection'?: string;
  };
}

const hardwareStatusConfig: EquipStatusCfg = {
  '': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'active'},
  'Pending': {'active': true, 'includeInRenewal': false, 'validInSet': true, 'displaySection': 'active'},
  'Live': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'active'},
  'Returning - Chargeable': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'transition'},
  'Returning - Non Chargeable': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition'},
  'Returning - ALB': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition'},
  'Returning - Chargeable - Faulty': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'transition'},
  'Returning - Non- Chargeable - Faulty': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition'},
  'Lost - Chasing': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'transition'},
  'Lost - Paid': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive'},
  'Missing': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive'},
  'Returned - Faulty': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive'},
  'Returned - Not Faulty': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive'},
  'Missing - Never Sent': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive'},
  'Damaged - Chargeable': {'active': true, 'includeInRenewal': true, 'validInSet': false, 'displaySection': 'transition'},
  'Damaged - Recommending Write Off': {'active': true, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'transition'},
  'Recommending Write Off': {'active': true, 'includeInRenewal': true, 'validInSet': true, 'displaySection': 'transition'},
  'Written Off': {'active': false, 'includeInRenewal': false, 'validInSet': false, 'displaySection': 'inactive'},
};

const keySafeStatusConfig: EquipStatusCfg = {
  'Bought': {'active': true, 'includeInRenewal': false, 'validInSet': false},
  'Returning - Faulty': {'active': true, 'includeInRenewal': false, 'validInSet': false},
  'Returning - Non-Faulty': {'active': true, 'includeInRenewal': false, 'validInSet': false},
  'Returned - Faulty': {'active': false, 'includeInRenewal': false, 'validInSet': false},
  'Returned': {'active': false, 'includeInRenewal': false, 'validInSet': false},
  'Missing': {'active': false, 'includeInRenewal': false, 'validInSet': false},
}

const equipmentStatuses: string[] = Object.keys(hardwareStatusConfig).filter((value: string) =>
  !!value
);

const keySafeStatuses: string[] = Object.keys(keySafeStatusConfig).filter((value: string) =>
  !!value
);

const serviceStatuses: string[] = [
  'Active',
  'Inactive'
];

// This is for the homepage filters
const equipStatusSelectItems: SelectItem<string>[] = [];

equipmentStatuses.forEach((status: string) => {
  equipStatusSelectItems.push({label: status, value: status});
});

export {
  StatusCfg,
  EquipStatusCfg,
  statuses,
  statusConfigs,
  statusSelectItems,
  hardwareStatusConfig,
  keySafeStatusConfig,
  equipmentStatuses,
  equipStatusSelectItems,
  keySafeStatuses,
  serviceStatuses,
  getStatusColour,
  getStatusBackgroundColour,
}
