import {DatedPrice} from '../models/datedPrice.model';

function datedPriceSort(priceA: DatedPrice, priceB: DatedPrice): number {
  // undefined fromDate should come first
  if (!priceA.fromDate) {
    return -1;
  }
  if (!priceB.fromDate) {
    return 1;
  }
  return priceA.fromDate.localeCompare(priceB.fromDate);
}

function doPricesOverlap(pricesToCheck: DatedPrice[]): boolean {
  let overlappingRange: boolean = false;
  for (let outerIndex: number = 0; outerIndex < pricesToCheck.length; outerIndex++) {
    /*
      Check each price with each other price - only need to check with prices after the one being checked
      as the later prices will have been checked against the earlier ones when they were checked
    */
    const outerPrice: DatedPrice = pricesToCheck[outerIndex];
    for (let innerIndex: number = outerIndex + 1; innerIndex < pricesToCheck.length; innerIndex++) {
      const innerPrice: DatedPrice = pricesToCheck[innerIndex];
      if (!outerPrice.fromDate) {
        if (!innerPrice.fromDate) {
          // Outer price and inner price are open on the from date, so must overlap
          overlappingRange = true;
        } else if (innerPrice.fromDate <= outerPrice.toDate) {
          overlappingRange = true;
        }
      } else if (!outerPrice.toDate) {
        if (!innerPrice.toDate) {
          // Outer price and inner price are open on the to date, so must overlap
          overlappingRange = true;
        } else if (innerPrice.toDate >= outerPrice.fromDate) {
          overlappingRange = true;
        }
      } else {
        // Both start and end date specified on the outer price
        if ((!!innerPrice.fromDate) && (innerPrice.fromDate >= outerPrice.fromDate) && (innerPrice.fromDate <= outerPrice.toDate) ||
            (!!innerPrice.toDate) && (innerPrice.toDate >= outerPrice.fromDate) && (innerPrice.toDate <= outerPrice.toDate)) {
          // And one side or other of the inner price's date range is within the outer range
          overlappingRange = true;
        } else if ((!!innerPrice.fromDate && (innerPrice.fromDate <= outerPrice.fromDate) && (!innerPrice.toDate || (innerPrice.toDate >= outerPrice.toDate))) ||
            (!!innerPrice.toDate && (innerPrice.toDate >= outerPrice.toDate) && !innerPrice.fromDate)) {
          // The outer range fits entirely within an existing range
          overlappingRange = true;
        }
      }
    }
  }
  return overlappingRange;
}

export {
  datedPriceSort,
  doPricesOverlap,
}