<div class="bgc-detail">
  <p-dialog header="Business Growth Customer Detail" (onHide)="closeDialog()" [(visible)]="displayModal" [modal]="true"
      [style]="{width: '90vw'}" [draggable]="false" [resizable]="false" [maximizable]="true">
    <form [formGroup]="bgcForm" #form="ngForm">
      <ng-template #bgcFormRef>
        <div class="col-12" *ngIf="duplicateError">
          <div class="alert alert-danger">
            {{duplicateError}}
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12">
          <div class="formsect mt-0">
            <div class="form-row">
              <div class="form-group col-9">
                <div class="form-row">
                  <div class="form-group col-4">
                    <label>Name</label>
                    <input type="text" name="bgcName" class="form-control" formControlName="bgcName" placeholder="Enter Name" required>
                  </div>
                  <div class="form-group col-4">
                    <label>Identifying Tag (and added if coupon code used)</label>
                    <p-dropdown [options]="tags" name="bgcIdentifyingTag" formControlName="bgcIdentifyingTag" [showClear]="true"
                        placeholder="Tag used to identify orders" [readonly]="openMode == 'view'" [filter]=true [resetFilterOnHide]="true"
                        [required]="tagRequired">
                      <ng-template let-selectedItem pTemplate="selectedItem">
                        <div [ngStyle]="{'background-color': selectedItem.value.color}" class="p-1">
                          {{selectedItem.label}}
                        </div>
                      </ng-template>
                      <ng-template let-tag pTemplate="item">
                        <div [ngStyle]="{'background-color': tag.value.color}" class="p-1">
                          {{tag.label}}
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="form-group col-4">
                    <label>Additional Tags (added if coupon code used)</label>
                    <p-multiSelect [options]="tags" name="bgcAdditionalTags" formControlName="bgcAdditionalTags"
                        placeholder="Additional Tags" [readonly]="openMode == 'view'" [filter]=true [resetFilterOnHide]="true">
                      <ng-template let-tag pTemplate="item">
                        <div [ngStyle]="{'background-color': tag.value.color}" class="p-1">
                          {{tag.label}}
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </div>
                  <div class="form-group col-4">
                    <label>Coupon Code</label>
                    <input type="text" name="bgcCouponCode" class="form-control" (input)="onCouponCodeChange()"
                          formControlName="bgcCouponCode" placeholder="Enter coupon code">
                  </div>
                  <div class="col-8">
                    <label>Automated Messages/Processes</label>
                    <p-multiSelect [options]="businessGrowthAutomatedMessageOptions" placeholder="Blocked Messages"
                        [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" [maxSelectedLabels]=4
                        selectedItemsLabel="{0} selected" [readonly]="openMode == 'view'"
                        formControlName="allowedAutomatedMesssages">
                    </p-multiSelect>
                  </div>
                </div>
              </div>
              <div class="form-group col-3">
                <label>Additional Services</label>
                <textarea name="additionalServices" class="form-control" rows="4" formControlName="additionalServices"
                    placeholder="eg. welfare calls">
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12" formArrayName="bgcContacts">
          <div class="formsect">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="formbar formbarbox">
                  Contacts
                  <div class="check-container">
                    <button type="button" name="pages" (click)="addContactsToForm({})" class="btn btn-primary" *ngIf="openMode != 'view'">
                      Add Contact
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12">
                <div class="contactbox" *ngFor="let contact of contactForms.controls; let contactIndex=index"
                      [formGroupName]="contactIndex">
                  <div class="formbar formbarbox">
                    Contact No. {{contactIndex+1}}
                    <div class="check-container">
                      <button type="button" *ngIf="(contactForms.controls.length > 1) && (openMode != 'view')"
                        name="deleteContact" class="btn btn-primary" (click)="deleteContact(contactIndex)">
                        Delete
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12 col-xl-3">
                      <div class="form-row">
                        <div class="col-6">
                          <label>First Name</label>
                          <input type="text" name="firstName" class="form-control" formControlName="firstName"
                            placeholder="Enter contact first name">
                        </div>
                        <div class="col-6">
                          <label>Last Name</label>
                          <input type="text" name="lastName" class="form-control" formControlName="lastName"
                            placeholder="Enter contact last name">
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 col-xl-9">
                      <div class="form-row">
                        <div class="form-group col-6 col-xl-6">
                          <label>Email</label>
                          <input type="text" name="email" class="form-control validated" formControlName="email"
                            placeholder="Enter contact email">
                        </div>
                        <div class="form-group col-6 col-xl-2">
                          <label>Role</label>
                          <input type="text" name="role" class="form-control" formControlName="role"
                            placeholder="Enter contact role">
                        </div>
                        <div class="form-group col-6 col-xl-2">
                          <label>Telephone</label>
                          <input type="text" (keypress)="numberOnly($event, false)" name="telephone"
                            class="form-control validated" formControlName="telephone"
                            placeholder="Enter contact telephone">
                        </div>
                        <div class="form-group col-6 col-xl-2">
                          <label>Mobile</label>
                          <input type="text" (keypress)="numberOnly($event, false)" name="mobile"
                            class="form-control validated" formControlName="mobile" placeholder="Enter contact mobile">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12" formArrayName="bgcAddresses">
          <div class="formsect">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="formbar formbarbox">
                  Address
                  <div class="check-container">
                    <button *ngIf="openMode != 'view'" type="button" name="pages" class="btn btn-primary"
                      (click)="addAddressesToForm(EMPTY_ADDRESS)">
                      Add Address
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12">
                <div class="addressbox" *ngFor="let address of addressForms.controls; let addressIndex=index"
                    [formGroupName]="addressIndex">
                  <div class="formbar formbarbox">
                    <div class="address">
                      Address No. {{addressIndex+1}}
                      <input type="text" autocomplete="off" [(ngModel)]="searchPostcodes[addressIndex]"
                        [ngModelOptions]="{standalone:true}" class="form-control ml-2" placeholder="Find Postcode">
                      <button type="button" (click)="addressSearch(addressIndex)" [disabled]="!searchPostcodes[addressIndex]"
                          class="btn btn-primary ml-2">
                        Find Postcode
                      </button>
                      <ng-container *ngIf="searchError[addressIndex]">
                        <span class="alert alert-danger ml-2">{{searchError[addressIndex]}}</span>
                      </ng-container>
                      <ng-container *ngIf="addressResults[addressIndex] && (addressResults[addressIndex].length > 0)">
                        <p-dropdown [options]="addressResults[addressIndex]" class="ml-2" [style]="{'width':'50%'}"
                          (onChange)="setAddress($event, addressIndex)" [appendTo]="'body'" >
                        </p-dropdown>
                      </ng-container>
                    </div>
                    <div class="check-container">
                      <button type="button" *ngIf="(addressForms.controls.length > 1) && (openMode != 'view')"
                        name="deleteAddress" class="btn btn-primary" (click)="deleteAddress(addressIndex)">
                        Delete
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12 col-xl-6">
                      <div class="form-row">
                        <div class="col-6">
                          <label>Address line 1</label>
                          <input type="text" name="addressOne" class="form-control validated" formControlName="addressOne"
                            placeholder="Enter address one" [readOnly]="!allowAddressManualEntry[addressIndex]">
                        </div>
                        <div class="col-6">
                          <label>Address line 2</label>
                          <input type="text" name="addressTwo" class="form-control" formControlName="addressTwo"
                            placeholder="Enter address two" [readOnly]="!allowAddressManualEntry[addressIndex]">
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-6 col-xl-2">
                      <label>City</label>
                      <input type="text" name="city" class="form-control validated" formControlName="city"
                        placeholder="Enter city" [readOnly]="!allowAddressManualEntry[addressIndex]">
                    </div>
                    <div class="form-group col-6 col-xl-2">
                      <label>County</label>
                      <input type="text" name="county" class="form-control" formControlName="county"
                        placeholder="Enter county" [readOnly]="!allowAddressManualEntry[addressIndex]">
                    </div>
                    <div class="form-group col-6 col-xl-1">
                      <label>Postcode</label>
                      <input type="text" name="postcode" class="form-control validated" formControlName="postcode"
                        placeholder="Enter postcode" [readOnly]="!allowAddressManualEntry[addressIndex]">
                    </div>
                    <div class="form-group col-6 col-xl-1">
                      <label>Role</label>
                      <input type="text" name="role" class="form-control" formControlName="role" placeholder="e.g Head office">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12" formArrayName="bgcEquipment">
          <div class="formsect">
            <div class="row no-gutters">
              <div class="col-12">
                <div class="formbar formbarbox">
                  Equipment
                  <div class="check-container">
                    <button type="button" name="pages" (click)="addEquipmentToForm({})" class="btn btn-primary" *ngIf="openMode != 'view'">
                      Add Equipment
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12">
                <div class="addressbox"
                  *ngFor="let equipment of equipmentForms.controls; let equipmentIndex=index" [formGroupName]="equipmentIndex">
                  <div class="formbar formbarbox">
                    Equipment No. {{equipmentIndex+1}}
                    <div class="check-container">
                      <button type="button" *ngIf="(equipmentForms.controls.length > 1) && (openMode != 'view')"
                          name="deleteEquipment" class="btn btn-primary" (click)="deleteEquipment(equipmentIndex)">
                        Delete
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12 col-xl-6">
                      <label>Equipment Name</label>
                      <input type="text" name="name" class="form-control" formControlName="equipmentName"
                        placeholder="Enter equipment name">
                    </div>
                    <div class="form-group col-12 col-xl-2">
                      <label>Quantity</label>
                      <input type="number" name="quantity" class="form-control" formControlName="quantity"
                        placeholder="Enter quantity" (keypress)="numberOnly($event, false)">
                    </div>

                    <div class="form-group col-6 col-xl-2">
                      <label>Price</label>
                      <input step="0.01" type="number" name="price" class="form-control" formControlName="price"
                        placeholder="Enter price" (keypress)="numberOnly($event, true)">
                    </div>
                    <div class="form-group col-6 col-xl-2">
                      <label>Total Price</label>
                      <input type="number" name="totalPrice" class="form-control" placeholder="Total"
                        value="{{equipment.value.quantity * equipment.value.price}}" readonly>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <div *ngIf="loading && openMode == 'edit'" class="loading-indicator d-flex align-items-center justify-content-center" style="height: 200px;">
        <p-progressSpinner *ngIf="loading"></p-progressSpinner>
      </div>
      <div *ngIf="openMode == 'add'" class="row">
        <ng-container *ngTemplateOutlet="bgcFormRef"></ng-container>
      </div>
      <fieldset *ngIf="openMode == 'view'" [disabled]="true">
        <div class="row">
          <ng-container *ngTemplateOutlet="bgcFormRef"></ng-container>
        </div>
      </fieldset>
      <div *ngIf="openMode == 'edit' && !loading" class="row">
        <ng-container *ngTemplateOutlet="bgcFormRef"></ng-container>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <button type="button" class="btn btn-warning" (click)="closeDialog()">
        Cancel
      </button>
      <button type="button" *ngIf="openMode != 'view'" class="btn btn-primary mr-2"
        [disabled]="bgcForm.invalid || disableSubmit" (click)="openMode == 'add'? addBusinessGrowthCustomer(): updateBusinessGrowthCustomer()">
        Submit
      </button>
    </ng-template>
  </p-dialog>
</div>