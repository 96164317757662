import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ReviewsImportRequest} from '../models/requests/reviewsImportRequest.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {ReviewImportMessage} from '../models/responses/reviewImportMessage.model';
import {Review} from '../models/review.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(
    private http: HttpClient
  ) { }

  private REVIEWS_URL: string = environment.protocol + environment.IPAddress + '/api/reviews';

  getReviewsForOrder(orderId: string): Observable<MultiRecordResponse<Review>> {
    return this.http.get<MultiRecordResponse<Review>>(`${this.REVIEWS_URL}/forOrder/${orderId}`);
  }

  importReviews(params: ReviewsImportRequest): Observable<MultiRecordResponse<ReviewImportMessage>> {
    return this.http.post<MultiRecordResponse<ReviewImportMessage>>(`${this.REVIEWS_URL}/import/review`, params);
  }
}
