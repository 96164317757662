import {SelectItem} from 'primeng/api';

// This is for primeng dropdowns/multiselects
const hardwareCategories: SelectItem<string>[] = [
  { 'label': 'Base Unit', 'value': 'Base Unit' },
  { 'label': 'GPS Alarm', 'value': 'GPS Alarm' },
  { 'label': 'Pendant/Accessory', 'value': 'Pendant/Accessory' },
  { 'label': 'Monitoring Only', 'value': 'Monitoring Only' },
];

// This is for primeng dropdowns/multiselects
const hardwareSetCategories: SelectItem<string>[] = [
  { 'label': 'Alarm', 'value': 'Alarm' },
  { 'label': 'Other', 'value': 'Other' },
];

export {
  hardwareCategories,
  hardwareSetCategories,
}