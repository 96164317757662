import {SelectItem} from 'primeng/api';

let howHeardSelectItems: SelectItem<string>[];
function getHowHeardOptions(): SelectItem<string>[] {
  if (!howHeardSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('CSE Order: How Heard')) {
      return [];
    }
    howHeardSelectItems = JSON.parse(localStorage.getItem('CSE Order: How Heard')) || [];
  }
  return howHeardSelectItems;
}

export {
  getHowHeardOptions,
}