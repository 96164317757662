import {Address, UserWithAddress} from '../models/address.model';
import {AlarmUserDetails} from '../models/order.model';

function getUserAddress(userDetail: UserWithAddress): string {
  let content: string = '';
  if (userDetail.firstName) {
    content = content + userDetail.firstName;
  }
  if (!userDetail.lastName) {
    content = content + '\n';
  } else {
    content = content + ' ' + userDetail.lastName + '\n';
  }
  if (userDetail.userAddress) {
    if (userDetail.userAddress.addressOne) {
      content = content + userDetail.userAddress.addressOne + '\n';
    } else {
      content = content + '\n';
    }
    if (userDetail.userAddress.addressTwo) {
      content = content + userDetail.userAddress.addressTwo + '\n';
    } else {
      content = content + '\n';
    }
    if (userDetail.userAddress.city) {
      content = content + userDetail.userAddress.city + '\n';
    } else {
      content = content + '\n';
    }
    if (userDetail.userAddress.county) {
      content = content + userDetail.userAddress.county + '\n';
    } else {
      content = content + '\n';
    }
    if (userDetail.userAddress.postcode) {
      content = content + userDetail.userAddress.postcode;
    }
  }
  return content;
}

function getCorrespondenceAsUserWithAddress(userDetail: AlarmUserDetails): UserWithAddress {
  return {
    'firstName': userDetail.correspondenceDetails.firstName,
    'lastName': userDetail.correspondenceDetails.lastName,
    'userAddress': {
      'addressOne': userDetail.correspondenceDetails.addressOne,
      'addressTwo': userDetail.correspondenceDetails.addressTwo,
      'city': userDetail.correspondenceDetails.city,
      'county': userDetail.correspondenceDetails.county,
      'postcode': userDetail.correspondenceDetails.postcode,
      'validated': userDetail.correspondenceDetails.validated,  
      'role': 'Correspondence',
    }
  };
}

function getBestAddress(userDetail: AlarmUserDetails): UserWithAddress {
  if (userDetail.correspondenceDetails && userDetail.correspondenceDetails.addressOne && 
      userDetail.correspondenceDetails.postcode &&
      (userDetail.correspondenceDetails.firstName || userDetail.correspondenceDetails.lastName)) {
    return getCorrespondenceAsUserWithAddress(userDetail);
  } else {
    return userDetail;
  }
}

function getFormattedAddress(address: Address): string {
  let content: string = '';
  if (address.addressOne) {
    content = content + address.addressOne + '\n';
  }
  if (address.addressTwo) {
    content = content + address.addressTwo + '\n';
  }
  if (address.city) {
    content = content + address.city + '\n';
  }
  if (address.county) {
    content = content + address.county + '\n';
  }
  if (address.postcode) {
    content = content + address.postcode;
  }
  return content
}

export {
  Address,
  getUserAddress,
  getCorrespondenceAsUserWithAddress,
  getBestAddress,
  getFormattedAddress,
}