import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {getUserAddress} from '../../helpers/getUserAddress';
import {Order} from '../../models/order.model';
@Component({
  selector: 'app-replacement-item[order][closePopup]',
  templateUrl: './replacement-item.component.html',
  styleUrls: ['./replacement-item.component.scss']
})
export class ReplacementItemComponent implements OnInit {

  constructor() { }
  @Output() closePopup: EventEmitter<void> = new EventEmitter<void>();
  @Input() order: Order;
  display: boolean = true;
  userInformation: string = '';
  ngOnInit(): void {
    this.userInformation = getUserAddress(this.order.alarmUserDetails);
  }

  closeDialog() {
    this.closePopup.emit();
  }
}
