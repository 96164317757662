<p-dialog (onHide)="closeDialog()" header="Maintain Content" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '90vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    [styleClass]="'maintainContentDialog'">
  <form [formGroup]="contentForm">
    <div class="row">
      <div class="col-12">
        <p>
          The category name should reflect what the customer the ad/blog is targetted at has or does not have,
          not the type of ad/blog e.g. Digital means the customer has a digital alarm,
          not that the ads/blogs in that category are for/about digital alarms.
        </p>
      </div>
      <div class="col-6 col-lg-4 form-group">
        <label for="contentName">Content Name (internal use only):</label>
        <input type="text" class="form-control" name="sbr_name" id="contentName" formControlName="sbr_name" required="true"/>
      </div>
      <div class="col-6 col-lg-2 form-group">
        <label for="contentType">Content Type:</label>
        <p-dropdown [options]="contentTypeSelects" name="sbr_contenttype" id="contentType" formControlName="sbr_contenttype"
          placeholder="Content Type" [required]="true" appendTo="body" [baseZIndex]="3200" (onChange)="changeContentType($event.value)">
        </p-dropdown>
      </div>
      <div class="col-6 col-lg-4 form-group">
        <label for="category">Category:</label>
        <p-dropdown [options]="fAndFCategories" name="_sbr_applicationcategoryid_value" id="category" formControlName="_sbr_applicationcategoryid_value"
          placeholder="Category" [required]="true" appendTo="body" [baseZIndex]="3200">
        </p-dropdown>
      </div>
      <div class="col-6 col-lg-2 form-group">
        <label for="sortOrder">Sort Order:</label>
        <input type="text" (keypress)="numberOnly($event, false)" class="form-control" name="sbr_sortorder"
          id="sortOrder" formControlName="sbr_sortorder" required="true"/>
      </div>
      <div class="col-12 form-group">
        <label for="contentTitle">Title (displayed in the App):</label>
        <input type="text" class="form-control" name="sbr_title" id="contentTitle" formControlName="sbr_title" required="true"/>
      </div>
      <div class="col-6 form-group">
        <label for="imageUrl">Image Url:</label>
        <input type="text" class="form-control" name="sbr_imageurl" id="imageUrl" formControlName="sbr_imageurl" required="true"/>
      </div>
      <ng-container *ngIf="contentForm.value.sbr_contenttype == 858810000">
        <div class="col-6 form-group">
          <label for="phoneNumber">Phone Number:</label>
          <input type="text" class="form-control" name="sbr_phonenumber" id="phoneNumber" formControlName="sbr_phonenumber" required="true"/>
        </div>
        <div class="col-12 form-group">
          <label for="adText">Description:</label>
          <textarea name="sbr_description" class="form-control" rows="4" formControlName="sbr_description" id='adText'
              placeholder="description">
          </textarea>
        </div>
      </ng-container>
      <ng-container *ngIf="contentForm.value.sbr_contenttype == 858810001">
        <div class="col-6 form-group">
          <label for="targetUrl">Url to Open:</label>
          <input type="text" class="form-control" name="sbr_targeturl" id="targetUrl" formControlName="sbr_targeturl"/>
        </div>
      </ng-container>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-warning" (click)="closeDialog()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary mr-2" [disabled]="!isOkToSave" (click)="saveContent()">
      Submit
    </button>
  </ng-template>
</p-dialog>
<p-confirmDialog key="error" [baseZIndex]="6000"></p-confirmDialog>