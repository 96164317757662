import {PaymentAccount} from '../models/payments/paymentAccount.model';
import {StringIndexedObject} from '../models/utility/stringIndexedObject.model';

const STRIPE_ACCOUNTS: StringIndexedObject<StringIndexedObject<PaymentAccount>> = {
  'Lifeline24 Ltd': {
    'CK': {
      'accountId': 'acct_17NnLGI8ZdZB2O9x',
      'accountName': '[Pre-25/05/22] Carelink24',
    },
    'CL': {
      'accountId': 'acct_169WmVBSov2QpCS1',
      'accountName': '[Pre-25/05/22] Careline',
    },
    'LL': {
      'accountId': 'acct_103MYJ2fgFzkJ6Ey',
      'accountName': 'Lifeline24',
    },
    'LLIE': {
      'accountId': 'acct_17YZEEK5rWFFs99Q',
      'accountName': 'Ireland LL24',
    },
    'TC': {
      'accountId': 'acct_16N0uIILoKDISJlD',
      'accountName': '[Pre-25/05/22] Telecare Choice',
    },
  },
  'Careline365 Ltd': {
    'CK': {
      'accountId': 'acct_1LNDrXKhCxrkk8k7',
      'accountName': 'Carelink24',
    },
    'CL': {
      'accountId': 'acct_1LTMpcA3SG7uUDhV',
      'accountName': 'Careline365',
    },
    'LL': {
      'accountId': 'acct_1LTN6gD1XjPt1RYU',
      'accountName': 'LifeConnect24',
    },
    'LLIE': {
      'accountId': 'acct_1LTNLeDxFrpV80kB',
      'accountName': 'Ireland LifeConnect24',
    },
    'TC': {
      'accountId': 'acct_1LQq8cCCeD0NTuVy',
      'accountName': 'Telecare Choice',
    },
  }
}

export {
  STRIPE_ACCOUNTS,
};
