import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {GenesysDataResponse} from '../models/responses/genesysDataResponse.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenesysService {
  private API_URL: string = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) { }

  getGenesysData(brand: string, phoneNo: string): Observable<GenesysDataResponse> {
    return this.http.get<GenesysDataResponse>(`${this.API_URL}/webhooks/genesysCrm/${brand}/${phoneNo}` );
  }
}
