import {FilterMatchMode, SelectItem} from 'primeng/api';

const tdCodeMatchModeOptions: SelectItem<string>[] = [
  { label: 'Equals', value: FilterMatchMode.EQUALS },
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'In', value: 'in-delimited-list' },
];

const nameMatchModeOptions: SelectItem<string>[] = [
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Contains', value: 'name-contains' },
  { label: 'Not contains', value: 'name-not-contains' },
  { label: 'Ends', value: 'name-ends-with' },
  { label: 'Equals', value: 'name-equals' },
  { label: 'Not equals', value: 'name-not-equals' },
];

export {
  tdCodeMatchModeOptions,
  nameMatchModeOptions,
};
