<div class="col-6">
    <button type="button" class="btn btn-primary m-2" (click)="bgCustomerOpenMode = 'add'">Add Customer</button>
</div>
<div class="row no-gutters">
    <div class="bg-customers">
        <p-table #dt [value]="businessGrowthCustomers" [columns]="columns" [paginator]="true" [rows]="15" [sortOrder]="1" sortMode="single">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field">
                        <div *ngSwitchCase="'actions'">
                            {{col.header}}
                        </div>
                        <div *ngSwitchDefault [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order">
                            </p-sortIcon>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" [ngSwitch]="col['field']">
                      <div *ngSwitchCase="'bgcName'">
                        {{rowData[col.field]}}
                      </div>
                      <div *ngSwitchCase="'bgcIdentifyingTag'" [ngStyle]="{'background-color': rowData[col.field]?.color}">
                          {{rowData[col.field]?.tagName}}
                      </div>
                      <div *ngSwitchCase="'actions'">
                        <button type="button" class="btn btn-info" (click)="openBGCustomer('view', rowData)">View</button>
                        <button type="button" class="btn btn-primary ml-2" (click)="openBGCustomer('edit', rowData)" >Edit</button>
                        <button type="button" class="btn btn-danger ml-2" (click)="confirmDeleteBGCustomer(rowData)" >Delete</button>
                      </div>
                      <div *ngSwitchDefault>
                          {{rowData.bgcAddresses[0][col.field]}}
                      </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-toast></p-toast>
<p-confirmDialog key="general"></p-confirmDialog>
<app-business-growth-customer *ngIf="bgCustomerOpenMode" [businessGrowthCustomer]="businessGrowthCustomer"
  [openMode]="bgCustomerOpenMode" (closeBGCustomer)="closeBGCustomer($event)">
</app-business-growth-customer>