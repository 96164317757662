import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Subscription} from 'rxjs';
import {isValidObjectId} from '../../helpers/helperFunctions';
import {AlarmUserDetails} from '../../models/order.model';
import {OrderLink} from '../../models/orderLink.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {OrderLinkService} from '../../services/order-link.service';
import {PostOrderSection} from '../post-order-section';
import {PostOrderService} from '../post-order.service';

@Component({
  selector: 'app-order-links-section[pageSection][minimizeSectionMethod][orderId]',
  templateUrl: './order-links-section.component.html',
  styleUrls: ['../post-order-sections.scss', './order-links-section.component.scss'],
})
export class OrderLinksSectionComponent extends PostOrderSection implements OnInit, OnDestroy {
  @Input() orderId: string;
  alarmUserDetailsSubscription: Subscription;
  orderLinks: OrderLink[];
  tdCodeToLinkOrder: string = null;
  linkReason: string;
  isAddLinkButtonDisable: boolean = false;
  tdCode: string;

  constructor(
    private orderLinkService: OrderLinkService,
    private postOrderService: PostOrderService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
  ) {
    super(confirmationService, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.orderLinks = [];
    this.tdCode = '';
    if (isValidObjectId(this.orderId)) {
      this.orderLinkService.getLinksForOrder(this.orderId).subscribe((response: MultiRecordResponse<OrderLink>) => {
        if (response.success) {
          this.orderLinks = response.data;
        } else {
          console.error('Error getting Order Links', response.message);
        }
      });
    }
    this.alarmUserDetailsSubscription = 
      this.postOrderService.alarmUserDetailsSubject.subscribe((alarmUserDetails: AlarmUserDetails) => {
        if (alarmUserDetails.tdCode) {
          this.tdCode = alarmUserDetails.tdCode;
        }
      });
  }

  ngOnDestroy(): void {
    this.alarmUserDetailsSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  get isAddLinkDisabled(): boolean {
    return this.isAddLinkButtonDisable || !this.tdCode;
  }
  
  addOrderLink() {
    this.isAddLinkButtonDisable = true;
    const params: OrderLink = {
      from: this.orderId,
      fromTdCode: this.tdCode,
      toTdCode: this.tdCodeToLinkOrder,
      reason: this.linkReason,
    };

    this.postOrderService.incPendingUpdates();
    this.orderLinkService.addOrderLink(params).subscribe((response: SingleRecordResponse<OrderLink>) => {
      this.postOrderService.decPendingUpdates();
      if (!response.success) {
        this.isAddLinkButtonDisable = false
        this.showErrorPopUp(response.message, 'Order failed to link');
      } else {
        this.tdCodeToLinkOrder = '';
        this.linkReason = '';
        this.isAddLinkButtonDisable = false;
        this.orderLinks.push(response.data);
        this.showSuccess('Link Added', 'The other order has been successfully linked');
      }
    }, (err: Error) => {
      this.postOrderService.decPendingUpdates();
      this.showErrorPopUp('Error linking orders',
          `Error linking orders, please try again. Error: ${err.message}`);
    });
  }
  
  deleteLinkedOrder(orderLinkId: string) {
    const acceptCallback: () => void = () => {
      this.postOrderService.incPendingUpdates();
      this.orderLinkService.deleteLinkedOrder(orderLinkId).subscribe((response: SimpleResponse) => {
          this.postOrderService.decPendingUpdates();
          if (response.success) {
            this.showSuccess('Link Deleted', 'The link to the other order has been removed.');
            this.orderLinks = this.orderLinks.filter((orderLink: OrderLink) => 
              orderLink._id != orderLinkId
            );
          } else {
            this.showErrorPopUp('Error removing order link', 'Error removing order link, please try again.');
          }
        }, (err: Error) => {
          this.postOrderService.decPendingUpdates();
          console.error('Error deleting link. Error:', err);
          this.showErrorPopUp('Error removing order link', 'Error removing order link, please try again.');
        });
    }
    this.showConfirmationPopup(
      'Delete linked order',
      'Are you sure you want to delink this order',
      acceptCallback,
    );
  }
}
