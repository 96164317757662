import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from './../../../environments/environment';
import {Observable} from 'rxjs';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SbrApplicationContent} from '../../models/responses/sbr/SbrApplicationContent.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {SbrApplicationCategory} from '../../models/responses/sbr/SbrApplicationCategory.model';

@Injectable({
  providedIn: 'root'
})
export class FriendsAndFamilyContentService {
  API_URL: string = `${environment.protocol}${environment.IPAddress}/api`;

  constructor(
    private http: HttpClient,
  ) { }

  getContent(): Observable<MultiRecordResponse<SbrApplicationContent>> {
    return this.http.get<MultiRecordResponse<SbrApplicationContent>>(`${this.API_URL}/accountServices/content/all`);
  }

  addContent(content: SbrApplicationContent): Observable<SingleRecordResponse<SbrApplicationContent>> {
    return this.http.post<SingleRecordResponse<SbrApplicationContent>>(`${this.API_URL}/accountServices/content`, content);
  }

  updateContent(content: SbrApplicationContent): Observable<SingleRecordResponse<SbrApplicationContent>> {
    return this.http.patch<SingleRecordResponse<SbrApplicationContent>>(`${this.API_URL}/accountServices/content`, content);
  }

  deleteContent(contentId: string): Observable<SingleRecordResponse<SbrApplicationContent>> {
    return this.http.delete<SingleRecordResponse<SbrApplicationContent>>(`${this.API_URL}/accountServices/content/${contentId}`,);
  }

  getContentCategories(): Observable<MultiRecordResponse<SbrApplicationCategory>> {
    return this.http.get<MultiRecordResponse<SbrApplicationCategory>>(`${this.API_URL}/accountServices/category/all`);
  }

  addContentCategory(category: SbrApplicationCategory): Observable<SingleRecordResponse<SbrApplicationCategory>> {
    return this.http.post<SingleRecordResponse<SbrApplicationCategory>>(`${this.API_URL}/accountServices/category`, category);
  }

  updateContentCategory(category: SbrApplicationCategory): Observable<SingleRecordResponse<SbrApplicationCategory>> {
    return this.http.patch<SingleRecordResponse<SbrApplicationCategory>>(`${this.API_URL}/accountServices/category`, category);
  }

  deleteContentCategory(categoryId: string): Observable<SingleRecordResponse<SbrApplicationCategory>> {
    return this.http.delete<SingleRecordResponse<SbrApplicationCategory>>(`${this.API_URL}/accountServices/category/${categoryId}`,);
  }
}
