import {SelectItem} from 'primeng/api';

const marketingOptInSelects: SelectItem<string>[] = [
  { label: '-- Select --', value: '', disabled: true },
  { label: 'Opted In', value: 'Opted In' },
  { label: 'Opted Out', value: 'Opted Out' },
];

export {
  marketingOptInSelects,
}