import {SelectItem} from 'primeng/api';

let refundMethodOptions: SelectItem<string>[];

function getRefundMethodOptions(): SelectItem<string>[] {
  if (!refundMethodOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('refunds: refund method')) {
      return [];
    }
    refundMethodOptions = JSON.parse(localStorage.getItem('refunds: refund method')) || [];
  }
  return refundMethodOptions;
}

export {
  getRefundMethodOptions,
}