<div class="container">
  <hr/>
  <p-tabView>
    <p-tabPanel header="Active Tags">
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">TAG</th>
            <th scope="col">COLOUR</th>
            <th scope="col">ALLOW EXPIRY DATE</th>
            <th scope="col">UPDATE</th>
            <th scope="col">ARCHIVE</th>
          </tr>
        </thead>
        <tbody>
          <tr style="background-color: rgba(251,237,255,0.38)">
            <td class="container">
              <input class="form-control" type="text" [(ngModel)]="newTag.tagName" placeholder="Add the new tag and choose color" />
            </td>
            <td class="">
              <input  class="mr-2" type="color" name="favcolor" [(ngModel)]="newTag.color" color />
            </td>
            <td class="align-middle">
              <input class="form-control" type="checkbox" [(ngModel)]="newTag.allowExpiry" />
            </td>
            <td class="" colspan="2" align="center">
              <button type="submit" class="btn btn-info" (click)="save()">Add new tag</button>
            </td>
          </tr>
          <!-- On cells (`td` or `th`) -->
          <tr *ngFor="let tag of tags ; let i = index" >
            <td><input class="form-control" type="text" placeholder="TAG name" [(ngModel)]="tag.tagName" /></td>
            <td><input type="color" name="favcolor" [(ngModel)]="tag.color" /></td>
            <td class="align-middle"><input class="form-control" type="checkbox" [(ngModel)]="tag.allowExpiry" /></td>
            <td>
              <button type="submit" class="btn btn-primary" (click)="updateTag(tag)">UPDATE</button>
            </td>
            <td>
              <button type="submit" class="btn btn-danger" (click)="deleteTag(tag,i)">Archive</button>
            </td>
          </tr>
          <tr>
            <td colspan="5"><hr /></td>
          </tr>
        </tbody>
      </table>
    </p-tabPanel>
    <p-tabPanel header="Archived Tags">
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">TAG</th>
            <th scope="col">Color</th>
            <th scope="col">ALLOW EXPIRY DATE</th>
            <th scope="col"></th>
            <th scope="col">Activate</th>
          </tr>
        </thead>
        <tbody>
          <!-- On cells (`td` or `th`) -->
          <tr *ngFor="let tag of archivedTags ;  let i = index " >
            <td class="container"><input class="form-control" type="text" placeholder="TAG name" readonly [(ngModel)]="tag.tagName" /></td>
            <td><input type="color" name="favcolor" [(ngModel)]="tag.color" readonly /></td>
            <td class="align-middle"><input class="form-control" type="checkbox" readonly onclick="return false" [(ngModel)]="tag.allowExpiry" /></td>
            <td></td>
            <td>
              <button type="submit" class="btn btn-success" (click)="tagActivation(tag,i)">Re-Activate</button>
            </td>
          </tr>
          <tr>
            <td colspan="5"><hr /></td>
          </tr>
        </tbody>
      </table>
    </p-tabPanel>
  </p-tabView>
</div>
