<div class="add-post-sheet">
    <p-dialog [header]="header" (onHide)="closeDialog()" [(visible)]="displayModal" [modal]="true"
        [style]="{width: '80vw'}" [draggable]="false" [resizable]="false">
        <div *ngIf="loading" class="loading-indicator d-flex align-items-center justify-content-center" style="height: 200px;">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <form *ngIf="!loading" [formGroup]="addPostSheetForm">
          <fieldset [disabled]="openMode == 'view'">
            <div class="row">
              <div class="form-group col-6">
                <div class="row">
                  <div class="form-group col-6">
                      <label>Post Sheet Type</label>
                      <p-dropdown [options]="postSheetTypes" name="leadType" [showClear]="false"
                          placeholder="Select an item" [required]="true" formControlName="postSheetType"
                          (onChange)="changeItem(false)" [readonly]="['addFromOrder', 'addFromLead'].includes(openMode)"
                          [styleClass]="['addFromOrder', 'addFromLead'].includes(openMode)? 'readonly': ''" [filter]=true [resetFilterOnHide]="true">
                          <ng-template let-postSheetType let-i="index" pTemplate="item">
                              <span>{{postSheetType.value}}</span>
                          </ng-template>
                      </p-dropdown>
                  </div>
                  <div class="form-group col-6">
                    <label>Td Code</label>
                    <input type="text" name="tdCode" class="form-control" placeholder="Enter tdCode"
                      formControlName="tdCode" (change)="validateTdCode()" [readonly]="!!order">
                  </div>
                  
                    <ng-container *ngIf="postSheetType && postSheetType.value && postSheetType.value != 'Chasers to be sent'">
                      <div class="form-group col-6">
                        <label>Brand</label>
                          <p-dropdown [options]="brands" name="websiteId" [showClear]="false" placeholder="Select a brand"
                            formControlName="websiteId" [readonly]="!!orderId" [styleClass]="!!orderId? 'readonly': ''" [filter]=true [resetFilterOnHide]="true">
                            <ng-template let-Pbrand let-i="index" pTemplate="item">
                              <span class="brandDropDown"
                                  [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color': brandConfigs[Pbrand.value].color}">
                                  &nbsp;{{Pbrand.value}}
                              </span>
                            </ng-template>
                          </p-dropdown>
                      </div>
                    </ng-container>
                  
                  <ng-container *ngIf="postSheetType && postSheetType.value == 'Item'">
                    <div class="form-group col-6">
                        <label>What to send</label>
                        <p-dropdown [options]="whatToSendOptions" placeholder="Select what to send" [showClear]="true"
                          formControlName="whatToSend" [required]="true" (onChange)="changeWhatToSend($event)" [filter]=true [resetFilterOnHide]="true">
                        </p-dropdown>
                    </div>
                    <div class="form-group col-6">
                        <label>Why it's being sent</label>
                        <p-dropdown [options]="sendReasonOptions" placeholder="Select a reason" [showClear]="true"
                            formControlName="reason" [required]="true" (onChange)="changeWhySend($event)" [filter]=true [resetFilterOnHide]="true">
                            <ng-template let-sendReason let-i="index" pTemplate="item">
                                <span data-toggle="tooltip" title="{{sendReason.tooltip}}">{{sendReason.label}}</span>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="form-group col-6">
                        <label>Class</label>
                        <input type="text" name="class" class="form-control" formControlName="class" placeholder="Class" required >
                    </div>
                    <div class="form-group col-6">
                        <label>Amount Paid</label>
                        <input type="text" name="paidAmount" class="form-control"
                            placeholder="Enter amount paid" formControlName="paidAmount" 
                            [required]="reasonsThatMustBePaidFor.includes(reasonBeingSent)"
                            (keypress)="numberPlusDecimal($event, false)">
                    </div>
                    <div class="form-group col-6">
                        <label>VAT?</label>
                          <p-dropdown name="vatOrNot" [options]="vatOrNotOptions"
                            placeholder="Paying VAT?" formControlName="vatOrNot" 
                            [required]="reasonsThatMustBePaidFor.includes(reasonBeingSent)">
                          </p-dropdown>
                    </div>
                    <div class="form-group col-6">
                        <label>Payment Method</label>
                        <p-dropdown name="paymentMethod" [options]="paymentMethodOptions"
                            placeholder="Enter payment method" formControlName="paymentMethod" 
                            [required]="reasonsThatMustBePaidFor.includes(reasonBeingSent)">
                        </p-dropdown>    
                    </div>
                  </ng-container>
                  <ng-container *ngIf="postSheetType && postSheetType.value == 'Info Pack'">
                    <div class="col-6">
                      <label>Phone</label>
                      <input type="text" name="phone" (keypress)="numberOnly($event, false)" class="form-control"
                          placeholder="Enter phone" formControlName="phone">
                    </div>
                    <div class="col-6">
                        <label>Email</label>
                        <input type="text" name="email" class="form-control" placeholder="Enter email" formControlName="email">
                    </div>
                    <div class="col-6">
                      <label>Ordered By? Ordered For?</label>
                      <input type="text" name="orderedByOrderedFor" class="form-control"
                          placeholder="Enter ordered by ordered for" formControlName="orderedByOrderedFor" required>
                    </div>
                    <div class="form-group col-6">
                      <label>Callback Date</label>
                      <input type="date" name="callbackDate" class="form-control" formControlName="callbackDate"
                          min="{{todayDate}}">
                    </div>
                        <div class="form-group col-6">
                            <label>Select what to send</label>
                            <p-dropdown [options]="whatToSendOptions" placeholder="Select what to send" [showClear]="true"
                                formControlName="whatToSend" [required]="true" [filter]=true [resetFilterOnHide]="true">
                            </p-dropdown>
                        </div>
                  </ng-container>
                  <ng-container *ngIf="postSheetType && postSheetType.value == 'Wrong Provider Calls'">
                      <div class="form-group col-6">
                          <label>Label Created</label>
                          <input type="text" name="labelCreated" class="form-control" formControlName="labelCreated"
                              placeholder="Enter label created">
                      </div>
                      <div class="form-group col-6">
                          <label>Date Sent</label>
                          <input type="date" name="dateSent" class="form-control" formControlName="dateSent">
                      </div>
                  </ng-container>
                  <ng-container *ngIf="postSheetType && postSheetType.value == 'Chasers to be sent'">
                      <div class="form-group col-6">
                          <label>What to send</label>
                          <p-dropdown [options]="chasersWhatToSendOptions" placeholder="Select what to send"
                              [showClear]="true" formControlName="whatToSend" [required]="true" [filter]=true [resetFilterOnHide]="true">
                          </p-dropdown>
                      </div>
                      <div class="form-group col-6">
                          <label>Class</label>
                          <input type="text" name="class" class="form-control" placeholder="Enter Postage Class"
                              formControlName="class" required>
                      </div>
                  </ng-container>
                </div>
              </div>
              <div class="form-group col-6 col-md-6">
                  <div class="row">
                    <div *ngIf="openMode == 'addFromOrder'" class="col-12 form-group">
                      <label for="contactDetails">Select contact, or enter details manually</label>
                        <p-dropdown [options]="availableContactDetails" name="contactDetails" id="contactDetails" [(ngModel)]="selectedContactDetails" [showClear]="true"
                            (onChange)="selectedContactChange()" [ngModelOptions]="{standalone: true}" placeholder="Optionally select an existing contact"
                            [appendTo]="'body'">
                        </p-dropdown>
                    </div>
                    <div class="col-6 col-md-6" style="margin-bottom: 6px">
                          <label>Name</label>
                          <input type="text" name="name" class="form-control" placeholder="Enter name" formControlName="name" required>
                    </div>
                    <div class="col-6">
                      <label>Address Search</label>
                      <div id="postcode_lookup_post">
                        <input type="text" id="getaddress_input_post" autocomplete="off" [(ngModel)]="addPostSheetAddressSearchPostCode" [ngModelOptions]="{standalone:true}" 
                          placeholder="Find postcode">
                        <button type="button" id="getaddress_button_post" (click)="addPostSheetAddressSearch()" [disabled]="!addPostSheetAddressSearchPostCode">
                          Find postcode
                        </button>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="addPostSheetAddressResults && (addPostSheetAddressResults.length > 0)">
                      <p-dropdown [options]="addPostSheetAddressResults" [style]="{'width':'100%'}" (onChange)="setPostSheetAddress($event)"
                        [appendTo]="'body'">
                      </p-dropdown>
                    </div>
                    <div class="col-12" *ngIf="postSheetSearchError">
                      <p class="alert alert-danger">{{postSheetSearchError}}</p>
                    </div>
                    <div class="col-12 col-md-12">
                      <label>Address Line One</label>
                        <input type="text" name="addressOne" class="form-control" placeholder="Enter your address one" formControlName="addressLineOne" required
                          id="postsheet_address_0" [readOnly]="!addPostSheetForm.value.postcode || addPostSheetForm.value.validated">
                    </div>
                    <div class="col-12 col-md-12">
                      <label>Address Line Two</label>
                          <input type="text" name="addressTwo" class="form-control" placeholder="Enter your address two" formControlName="addressLineTwo"
                            id="postsheet_locality" [readOnly]="!addPostSheetForm.value.postcode || addPostSheetForm.value.validated">
                      </div>
                      <div class="col-12 col-md-12">
                          <label>City</label>
                          <input type="text" name="city" class="form-control" placeholder="Enter your city" formControlName="city" required id="postsheet_town_or_city"
                            [readOnly]="!addPostSheetForm.value.postcode || addPostSheetForm.value.validated">
                      </div>
                      <div class="col-12 col-md-12">
                          <label>County</label>
                          <input type="text" name="county" class="form-control" placeholder="Enter your county" formControlName="county" id="postsheet_county"
                            [readOnly]="!addPostSheetForm.value.postcode || addPostSheetForm.value.validated">
                      </div>
                      <div class="col-12 col-md-12">
                          <label>Postcode</label>
                          <input type="text" name="postcode" class="form-control" placeholder="Enter your postcode" formControlName="postcode" required id="postsheet_postcode"
                            [readOnly]="!addPostSheetForm.value.postcode || addPostSheetForm.value.validated">
                      </div>
                  </div>
              </div>
              <div class="form-group col-6">
                <div class="row">
                  <div class="form-group col-12">
                      <label>Added By</label>
                      <input type="text" name="addedBy" class="form-control" formControlName="addedBy" [required]="true"
                          readonly>
                  </div>
                  <div class="form-group col-12">
                    <label>Posted On</label>
                    <input type="date" name="postedOn" class="form-control" formControlName="postedOn" [readonly]="isAlreadyPosted">
                  </div>
                  <div class="form-group col-12">
                      <label>Posted By</label>
                      <input type="text" name="postedBy" class="form-control" formControlName="postedBy" [readonly]="isAlreadyPosted">
                  </div>
                </div>
              </div>
              <div class="form-group col-6">
                <label>Comments</label>
                  <textarea class="form-control" name="comments" [rows]="8" pInputTextarea
                      formControlName="comments" placeholder="Enter comments"></textarea>
              </div>
            </div>              
                 
          </fieldset>
        </form>

        <ng-template pTemplate="footer">
            <button *ngIf="openMode != 'view'" type="button" class="btn btn-primary" [disabled]="isFormInvalid || isSubmitDisabled"
                (click)="addPostSheet()">Submit</button>
            <button *ngIf="openMode == 'update'" type="button" class="btn btn-primary mr-2"
                [disabled]="isAlreadyPosted" (click)="postPostSheet()">Post</button>
            <button type="button" class="btn btn-warning mr-2" (click)="closeDialog()">{{openMode == 'view'?'Close':'Cancel'}}</button>
        </ng-template>
        <p-toast></p-toast>
    </p-dialog>
</div>