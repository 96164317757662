import {Component, Input, OnInit, Output,EventEmitter} from '@angular/core';
import {getSendReasonOptions, getWhatToSendOptions, SendReason, WhatToSend} from '../../lookups/postSheet';
import {PostsheetReportData} from '../../models/reporting/postsheetReportData.model';
import 'chartjs-plugin-colorschemes';

@Component({
  selector: 'app-post-sheet-item-chart[reportData][closeChart]',
  templateUrl: './post-sheet-item-chart.component.html',
  styleUrls: ['./post-sheet-item-chart.component.scss']
})
export class PostSheetItemChartComponent implements OnInit {

  constructor() { }
  @Input() reportData: PostsheetReportData[];
  @Output() closeChart: EventEmitter<void> = new EventEmitter<void>();
  indexedReportData: {[what: string]: PostsheetReportData} = {};
  data: any;
  displayModal: boolean = true;
  options: any;

  ngOnInit() {
    this.displayModal = true;
    this.reportData.forEach((dataItem: PostsheetReportData) => {
      this.indexedReportData[dataItem.whatToSend] = dataItem;
    });
    this.options = {
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
        }],
      },
      plugins: {
        colorschemes: {
          scheme: 'tableau.Tableau20'
        }
      }
    };

    this.data = {
      labels: [],
      datasets: [],
    };
    /*
    const totalDataSet = {
      label: 'Total',
      //borderColor: '#',
      borderWidth: 0,
      data: [],
    };*/
    getWhatToSendOptions().forEach((what: WhatToSend) => {
      this.data.labels.push(
        what.label,
      );
      /*
      if (this.indexedReportData[what.label]) {
        totalDataSet.data.push(this.indexedReportData[what.label].total);
      } else {
        totalDataSet.data.push(0);
      }*/
    });
    //this.data.datasets.push(totalDataSet);
    
    getSendReasonOptions().forEach((reason: SendReason) => {
      const reasonDataSet = {
        label: reason.label,
        //borderColor: '#',
        borderWidth: 0,
        data: [],
      };
      getWhatToSendOptions().forEach((what: WhatToSend) => {
        if (this.indexedReportData[what.label] && this.indexedReportData[what.label].breakdownByReason[reason.value]) {
          reasonDataSet.data.push(this.indexedReportData[what.label].breakdownByReason[reason.value]);
        } else {
          reasonDataSet.data.push(0);
        }
      });
      this.data.datasets.push(reasonDataSet);
    });
  };


  closeDialog(): void {
    this.displayModal = false;
    this.closeChart.emit();
  }

}
