import {SelectItem} from 'primeng/api';

let messageRejectionReasons: SelectItem<string>[];

function getMessageRejectionReasons(): SelectItem<string>[] {
  if (!messageRejectionReasons) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('messages: rejection reasons')) {
      return [];
    }
    messageRejectionReasons = JSON.parse(localStorage.getItem('messages: rejection reasons')) || [];
  }
  return messageRejectionReasons;
}

export {
  getMessageRejectionReasons,
}