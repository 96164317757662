<table>
  <tbody>
    <ng-container *ngFor="let index of oddLabelNumbers;">
      <tr>
        <td class="mailing-label">
          <div *ngIf="postToPrintLabelsFor[index]; let postItem">
            {{postItem.name}}<br/>
            <ng-container *ngIf="postItem.addressLineOne">{{postItem.addressLineOne}}<br/></ng-container>
            <ng-container *ngIf="postItem.addressLineTwo">{{postItem.addressLineTwo}}<br/></ng-container>
            <ng-container *ngIf="postItem.city">{{postItem.city}}<br/></ng-container>
            <ng-container *ngIf="postItem.county">{{postItem.county}}<br/></ng-container>
            <ng-container *ngIf="postItem.postcode">{{postItem.postcode}}</ng-container>
          </div>
        </td>
        <td class="mailing-label">
          <div *ngIf="index + 1 < postToPrintLabelsFor.length">
            <ng-container *ngIf="postToPrintLabelsFor[index+1]; let postItem">
              {{postItem.name}}<br/>
              <ng-container *ngIf="postItem.addressLineOne">{{postItem.addressLineOne}}<br/></ng-container>
              <ng-container *ngIf="postItem.addressLineTwo">{{postItem.addressLineTwo}}<br/></ng-container>
              <ng-container *ngIf="postItem.city">{{postItem.city}}<br/></ng-container>
              <ng-container *ngIf="postItem.county">{{postItem.county}}<br/></ng-container>
              <ng-container *ngIf="postItem.postcode">{{postItem.postcode}}</ng-container>
            </ng-container>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
