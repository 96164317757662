import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {renewalTypeDisplayLookup} from '../../../lookups/renewalTypes';
import {getStatusBackgroundColour, getStatusColour} from '../../../lookups/statuses';
import {Environment} from '../../../models/environment.model';
import {Order} from '../../../models/order.model';
import {ProposedMessage} from '../../../models/proposedMessage.model';

@Component({
  selector: 'app-auto-enrolment[message][order][isSmallerThanLarge]',
  templateUrl: './auto-enrolment.component.html',
  styleUrls: ['./auto-enrolment.component.scss']
})
export class AutoEnrolmentComponent {

  constructor() { }
  @Input() message: ProposedMessage;
  @Input() order: Order;
  @Input() isSmallerThanLarge: boolean;
  environment: Environment = environment;
  getStatusColour = getStatusColour;
  getStatusBackgroundColour = getStatusBackgroundColour;
  renewalTypeDisplayLookup: {[lookupName: string]: string} = renewalTypeDisplayLookup;
}
