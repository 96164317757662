import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {NcfDialogData} from '../../models/ncfDialogData.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {DomHandler} from 'primeng/dom';

@Component({
  selector: 'app-ncf-dialog[closeModal]',
  templateUrl: './ncf-dialog.component.html',
  styleUrls: ['./ncf-dialog.component.scss']
})
export class NcfDialogComponent implements OnInit {
  @Output() closeModal: EventEmitter<NcfDialogData|undefined> = new EventEmitter<NcfDialogData|undefined>();
  saveDisabled: boolean;
  dialogVisible: boolean;
  ncfForm: FormGroup;
  closing: boolean;
  ncfMethods: SelectItem<string>[] = [{
    'label': 'Email',
    'value': 'Email',
  }, {
    'label': 'Post',
    'value': 'Post',
  }];

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.saveDisabled = true;
    this.dialogVisible = true;
    this.closing = false;
    this.ncfForm = this.formBuilder.group({
      'ncfMethod': ['', Validators.required],
      'isCustomerRip': false,
    });
    this.saveDisabled = false;
  }

  get ncfMethod(): string {
    return this.ncfForm.get('ncfMethod').value;
  }

  saveNcfDetails(): void {
    if (this.closing) {
      return;
    }
    this.closing = true;
    this.closeModal.emit(this.ncfForm.value);
  }

  isOkToSave(): boolean {
    return this.ncfForm.valid && !this.saveDisabled;
  }

  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');
  }

  closeDialog(): void {
    if (!this.closing) {
      this.closing = true;
      this.closeModal.emit();
    }
  }

}
