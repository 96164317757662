<ng-container [formGroup]="orderForm">
  <div class="formbar row no-gutters">
    <div class="col-12">
      Initial Order Dispatch Information
      <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
        <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
        <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
      </button>
    </div>
  </div>
  <div *ngIf="formLoaded" class="formsect" formGroupName="initialOrderDispatchInformation">
    <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
      <div class="form-row">
        <div class="form-group col-6">
          <label>Dispatch Date</label>
          <input onkeydown="return false"
              [ngStyle]="{'color': !safeGetFormField('dispatchDate')? '#ffffff' : 'black' }"
              type="date" name="dispatchDate" min="2014-01-01"
              formControlName="dispatchDate" (change)="dispatchFieldVerfication()">
        </div>
        <div class="form-group col-6">
          <label>Shipping Method</label>
          <input type="text" name="ShippingMethod" formControlName="ShippingMethod" (change)="dispatchFieldVerfication()">
        </div>
        <div *ngIf="!!safeGetFormField('sfOrderId'); else noSfId" class="form-group col-6">
          <label>SF Order ID</label>
          <input type="text" name="sfOrderId" formControlName="sfOrderId" (change)="dispatchFieldVerfication()">
        </div>
        <ng-template #noSfId>
          <div class="form-group col-6">
            <label>Click And Drop Order ID</label>
            <input type="text" name="cadOrderId" formControlName="cadOrderId" (change)="dispatchFieldVerfication()">
          </div>
        </ng-template>
        <div class="form-group col-6">
          <label>Tracking No.</label>
          <ng-container *ngIf="showTrackingLink; else showTrackingInput">
            <a class="trackingLink" (click)="navigateToTracking()">{{trackingNumber}}</a>
          </ng-container>
          <ng-template #showTrackingInput>
            <input type="text" name="trackingNo" formControlName="trackingNo" (change)="dispatchFieldVerfication()">
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>