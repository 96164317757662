<div class="formbar row no-gutters">
  <div class="col-12">
    Initial Order Details
    <span id="order-numb">{{webOrderId}}</span>
    <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
      <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
      <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
    </button>
  </div>
</div>
<div *ngIf="formLoaded" class="formsect">
  <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
    <div class="form-row">
      <div class="form-group col-4">
        <label>Processor</label>
        <input type="text" name="processor" value="{{initialOrderDetails.processor}}" readonly>
      </div>
      <div class="form-group col-4">
        <label>Payment</label>
        <input type="text" name="payment" value="{{initialOrderDetails.payment}}" readonly>
      </div>
      <div class="form-group col-4">
        <label>Customer Order Id</label>
        <input type="text" name="orderId" value="{{initialOrderDetails.orderId}}" readonly>
      </div>
      <div class="form-group col-4">
        <label>Order Date</label>
        <input type="text" name="orderDate" value="{{initialOrderDetails.orderDate}}" readonly>
      </div>
      <div class="form-group col-4">
        <label>Order Type</label>
        <input type="text" name="orderType" value="{{initialOrderDetails.orderType}}" readonly>
      </div>
      <div class="form-group col-4">
        <label>Renewal</label>
        <input type="text" name="renewal" value="{{initialOrderDetails.renewal}}" readonly>
      </div>
      <div class="form-group col-6">
        <label>First Name</label>
        <input type="text" name="firstName" value="{{initialOrderDetails.firstName}}" readonly>
      </div>
      <div class="form-group col-6">
        <label>Last Name</label>
        <input type="text" name="lastName" value="{{initialOrderDetails.lastName}}" readonly>
      </div>
      <div class="form-group col-6">
        <label>Telephone</label>
        <input type="text" name="telephone" value="{{initialOrderDetails.telephone}}" readonly>
      </div>
      <div class="form-group col-6">
        <label>Email</label>
        <input type="text" name="email" value="{{initialOrderDetails.email}}" readonly>
      </div>
      <div class="form-group col-6">
        <label>Alarm User</label>
        <input type="text" name="alarmUser" value="{{initialOrderDetails.alarmUser}}" readonly>
      </div>

      <div class="form-group col-6">
        <label>SF Order ID</label>
        <input type="text" name="sfOrderId" value="{{initialOrderDetails.sfOrderId}}" readonly>
      </div>

      <div class="form-group col-6">
        <label>Coupons</label>
        <input type="text" name="couponCode" value="{{initialOrderDetails.couponCode}}" readonly>
      </div>
      <div class="form-group col-6">
        <label>Total Discount</label>
        <input type="text" name="totalDiscount" value="{{initialOrderDetails.totalDiscount}}" readonly>
      </div>
      <hr style="width: 100%;margin: 8px 0px;">
      <div class="form-group col-6">
        <label>Vat Total</label>
        <input type="text" name="vatTotal" value="{{initialOrderDetails.vatTotal}}" readonly>
      </div>
      <div class="form-group col-6">
        <label>Total</label>
        <input type="text" name="total" value="{{initialOrderDetails.total}}" readonly>
      </div>
      <div class="form-group col-6">
        <label>Billing</label>
        <textarea class="address" rows="8" value="{{initialOrderDetails.billing}}" readonly></textarea>
      </div>
      <div class="form-group col-6">
        <label>Shipping</label>
        <textarea class="address" rows="8" value="{{initialOrderDetails.shipping}}" readonly></textarea>
      </div>
      <div class="form-group col-6">
        <label>Installation Address</label>
        <textarea class="address" rows="8" value="{{initialOrderDetails.installationAddress}}" readonly></textarea>
      </div>
      <div class="form-group col-6">
        <label>Customer Called From</label>
        <input type="text" name="customerCalledFrom" value="{{initialOrderDetails.customerCalledFrom}}" readonly>
      </div>
      <div class="form-group col-5">
        <label>Linked Lead</label>
        <input type="text" name="linkedLead" value="{{initialOrderDetails.leadId}}" readonly>
      </div>
      <div class="form-group col-1">
        <label></label>
        <a routerLink="/leads/view/{{initialOrderDetails.leadId}}"
            target="_blank"
          *ngIf="initialOrderDetails.leadId; else noLeadLink" title="No Lead linked">
          <i class="pi pi-external-link"></i>
        </a>
        <ng-template #noLeadLink>
          <div title="Open Lead" class="mt-1">
            <i class="pi pi-external-link disabledLink"></i>
          </div>
        </ng-template>
      </div>
      <div class="form-group col-12">
        <label>Notes</label>
        <textarea class="notes" rows="5" value="{{initialOrderDetails.notes}}" readonly></textarea>
      </div>

      <div class="form-group form-row col-12 title-fb producttitle" *ngFor="let item of items">
        <hr style="width: 100%;margin: 8px 0px;">
        <div class="col-12" style="padding: 0;margin-bottom: 10px;">
          <label>Product Purchased</label>
          <input type="text" value="{{item.productId.title}}" readonly>
        </div>
        <div *ngIf="item.subProductId" class="form-row" style="width: calc(100% + 10px);margin-bottom: 10px;">
          <div class="col-6" *ngFor="let attribute of getItemAttributes(item)">
            <label>{{attribute.name}}</label>
            <input type="text" value="{{attribute.option}}" readonly>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>