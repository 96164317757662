import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {getExternalEmailValidator} from '../../validators/email.validator';
import {phoneNumberValidator} from '../../validators/phone-number.validator';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {getCancellationReasonCategories, cancellationCategoriesRequiringPrompt, CancellationCategorySelectItem, CancellationReasonSelectItem, getCancellationReasonsForCatgory} from '../../lookups/cancellationReasons';
import {DomHandler} from 'primeng/dom';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {NoteTemplate, getNoteTemplates} from '../../lookups/noteTemplate';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';
import {NoteCategory, getNoteCategories} from '../../lookups/noteCategory';
import {CancellingDialogData} from '../../models/cancellingDialogData.model';

@Component({
  selector: 'app-cancelling-dialog[emailTemplates][noEmailTagName][closeModal]',
  templateUrl: './cancelling-dialog.component.html',
  styleUrls: ['./cancelling-dialog.component.scss']
})
export class CancellingDialogComponent implements OnInit {
  @Input() emailTemplates: SelectItem<string>[]; 
  @Input() noEmailTagName: string;
  @Output() closeModal: EventEmitter<CancellingDialogData|undefined> = new EventEmitter<CancellingDialogData|undefined>();
  saveDisabled: boolean;
  dialogVisible: boolean;
  cancellingForm: FormGroup;
  cancellationCategoriesSelectItems: CancellationCategorySelectItem[] = [];
  cancellationReasonsSelectItems: CancellationReasonSelectItem[] = [];
  numberOnly = numberOnly;
  noteTemplates: NoteTemplate[];
  categoriesToAddNote: NoteCategory[] = [];
  defaultNoteCategories: string[] = [];
  closing: boolean;
  emailTemplateExists: boolean;
  noEmailReasons: SelectItem<string>[] = [{
    'label': 'No Email',
    'value': 'No Email',
  }, {
    'label': 'No Printer',
    'value': 'No Printer',
  }, {
    'label': 'Complaint',
    'value': 'Complaint',
  }, {
    'label': 'No Email + Printer',
    'value': 'No Email + Printer',
  }];

  constructor(
    private formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.saveDisabled = true;
    this.dialogVisible = true;
    this.closing = false;
    this.defaultNoteCategories = JSON.parse(localStorage.getItem('defaultNoteCategories'));
    if (this.defaultNoteCategories.length == 0) {
      this.defaultNoteCategories = ['General'];
    }
    this.cancellationCategoriesSelectItems = getCancellationReasonCategories(true);
    this.cancellingForm = this.formBuilder.group({
      'data': this.formBuilder.group({
        'cancellationReason': ['', Validators.required],
        'detailedCancellationReason': ['', Validators.required],
        'otherReason': ['', Validators.required],
        'personReturning': ['', Validators.required],
        'cancellationEmail': ['', [getExternalEmailValidator(false)]],
        'telephoneNumber': ['', [phoneNumberValidator, Validators.required]],
        'setToCancellingBy': localStorage.getItem('userName'),
      }),
      'note': [''],
      'noteCategories': [this.defaultNoteCategories, Validators.required],
      'emailRequired': false,
      'emailType': '',
      'reasonForNoEmail': ''
    });
    this.changeCancellationCategory('');
    this.noteTemplates = getNoteTemplates();
    this.categoriesToAddNote = getNoteCategories(true);
    this.saveDisabled = false;
  }

  get cancellationData(): AbstractControl {
    return this.cancellingForm.get('data');
  }

  get cancellationReason(): string {
    return this.cancellationData.value.cancellationReason;
  }

  get detailedCancellationReason(): string {
    return this.cancellationData.value.detailedCancellationReason;
  }

  get otherReason(): string {
    return this.cancellationData.value.otherReason;
  }

  get isBulkCustomer(): boolean {
    return this.noEmailTagName && this.noEmailTagName.toLocaleLowerCase().startsWith('bulk');
  }

  changeCancellationCategory(newCategory: string): void {
    if (!newCategory) {
      this.cancellationReasonsSelectItems = [];
    } else {
      this.cancellationReasonsSelectItems = getCancellationReasonsForCatgory(newCategory);
    }
    this.cancellationData.get('detailedCancellationReason').setValue('');
    this.changeCancellationReason('');
  }

  changeCancellationReason(newReason: string): void {
    const categorySelect: CancellationCategorySelectItem = this.cancellationCategoriesSelectItems.find((catSelect: CancellationCategorySelectItem) => 
      catSelect.value == this.cancellationReason
    );
    const reasonSelect: CancellationReasonSelectItem = this.cancellationReasonsSelectItems.find((reasonSelect: CancellationReasonSelectItem) => 
      reasonSelect.value == newReason
    );
    this.cancellationData.get('otherReason').setValue('');
    if (this.cancellationReasonsSelectItems.length > 0) {
      this.cancellationData.get('detailedCancellationReason').enable();
    } else {
      this.cancellationData.get('detailedCancellationReason').disable();
    }
    if ((categorySelect && categorySelect.requiresFreeText) || (reasonSelect && reasonSelect.requiresFreeText)) {
      this.cancellationData.get('otherReason').enable();
    } else {
      this.cancellationData.get('otherReason').disable();
    }
    if (!categorySelect || !reasonSelect || reasonSelect.requiresContactDetails) {
      this.cancellationData.get('personReturning').enable();
      this.cancellationData.get('cancellationEmail').enable();
      this.cancellationData.get('telephoneNumber').enable();
    } else {
      this.cancellationData.get('personReturning').disable();
      this.cancellationData.get('cancellationEmail').disable();
      this.cancellationData.get('telephoneNumber').disable();
    }
    const emailRequired: boolean = !reasonSelect || reasonSelect.requiresContactDetails;
    const emailType: string = categorySelect? categorySelect.emailTemplate: '';
    this.emailTemplateExists = this.emailTemplates.some((emailTemplate: SelectItem<string>) =>
      emailTemplate.value == emailType
    );
    let reasonForNoEmail: string = '';
    if (categorySelect && emailRequired) {
      if (this.isBulkCustomer) {
        reasonForNoEmail = `order has ${this.noEmailTagName} tag on it, so cancellation initial emails are not sent to the customer or Admin.`;
      } else if (this.noEmailTagName) {
        reasonForNoEmail = `order has ${this.noEmailTagName} tag on it, so cancellation initial emails are not sent to the customer.`;
      } else if (!emailType) {
        reasonForNoEmail = `cancellation reason ${categorySelect.label} does not send cancellation initial emails to the customer.`;
      } else if (!this.emailTemplateExists) {
        reasonForNoEmail = `no ${emailType} template exists for the brand. Cancelling reason selected was ${categorySelect.label}`;
        if (reasonSelect) {
          reasonForNoEmail = `${reasonForNoEmail} ${reasonSelect.label}`
        }
      }
    }
    this.cancellingForm.patchValue({
      'emailRequired': emailRequired,
      'emailType': emailType,
      'reasonForNoEmail': reasonForNoEmail,
    });

  }

  saveCancellingDetails(): void {
    if (this.closing) {
      return;
    }
    if (!cancellationCategoriesRequiringPrompt.includes(this.cancellationReason)) {
      this.closing = true;
      this.closeModal.emit(this.cancellingForm.value);
    } else {
      this.showConfirmationPopup('ARC Notification Email',
          `You are about to change the cancellation reason to ${this.cancellationReason}. Are you sure you want to make this change?`,
          () => {
            this.closing = true;
            this.closeModal.emit(this.cancellingForm.value)
          }
      );
    }
  }

  showConfirmationPopup(header: string, message: string, acceptCallback?: () => void, rejectCallback?: () => void,
      isRejectLabelVisible: boolean = true) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      'key': 'aboveBlockMessage',
      'header': header,
      'message': message,
      'rejectVisible': isRejectLabelVisible,
      'acceptLabel': isRejectLabelVisible? 'Yes': 'OK',
      'rejectLabel': 'No',
      'accept': () => {
        acceptClicked = true;
        if (acceptCallback) {
          acceptCallback();
        }
      },
      'reject': () => {
        if (rejectCallback && !acceptClicked) {
          rejectCallback();
        }
      }
    });
  }

  changeNoteTemplate(event: DropDownChangeEvent<NoteTemplate>) {
    if (event.value) {
      this.cancellingForm.patchValue({
        'note': event.value.value,
        'noteCategories': event.value.noteCategories,
      });
    }
  }

  isOkToSave(): boolean {
    return this.cancellingForm.valid && !this.saveDisabled;
  }

  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');
  }

  closeDialog(): void {
    if (!this.closing) {
      this.closing = true;
      this.closeModal.emit();
    }
  }

}
