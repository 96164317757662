import {FaultyEquipmentService} from './../faulty-equipment.service';
import {Component, EventEmitter, Output, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {getAlarmsBrands, getCableBrands, getAccessoryBrands, equipmentCategories, getAlarmFaults, getAccessoryFaults, getCableFaults}
  from '../../../lookups/faultyEquipment';
import {SingleRecordResponse} from '../../../models/responses/singleRecordResponse.model';
import {FaultyEquipment} from '../../../models/faultyEquipment.model';
import {AddFaultyEquipmentRequest} from '../../../models/requests/addFaultyEquipmentRequest.model';
@Component({
  selector: 'app-add-equipment-fault[equipDetails][orderId][closeDialog]',
  templateUrl: './add-equipment-fault.component.html',
  styleUrls: ['./add-equipment-fault.component.scss'],
  providers: [],
})
export class AddEquipmentFaultComponent implements OnInit {
  
  display: boolean = true;
  // TODO remove for hardware version
  @Input() equipDetails: {'serialNumber': string, 'name': string, 'status': string, 'isPendant': boolean};
  // TODO add for hardware version
  // @Input() equipDetails: {'serialNumber': string, 'name': string, 'status': string};
  @Input() orderId: string;
  @Output() closeDialog = new EventEmitter<{ status: string, noteText?: string }>();
  equipmentCategories: SelectItem<string>[];
  selectedEquipmentCategory: string;
  equipmentBrandOptions: SelectItem<string>[];
  selectedEquipmentBrand: string;
  equipmentFaultOptions: SelectItem<string>[];
  selectedEquipmentFault: string;
  additionalNote: string;
  equipmentBrandByCategory: {[brand: string]: SelectItem<string>[]} = {};
  equipmentFaultByCategory: {[brand: string]: SelectItem<string>[]} = {};
  isSubmitDisabled: boolean = false;

  constructor (
    private faultyEquipmentService: FaultyEquipmentService,
  ) {
  }

  ngOnInit(): void {
    this.isSubmitDisabled = true;
    this.initializeVariables();
  }

  onSubmitFault() {
    this.isSubmitDisabled = true;
    const params: AddFaultyEquipmentRequest = {
      orderId: this.orderId,
      serial: this.equipDetails.serialNumber,
      additionalNote: this.additionalNote,
      reportingUser: localStorage.getItem('userName'),
      reportedAt: (new Date()).toISOString(),
      equipmentType: this.selectedEquipmentCategory,
      equipmentBrand: this.selectedEquipmentBrand,
      reportedFault: this.selectedEquipmentFault,
    }

    this.faultyEquipmentService.addFaultToEquipment(params).subscribe(
      (response: SingleRecordResponse<FaultyEquipment>) => {
        console.debug("Response on adding fault to equipment :: ", response);
        if (response.success && response.data) {
          const noteContent: string = `${params.reportingUser} reported a fault, ${params.reportedFault}, for ${params.equipmentBrand} ` + 
            `with serial: ${params.serial}. ${params.additionalNote? 'Adding additional information: ' + params.additionalNote: ''}`;
          this.closeDialog.emit({ status: 'success', noteText: noteContent });
        } else {
          this.closeDialog.emit({ status: 'failed' });
        }
      }, (err: Error) => {
        this.closeDialog.emit({ status: 'failed' });
      }
    );
  }

  onEquipmentCategoryChange() {
    this.selectedEquipmentBrand = null;
    this.selectedEquipmentFault = null;
    this.equipmentBrandOptions = this.equipmentBrandByCategory[this.selectedEquipmentCategory];
    this.equipmentFaultOptions = null;
  };

  onEquipmentBrandChange() {
    this.equipmentFaultOptions = [
      ...this.equipmentFaultByCategory[this.selectedEquipmentCategory]
    ];
  }
  
  initializeVariables() {
    this.equipmentBrandByCategory['Alarm'] = getAlarmsBrands();
    this.equipmentBrandByCategory['Pendant/Accessories'] = getAccessoryBrands();
    this.equipmentBrandByCategory['Cable'] = getCableBrands();
    this.equipmentFaultByCategory['Alarm'] = getAlarmFaults();
    this.equipmentFaultByCategory['Pendant/Accessories'] = getAccessoryFaults();
    this.equipmentFaultByCategory['Cable'] = getCableFaults();
    // TODO remove for hardware version
    if (this.equipDetails.isPendant) {
      this.selectedEquipmentCategory = 'Pendant/Accessories';
      this.onEquipmentCategoryChange();
    } else {
      this.equipmentCategories = equipmentCategories;
    }
    // TODO add for hardware version
    // this.equipmentCategories = equipmentCategories;
    this.isSubmitDisabled = false;
  }

  onCloseDialog() {
    this.closeDialog.emit({status:'closed'});
  }

  
}
