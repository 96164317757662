import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params} from '@angular/router';
import {DataService} from './data.service';
import {clearLocalStorage} from './helpers/helperFunctions';
import {CRM_CLIENT_VERSION} from './models/environment.model';
import {JwtPayload} from './models/jwtPayload.model';
import {UsersService} from './setup/users/users.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private usersService: UsersService,
    private data: DataService
  ) {}

  private tokenExpired(token: string): boolean {
    try {
      const parsedTokenPayload: JwtPayload = JSON.parse(atob(token.split('.')[1]));
      const expiry: number = parsedTokenPayload.exp;
      return (Math.floor((new Date()).getTime() / 1000) >= expiry);
    } catch (error) {
      // Token isn't in valid JWT format, treat as expired
      return true;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token: string = localStorage.getItem('token');
    let redirectToLogin: boolean = false;
    let errorMsg: string = '';
    if (token && !this.tokenExpired(token)) {
      const requiredPermission: string = route.data.permission as string;
      if (!!requiredPermission && !this.usersService.userHasPermission(requiredPermission)) {
        this.router.navigate(['/permission']);
        return false;
      }
      const parsedTokenPayload: JwtPayload = JSON.parse(atob(token.split('.')[1]));
      if (!parsedTokenPayload.crmClientMinVersion) {
        errorMsg = 'Updated CRM Server version, please log in again.';
        redirectToLogin = true;
      } else if (CRM_CLIENT_VERSION < parsedTokenPayload.crmClientMinVersion) {
        errorMsg = `CRM Client version too low. It should be at least ${parsedTokenPayload.crmClientMinVersion}, ` +
          'please refresh the browser to pick up the new version then log in.';
        redirectToLogin = true;
      }
    } else {
      errorMsg = 'You are not logged in, please log in to access the CRM.';
      redirectToLogin = true;
    }
    if (!redirectToLogin) {
      return true;
    }

    clearLocalStorage();
    const loginParams: Params = {
      'redirectURL': state.url,
    };
    this.router.navigate(['/login'], {queryParams: loginParams, skipLocationChange: true})
      .then((_success: boolean) => {
        this.data.error(errorMsg);
      });
    return false;
  }
}
