import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {CustomerFeedbackService} from '../../customer-feedback/customer-feedback.service';
import {isValidObjectId} from '../../helpers/helperFunctions';
import {getCustomerFeedbackStatuses, getCustomerFeedbackTypes} from '../../lookups/customerFeedback';
import {AddNoteParams} from '../../models/addNoteParams.model';
import {CustomerFeedback} from '../../models/customerFeedback.model';
import {Order} from '../../models/order.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {CustFeedbackLockData} from '../../models/socket-io/custFeedbackLockData.model';
import {CustomerFeedbackSocketService} from '../../sockets/customer-feedback-socket.service';
import {LocksSocketService} from '../../sockets/locks-socket.service';
import {PostOrderSection} from '../post-order-section';
import {getUserPreferenceValue, updateUserPreference} from '../../lookups/userPreference';
import {OrderIdPlanOnly} from '../../models/mongoose-populated-ids/orderIdPlanOnly.model';
import {OrderFormTag} from '../../models/orderFormTag.model';

@Component({
  selector: 'app-customer-feedback-section[pageSection][minimizeSectionMethod][orderId][order][tags][feedbackIdToEdit][addNoteFromSection][removeActionFromSection]',
  templateUrl: './customer-feedback-section.component.html',
  styleUrls: ['../post-order-sections.scss', './customer-feedback-section.component.scss']
})
export class CustomerFeedbackSectionComponent extends PostOrderSection implements OnInit, OnDestroy {
  // orderId is used to load feedback without having to wait for order to be loaded
  @Input() orderId: string;
  @Input() order: Order;
  @Input() tags: OrderFormTag[];
  @Input() feedbackIdToEdit: string;
  feedbackRecords: CustomerFeedback[];
  showAddFeedbackDialog: boolean;
  custFeedbackLockList: CustFeedbackLockData[] = [];
  tdCode: string;
  websiteId: string;
  feedbackTypes: SelectItem<string>[];
  feedbackStatuses: SelectItem<string>[];
  selectedTypes: string[];
  selectedStatuses: string[];

  constructor(
    private customerFeedbackService: CustomerFeedbackService,
    private customerFeedbackSocket: CustomerFeedbackSocketService,
    private locksSocket: LocksSocketService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
  ) {
    super(confirmationService, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.feedbackRecords = [];
    this.selectedTypes = getUserPreferenceValue<string[]>('Customer Feedback Type Filter');
    if (!this.selectedTypes) {
      this.selectedTypes = [];
    }
    this.selectedStatuses = getUserPreferenceValue<string[]>('Customer Feedback Status Filter');
    if (!this.selectedStatuses) {
      this.selectedStatuses = ['Open', 'Pending'];
    }
    this.showAddFeedbackDialog = false;
    this.feedbackTypes = getCustomerFeedbackTypes();
    this.feedbackStatuses = getCustomerFeedbackStatuses();
    this.loadCustomerFeedback();
    this.customerFeedbackSocket.on('updateCustFeedback', this.processUpdateFromSocketIo);
    this.customerFeedbackSocket.on('deletedCustFeedback', this.processFeedbackDeletion);
    this.locksSocket.on('custFeedbackLockList', (lockData: CustFeedbackLockData[]) => {
      this.custFeedbackLockList = lockData;
    });
    this.locksSocket.emit('getLockedCustFeedback');
  }

  changeStatusFilter(): void {
    updateUserPreference<string[]>('Customer Feedback Status Filter', this.selectedStatuses);
  }

  changeTypeFilter(): void {
    updateUserPreference<string[]>('Customer Feedback Type Filter', this.selectedTypes);
  }

  processUpdateFromSocketIo: (updatedCustFeedback: CustomerFeedback) => void = (updatedCustFeedback: CustomerFeedback) => {
    if ((updatedCustFeedback.orderId as OrderIdPlanOnly)._id != this.orderId) {
      return;
    }
    this.feedbackRecords = [...[updatedCustFeedback], ...this.feedbackRecords.filter((feedback: CustomerFeedback) => 
      feedback._id !== updatedCustFeedback._id
    )];
    this.feedbackRecords.sort((feedbackA: CustomerFeedback, feedbackB: CustomerFeedback) => 
      feedbackA.dateLogged.localeCompare(feedbackB.dateLogged)
    );
  }

  processFeedbackDeletion: (orderId: string, feedbackId: string) => void = (orderId: string, feedbackId: string) => {
    if (orderId != this.orderId) {
      return;
    }
    this.feedbackRecords = this.feedbackRecords.filter((feedback: CustomerFeedback) => 
      feedback._id !== feedbackId
    );
  }

  loadCustomerFeedback(): void {
    this.feedbackRecords = [];
    if (isValidObjectId(this.orderId)) {
      this.customerFeedbackService.getCustomerFeedbackForOrder(this.orderId)
        .subscribe((response: MultiRecordResponse<CustomerFeedback>) => {
          if (response.success) {
            this.feedbackRecords = response.data;
          } else {
            console.error('Error getting Customer Feedback', response.message);
          }
        });
    }
  }

  addFeedback(): void {
    this.showAddFeedbackDialog = true;
  }

  closeAddFeedback(addNoteParams: AddNoteParams): void {
    if (addNoteParams) {
      this.addNote(addNoteParams);
    }
    this.showAddFeedbackDialog = false;
  }

  editFeedback(feedbackId: string): void {
    this.feedbackIdToEdit = feedbackId;
  }

  closeEditFeedback(complaintClosed: boolean): void {
    this.feedbackIdToEdit = '';
    if (complaintClosed) {
      let openComplaintRemains: boolean = false;
      this.feedbackRecords.forEach((custFeedback: CustomerFeedback) => {
        if (('Complaint' == custFeedback.feedbackType) && ('Closed' != custFeedback.status)) {
          openComplaintRemains = true;
        }
      });
      if (!openComplaintRemains) {
        this.removeAction('Outstanding Complaint');
      }
    }
  }

  ngOnDestroy() {
    this.customerFeedbackSocket.removeAllListeners();
    this.locksSocket.removeAllListeners();
    super.ngOnDestroy();
  }

  isCustFeedbackLocked(custFeedbackId: string) {
    return this.custFeedbackLockList.filter((lockedCustFeedback: CustFeedbackLockData) => lockedCustFeedback.id == custFeedbackId).length > 0;
  }

  custFeedbackLockedBy(custFeedbackId: string) {
    return this.custFeedbackLockList.filter((lockedCustFeedback: CustFeedbackLockData) => lockedCustFeedback.id == custFeedbackId)[0].user;
  }

  isAddFeedbackDisabled(): boolean {
    return !this.order;
  }
}
