import { Pipe, PipeTransform } from '@angular/core';
import {HomepageFormatOrder} from '../models/homepageFormatOrder.model';

@Pipe({
  name: 'homepageNotesToField'
})
export class HomepageNotesToFieldPipe implements PipeTransform {

  transform(order: HomepageFormatOrder, fieldName: string): string {
    if (!order.notesUserName || (order.notesUserName.length == 0)) {
      return '';
    }
    if (fieldName == 'notesWithDate') {
      return order.notesUserName.map((noteUser: string, noteIdx: number) => {
        return `${noteUser? noteUser + ': ' : ''} ${order.noteDateFormatted[noteIdx]} [${order.noteCategory[noteIdx]}] ${order.notesContent[noteIdx]}`
      }).join('|');
    }
    return order[fieldName].join(' | ');
  }

}
