import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {numberOnly} from '../helpers/keyboardHelpers';
import {AddNoteParams} from '../models/addNoteParams.model';
import {PageSection} from '../models/page-section.model';

@Component({
  'template': '',
})
export class PostOrderSection implements OnInit, OnDestroy {
  @Input() pageSection: PageSection;
  @Output() minimizeSectionMethod: EventEmitter<PageSection> = new EventEmitter<PageSection>();
  @Output() addNoteFromSection: EventEmitter<AddNoteParams> = new EventEmitter<AddNoteParams>();
  @Output() removeActionFromSection: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveOrderFromSection: EventEmitter<string> = new EventEmitter<string>();
  private userName: string;
  numberOnly = numberOnly;

  constructor(
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
  ) {

  }

  ngOnInit(): void {
    this.userName = localStorage.getItem('userName');
  }

  ngOnDestroy(): void {
    if (this.minimizeSectionMethod) {
      this.minimizeSectionMethod.complete();
    }
    if (this.addNoteFromSection) {
      this.addNoteFromSection.complete();
    }
    if (this.removeActionFromSection) {
      this.removeActionFromSection.complete();
    }
  }
  
  get username(): string {
    return this.userName;
  }

  minimizeSection(pageSection: PageSection) {
    this.minimizeSectionMethod.emit(pageSection);
  }

  addNote(params: AddNoteParams) {
    if (this.addNoteFromSection) {
      this.addNoteFromSection.emit(params);
    }
  }

  saveOrder() {
    if (this.saveOrderFromSection) {
      this.saveOrderFromSection.emit('');
    }
  }

  removeAction(actionName: string) {
    if (this.removeActionFromSection) {
      this.removeActionFromSection.emit(actionName);
    }
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('aboveBlockMessage', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  showConfirmationPopup(header: string, message: string, acceptCallback?: () => void, rejectCallback?: () => void,
      isRejectLabelVisisble: boolean = true) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      'key': 'general',
      'header': header,
      'message': message,
      'rejectVisible': isRejectLabelVisisble,
      accept: () => {
        acceptClicked = true;
        if (acceptCallback) {
          acceptCallback();
        }
      },
      reject: () => {
        if (rejectCallback && !acceptClicked) {
          rejectCallback();
        }
      }
    });
  }

  showSuccess(summary: string = 'Update Success!', message: string = 'Changes Successfully Applied') {
    this.messageService.add({
      'severity': 'success',
      'life': 1000,
      'summary': summary,
      'detail': message,
    });
  }
}
