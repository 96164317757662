import {Pipe, PipeTransform} from '@angular/core';
import {CustomerFeedback} from '../models/customerFeedback.model';

@Pipe({
  name: 'customerFeedbackFilter'
})
export class CustomerFeedbackFilterPipe implements PipeTransform {

  transform(feedback: CustomerFeedback[], types: string[], statuses: string[]): CustomerFeedback[] {
    if (!feedback) {
      return [];
    }
    if ((statuses.length == 0) && (types.length == 0)) {
      return feedback;
    }
    feedback = feedback.filter((feedback: CustomerFeedback) => {
      if ((statuses.length > 0) && !statuses.includes(feedback.status)) {
        return false;
      }
      if ((types.length > 0) && !types.includes(feedback.feedbackType)) {
        return false;
      }
      return true;
    })
    return feedback;
  }

}
