import { Pipe, PipeTransform } from '@angular/core';
import {SelectItem} from 'primeng/api';
import {getBrandsOrTypesForPlanCode} from '../lookups/hardwareBrandsOrTypes';

@Pipe({
  name: 'planCodeTypes'
})
export class PlanCodeTypesPipe implements PipeTransform {

  transform(planCode: string, activateAll: boolean): SelectItem<string>[] {
    const brandsAndTypes: SelectItem<string>[] = getBrandsOrTypesForPlanCode(planCode);
    const brandsAndTypesActivated : SelectItem<string>[] = [];
    if (!activateAll) {
      return brandsAndTypes;
    }
    brandsAndTypes.forEach((selectItem:  SelectItem<string>) => {
      brandsAndTypesActivated.push({
        'label': selectItem.label,
        'value': selectItem.value,
      });
    });
    return brandsAndTypesActivated;
  }

}
