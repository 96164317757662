import {SelectItem} from 'primeng/api';

interface NoteCategory extends SelectItem<string> {
  color?: string
}

let noteCategories: NoteCategory[];

function getNoteCategories(userAddableNoteCategories: boolean): NoteCategory[] {
  if (!noteCategories) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('notes: categories')) {
      return [];
    }
    noteCategories = JSON.parse(localStorage.getItem('notes: categories'));
  }
  if (userAddableNoteCategories) {
    return noteCategories.filter((category) => 
      !['', 'CRM Automation'].includes(category.label)
    );
  }
  return noteCategories;
}

export {
  NoteCategory,
  getNoteCategories
}