<div class="row mx-0 my-2 messageDetails">
  <div class="col-6 col-lg-2 offset-0 offset-lg-1">
    <div>
      Order Status:
      <span class="statusBlock" [style.background-color]="getStatusBackgroundColour(order.status.status)" 
          [style.color]="getStatusColour(order.status.status)">
        {{order.status.status}}
      </span>
    </div>
  </div>
  <div class="col-6 col-lg-3">
    Order Date: {{order.created | date:'dd/MM/y'}}
  </div>
  <div class="col-6 col-lg-2">
    Plan: {{order.accountDetails.plan}}
  </div>
  <div class="col-6 col-lg-4">
    Derived Plan for Costs: {{message.cancellationInfo.derivedPlan}}
  </div>
</div>
<div class="row mx-0 my-2 messageDetails">
  <div class="col-6 col-lg-5 offset-0 offset-lg-1">
    Pendant Serials:<br/>
    <ng-container *ngFor="let plan of order.plans">
      <ng-container *ngIf="plan.serialPendant != ''">{{plan.serialPendant}}<br/></ng-container>
    </ng-container>
  </div>
  <div class="col-6 col-lg-6">
    Additional Equipment Serials:<br/>
    <ng-container *ngFor="let equip of order.additionalEquipment">
      <ng-container *ngIf="equip.serial != ''">{{equip.serial}}<br/></ng-container>
    </ng-container>
  </div>
</div>
<form [formGroup]="messageForm">
  <div class="row mx-0 my-2 messageDetails" formGroupName="invoice">
    <div class="col-6 col-lg-2 offset-0 offset-lg-1">
      <label for="equipName">Equipment Name</label>
      <input type="text" class="form-control" name="equipName" id="equipName" formControlName="equipName" required />
    </div>
    <div class="col-6 col-lg-3">
      <label for="unitCost">Unit Cost</label>
      <input type="text" class="form-control" name="unitCost" id="unitCost" formControlName="unitCost" required
        (keypress)="numberOnly($event, true)" />
    </div>
    <div class="col-6 col-lg-3">
      <label for="vatCost">VAT Cost</label>
      <input type="text" class="form-control" name="vatCost" id="vatCost" formControlName="vatCost" required
        (keypress)="numberOnly($event, true)" />
    </div>
    <div class="col-6 col-lg-3">
      <label for="equipCost">Equipment Cost</label>
      <input type="text" class="form-control" name="equipCost" id="equipCost" formControlName="equipCost" required
        (keypress)="numberOnly($event, true)" />
    </div>
  </div>
</form>
