import {FormArray, Validators} from '@angular/forms';
import {FormGroup} from '@angular/forms';
import {AbstractControl} from '@angular/forms';
import {FormBuilder} from '@angular/forms';
import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {LeadsService} from '../leads.service';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {Lead, LeadCallNote, LeadComment} from '../../models/lead.model';
import * as moment from 'moment-timezone';
import {CALL_DATE_SHIFTS, LEAD_STATUSES, LeadType, getLeadNoCallbackOptions, getLeadTypes} from '../../lookups/leads';
import {LocksSocketService} from '../../sockets/locks-socket.service';
import {PostSheet} from '../../models/postSheet.model';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {LeadLockData} from '../../models/socket-io/leadLockData.model';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';
import { NoteTemplate, getNoteTemplates } from '../../lookups/noteTemplate';
import { DomHandler } from 'primeng/dom';

import {Router} from '@angular/router';
@Component({
  selector: 'app-update-lead[leadId][closeModal]',
  templateUrl: './update-lead.component.html',
  styleUrls: ['./update-lead.component.scss'],
  providers: [ConfirmationService]
})
export class UpdateLeadComponent implements OnInit, OnDestroy {

  constructor(
    private leadsService: LeadsService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private locksSocket: LocksSocketService,
    private router: Router,
  ) { }

  @Input() leadId: string = null;
  @Output() closeModal = new EventEmitter<SingleRecordResponse<Lead>>()
  loading: boolean = true;
  displayModal: boolean = true;
  leadDetail: Lead;
  callDateType: string;
  documentBlocked: boolean = false;
  socketId: string;
  user: string;
  leadEditingBy: LeadLockData;
  closing: boolean = false;
  isSubmitDisabled: boolean = false;
  todayDate: Date = moment.tz('Europe/London').startOf('day').toDate();
  brands: SelectItem<string>[] = getBrandSelectItems();
  brandConfigs: BrandCfg = getBrandConfigs();
  callDateShifts: SelectItem<string>[] = CALL_DATE_SHIFTS;
  leadDeclinedOptions:  SelectItem<string>[] = getLeadNoCallbackOptions();
  leadTypes: LeadType[];
  leadForm: FormGroup;
  showPostSheetAdd: boolean = false;
  addCallNoteForm: FormGroup = this.formBuilder.group({
    content: [''],
  });
  addCommentForm: FormGroup = this.formBuilder.group({
    comment: [''],
  });

  displayContactAttempt: boolean = false;
  contactOptions: SelectItem<string>[] = [
    {
      label: 'Successful Contact - Order placed',
      value: 'Successful Contact - Order placed',
    },
    {
      label: 'Decision Pending - Callback required',
      value:'Decision Pending - Callback required',
    },
    {
      label: 'Declined - No Callback Required',
      value: 'Declined - No Callback Required',
    },
    {
      label: 'Unsuccessful Contact Attempt',
      value: 'Unsuccessful Contact Attempt',
    },
    
  ];
  contactAttemptForm: FormGroup;
  phoneNumbersForSelect: SelectItem<string>[] = [];
  phoneNumberList: SelectItem<string>[] = [];
  isDateChange: boolean = false;
  placeholderForContactAttemptOption: string = "";
  isSubmitButtonDisabled: boolean = false;
  noteTemplates: NoteTemplate[];
  whoReferredOptions: SelectItem[] = [
    {label:'Self', value: 'Self'},  
    {label:'Third Party', value: 'Third Party'},  
    {label:'Professional', value: 'Professional'},  
  ];
  statusSelects: SelectItem<string>[] = LEAD_STATUSES;
  
  numberOnly = numberOnly;

  ngOnInit(): void {
    this.closing = false;
    this.user = localStorage.getItem('userName');
    this.leadDeclinedOptions;
    this.leadTypes = getLeadTypes();
    this.locksSocket.emit('lockingLead', {
      leadId: this.leadId,
      user: this.user
    }, (addedLead: LeadLockData) => {
      if (addedLead.added) {
        this.documentBlocked = false;
        this.socketId = addedLead.socketId;
        this.leadEditingBy = addedLead;
      } else {
        this.documentBlocked = true;
        this.leadEditingBy = addedLead;
        this.socketId = '';
      }
      this.getLead(this.leadId);
    });
    this.contactAttemptForm = this.formBuilder.group({
      'selectedContactOption': [null, Validators.required],
      'isManualDateSelected': [false],
      'selectedNumber': [null],
      'additionalNote': [''],
      'isMessageLeftOnUnsuccessfulAttempt': [false],
      'manualContactDate': [null],
      'leadDeclinedReason': [''],
      'leadDeclinedReason2': [''],
    });     

    this.locksSocket.on('lockedLead', this.processLeadLock);
    this.locksSocket.on('leadLockList', this.processLeadLockList);
    this.locksSocket.emit('getLockedLead');
    this.noteTemplates = getNoteTemplates();
  }

  processLeadLock: (lockedLead: LeadLockData) => void = (leadLock: LeadLockData) => {
    if (leadLock.socketId === this.socketId) {
      this.leadEditingBy = leadLock;
      this.documentBlocked = true;
    }
  };

  processLeadLockList: (leadLockList: LeadLockData[]) => void = (leadLockList: LeadLockData[]) => {
    if (this.closing) {
      return;
    }
    if (this.documentBlocked && leadLockList.filter((e: LeadLockData) => e.leadId == this.leadId).length == 0) {
      this.locksSocket.emit('lockingLead', { leadId: this.leadId, user: this.user },
        (currentLead: LeadLockData) => {
          if (currentLead.added) {
            this.socketId == currentLead.socketId;
            this.getLead(this.leadId);
            this.documentBlocked = false;
          }
        });
    }
  };

  getLead(leadId: string): void {
    this.leadsService.getLead(leadId).subscribe((response: SingleRecordResponse<Lead>) => {
      this.leadDetail = response.data;
      if (this.leadDetail.callDateShift == 'Specific Time') {
        this.callDateType = 'datetime-local';
        this.leadDetail.callDate = moment.tz(this.leadDetail.callDate,'Europe/London').format('YYYY-MM-DDTHH:mm');
      } else {
        this.callDateType = 'date';
        if (this.leadDetail.callDate) {
          this.leadDetail.callDate = moment.tz(this.leadDetail.callDate,'Europe/London').format('YYYY-MM-DD');
        }
      }
      if (this.leadDetail.alarmUserDetails) {
        this.addPhoneNumberToSelection(this.leadDetail.alarmUserDetails.telephone,
          `${this.leadDetail.alarmUserDetails.firstName} ${this.leadDetail.alarmUserDetails.lastName}`);
        this.addPhoneNumberToSelection(this.leadDetail.alarmUserDetails.mobile,
          `${this.leadDetail.alarmUserDetails.firstName} ${this.leadDetail.alarmUserDetails.lastName}`);
      }
      if (this.leadDetail.referrerDetails) {
        this.addPhoneNumberToSelection(this.leadDetail.referrerDetails.telephone,
          `${this.leadDetail.referrerDetails.firstName} ${this.leadDetail.referrerDetails.lastName}`);
        this.addPhoneNumberToSelection(this.leadDetail.referrerDetails.mobile,
          `${this.leadDetail.referrerDetails.firstName} ${this.leadDetail.referrerDetails.lastName}`);
      }
      if (this.leadDetail.salesContactDetails) {
        this.addPhoneNumberToSelection(this.leadDetail.salesContactDetails.telephone,
          `${this.leadDetail.salesContactDetails.firstName} ${this.leadDetail.salesContactDetails.lastName}`);
        this.addPhoneNumberToSelection(this.leadDetail.salesContactDetails.mobile,
          `${this.leadDetail.salesContactDetails.firstName} ${this.leadDetail.salesContactDetails.lastName}`);
      }
  
      this.addPhoneNumberToSelection('Email','');
      this.addPhoneNumberToSelection('Other','');
      
      this.initializeForm();
      this.loading = false;
    }, (err: Error) => {
      console.log("Error while getting lead by id :: ", err.message);
      this.showErrorPopUp('Error getting lead', `Error getting lead. Error ${err.message}`);
    })
  }

  initializeForm(): void {
    this.leadForm = this.formBuilder.group({
      _id: [this.leadDetail._id],
      leadType: [this.leadDetail.leadType],
      whoReferred: [this.leadDetail.whoReferred, [Validators.required]],
      brand: [this.leadDetail.brand],
      referralDate: [(this.leadDetail.referralDate ?
        moment.tz(this.leadDetail.referralDate, 'Europe/London').format('YYYY-MM-DD') :
        moment.tz('Europe/London').format('YYYY-MM-DD')),
      ],
      alarmUserDetails: this.formBuilder.group({
        firstName: [this.leadDetail.alarmUserDetails?.firstName],
        otherName: [this.leadDetail.alarmUserDetails?.otherName],
        lastName: [this.leadDetail.alarmUserDetails?.lastName],
        preferredName: [this.leadDetail.alarmUserDetails?.preferredName],
        email: [this.leadDetail.alarmUserDetails?.email],
        telephone: [this.leadDetail.alarmUserDetails?.telephone],
        mobile: [this.leadDetail.alarmUserDetails?.mobile],
        salesContact: [this.leadDetail.alarmUserDetails?.salesContact],
        userAddress: this.leadDetail.alarmUserDetails.userAddress,
      }),
      referrerDetails: this.formBuilder.group({
        firstName: [this.leadDetail.referrerDetails?.firstName],
        otherName: [this.leadDetail.referrerDetails?.otherName],
        lastName: [this.leadDetail.referrerDetails?.lastName],
        email: [this.leadDetail.referrerDetails?.email,],
        relationship: [this.leadDetail.referrerDetails?.relationship],
        telephone: [this.leadDetail.referrerDetails?.telephone],
        mobile: [this.leadDetail.referrerDetails?.mobile],
        salesContact: [this.leadDetail.referrerDetails?.salesContact],
        accountContactForCRM: [this.leadDetail.referrerDetails?.accountContactForCRM],
        userAwareOfReferral: [this.leadDetail.referrerDetails?.userAwareOfReferral],
        referredBy: [this.leadDetail.referrerDetails?.referredBy]
      }),

      salesContactDetails: this.formBuilder.group({
        firstName: [this.leadDetail.salesContactDetails? this.leadDetail.salesContactDetails.firstName: ''],
        otherName: [this.leadDetail.salesContactDetails? this.leadDetail.salesContactDetails.otherName: ''],
        lastName: [this.leadDetail.salesContactDetails? this.leadDetail.salesContactDetails.lastName: ''],
        email: [this.leadDetail.salesContactDetails? this.leadDetail.salesContactDetails.email: ''],
        relationship: [this.leadDetail.salesContactDetails? this.leadDetail.salesContactDetails.relationship: ''],
        telephone: [this.leadDetail.salesContactDetails? this.leadDetail.salesContactDetails.telephone: ''],
        mobile: [this.leadDetail.salesContactDetails? this.leadDetail.salesContactDetails.mobile: ''],
        accountContactForCRM: [this.leadDetail.salesContactDetails? this.leadDetail.salesContactDetails.accountContactForCRM: ''],
      }),
      askedForInfoPack: [this.leadDetail.askedForInfoPack],
      status: [this.leadDetail.status],
      callDate: [this.leadDetail.callDate],
      callDateShift: [this.leadDetail.callDateShift],
      leadDeclinedReason: [{value: this.leadDetail.leadDeclinedReason, disabled: false}],
      leadDeclinedReason2: [{value: this.leadDetail.leadDeclinedReason2, disabled: false}],
      callNotes: this.addCallNotes(),
      comments: this.addComments(),
      paymentRef: this.leadDetail.paymentRef,
    });
    this.statusChange();
    this.changeLeadType();
  }

  addCallNotes(): FormArray {
    return this.formBuilder.array(
      this.leadDetail.callNotes.map((note: LeadCallNote) => {
        return this.formBuilder.group({
          _id: [note._id],
          content: [note.content],
          createdBy: [note.createdBy],
          createdAt: [note.createdAt],
        });
      })
    );
  }
  
  addComments(): FormArray {
    return this.formBuilder.array(
      this.leadDetail.comments.map((note: LeadComment) => {
        return this.formBuilder.group({
          _id: [note._id],
          comments: [note.comments],
          createdBy: [note.createdBy],
          createdAt: [note.createdAt],
        });
      })
    );
  }

  getUpdateLeadParams(): Lead {
    const params: Lead = this.leadForm.value;
    if (this.addCallNoteForm.value.content) {
      params.callNotes.push({
        content: this.addCallNoteForm.value.content,
        createdBy: localStorage.getItem('userName'),
        createdAt: moment.tz('Europe/London').toISOString(),
      });
    }

    if (this.addCommentForm.value.comments) {
      params.comments.push({
        comments: this.addCommentForm.value.comment,
        createdBy: localStorage.getItem('userName'),
        createdAt: moment.tz('Europe/London').toISOString(),
      });
    }
    
    if (!this.salesContactDetails.enabled) {
      params['salesContactDetails'] = null;
    }

    if ((this.status.value == 'Declined') && (this.leadDeclinedReason.value != 'Other')) {
      params.leadDeclinedReason2 = '';
    }
    return params;
  }

  submit(): void {
    this.isSubmitDisabled = true;
    const params: Lead = this.getUpdateLeadParams();
    this.leadsService.updateLead(params).subscribe((response: SimpleResponse) => {
      this.isSubmitDisabled = false;
      this.closeModal.emit(response);
    }, (err: Error) => {
      this.isSubmitDisabled = false;
      this.closeModal.emit({ 
        'success': false,
        'message': `Error updating lead. Error: ${err.message}`
      });
    });
  }

  changeLeadType() {
    if (!this.whoReferredRequired) {
      this.whoReferred.disable();
    } else {
      this.whoReferred.enable();
    }
    if (!this.isProfessionalReferral) {
      this.salesContactDetails.clearValidators();
      this.salesContactDetails.disable();
      this.askedForInfoPack.setValue('Yes');
    } else {
      this.salesContactDetails.enable();
    }
  }

  shiftChange(event: DropDownChangeEvent<string>): void {
    if (event.value == 'Specific Time') {
      this.callDateType = 'datetime-local';
    } else {
      this.callDateType = 'date';
    }
    this.leadForm.get('callDate').setValue(null);
    
  }

  get leadDeclinedReason(): AbstractControl {
    return this.leadForm.get('leadDeclinedReason');
  }

  get leadDeclinedReason2(): AbstractControl {
    return this.leadForm.get('leadDeclinedReason2');
  }

  get callDate(): AbstractControl {
    return this.leadForm.get('callDate');
  }

  get callDateShift(): AbstractControl {
    return this.leadForm.get('callDateShift');
  }

  get askedForInfoPack(): AbstractControl {
    return this.leadForm.get('askedForInfoPack');
  }

  get status(): AbstractControl {
    return this.leadForm.get('status');
  }

  get callNotes(): AbstractControl {
    return this.leadForm.get('callNotes');
  }
  get comments(): AbstractControl {
    return this.leadForm.get('comments');
  }
  // get comments(): AbstractControl {
  //   return this.leadForm.get('comments');
  // }

  get alarmUserDetails(): AbstractControl {
    return this.leadForm.get('alarmUserDetails');
  }

  get referrerDetails(): AbstractControl {
    return this.leadForm.get('referrerDetails');
  }

  get salesContactDetails(): AbstractControl {
    return this.leadForm.get('salesContactDetails');
  }

  get selectedBrand(): AbstractControl {
    return this.leadForm.get('brand');
  }

  get callNotesForms(): FormArray {
    return this.leadForm.get('callNotes') as FormArray;
  }
  
  get commentForms(): FormArray {
    return this.leadForm.get('comments') as FormArray;
  }

  get selectedLeadType(): AbstractControl {
    return this.leadForm.get('leadType');
  }

  get isFormInvalid(): boolean {
    return this.addCallNoteForm.invalid || this.leadForm.invalid || this.isSubmitDisabled || this.addCommentForm.invalid;
  }


  get selectedContactOption(): AbstractControl {
    return this.contactAttemptForm.get('selectedContactOption');
  }

  get selectedNumber(): AbstractControl {
    return this.contactAttemptForm.get('selectedNumber');
  }

  get reasonToChangeDateManually(): AbstractControl {
    return this.contactAttemptForm.get('reasonToChangeDateManually');
  }
  
  get isManualDateSelected(): AbstractControl {
    return this.contactAttemptForm.get('isManualDateSelected');
  }

  get manualContactDate(): AbstractControl {
    return this.contactAttemptForm.get('manualContactDate');
  }

  get isProfessionalReferral(): boolean {
    return (this.selectedLeadType.value && this.selectedLeadType.value.includes('Professional'));
  }

  get whoReferred(): AbstractControl {
    return this.leadForm.get('whoReferred');
  }

  get whoReferredRequired(): boolean {
    if (!this.selectedLeadType.value) {
      return false
    }
    const leadType: LeadType|undefined = this.leadTypes.find((leadtype: LeadType) => this.selectedLeadType.value == leadtype.value);
    if (!leadType) {
      return false;
    }
    return leadType.whoReferredRequired;
  }

  closeDialog(): void {
    if (this.leadForm.dirty && !this.isFormInvalid) {
      this.confirmationService.confirm({
        message: `You have unsaved changes. Do you want to save?`,
        header: 'Warning',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.submit()
        },
        reject: () => {
          this.closeModal.emit({
            'success': true,
            'message': 'dialog closed'
          });
          this.closing = true;
          if (!this.documentBlocked) {
            this.documentBlocked = true;
            this.locksSocket.emit('unlockingLead', { 'leadId': this.leadId, 'user': this.user });
          }
          this.displayModal = false;
        }
      })
      return;
    }
    this.closeModal.emit({
      'success': true,
      'message': 'dialog closed'
    });
    this.closing = true;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingLead', { 'leadId': this.leadId, 'user': this.user });
    }
    this.displayModal = false;
  }

  unlock(): void {
    this.confirmationService.confirm({
      message: `${this.leadEditingBy.user} is curently editing this lead, do you want to have full access permission? Warning: any changes made by ${this.leadEditingBy.user} will be lost`,
      header: 'Warning',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.locksSocket.emit('unlockingLead-F', { leadId: this.leadId, user: this.user }, (data: LeadLockData) => {
          if (data.added) {
            this.socketId = data.socketId;
            this.getLead(this.leadId);
            this.documentBlocked = false;
          }
        })
      },
      reject: () => {
        
      }
    });
  }

  ngOnDestroy(): void {
    // Don't want to remove all listeners here as the parent component is listening on this socket too
    this.locksSocket.removeListener('lockedLead', this.processLeadLock);
    this.locksSocket.removeListener('leadLockList', this.processLeadLockList);
    this.closing = true;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingLead', { 'leadId': this.leadId, 'user': this.user });
    }
  }

  close(): void {
    this.locksSocket.removeAllListeners();
    this.closing = true;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingLead', { 'leadId': this.leadId, 'user': this.user });
    }
  }

  showAddPost() {
    this.showPostSheetAdd = true;
  }

  closeAddPostSheet(event: PostSheet|string|undefined) {
    if (typeof event == 'string') {
      this.showErrorPopUp('Error adding Post Sheet entry.', `Error saving new post sheet entry. Error ${event}`);
    }
    this.showPostSheetAdd = false;
  }

  showContactAttempt() {
    this.contactAttemptForm = this.formBuilder.group({
      'selectedContactOption': [null, Validators.required],
      'isManualDateSelected': [false],
      'selectedNumber': [null],
      'additionalNote': [''],
      'isMessageLeftOnUnsuccessfulAttempt': [false],
      'manualContactDate': [null],
      'leadDeclinedReason': [''],
      'leadDeclinedReason2': [''],
    });
    this.displayContactAttempt = true;
  }
  addPhoneNumberToSelection(phoneNumber: string, name: string) {
    if (!phoneNumber) {
      return;
    }
    if (!this.phoneNumberList.some(value => (value.value === phoneNumber))) {
      this.phoneNumberList.push({
        'label': name? `${name}: ${phoneNumber}`: phoneNumber,
        'value': phoneNumber,
      });
    }
  }

  changeNoteTemplate(event: DropDownChangeEvent<NoteTemplate>) {
    if (event.value) {
      this.contactAttemptForm.patchValue({
        additionalNote: event.value.value,
      });
    }
  }

  selectedNumberChange() {
    if (this.selectedNumber.value) {
    this.leadForm.updateValueAndValidity();
    }
  }

  contactOptionChange() {
    if (this.selectedContactOption.value) {
      if (this.selectedContactOption.value == "Unsuccessful Contact Attempt") {
        this.phoneNumbersForSelect = this.phoneNumberList.filter(phoneNumber => phoneNumber.label != 'Other');
        this.placeholderForContactAttemptOption = "Which number were you unsuccessful on?";
        this.selectedNumber.setValidators(Validators.required);
      } else {
        this.phoneNumbersForSelect = [...this.phoneNumberList];
        this.placeholderForContactAttemptOption = "Which number did the customer answer on?"
        this.selectedNumber.setValidators(Validators.required);
      }
      // Have to do this to re-evalutate valid status
      this.selectedNumber.updateValueAndValidity();
      // this.reasonToChangeDateManually.updateValueAndValidity();
    }
  }

  closeContactAttempt(contactFormData): void {
    let contactAttemptValue: string;
    let currentStatus: string = this.leadForm.value.status;
    if (contactFormData.selectedContactOption == 'Successful Contact - Order placed') {
      this.leadForm.patchValue({status: 'Already Ordered'});
      contactAttemptValue = `${contactFormData.selectedContactOption}\nSelected Contact: ${contactFormData.selectedNumber}`;
    } else if (contactFormData.selectedContactOption == 'Decision Pending - Callback required') {
      contactAttemptValue = `${contactFormData.selectedContactOption}\nSelected Contact: ${contactFormData.selectedNumber}`;
      if (this.isManualDateSelected.value == false) {
        const startOfTwoDaysTime: moment.Moment = moment.tz('Europe/London').add(2, 'day').startOf('day');
        if (this.leadForm.value.callDateShift == 'Specific Time') {
          const existingCallMoment: moment.Moment = moment.tz(this.leadForm.value.callDate, 'Europe/London');
          existingCallMoment.set('year', startOfTwoDaysTime.year());
          existingCallMoment.set('month', startOfTwoDaysTime.month());
          existingCallMoment.set('date', startOfTwoDaysTime.date());
          this.leadForm.patchValue({
            callDate: existingCallMoment.format('YYYY-MM-DDTHH:mm'),
            callDateShift: 'Specific Time',
            status: (['Active', 'Unable to Contact'].includes(currentStatus))? 'Considering': currentStatus,
          });
        } else {
          let hoursToAdd: number = 0;
          switch (this.leadForm.value.callDateShift) {
            case 'Morning':
              hoursToAdd = 9;
              break;
            case 'Afternoon':
              hoursToAdd = 12;
              break;
            case 'Evening':
              hoursToAdd = 17;
              break;
            default:
              break;
          }
          this.leadForm.patchValue({
            callDate: startOfTwoDaysTime.add(hoursToAdd, 'hours').format('YYYY-MM-DDTHH:mm'),
            status: (['Active', 'Unable to Contact'].includes(currentStatus))? 'Considering': currentStatus,
          });
        }
      } else {
        this.leadForm.patchValue({
          callDate: contactFormData.manualContactDate,
          callDateShift: 'Specific Time',
          status: (['Active', 'Unable to Contact'].includes(currentStatus))? 'Considering': currentStatus,
        });
      }
    } else if (contactFormData.selectedContactOption == 'Declined - No Callback Required') {
      this.leadForm.patchValue({
        status: 'Declined', 
        leadDeclinedReason: contactFormData.leadDeclinedReason, 
        leadDeclinedReason2: contactFormData.leadDeclinedReason2,
      });
      contactAttemptValue =
        `${contactFormData.selectedContactOption}\nSelected Contact: ${contactFormData.selectedNumber}\n${contactFormData.leadDeclinedReason}\n${contactFormData.leadDeclinedReason2}`;
      if (contactFormData.leadDeclinedReason == 'Other') {
        contactAttemptValue =
          `${contactFormData.selectedContactOption}\nSelected Contact: ${contactFormData.selectedNumber}\n${contactFormData.leadDeclinedReason} ${contactFormData.leadDeclinedReason2}`;
      }
    } else if (contactFormData.selectedContactOption == 'Unsuccessful Contact Attempt') {
      const callbackDate: moment.Moment = moment.tz('Europe/London').add(1, 'day');
      this.leadForm.patchValue({
        callDate: callbackDate.format('YYYY-MM-DDTHH:mm'),
        status: (currentStatus == 'Active')? 'Unable to Contact': currentStatus,
      });
      contactAttemptValue =
        `${contactFormData.selectedContactOption}\nSelected Contact: ${contactFormData.selectedNumber}\nCallback pushed back till ${callbackDate.format('DD/MM/YYYY HH:mm')}`;
    };
    if (contactFormData.additionalNote != '') {
      contactAttemptValue = `${contactAttemptValue}\n${contactFormData.additionalNote}`;
    }
    if (contactFormData.isMessageLeftOnUnsuccessfulAttempt) {
      contactAttemptValue = `${contactAttemptValue}\nMessage left with customer.`;
    }
    this.statusChange();
    const notesType: FormGroup = this.formBuilder.group({
      content: contactAttemptValue,
      createdBy: localStorage.getItem('userName'),
      createdAt: moment.tz('Europe/London').toDate(),
    });
    this.callNotesForms.push(notesType);
  }

  submitData() {
    this.isSubmitButtonDisabled = true;
    this.callDateType = 'datetime-local';
    setTimeout(() => {
      this.closeContactAttempt(this.contactAttemptForm.value);
    });
    this.displayContactAttempt = false;
    this.isSubmitButtonDisabled = false;
  }

  getPaymentRef(): void {
    this.leadsService.getNextNumber('Leads Payment Ref').subscribe((refResponse: SingleRecordResponse<number>) => {
      if (!refResponse.success || !refResponse.data) {
        this.confirmationService.confirm({
          'header': 'Error Getting Payment Ref',
          'message':
            `Error getting unique reference for payment, click the Get Ref button to try again. Reason: ${refResponse.message}`,
          'rejectVisible': false,
          'acceptLabel': 'OK',
          'icon': 'pi pi-exclamation-triangle',
        });
      } else {
        this.leadForm.get('paymentRef').setValue(`Lead-${refResponse.data}`);
      }
    });
  }

  statusChange(): void {
    if ((this.status.value == 'Pending Payment') && !this.leadForm.get('paymentRef').value) {
      this.getPaymentRef();
    }

    if (this.status.value != 'Declined') {
      this.leadDeclinedReason.setValue('');
      this.leadDeclinedReason2.setValue('');
    }

    if (['Active', 'Pending Payment', 'Considering', 'Unable to Contact'].includes(this.status.value)) {
      this.callDate.setValidators(Validators.required);
      this.callDateShift.setValidators(Validators.required);
    } else {
      this.callDate.clearValidators();
      this.callDateShift.clearValidators();
      this.callDate.setValue(null);
      this.callDateShift.setValue(null);
    }
    this.leadForm.updateValueAndValidity();
  }
  
  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');
  }

  createOrder() {
    this.isSubmitDisabled = true;
    const params: Lead = this.getUpdateLeadParams();
    this.leadsService.updateLead(params).subscribe((response: SimpleResponse) => {
      this.isSubmitDisabled = false;
      if (response.success) {
        this.router.navigate(['/cses'], {state: {'brand': params.brand, 'alarmUserDetails': params.alarmUserDetails, 'leadId': params._id}})
          .then((success: boolean) => {
            if (!success) {
              console.error('Navigation to CSE order page failed');
            }
          });
      } else {
        console.log("Failure to save lead: ", response);  
        this.showErrorPopUp('Error saving Lead',
          `There was a problem saving the lead before creating the order. Error: ${response.message}`);
      }
    }, (err: Error) => {
      console.log("ERROR :: ", err);
      this.isSubmitDisabled = false;
      this.showErrorPopUp('Error saving Lead',
          `There was a problem saving the lead before creating the order. Error: ${err.message}`);
    });
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

}
