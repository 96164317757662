import {NotificationService} from './../notification.service';
import {Component, OnInit, Output, EventEmitter, Input, OnDestroy} from '@angular/core';
import {MessageService} from 'primeng/api';
import {UserNotification} from '../../models/notifications/userNotification';
import {MarkNotificationAsReadRequest} from '../../models/requests/markNotificationAsReadRequest.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {MarkAllNotificationAsReadRequest} from '../../models/requests/markAllNotificationAsReadRequest.model';
@Component({
  selector: 'app-display-notifications[isOutstandingNotifications][closeDisplayNotificationDialog]',
  templateUrl: './display-notifications.component.html',
  styleUrls: ['./display-notifications.component.scss'],
  providers: [MessageService]
})
export class DisplayNotificationsComponent implements OnInit,OnDestroy {
  constructor(
    private notificationService: NotificationService,
    private messageService: MessageService,
  ) { }
  
  displayAsSideBar: boolean = false;
  displayAsPopup: boolean = false
  @Output() closeDisplayNotificationDialog = new EventEmitter();
  @Input() isOutstandingNotifications: number;
  userId: string;
  notificationList: UserNotification[] = [];
  notificationDetail: UserNotification = null;
  isMarkAsReadDisabled: boolean = false;
  loading: boolean = true;
  loadingDetail: boolean = false;

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    if (this.isOutstandingNotifications) {
      this.getUserUnreadNotifications();
      this.displayAsPopup = true;
    } else {
      this.displayAsSideBar = true;
      this.getUserUnreadNotifications();
    }
  }

  ngOnDestroy() {
    if (this.isOutstandingNotifications) {
      localStorage.removeItem('outstandingNotificationCount');
    }
  }

  getUserUnreadNotifications() {
    this.notificationService.getUserUnreadNotifications(this.userId)
      .subscribe((response: MultiRecordResponse<UserNotification>) => {
        if (response.success) {
          this.loading = false;
          this.notificationList = response.data;
        }
      }, (err: Error) => {
      
      });
  }
  
  displayNotificationDetail(notificationDetail: UserNotification) {
    this.loadingDetail = true;
    this.notificationService.getNotificationDetailById(notificationDetail?._id)
      .subscribe((response: SingleRecordResponse<UserNotification>) => {
        this.loadingDetail = false;
        // console.log("Response ::: ", response);
        if (response.success && response.data) {
          this.notificationDetail = response.data;
        } else {
          this.showError('Error', 'Something went wrong try again.');
          this.notificationDetail = response.data;
        }
      });
  }

  closeNotificationDetail() {
    this.loadingDetail = false;
    this.notificationDetail = null;
  }

  markNotificationAsRead() {
    this.isMarkAsReadDisabled = true;
    const idToRemove: string = this.notificationDetail._id;
    const params: MarkNotificationAsReadRequest = {
      userId: localStorage.getItem('userId'),
      userNotificationId: this.notificationDetail._id
    };
    this.notificationService.markNotificationAsRead(params)
      .subscribe((response: SimpleResponse) => {
        this.isMarkAsReadDisabled = false;
        if (response.success) {
          this.notificationList = this.notificationList.filter((notifcation: UserNotification) => 
            notifcation._id != idToRemove
          );
          this.notificationDetail = null;
        } else
          this.showError(
            'Changes not applied',
            'Something went wrong, try again'
          );
      }, (err: Error) => {
        this.isMarkAsReadDisabled = false;
        this.showError(
          'Changes not applied',
          'Something went wrong, try again'
        );
      });
  }

  markAllNotificationsAsRead() {
    this.isMarkAsReadDisabled = true;
    const idsToMarkAsRead: string[] = this.notificationList.map((notification: UserNotification) => 
      notification._id
    );
    const params: MarkAllNotificationAsReadRequest = {
      userId: localStorage.getItem('userId'),
      userNotificationIds: idsToMarkAsRead
    }
    this.notificationService.markAllNotificationsAsRead(params)
      .subscribe((response: SimpleResponse) => {
        this.isMarkAsReadDisabled = false;
        if (response.success) {
          this.notificationList = this.notificationList.filter((notifcation: UserNotification) => 
            !idsToMarkAsRead.includes(notifcation._id)
          );
        } else
          this.showError(
            'Changes not applied',
            'Something went wrong, try again'
          );
      }, (err: Error) => {
        this.isMarkAsReadDisabled = false;
        this.showError(
          'Changes not applied',
          'Something went wrong, try again'
        );
      });
  }

  onHideDialog() {
    this.closeDisplayNotificationDialog.emit();
  }

  onHideAsPopupDialog() {
    if (this.isOutstandingNotifications) {
      localStorage.removeItem('outstandingNotificationCount');
    }
    this.closeDisplayNotificationDialog.emit();
  }

  showError(summary: string, detail: string) {
    this.messageService.add({
      life: 300000,
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
