import {Component, Host, Input, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Subject, Subscription} from 'rxjs';
import {InitialOrderDispatchInformation} from '../../models/order.model';
import {PostOrderSection} from '../post-order-section';
import {PostOrderService} from '../post-order.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-initial-dispatch-section[pageSection][minimizeSectionMethod]',
  templateUrl: './initial-dispatch-section.component.html',
  styleUrls: ['../post-order-sections.scss', './initial-dispatch-section.component.scss']
})
export class InitialDispatchSectionComponent extends PostOrderSection implements OnInit, OnDestroy {
  initialOrderDispatchInformationSubscription: Subscription;

  constructor(
    @Host() private parentFormDirective: FormGroupDirective,
    private formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private postOrderService: PostOrderService,
  ) {
    super(confirmationService, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.prepareForm();
    this.initialOrderDispatchInformationSubscription = 
      this.postOrderService.initialOrderDispatchInformationSubject.subscribe((dispatchInfo: InitialOrderDispatchInformation) => {
        // Have to prepare the form again here as post-order recreates the form when it gets updates
        this.prepareForm();
        this.orderForm.patchValue({
          'initialOrderDispatchInformation': dispatchInfo,
        });
      });
  }

  ngOnDestroy(): void {
    this.initialOrderDispatchInformationSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  get orderForm(): FormGroup {
    return this.parentFormDirective.form;
  }

  get formLoaded(): boolean {
    return !!this.orderForm.get('initialOrderDispatchInformation');
  }

  prepareForm(): void {
    if (!this.formLoaded) {
      this.orderForm.addControl('initialOrderDispatchInformation',
        this.formBuilder.group({
          'dispatchDate': [''],
          'ShippingMethod': [''],
          'sfOrderId': [''],
          'cadOrderId': [''],
          'trackingNo': [''],
          'dispatcher': [''],
        })
      );
    }
  }

  dispatchFieldVerfication(): void {
    if (
      (this.orderForm.value.status.status == '') &&
      (this.safeGetFormField('dispatchDate').length == 10) &&
      (this.safeGetFormField('ShippingMethod') != '') &&
      ((this.safeGetFormField('sfOrderId') != '') || (this.safeGetFormField('cadOrderId') != '')) &&
      (this.safeGetFormField('trackingNo') != '')
    ) {
      (this.orderForm.controls['status'] as FormGroup).patchValue({
        'status': 'no ncf',
        'setby': this.username,
        'date': moment.tz('Europe/London').toISOString(),
      });
    }
  }

  get trackingNumber(): string {
    return this.safeGetFormField('trackingNo');
  }

  get clickAndDropId(): string {
    return this.safeGetFormField('cadOrderId');
  }

  get shippingMethod(): string {
    return this.safeGetFormField('ShippingMethod');
  }

  get showTrackingLink(): boolean {
    return (this.shippingMethod == 'Royal Mail') || (!!this.clickAndDropId);
  }

  safeGetFormField(fieldName: string): string {
    if (!this.orderForm.get('initialOrderDispatchInformation') || !this.orderForm.get('initialOrderDispatchInformation').get(fieldName)) {
      return '';
    }
    return this.orderForm.get('initialOrderDispatchInformation').get(fieldName).value;
  }

  navigateToTracking(): void {
    window.open('https://www.royalmail.com/track-your-item#/tracking-results/' + this.trackingNumber);
  }
}
