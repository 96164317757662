
<p-dialog class="add-equipment-fault" header="Add Equipment Fault" (onHide)="onCloseDialog()" [(visible)]="display" [modal]="true" [style]="{width: '60vw'}"
    [maximizable]="true" [draggable]="false" [resizable]="false">
  <form #formRef="ngForm">
    <div class="row">
      <div class="form-group col-12 col-md-4">
        <label>Equipment</label>
        <input type="text" class="form-control" readonly value="{{equipDetails.name}}" />
      </div>
      <div class="form-group col-12 col-md-4">
        <label>Serial Number</label>
        <input type="text" class="form-control" readonly value="{{equipDetails.serialNumber}}" />
      </div>
      <div class="form-group col-12 col-md-4">
        <label>Status</label>
        <input type="text" class="form-control" readonly value="{{equipDetails.status}}" />
      </div>
      <div class="form-group col-12">
        <label></label>
        <input *ngIf="equipDetails.isPendant" type="text" class="form-control" readonly value="{{selectedEquipmentCategory}}" />
        <p-dropdown *ngIf="!equipDetails.isPendant" name="selectedEquipmentCategory" [options]="equipmentCategories" [(ngModel)]="selectedEquipmentCategory"
            [filter]="true" filterBy="label" [showClear]="true" 
            placeholder="Select a category" #selectedFaultRef="ngModel" [required]=true
            (onChange)="onEquipmentCategoryChange()" appendTo="body">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedEquipmentCategory">
              <div>{{selectedEquipmentCategory}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="form-group col-12 col-md-6" *ngIf="selectedEquipmentCategory">
        <label></label>
        <p-dropdown name="selectedEquipmentBrand" [options]="equipmentBrandOptions" [(ngModel)]="selectedEquipmentBrand" 
            [filter]="true" filterBy="label" [showClear]="true" 
            placeholder="Select equipment details" #selectedEquipmentBrandRef="ngModel" [required]=true
            (onChange)="onEquipmentBrandChange()" appendTo="body">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedEquipmentBrand">
              <div>{{selectedEquipmentBrand}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="form-group col-12 col-md-6" *ngIf="selectedEquipmentCategory && selectedEquipmentBrand">
        <label></label>
        <p-dropdown name="selectedEquipmentFault" [options]="equipmentFaultOptions" [(ngModel)]="selectedEquipmentFault"
         [filter]="true" filterBy="label" [showClear]="true" appendTo="body"
          placeholder="Select a fault" #selectedEquipmentFaultRef="ngModel" [required]=true>
        <ng-template pTemplate="selectedItem">
          <div *ngIf="selectedEquipmentFault">
            <div>{{selectedEquipmentFault}}</div>
          </div>
        </ng-template>
        </p-dropdown>
      </div>
      <div class="form-group col-12">
        <label for="additionalNoteId">Additional Note</label>
        <textarea name="additionalNote" class="form-control" id="additionalNoteId" rows="7" [(ngModel)]="additionalNote" #additionalNoteRef="ngModel"></textarea>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="onCloseDialog()" label="Cancel" styleClass="p-button-text">
    </p-button>
    <p-button icon="pi pi-check" (click)="onSubmitFault()" label="Submit" styleClass="p-button-text" [disabled]="formRef.invalid || isSubmitDisabled">
    </p-button>
  </ng-template>
</p-dialog>