import {SelectItem} from 'primeng/api';
import {SelectItemWithEmail} from '../models/selectItemWithEmail.model';

interface SelectItemWithAddFilter<T> extends SelectItem<T> {
  'showOnAdd': boolean;
}

let customerFeedbackTypesSelectItems: SelectItemWithAddFilter<string>[];
function getCustomerFeedbackTypes(): SelectItemWithAddFilter<string>[] {
  if (!customerFeedbackTypesSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('customer feedback: types')) {
      return [];
    }
    customerFeedbackTypesSelectItems = JSON.parse(localStorage.getItem('customer feedback: types')) || [];
  }
  return customerFeedbackTypesSelectItems;
}

let customerFeedbackPartnershipSelectItems: SelectItemWithEmail<string>[];
function getCustomerFeedbackPartnerships(): SelectItemWithEmail<string>[] {
  if (!customerFeedbackPartnershipSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('customer feedback: partnerships')) {
      return [];
    }
    customerFeedbackPartnershipSelectItems = JSON.parse(localStorage.getItem('customer feedback: partnerships')) || [];
  }
  return customerFeedbackPartnershipSelectItems;
}

let customerFeedbackStatusSelectItems: SelectItem<string>[];
function getCustomerFeedbackStatuses(): SelectItem<string>[] {
  if (!customerFeedbackStatusSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('customer feedback: status')) {
      return [];
    }
    customerFeedbackStatusSelectItems = JSON.parse(localStorage.getItem('customer feedback: status')) || [];
  }
  return customerFeedbackStatusSelectItems;
}

let customerFeedbackMainReasonSelectItems: SelectItemWithEmail<string>[];
function getCustomerFeedbackMainReasons(): SelectItemWithEmail<string>[] {
  if (!customerFeedbackMainReasonSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('customer feedback: main reasons')) {
      return [];
    }
    customerFeedbackMainReasonSelectItems = JSON.parse(localStorage.getItem('customer feedback: main reasons')) || [];
  }
  return customerFeedbackMainReasonSelectItems;
}

let customerFeedbackSpecificReasonByMainReason: {[mainReason: string]: SelectItem<string>[]} = {};
function getCustomerFeedbackSpecificReasonsForMainReason(mainReason: string): SelectItem<string>[] {
  if (!mainReason) {
    return [];
  }
  if (!customerFeedbackSpecificReasonByMainReason[mainReason]) {
    const lookupKey: string = `customer feedback: ${mainReason} reasons`;
    // Handle requests to get details before login is complete
    if (!localStorage.getItem(lookupKey)) {
      return [];
    }
    customerFeedbackSpecificReasonByMainReason[mainReason] = JSON.parse(localStorage.getItem(lookupKey)) || [];
  }
  return customerFeedbackSpecificReasonByMainReason[mainReason];
}

let customerFeedbackContactMethodsSelectItems: SelectItem<string>[];
function getCustomerFeedbackContactMethods(): SelectItem<string>[] {
  if (!customerFeedbackContactMethodsSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('customer feedback: contact methods')) {
      return [];
    }
    customerFeedbackContactMethodsSelectItems = JSON.parse(localStorage.getItem('customer feedback: contact methods')) || [];
  }
  return customerFeedbackContactMethodsSelectItems;
}

export {
  SelectItemWithAddFilter,
  getCustomerFeedbackTypes,
  getCustomerFeedbackPartnerships,
  getCustomerFeedbackStatuses,
  getCustomerFeedbackMainReasons,
  getCustomerFeedbackSpecificReasonsForMainReason,
  getCustomerFeedbackContactMethods,
}