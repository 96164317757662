import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CustomerFeedback} from '../models/customerFeedback.model';
import {CustomerFeedbackRequest} from '../models/requests/customerFeedbackRequest.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {PageCountResponse} from '../models/responses/pageCountResponse.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerFeedbackService {
  CUST_FEEDBACK_URL = environment.protocol + environment.IPAddress + '/api/customer-feedback';

  constructor(
    private http: HttpClient,
  ) { }

  getSpecificCustomerFeedback(feedbackId: string): Observable<SingleRecordResponse<CustomerFeedback>> {
    return this.http.get<SingleRecordResponse<CustomerFeedback>>(`${this.CUST_FEEDBACK_URL}/${feedbackId}`);
  }

  getCustomerFeedbackForOrder(orderId: string): Observable<MultiRecordResponse<CustomerFeedback>> {
    return this.http.get<MultiRecordResponse<CustomerFeedback>>(`${this.CUST_FEEDBACK_URL}/forOrder/${orderId}`);
  }

  getCustomerFeedbackPageCount(): Observable<PageCountResponse> {
    return this.http.get<PageCountResponse>(`${this.CUST_FEEDBACK_URL}/list/pageCount`);
  }

  getCustomerFeedbackPage(page: number): Observable<MultiRecordResponse<CustomerFeedback>> {
    return this.http.get<MultiRecordResponse<CustomerFeedback>>(`${this.CUST_FEEDBACK_URL}/list/page/${page}`);
  }

  saveCustomerFeedback(id: string, feedbackParams: CustomerFeedbackRequest): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(`${this.CUST_FEEDBACK_URL}/${id}`, feedbackParams);
  }

  saveNewCustomerFeedback(feedbackParams: CustomerFeedbackRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.CUST_FEEDBACK_URL}`, feedbackParams);
  }

  deleteCustomerFeedback(id: string): Observable<SimpleResponse> {
    return this.http.delete<SimpleResponse>(`${this.CUST_FEEDBACK_URL}/${id}`);
  }

}
