<div class='container-fluid'>
  <div *ngIf="errorMsg" class="alert alert-danger">
    {{errorMsg}}
  </div>

  <div *ngIf="hasOrders" class='mt-2'>
    <h3>Matching Orders</h3>
    <p-table #genesysOrdersTable [value]="genesysOrders" [columns]="orderCols" [paginator]="false" [rows]="10">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            <div>{{col.header}}</div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" [ngSwitch]="col.field">
            <div *ngSwitchCase="'brandTitle'" [ngStyle]="{'background-color': rowData.brandColour, 'color': rowData.textColour}">
              {{rowData.brandTitle}}
            </div>
            <div *ngSwitchCase="'thisBrand'">
              {{rowData.thisBrand? 'Yes': 'No'}}
            </div>
            <div *ngSwitchCase="'Order Link'">
              <a href="{{rowData['Order Link']}}" target="_blank">
                Click here to Open
              </a>
            </div>
            <div *ngSwitchDefault>
              {{rowData[col.field]}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="hasLeads" class='mt-2'>
    <h3>Matching Leads</h3>
    <p-table #genesysLeadsTable [value]="genesysLeads" [columns]="leadCols" [paginator]="false" [rows]="10">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            <div>{{col.header}}</div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" [ngSwitch]="col.field">
            <div *ngSwitchCase="'brandTitle'" [ngStyle]="{'background-color': rowData.brandColour, 'color': rowData.textColour}">
              {{rowData.brandTitle}}
            </div>
            <div *ngSwitchCase="'thisBrand'">
              {{rowData.thisBrand? 'Yes': 'No'}}
            </div>
            <div *ngSwitchCase="'Lead Link'">
              <a href="{{rowData['Lead Link']}}" target="_blank">
                Click here to Open
              </a>
            </div>
            <div *ngSwitchDefault>
              {{rowData[col.field]}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="hasBgInfo" class='mt-2'>
    <h3>Matching Business Growth Accounts</h3>
    <p-table #genesysBgTable [value]="genesysBg" [columns]="bgCols" [paginator]="false" [rows]="10">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            <div>{{col.header}}</div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <div>
              {{rowData[col.field]}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>