import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {PostOrderComponent} from './post-order.component';

@Injectable()
export class PostOrderDeactivateGuard implements CanDeactivate<PostOrderComponent> {

constructor() { }
  canDeactivate(component: PostOrderComponent, _currentRoute: ActivatedRouteSnapshot, _currentState: RouterStateSnapshot, 
      nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return component.isOkToDeactivate(false, nextState? nextState.url: '');
  }

}
