<p-dataView #dv [value]="filteredUsers" [paginator]="true" [rows]="100" paginatorPosition="both" filterBy="name" [sortOrder]="sortOrder" [sortField]="sortField">
  <p-header>
    <div class="row no-gutters">
      <div class="col-3 ui-g-12">
        <button class="btn btn-primary" type="button" (click)="displayMaintainGroups()">Maintain Groups</button>
        <button class="btn btn-primary ml-1" type="button" (click)="exportUserPermissions()">Export Permission List</button>
      </div>
      <div class="col-3 ui-g-12">
        <input type="search" pInputText placeholder="Search Users by Name" (input)="dv.filter($any($event.target).value, 'contains')">
      </div>
      <div class="col-2 ui-g-12">
        <p-dropdown [options]="userRoles" placeholder="Filter By Role" [(ngModel)]="selectedRole" [showClear]="true"
          [filter]="true" (onChange)="onRoleOrPermissionChange()">
        </p-dropdown>
      </div>
      <div class="col-2 ui-g-12">
        <p-dropdown [options]="availablePermissions" placeholder="Filter By Permission" [(ngModel)]="selectedPermission" [showClear]="true"
          [filter]="true" (onChange)="onRoleOrPermissionChange()">
        </p-dropdown>
      </div>
      <div class="col-2 ui-g-12 sort">
        <label>Sort By:</label>&nbsp;
        <p-dropdown [options]="sortOptions" placeholder="Sort By" (onChange)="onSortChange()" [(ngModel)]="selectedSortOption">
        </p-dropdown>
      </div>
    </div>
  </p-header>
  <ng-template let-user pTemplate="listItem">
    <div class="row userRow">
      <div class="col-12 col-md-3" style="text-align: center">
        <img src="{{user.picture}}">
      </div>
      <div class="col-12 col-md-3">
        <div class="row">
          <div class="col-6 col-md-2 py-2">Name:</div>
          <div class="col-6 col-md-10 py-2"><b>{{user.name}}</b></div>

          <div class="col-6 col-md-2 py-2">email:</div>
          <div class="col-6 col-md-10 py-2"><b>{{user.email}}</b></div>

          <div class="col-6 col-md-2 py-2">Created:</div>
          <div class="col-6 col-md-10 py-2"><b>{{user.created | date: 'fullDate'}}</b></div>

        </div>
      </div>
      <div class="col-12 col-md-6 search-icon">
        <div style="margin-bottom: 10px">
          <p-multiSelect [options]="userRoles" [(ngModel)]="user.roles" [maxSelectedLabels]=10 [scrollHeight]="'400px'"
            selectedItemsLabel="{0} users selected" [style]="{top:'0px'}"
            placeholder="Select roles" (onChange)="confirmRoleChange($event, user)">
          </p-multiSelect>
        </div>
        <div style="margin-bottom: 10px"><button pButton type="button" icon="pi pi-trash" (click)="confirmDelete(user)"></button></div>
        <div style="margin-bottom: 10px"><button pButton type="button" icon="pi pi-search" (click)="selectUser($event, user)"></button></div>
      </div>
    </div>
  </ng-template>
</p-dataView>
<p-confirmDialog>
</p-confirmDialog>
<p-toast></p-toast>
<p-dialog header="Update User Details" [(visible)]="displayDialog" showEffect="fade" [modal]="true" [style]="{width: '800px'}">
  <div *ngIf="selectedUser">
    <div class="ui-g-12" style="text-align:center">
      <img src="{{selectedUser.picture}}">
    </div>
    <!--Username-->
    <div class="ui-g-12">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">
          <i class="fa fa-user"></i>
        </span>
        <input type="text" pInputText placeholder="Username"  [(ngModel)]="selectedUser.name">
      </div>
    </div>
    <!-- End Username-->

    <!--Email-->
    <div class="ui-g-12">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">
          <i class="fa fa-envelope"></i>
        </span>
        <input type="email" pInputText placeholder="Email"  [(ngModel)]="selectedUser.email"  >
      </div>
    </div>
    <!-- End Email-->

    <!--Password-->
    <div class="ui-g-12">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">
          <i class="fa fa-key"></i>
        </span>
        <input type="password" pInputText placeholder="Password" [(ngModel)]="selectedUser.password">
      </div>
    </div>
    <!-- End Password-->

    <!--Password-->
    <div class="ui-g-12 ">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">
          <i class="fa fa-key"></i>
        </span>
        <input type="password" pInputText placeholder="Confirm Password" [(ngModel)]="selectedUser.confirmPassword">
      </div>
    </div>
    <!-- End Password-->
    <div class="text-right">
      <button type="button" class="btn btn-primary" [disabled]="(selectedUser.password && (selectedUser.password != selectedUser.confirmPassword))" 
          (click)="confirmUpdate(selectedUser)">
        Update
      </button>
    </div>
  </div>
</p-dialog>

<app-maintain-groups *ngIf="isMaintainGroupVisible" (closeDialog)="hideMaintainGroups()"></app-maintain-groups>
