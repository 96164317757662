import {Table} from 'primeng/table';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LeadsService} from './leads.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {LocksSocketService} from '../sockets/locks-socket.service';
import {getBrandSelectItems,getBrandBackgroundColour, getBrandColour} from '../lookups/brands';
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';
import {ExcelExportService} from '../post-order/excel-export.service';
import {Lead, LeadAlarmUserDetails, MatchedOrder} from '../models/lead.model';
import {Title} from '@angular/platform-browser';
import {doesSheetContainColumnsNamed, filteredUsersByRole, getBase64EncodedFileContents, isValidObjectId, sortByLabel, subfieldSort} from '../helpers/helperFunctions';
import {UsersAndRolesResponse} from '../models/responses/usersAndRolesResponse.model';
import {User} from '../models/user.model';
import {UsersService} from '../setup/users/users.service';
import {nameMatchModeOptions} from '../lookups/filterOptions';
import {LeadLockData} from '../models/socket-io/leadLockData.model';
import {Column} from '../models/column.model';
import {LEAD_STATUSES, LeadType, getLeadNoCallbackOptions, getLeadTypes} from '../lookups/leads';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { OrderLockData } from '../models/socket-io/orderLockData.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {PageCountResponse} from '../models/responses/pageCountResponse.model';

const MAX_PARALLEL: number = 5;
const PAGE_RETRIES: number = 3;

const INFO_PACK_MANDATORY_FIELDS: string[] = [
  'Brand',
  'Info pack and order form',
  'Name and Address',
  'Phone',
];
const REFERRAL_MANDATORY_FIELDS: string[] = [
  'Added By',
  'Brand',
  'Query',
];

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class LeadsComponent implements OnInit, OnDestroy {

  constructor(
    private leadsService: LeadsService,
    private messageService: MessageService,
    private locksSocket: LocksSocketService,
    private excelService: ExcelExportService,
    private title: Title,
    private userService: UsersService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
  ) { }
  subfieldSort = subfieldSort;
  leads: Lead[] = [];
  leadsSheet: File;
  columns: Column[] = [];
  duplicatesCols: Column[] = [];
  name: string[];
  statusSelects: SelectItem<string>[] = LEAD_STATUSES;
  ownerNames: SelectItem<string>[] = [];
  selectedStatus = [];
  callLeadId: string = null;
  leadLockList: LeadLockData[] = [];
  leadOpenMode: string;
  editViewLeadId: string = null;
  displayImportDialog: boolean = false;
  brands: SelectItem<String>[];
  disableImportLeads: boolean = false;
  displayDuplicates: boolean = false;
  disableProcessDuplicates: boolean = false;
  deleteButtonsDisabled: boolean = true;
  leadImportStatus: string = null;
  selectedLeads: Lead[] = [];
  newLeads: Lead[] = [];
  possibleDuplicateLeads: Lead[] = [];
  additionalMessage: string = '';
  selectedDuplicateLeads: Lead[] = [];
  orderLockList: OrderLockData[] = [];
  matchedFilter: SelectItem<string>[] = [{
    'label': 'All - regardless of match status',
    'value': 'all',
  }, {
    'label': 'Matched Only',
    'value': 'hasValues',
  }, {
    'label': 'Unmatched Only',
    'value': 'doesNotHaveValues',
  }];
  
  moment = moment;
  leadTypes: LeadType[];
  importError: string;
  nameMatchModeOptions: SelectItem<string>[] = nameMatchModeOptions;
  leadDeclinedOptions:  SelectItem<string>[] = getLeadNoCallbackOptions();
  getBrandBackgroundColour = getBrandBackgroundColour;
  getBrandColour = getBrandColour;
  @ViewChild('dt', { static: true }) table: Table;
  importType: string;
  inactiveLeadsLoading: boolean = false;
  inactiveLeadPagesToLoad: number = 0;
  pagesProcessing: number;

  ngOnInit(): void {
    this.getLeads(true);
    this.initializeVariables()
    this.brands = getBrandSelectItems();
    this.locksSocket.on('leadLockList', (data: LeadLockData[]) => {
      this.leadLockList = data;
    });
    this.locksSocket.emit('getLockedLead');
    this.title.setTitle('CRM Leads');
    this.getUsers();
    if (this.userService.userHasPermission('Can delete leads')) {
      this.deleteButtonsDisabled = false
    }
    this.leadTypes = getLeadTypes();
  }

  ngOnDestroy(): void {
    this.locksSocket.removeAllListeners();
  }

  getLeads(intialLoad: boolean) {
    // this.leadsService.getLeads().subscribe((response: any) => {
    this.leadsService.getActiveLeads().subscribe((response: MultiRecordResponse<Lead>) => {
      if (response.success) {
        this.leads = response.data;
        this.route.url.subscribe((urlSegments: UrlSegment[]) => {
          if (intialLoad && (urlSegments.length == 3)) {
            if (['view', 'edit'].includes(urlSegments[1].path) && isValidObjectId(urlSegments[2].path)) {
              this.leadOpenMode = urlSegments[1].path;
              this.editViewLeadId = urlSegments[2].path;
            } else if (['call'].includes(urlSegments[1].path) && isValidObjectId(urlSegments[2].path)) {
              this.callLeadId = urlSegments[2].path;
            }
          }
        });  
      } else {
        this.showErrorPopUp('Error getting active leads', `Error getting active leads. ${response.message}`);
      }
    }, (err: Error) => {
      console.log("Response :: ", err);
      this.showErrorPopUp('Error getting active leads', `Error getting active leads. Error: ${err.message}`);
    });
  }

  delay(ms: number): Promise<void> {
    return new Promise((resolve): void => {
      setTimeout(resolve, ms);
    });
  }

  loadInactiveLeads() {
    this.inactiveLeadsLoading = true;
    this.inactiveLeadPagesToLoad = 0;
    this.leadsService.getInactiveLeadPageCount().subscribe(async (response: PageCountResponse) => {
      if (!response.success) {
        this.inactiveLeadsLoading = false;
        this.showErrorPopUp('Error', `Error getting page count for inactive leads. ${response.message}`);
      } else {
        this.inactiveLeadPagesToLoad = response.pageCount!;
        this.pagesProcessing = 0;
        if (this.inactiveLeadPagesToLoad === 0) {
          this.inactiveLeadsLoading = false;
        } else {
          for (let page: number = 1; page <= response.pageCount!; page++) {
            // Limit the max number of pages being updated in parallel which allows other work to complete
            // whilst pages are still loading and allows abort if page navigated away from
            while (this.pagesProcessing >= MAX_PARALLEL) {
              await this.delay(250);
            }
            this.pagesProcessing++;
            this.loadInactiveLeadPage(page, PAGE_RETRIES);
          }
        }
      }
    })
  }

  loadInactiveLeadPage(page: number, retryCount: number) {
    this.leadsService
      .getInactiveLeadPage(page)
      .subscribe((response: MultiRecordResponse<Lead>) => {
        if ((!response.success) || (!response.data)) {
          console.log(`Error loading page ${page}. Error: ${response.message}`);
          if (retryCount > 0) {
            this.loadInactiveLeadPage(page, retryCount - 1);
            return;
          }
          // Only decrement count if we are not retrying page
          this.pagesProcessing--;
          this.showErrorPopUp('Error loading inactive lead records', `Something went wrong try again. Error: ${response.message}`);
          return;
        }
        this.pagesProcessing--;
        const leadsToAdd: Lead[] = [];
        response.data.forEach((newLead: Lead) => {
          if (!this.leads.some((existingLead: Lead) => 
            newLead._id == existingLead._id
          )) {
            leadsToAdd.push(newLead);
          }
        });
        if (leadsToAdd.length> 0) {
          this.leads = this.leads.concat(leadsToAdd);
        }
        this.inactiveLeadPagesToLoad--;
        if (this.inactiveLeadPagesToLoad === 0) {
          this.inactiveLeadsLoading = false;
        }
      }, 
      (err: any) => {
        console.log(`Error loading page ${page}. Error ${err.message}`);
        if (retryCount > 0) {
          this.loadInactiveLeadPage(page, retryCount - 1);
          return;
        }
        // Only decrement count if we are not retrying page
        this.pagesProcessing--;
        this.showErrorPopUp('Error loading lead records', `Something went wrong try again. Error: ${err.message}`);
      });
  }

  incomingFile(event) {
    this.leadsSheet = event.target.files[0];
  }

  async uploadLeadsSheet() {
    this.disableImportLeads = true;
    this.displayDuplicates = false;
    this.disableProcessDuplicates = false;
    const fileContents: string = await getBase64EncodedFileContents(this.leadsSheet);
    this.processLeadsImport(fileContents);
  }

  processLeadsImport(fileContentsBase64: string) {
    this.additionalMessage = '';
    this.importError = '';
    this.newLeads = [];
    this.possibleDuplicateLeads = [];
    this.selectedDuplicateLeads = [];
    try {
      const workbook = XLSX.read(fileContentsBase64, { type: 'base64' });
      // const sheetName: string = workbook.SheetNames.find((name: string) => (name == 'Sales') || name.startsWith('Info Pack')); Just incase we need import referrals again 
      const sheetName: string = workbook.SheetNames.find((name: string) => name.startsWith('Info Pack'));
      if (!sheetName) {
        this.disableImportLeads = false;
        this.showErrorPopUp('Warning', 
          'Cannot read sheet name. Please make sure that the sheet name is "Sales" for Referal imports, ' +
          'or "Info Packs" for Info Pack imports.');
        return;
      }
      const worksheet = workbook.Sheets[sheetName];
      this.importType = sheetName.startsWith('Info Pack')? 'Info Pack - by post': 'Referral';
      let rows: any[] = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      if (rows.length == 0) {
        this.importError = 'No rows in the spreadsheet.';
        this.leadImportStatus = 'failed'
        return;
      }
      switch (this.importType) {
        case 'Info Pack - by post':
          if (!doesSheetContainColumnsNamed(INFO_PACK_MANDATORY_FIELDS, worksheet)) {
            this.importError = `The spreadsheet must contain columns: ${INFO_PACK_MANDATORY_FIELDS.join(', ')}`;
            this.leadImportStatus = 'failed'
            return;
          }
          break;
        case 'Referral':
          if (!doesSheetContainColumnsNamed(REFERRAL_MANDATORY_FIELDS, worksheet)) {
            this.importError = `The spreadsheet must contain columns: ${REFERRAL_MANDATORY_FIELDS.join(', ')}`;
            this.leadImportStatus = 'failed'
            return;
          }
          break;
      }
      if (this.importType == 'Referral') {
        const mergedRows: any[] = [];
        let currentRow: any = undefined;
        rows.forEach((row: any) => {
          if (!currentRow) {
            currentRow = row;
          } else if (!row.Brand && !row['Added By'] && row.Query) {
            currentRow.Query += `\n${row.Query}`;
          } else {
            mergedRows.push(currentRow);
            currentRow = row;
          }
        });
        if (currentRow) {
          mergedRows.push(currentRow);
        }
        rows = mergedRows;
      }

      let skippedNoPhoneCount: number = 0;
      let skippedNotInfoPackAndOrderFormCount: number = 0;
      let skippedUnrecognisedBrandCount: number = 0;
      rows.forEach(row => {
        let lead: Lead = {
          'leadType': this.importType,
          'whoReferred': '',
          'brand': '',
          'referralDate': new Date().toISOString(),
          'tdCode': '',
          'status': 'Active',
          'ownerName': '',
          'addedBy': '',
          'deleted': false,
          'deletedAt': '',
          'deletedBy': '',
          'alarmUserDetails': {
            'firstName': '',
            'otherName': '',
            'lastName': '',
            'preferredName': '',
            'email': '',
            'telephone': '',
            'mobile': '',
            'salesContact': '',
            'userAddress': {
              'addressOne': '',
              'addressTwo': '',
              'city': '',
              'county': '',
              'postcode': '',
              'dob': '',
              'validated': false,
            },
          },
          'referrerDetails': {
            'firstName': '',
            'otherName': '',
            'lastName': '',
            'mobile': '',
            'telephone': '',
            'relationship': '',
            'email': '',
            'salesContact': '',
            'accountContactForCRM': '',
            'userAwareOfReferral': '',
            'referredBy': '',
          },
          'callNotes': [],
          'comments': [],
          'orderId': null,
          'paymentRef': '',
        };
        if (['Lifeline', 'LL24', 'LC24', 'Lifeconnect'].includes(row['Brand'])) {
          lead.brand = 'LL';
        } else if (['Careline', 'CL', 'CL365'].includes(row['Brand'])) {
          lead.brand = 'CL';
        } else if (['Carelink', 'CK'].includes(row['Brand'])) {
          lead.brand = 'CK';
        } else if (['LifeLineIE', 'LLIE'].includes(row['Brand'])) {
          lead.brand = 'LLIE';
        } else if (['Telecare', 'TC'].includes(row['Brand'])) {
          lead.brand = 'TC';
        } else if (['Lone Alarms', 'LA'].includes(row['Brand'])) {
          lead.brand = 'LA';
        } else {
          skippedUnrecognisedBrandCount++;
          return;
        }
        lead.addedBy = localStorage.getItem('userName')
        
        if (this.importType == 'Referral') {
          if (row['Contact Details']) {
            const contactDetails: string[] = row['Contact Details'].split(/-/);
            const names: string[] = contactDetails[0]?.trim()?.split(' ');
            if (names.length > 1) {
              lead.referrerDetails.lastName = names.pop();
              lead.alarmUserDetails.lastName = lead.referrerDetails.lastName
            }
            lead.referrerDetails.firstName = names.join(' ');
            lead.alarmUserDetails.firstName = lead.referrerDetails.firstName
            if (contactDetails.length > 1) {
              const contactNumber: string = contactDetails[1].trim();
              if (/\d+/.test(contactNumber)) {
                if (contactNumber.startsWith('07')) {
                  lead.referrerDetails.mobile = contactNumber;
                } else if (contactNumber.startsWith('7')) {
                  lead.referrerDetails.mobile = '0' + contactNumber;
                  lead.alarmUserDetails.mobile = '0' + contactNumber;
                } else {
                  lead.referrerDetails.telephone = contactNumber;
                  lead.alarmUserDetails.telephone = contactNumber;
                }
              }
            }
         
          }
          if (row['Contact Relationship']) {
            lead.referrerDetails.relationship = `${row['Contact Relationship']}`
          }
          lead.callDateShift = 'Morning';
          lead.callDate = moment.tz('Europe/London').add(1, 'day').startOf('day').add(9, 'hour').toISOString();
        } else if (['Into Pack - by post', 'Info Pack - by email', 'Info Pack'].includes(this.importType)) {
          if (row['Name']) {
            const owner: string[] = row['Name'].split(/-/);
            if (owner.length > 1) {
              lead.ownerName = owner[1].trim();
            } 
          } 
        }
        if (row['Name and Address']) {
          const nameAndAddressLines: string[] = row['Name and Address'].split(/\r?\n/);
          if (nameAndAddressLines[0]?.trim()) {
            const names: string[] = nameAndAddressLines[0]?.trim()?.split(' ');
            if (names.length > 1) {
              lead.alarmUserDetails.lastName = names.pop();
            }
            lead.alarmUserDetails.firstName = names.join(' ');
          }
          lead.alarmUserDetails.userAddress.addressOne = nameAndAddressLines[1];
          if (nameAndAddressLines.length == 4) {
            lead.alarmUserDetails.userAddress.city = nameAndAddressLines[2];
          } else if (nameAndAddressLines.length == 5) {
            lead.alarmUserDetails.userAddress.city = nameAndAddressLines[2];
            lead.alarmUserDetails.userAddress.county = nameAndAddressLines[3];
          } else if (nameAndAddressLines.length == 6) {
            lead.alarmUserDetails.userAddress.addressTwo = nameAndAddressLines[2];
            lead.alarmUserDetails.userAddress.city = nameAndAddressLines[3];
            lead.alarmUserDetails.userAddress.county = nameAndAddressLines[4];
          }
          if (nameAndAddressLines.length > 1) {
            lead.alarmUserDetails.userAddress.postcode = nameAndAddressLines[nameAndAddressLines.length - 1];
          }
        }
        if (typeof row['Phone'] == 'number') {
          row['Phone'] = '' + row['Phone'];
          if (row['Phone'].length > 0) {
            //lead zero will have been stripped off, add it back
            row['Phone'] = '0' + row['Phone'];
          }
        }
        if (row['Phone']) {
          if (row['Phone'].startsWith('07')) {
            lead.alarmUserDetails.mobile = row['Phone'];
          } else if (row['Phone'].startsWith('7')) {
            lead.alarmUserDetails.mobile = '0' + row['Phone'];
          } else {
            lead.alarmUserDetails.telephone = row['Phone'];
          }
        }
        if (this.importType.startsWith('Info Pack')) {
          if (!row['Phone']) {
            skippedNoPhoneCount++;
            return;
          }
          if (!['Info pack and order form' , 'DD35 Info pack and order form'].includes(row['Info pack and order form'] )) {
            skippedNotInfoPackAndOrderFormCount++;
            return;
          }
        }
        if (row['Email']) {
          lead.alarmUserDetails.email = row['Email'];
        }

        if (row['Medium']) {
          lead.comments.push({
            createdBy: localStorage.getItem('userName'),
            comments:`Medium:\n ${row['Medium']}`,
            createdAt: moment.tz('Europe/London').toISOString()
          });
        }
        if (row['Query']) {
          lead.comments.push({
            createdBy: localStorage.getItem('userName'),
            comments:`Query:\n ${row['Query']}`,
            createdAt: moment.tz('Europe/London').toISOString()
          });
        }
        if (row['Contact Relationship']) {
          lead.comments.push({
            createdBy: localStorage.getItem('userName'),
            comments:`Contact Relationship: ${row['Contact Relationship']}`,
            createdAt: moment.tz('Europe/London').toISOString()
          });
        }
        if (row['Additional Notes (If a sale is unsuccesful, please place the reason here:']) {
          lead.comments.push({
            createdBy: localStorage.getItem('userName'),
            comments:`Additional notes:\n ${row['Additional Notes (If a sale is unsuccesful, please place the reason here:']}`,
            createdAt: moment.tz('Europe/London').toISOString()
          });
        }
        if (row['First Call Outcome']) {
          lead.callNotes.push({
            createdBy: localStorage.getItem('userName'),
            content: `First Call Outcome:\n ${row['First Call Outcome']}`,
            createdAt: moment.tz('Europe/London').toISOString()
          });
          if (row['First Call Outcome'] == 'Considering - Call back in 2 days') {
            lead.callDate = moment.tz('Europe/London').startOf('day').add(2, 'day').toISOString()
            lead.callDateShift = 'Morning'
          }
        }
        if (row['Second Call Outcome']) {
          lead.callNotes.push({
            createdBy: localStorage.getItem('userName'),
            content: `Second Call Outcome:\n ${row['Second Call Outcome']}`,
            createdAt: moment.tz('Europe/London').toISOString()
          });
          if (row['Second Call Outcome'] == 'Considering - Call back in 2 days') {
            lead.callDate = moment.tz('Europe/London').startOf('day').add(2, 'day').toISOString()
            lead.callDateShift = 'Morning'
          }
        }

        if (row['Ordered by?\r\nOrdered for?']) {
          lead.comments.push({
            createdBy: localStorage.getItem('userName'),
            comments:`Ordered by? Ordered for?:\n ${row['Ordered by?\r\nOrdered for?']}`,
            createdAt: moment.tz('Europe/London').toISOString()
          });
        }
        if (row['Comments']) {
          lead.comments.push({
            createdBy: localStorage.getItem('userName'),
            comments:`Comments:\n ${row['Comments']}`,
            createdAt: moment.tz('Europe/London').toISOString()
          });
        }
        if (this.isPossibleDuplicateLead(lead)) {
          this.possibleDuplicateLeads.push(lead)
        } else {
          this.newLeads.push(lead);
        }
      });

      if (skippedNoPhoneCount > 0) {
        this.additionalMessage = `${skippedNoPhoneCount} rows skipped as they have no value in "Phone"\n`;
      }
      if (skippedNotInfoPackAndOrderFormCount > 0) {
        this.additionalMessage +=
          `${skippedNotInfoPackAndOrderFormCount} rows skipped as they have value in "Info pack and order form" that is not imported\n`;
      }
      if (skippedUnrecognisedBrandCount > 0) {
        this.additionalMessage += `${skippedUnrecognisedBrandCount} rows skipped as they have invalid value in "Brand"\n`;
      }
      if ((this.newLeads.length == 0) && (this.possibleDuplicateLeads.length == 0)) {
        this.importError =
          'No Leads found for import. Please make sure column headings are correct and all required information is filled in.\n' +
          this.additionalMessage;
        this.leadImportStatus = 'failed';
        return;
      }

      if (this.possibleDuplicateLeads.length > 0) {
        this.displayDuplicates = true;
      } else {
        this.importLeads();
      }
    } catch (error) {
      this.importError = `Lead import failed. ${error.message}\n${this.additionalMessage}`
      this.leadImportStatus = 'failed';
      console.error("Error processing leads import: ", error);
    }

  }

  processDuplicates() {
    this.disableProcessDuplicates = true;
    if (this.selectedDuplicateLeads.length) {
      this.newLeads = this.newLeads.concat(this.selectedDuplicateLeads);
    }
    this.importLeads();
  }

  importLeads() {
    // TODO remove JSON.stringify when server code and service changed
    this.leadsService.importLeads({ leads: JSON.stringify(this.newLeads) }).subscribe((response: MultiRecordResponse<Lead>) => {
      this.disableImportLeads = false;
      this.leadsSheet = null;
      if (response.success) {
        if (this.additionalMessage) {
          this.importError = this.additionalMessage;
          this.leadImportStatus = 'warning';
        } else {
          this.leadImportStatus = 'success';
        }
        this.leads = this.leads.concat(response.data)
      } else {
        this.importError = `Lead import failed. ${response.message}\n${this.additionalMessage}`
        this.leadImportStatus = 'failed';
      }
    }, (err: Error) => {
      this.disableImportLeads = false;
      this.importError = `Lead import failed. ${err.message}\n${this.additionalMessage}`
      this.leadImportStatus = 'failed';
      this.leadsSheet = null;
      console.error("Error saving leads import: ", err);
    });
  }

  isPossibleDuplicateLead(lead: Lead): boolean {
    return this.leads.some((existingLead: Lead) => {
      const existingAUD: LeadAlarmUserDetails = existingLead.alarmUserDetails;
      const newAUD: LeadAlarmUserDetails = lead.alarmUserDetails;
      if (existingLead.brand != lead.brand) {
        return false;
      }
      // these always have to match to be a duplicate
      if ((existingAUD.firstName != newAUD.firstName) || (existingAUD.lastName != newAUD.lastName)) {
        return false;
      }
      // For the rest of the fields only compare if not blank
      if ((existingAUD.email && newAUD.email) && (existingAUD.email != newAUD.email)) {
        return false;
      }
      if ((existingAUD.telephone && newAUD.telephone) && (existingAUD.telephone != newAUD.telephone)) {
        return false;
      }
      if ((existingAUD.mobile && newAUD.mobile) && (existingAUD.mobile != newAUD.mobile)) {
        return false;
      }
      if ((existingAUD.userAddress.addressOne && newAUD.userAddress.addressOne) && (existingAUD.userAddress.addressOne != newAUD.userAddress.addressOne)) {
        return false;
      }
      if ((existingAUD.userAddress.postcode && newAUD.userAddress.postcode) && (existingAUD.userAddress.postcode != newAUD.userAddress.postcode)) {
        return false;
      }
      return true;
    });
  }

  closeImport() {
    this.displayImportDialog = false;
    this.leadImportStatus = null;
    this.leadsSheet = null;
  }

  exportLeads() {
    const jsonData: any[] = this.selectedLeads.map((lead: Lead) => {
      return {
        'Name': `${lead.alarmUserDetails?.firstName || ''} ${lead.alarmUserDetails?.otherName || ''} ${lead.alarmUserDetails?.lastName || ''}`,
        'Preferred Name': lead.alarmUserDetails?.preferredName,
        'tdCode': lead.tdCode,
        'Email': lead.alarmUserDetails?.email,
        'Telephone': lead.alarmUserDetails?.telephone,
        'Mobile': lead.alarmUserDetails?.mobile,
        'Sales Contact': lead.alarmUserDetails?.salesContact,
        'Address One': lead.alarmUserDetails?.userAddress?.addressOne,
        'Address Two': lead.alarmUserDetails?.userAddress?.addressTwo,
        'City': lead.alarmUserDetails?.userAddress?.city,
        'County': lead.alarmUserDetails?.userAddress?.county,
        'Postcode': lead.alarmUserDetails?.userAddress?.postcode,
        'DOB': lead.alarmUserDetails?.userAddress?.dob,
        'Brand': lead.brand,
        'Referral Date': lead.referralDate ? moment.tz(lead.referralDate, 'Europe/London').format('DD/MM/YYYY') : '',
        'Referrer Name': `${lead.referrerDetails?.firstName || ''} ${lead.referrerDetails?.otherName || ''} ${lead.referrerDetails?.lastName || ''}`,
        'Referrer Email': lead.referrerDetails?.email,
        'Referrer Telephone': lead.referrerDetails?.telephone,
        'Referrer Mobile': lead.referrerDetails?.mobile,
        'Referrer Relationship': lead.referrerDetails?.relationship,
        'Referrer Sales Contact': lead.referrerDetails?.salesContact,
        'Account Contact For CRM': lead.referrerDetails?.accountContactForCRM,
        'User Aware Of Referral': lead.referrerDetails?.userAwareOfReferral,
        'Referred By': lead.referrerDetails?.referredBy,
        'Salesman Name': `${lead.salesContactDetails?.firstName || ''} ${lead.salesContactDetails?.otherName || ''} ${lead.salesContactDetails?.lastName || ''}`,
        'Salesman Email': lead.salesContactDetails?.email,
        'Salesman Relationship': lead.salesContactDetails?.relationship,
        'Salesman Telephone': lead.salesContactDetails?.telephone,
        'Salesman Mobile': lead.salesContactDetails?.mobile,
        'Sales Account Contact For CRM': lead.askedForInfoPack,
        'Status': lead.status,
        'Payment Ref': lead.paymentRef,
        'Call Date': lead.callDate ? moment.tz(lead.callDate, 'Europe/London').format('DD/MM/YYYY') : '',
        'Call Date Shift': lead.callDateShift,
        'Lead Declined Reason': lead.leadDeclinedReason,
        'Lead Declined Reason Two': lead.leadDeclinedReason2,
        'Lead Type': lead.leadType,
        'Note Content': lead.callNotes.map(note => (note.content || '')).join('|'),
        'Note Created By': lead.callNotes.map(note =>note.createdBy).join('|'),
        'Note Created At': lead.callNotes.map(note => (
          note.createdAt?moment.tz(note.createdAt,'Europe/London').format('DD/MM/YYYY'):''
        )).join('|'),
        'Added By': lead.addedBy,
        'Created At': lead.createdAt? moment.tz(lead.createdAt, 'Europe/London').format('DD/MM/YYYY HH:mm') : '',
        'First Contact At': (lead.callNotes && (lead.callNotes.length > 0))? moment.tz(lead.callNotes[0].createdAt, 'Europe/London').format('DD/MM/YYYY HH:mm') : '',
        'Last Updated At': lead.updatedAt? moment.tz(lead.updatedAt, 'Europe/London').format('DD/MM/YYYY HH:mm') : '',
      }
    });
    this.excelService.exportAsExcelFile({ Leads: jsonData }, 'Leads');
  }

  initializeVariables() {
    this.columns = [
      { field: 'name', header: 'Name' },
      { field: 'brand', header: 'Brand' },
      { field: 'tdCode', header: 'TD Code' },
      { field: 'paymentRef', header: 'Pay. Ref' },
      { field: 'leadType', header: 'Lead Type' },
      { field: 'alarmUserDetails.telephone', header: 'Phone No.' },
      { field: 'alarmUserDetails.email', header: 'Email' },
      { field: 'alarmUserDetails.userAddress.postcode', header: 'Postcode' },
      { field: 'ownerName', header: 'Owner' },
      { field: 'referralDate', header: 'Referred' },
      { field: 'callDate', header: 'Contact Date' },
      { field: 'callDateShift', header: 'Shift' },
      { field: 'status', header: 'Status' },
      { field: 'actions', header: 'Actions' }
    ];
    this.duplicatesCols = [
      { field: 'name', header: 'Name' },
      { field: 'brand', header: 'Brand' },
      { field: 'telephone', header: 'Phone Number' },
      { field: 'mobile', header: 'Mobile' },
      { field: 'addressOne', header: 'Address Line 1' },
      { field: 'postcode', header: 'Postcode' },
    ];
    this.selectedStatus = ['Active', 'Pending Payment', 'Considering', 'Unable to Contact'];
    this.table.filter(this.selectedStatus, 'status', 'in');
  }

  leadLockedBy(leadId: string): string {
    const isLocked: LeadLockData = this.leadLockList.find(lead => lead.leadId == leadId);
    return isLocked ? isLocked.user : null;
  }

  closeModal(event: SingleRecordResponse<Lead>) {
    this.callLeadId = null;
    if (!event.success) {
      this.showErrorPopUp('Error updating lead', `Error updating lead. Error: ${event.message}`);
    } else if (event.data) {
      this.showSuccess();
      const tmpLeads: Lead[] = this.leads;
      if (event.data) {
        this.leads = [event.data, ...tmpLeads.filter((lead: Lead) => lead._id != event.data._id)];
      }
    }
  }

  closeEditViewLead(event: SingleRecordResponse<Lead>) {
    if (!event.success) {
      this.showErrorPopUp('Error updating lead', `Error updating lead. Error: ${event.message}`);
    } else if (event.data) {
      this.showSuccess();
      const tmpLeads: Lead[] = this.leads;
      if (event.data) {
        this.leads = [event.data, ...tmpLeads.filter((lead: Lead) => lead._id != event.data._id)];
      }
    }
    this.editViewLeadId = null;
    this.leadOpenMode = null;
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Changes successfuly applied',
      life: 1000
    });
  }

// if user has permissions to do so show delete button otherwise hidden to users . check if  . add button to the actions tab on each order and add a delete selected option

  deleteRecord(leadId: string) {
    if (this.userService.userHasPermission('Can delete leads')) {
      this.leadsService.updateLead({
        '_id': leadId,
        'deleted': true,
        'deletedAt': (new Date()).toISOString(),
        'deletedBy': localStorage.getItem('userName'),
      }).subscribe((response: SingleRecordResponse<Lead>) => {
        if (response.success) {
          this.leads = this.leads.filter((lead: Lead) => !leadId.includes(lead._id));
          this.showSuccess();
        } else {
          this.deleteButtonsDisabled = false;
          this.showErrorPopUp('Error deleting lead', `Error deleting lead. Error: ${response.message}`);
        }
      }, (err: Error) => {
        console.error("Error deleting lead. Error:", err);
        this.showErrorPopUp('Error deleting lead', `Error deleting lead. Error: ${err.message}`);
      });
    }
  }

  deleteRecords() {
    if (this.userService.userHasPermission('Can delete leads')){
      if (this.selectedLeads.length > 0) {
        const leadsToDelete: string[] = this.selectedLeads.map((lead: Lead) => lead._id);
        this.leadsService.deleteLeads({
          'selectedLeadIds': leadsToDelete,
          'deleted': true,
          'deletedAt': (new Date()).toISOString(),
          'deletedBy': localStorage.getItem('userName'),
        }).subscribe((response: SingleRecordResponse<Lead>) => {
          if (response.success) {
            this.leads = this.leads.filter((lead: Lead) => 
              !leadsToDelete.includes(lead._id)
            );
            this.showSuccess();
          } else {
            this.deleteButtonsDisabled = false;
            this.showErrorPopUp('Error deleting leads', `Error deleting leads. Error: ${response.message}`);
          }
        }, (err: Error) => {
          console.error("Error deleting lead. Error:", err);
          this.showErrorPopUp('Error deleting leads', `Error deleting leads. Error: ${err.message}`);
        });
      }
    }
  }


  isOkToUpload(): boolean {
    return (!this.disableImportLeads && !!this.leadsSheet);
  }

  getUsers() {
    this.userService.getUsers().subscribe((response: UsersAndRolesResponse) => {
      if (response.success) {
        response.users = filteredUsersByRole(response.users);
        this.ownerNames = response.users.map((user: User) => {
          return {
            value: user.name,
            label: user.name
          }
        });
        this.ownerNames = sortByLabel(this.ownerNames);
      }
    }, (err: Error) => {
      this.showErrorPopUp('Error getting users', `Error getting users. Error: ${err.message}`);
      console.log('Error while getting users in leads group component :: ',);
    })
  }

  applyFilter(event: Event, field: string, filterType: string): void {
    this.table.filter((event.target as HTMLInputElement).value, field, filterType);
  }
  
  applySubFieldFilter(event: Event, field: string, filterType: string, subfields: string[]): void {
    subfields.unshift((event.target as HTMLInputElement).value);
    this.table.filter(subfields, field, filterType);
  }

  isOrderLocked(_id: string) {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id).length > 0;
  }

  orderLockedBy(_id: string) {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id)[0].user;
  }

  getRowClass(lead: Lead): string {
    if (!['Active', 'Pending Payment', 'Considering', 'Unable to Contact'].includes(lead.status)) {
      return '';
    }
    let hasUncheckedOrder: boolean = false;
    if ((lead.matchedOrders) && (lead.matchedOrders.length > 0)) {
      hasUncheckedOrder = lead.matchedOrders.some((order: MatchedOrder) => {
        return !order.orderChecked;
      });
    }
    if (['Pending Payment'].includes(lead.status)) {
      return hasUncheckedOrder? 'pendingPaymentToCheck': 'pendingPayment';
    }
    return hasUncheckedOrder? 'toCheck': '';
  }

  
  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
  
}
