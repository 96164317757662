<div class="row mx-0 my-2 messageDetails">
  <div class="col-6 col-lg-2 offset-0 offset-lg-1">
    <div>
      Order Status:
      <span class="statusBlock" [style.background-color]="getStatusBackgroundColour(order.status.status)" 
          [style.color]="getStatusColour(order.status.status)">
        {{order.status.status}}
      </span>
    </div>
  </div>
  <div class="col-6 col-lg-3">
    Order Date: {{order.created | date:'dd/MM/y'}}
  </div>
  <div class="col-6 col-lg-2">
    Plan: {{order.accountDetails.plan}}
  </div>
  <div class="col-6 col-lg-4">
    Renewal Type: {{renewalTypeDisplayLookup[order.renewalInformation.renewalType]}}
  </div>
</div>
<div class="row mx-0 my-2 messageDetails">
  <div class="col-6 col-lg-2 offset-0 offset-lg-1">
    Renewal Price: {{order.renewalInformation.renewalPrice}}
  </div>
  <div class="col-6 col-lg-3">
    Payment Due: {{order.renewalInformation.paymentDueDate | date:'dd/MM/y'}}
  </div>
  <div class="col-6 col-lg-3">
    Expiry Date On Order: {{order.renewalInformation.renewalDate | date:'dd/MM/y'}}
    <br *ngIf="!isSmallerThanLarge" />
    {{message.daysToGo}} days to go
  </div>
  <div class="col-6 col-lg-3">
    Expiry Date When Message Created: {{message.renewalDate | date:'dd/MM/y'}}
  </div>
</div>
<div class="row mx-0 my-2 messageDetails">
  <div class="col-6 col-lg-5 offset-0 offset-lg-1">
    Stripe Customer Id:
    <a href="https://dashboard.stripe.com{{!environment.production? '/test': ''}}/customers/{{order.accountDetails.stripeCustomerId}}"
        target="_blank" *ngIf="order.accountDetails.stripeCustomerId">
      {{order.accountDetails.stripeCustomerId}}
    </a>
  </div>
  <div class="col-6 col-lg-5">
    Stripe Subscription Id:
    <a href="https://dashboard.stripe.com{{!environment.production? '/test': ''}}/subscriptions/{{order.accountDetails.stripeSubscriptionId}}"
        target="_blank" *ngIf="order.accountDetails.stripeSubscriptionId">
      {{order.accountDetails.stripeSubscriptionId}}
    </a>
  </div>
</div>
