import {Component, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {Title} from '@angular/platform-browser';
import {UsersService} from '../setup/users/users.service';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {isStrongPassword} from '../helpers/passwordHelper';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  name: string = '';
  email: string = '';
  password: string = '';
  password1: string = '';
  btnDisabled: boolean = false;
  unsafePassword: boolean = false;

  constructor(
    private data: DataService,
    private usersService: UsersService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle('CRM Registration');
  }

  validate(): boolean {
    this.unsafePassword = false;
    if (this.name) {
      if (this.email) {
        if (this.password) {
          if(isStrongPassword(this.password)){
            if (this.password1) {
              if (this.password === this.password1) {
                return true;
              } else {
                this.data.error('Passwords do not match.');
                document.getElementById("errrorpass").style.display = "none";
              }
            } else {
              this.data.error('Confirmation Password is not entered');
            }
          } else {
            this.unsafePassword = true;
            this.data.error("");
          }
        } else {
          this.data.error('Password is not entered');
        }
      } else {
        this.data.error('Email is not entered.');
      }
    } else {
      this.data.error('Name is not entered.');
    }
    return false;
  }

  async register() {
    this.btnDisabled = true;
    if (!this.validate()) {
      this.btnDisabled = false;
      return;
    }
    this.usersService.registerUser({
      name: this.name,
      email: this.email,
      password: this.password,
    }).subscribe((response: SimpleResponse) => {
      if (response.success) {
        location.href='/login';
      } else {
        this.data.error(response.message);
        this.btnDisabled = false;
      }
    }, (error: any) => {
      this.data.error(error.message);
      this.btnDisabled = false;
    });
  }
}
