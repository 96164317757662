<div class="bg-purple">
  <section class="container">
    <h1 class="title">
      PERMISSION DENIED TO ACCESS THIS PAGE
    </h1>
    <h2 class="title">
      PLEASE TALK TO YOUR MANAGER IF YOU NEED TO HAVE ACCESS
    </h2>
  </section>
  <button type="button" class="btn-go-home" (click)="gohome()">GO BACK HOME</button>
  <button type="button" class="btn-go-home"(click)="logout()">LOGOUT</button>
  <div class="permision">PERMISSION DENIED TO ACCESS THIS PAGE</div>
</div>
