<div class="hardware-set-list">
  <div class="container">
    <div class="d-flex justify-content-center m-1">
      <p-dropdown [options]="brands" appendTo="body" [(ngModel)]="selectedBrand" placeholder="Brands" scrollHeight="600px">
        <ng-template let-Pbrand let-i="index" pTemplate="item">
          <span class="brandDropDown" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color': brandConfigs[Pbrand.value].color}">
            &nbsp;{{Pbrand.value}}
          </span>
        </ng-template>
      </p-dropdown>
      <div class="ml-2">
        <button type="button" class="btn btn-primary" pButton (click)="loadHardwareSets()" label="Load Hardware Sets" [disabled]="!selectedBrand">
        </button>
      </div>
      <div class="ml-2">
        <button type="button" class="btn btn-primary" pButton (click)="addHardwareSet()" label="Add Hardware Set" [disabled]="!canAddHardwareSet">
        </button>
      </div>
    </div>
  </div>
  <p-table #dt [value]="hardwareSets" [columns]="cols" [paginator]="true" [rows]="20" sortField="title" [sortOrder]="1" sortMode="single" 
      tableStyleClass="hardwareSetTable">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">
            <div *ngSwitchCase="'overridePricesExVat'">
              {{col.header}}<br/>
              <span class="info">Prices apply to orders created within the specified date range.</span>
            </div>
            <div *ngSwitchCase="'actions'">
              {{col.header}}
            </div>
            <div *ngSwitchCase="'overridePlanSymbol'" [pSortableColumn]="col.field">
              Plan
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
              Symbol
            </div>
            <div *ngSwitchDefault [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
              <ng-container *ngIf="col.field == 'title'">Must be unique within the brand</ng-container>
            </div>
          </ng-container>
        </th>
      </tr>
      <tr>
        <th *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">
            <div *ngSwitchCase="'overridePricesExVat'" class="info">
              Changes to prices and the additional/removal of prices will not be saved until you click the Update button.
            </div>
            <input *ngSwitchCase="'title'" pInputText type="search" (input)="applyFilter($event, col.field, 'contains')"
                [value]="getFilterValue('title')" style="width: 100%;">
            <input *ngSwitchCase="'overridePlanSymbol'" pInputText type="search" (input)="applyFilter($event, col.field, 'contains')"
                [value]="getFilterValue('overridePlanSymbol')" style="width: 100%;">
            <p-multiSelect *ngSwitchCase="'category'" [options]="hardwareSetCategories" appendTo="body" [(ngModel)]="selectedCategories"
                placeholder="Category" filterPlaceHolder="Category" [panelStyle]="{maxWidth:'135px'}" scrollHeight="600px"
                [maxSelectedLabels]=0 selectedItemsLabel="{0} selected" [style]="{'display':'flex'}"
                (onChange)="dt.filter($event.value, col.field, 'in')" >
            </p-multiSelect>
          </ng-container>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <ng-container *ngSwitchCase="'containedHardware'">
            <ng-container *ngFor="let hardware of rowData[col.field]; let i = index">
              <div>
                {{hardware.title}}
                <button type="button" class="btn icon-btn" (click)="deleteHardware(rowData, i)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <hr />
            </ng-container>
            <div>
              <p-dropdown styleClass="addHardware" [options]="hardwareList" placeholder="Select hardware" [(ngModel)]="rowData.newHardware"
                [filter]="true">
              </p-dropdown>
              <button type="button" class="btn icon-btn" (click)="addHardware(rowData)">
                <i class="fas fa-plus-square"></i>
              </button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'overridePricesExVat'">
            <ng-container *ngFor="let datedPrice of rowData[col.field]; let i = index">
              <div>
                <input name="fromDate_{{i}}" type="date" [(ngModel)]="rowData.overridePricesExVat[i].fromDate" min="2014-01-01" 
                    [ngClass]="{'empty' : !rowData.overridePricesExVat[i].fromDate}"/>
                <label for="toDate_{{i}}">to</label>
                <input name="toDate_{{i}}" type="date" [(ngModel)]="rowData.overridePricesExVat[i].toDate" min="2014-01-01"
                    [ngClass]="{'empty' : !rowData.overridePricesExVat[i].toDate}"/>
                <label for="quarterly_{{i}}">Quarterly: </label>
                <input name="quarterly_{{i}}" pInputText type="number" [(ngModel)]="rowData.overridePricesExVat[i].quarterly"  
                    class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
                <label for="annual_{{i}}">Annual: </label>
                <input name="annual_{{i}}" pInputText type="number" [(ngModel)]="rowData.overridePricesExVat[i].annual"
                    class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
                <button type="button" class="btn icon-btn" (click)="deletePrice(rowData, i)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <hr />
            </ng-container>
            <div>
              <div><label>Add New Price:</label></div>
              <input name="newFromDate" type="date" [(ngModel)]="rowData['newFromDate']" min="2014-01-01"
                  [ngClass]="{'empty' : !rowData['newFromDate']}"/>
              <label for="newToDate">to</label>
              <input name="newToDate" type="date" [(ngModel)]="rowData['newToDate']" min="2014-01-01"
                  [ngClass]="{'empty' : !rowData['newToDate']}"/>
              <label for="newDateQuarterly">Quarterly: </label>
              <input name="newDateQuarterly" pInputText type="number" [(ngModel)]="rowData['newQuarterly']"  
                  class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
              <label for="newDateAnnual">Annual: </label>
              <input name="newDateAnnual" pInputText type="number" [(ngModel)]="rowData['newAnnual']"
                  class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
              <button type="button" class="btn icon-btn" (click)="addPrice(rowData)">
                <i class="fas fa-plus-square"></i>
              </button>
            </div>
            <div>
              <div><label>Prices for orders under 1 year old. If not set will use appropriate dated price:</label></div>
              <div class="recentPrices">
                <label for="recentQuarterly">Quarterly: </label>
                <input name="recentQuarterly" pInputText type="number" [(ngModel)]="rowData.overrideRecentQuarterlyPrice"  
                    class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)"/>
                <label for="recentAnnual">Annual: </label>
                <input name="recentAnnual" pInputText type="number" [(ngModel)]="rowData.overrideRecentAnnualPrice"
                    class="font-weight-normal text-right price" min="0" step="0.01" (keypress)="numberOnly($event, true)"/>
              </div>
            </div>
          </ng-container>
          <div *ngSwitchCase="'overrideReplacementPrice'">
            <input name="overrideReplacementPrice" pInputText type="number" [(ngModel)]="rowData['overrideReplacementPrice']"  
                class="font-weight-normal text-right" min="0" step="0.01" (keypress)="numberOnly($event, true)" />
          </div>
          <div *ngSwitchCase="'websiteId'">
            <span class="brand" [ngStyle]="{'background-color':rowData[col.field].background, 'color':rowData[col.field].color}">
              &nbsp;{{rowData[col.field].title}}
            </span>
          </div>
          <div *ngSwitchCase="'category'">
            <p-dropdown [options]="hardwareSetCategories" placeholder="Select a category" [(ngModel)]="rowData[col.field]" [required]=true>
            </p-dropdown>
          </div>
          <div *ngSwitchCase="'updated'">
            By {{rowData['updatedBy']}} on {{rowData['updatedAt'] | date:'dd/MM/y \'at\' HH:mm'}}
          </div>
          <div *ngSwitchCase="'actions'">
            <button type="button" class="btn btn-primary" (click)="saveHardwareSet(rowData)">
              <ng-container *ngIf="!!rowData['_id']">Update</ng-container>
              <ng-container *ngIf="!rowData['_id']">Save New</ng-container>
            </button>
          </div>
          <div *ngSwitchDefault>
            <input name="{{col.field}}" pInputText type="text" [(ngModel)]="rowData[col.field]" class="font-weight-normal" required/>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-toast></p-toast>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error"></p-confirmDialog>
<p-confirmDialog key="save"></p-confirmDialog>