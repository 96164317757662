import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment-timezone';
import {SelectItem} from 'primeng/api';
import {getPlanCodeAndPrice, getRenewalDateDetails, PRICE_REGEX} from '../../helpers/getPlanCodeAndPrice';
import {sortByLabel} from '../../helpers/helperFunctions';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {vatStatuses} from '../../lookups/vatStatuses';
import {Hardware} from '../../models/hardware.model';
import {HardwareSet} from '../../models/hardwareSet.model';
import {Order} from '../../models/order.model';
import {PlanCodeAndPrice} from '../../models/planCodeAndPrice.model';
import {RenewalDiscount} from '../../models/renewalDiscount.model';
import {AccountService} from '../../models/accountService.model';
import {FutureRenewalDetails, RenewalDateDetails} from '../../models/responses/functionReturns/renewalDateDetails.model';

const PLAN_MULTIPLIERS: {[planType: string]: number} = {
  'monthly': 1,
  'quarterly': 3,
  'annual': 12,
};

@Component({
  selector: 'app-price-book[order][allHardwareSets][allHardware][allServices][closeModal]',
  templateUrl: './price-book.component.html',
  styleUrls: ['./price-book.component.scss']
})
export class PriceBookComponent implements OnInit {
  @Input() order: Order;
  @Input() allHardwareSets: HardwareSet[];
  @Input() allHardware: Hardware[];
  @Input() allServices: AccountService[];
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  numberOnly = numberOnly;
  vatStatuses: SelectItem<string>[] = vatStatuses;
  dialogVisible: boolean;
  hardwareSetList: SelectItem<HardwareSet>[] = [];
  hardwareList: SelectItem<Hardware>[] = [];
  serviceList: SelectItem<AccountService>[] = [];
  crmRenewalDate: string;
  nextRenewalDate: string;
  proRataRenewalDate: string;
  currentMoment: moment.Moment;
  newPlanType: string;
  newVatStatus: string;
  hardwareSetsToQuoteFor: HardwareSet[] = [];
  hardwareToQuoteFor: Hardware[] = [];
  servicesToQuoteFor: AccountService[] = [];
  newHardwareSet: HardwareSet;
  newHardware: Hardware;
  newService: AccountService;
  newPlanCodeAndPrice: PlanCodeAndPrice;
  priceDiffForPeriod: string;
  daysToGo: number;
  proRataWholePeriods: number;
  proRataOldPrice: string;
  proRataNewPrice: string;
  proRataDiff: string;
  showPlanTypeChangeWarning: boolean;
  newDiscount: number;
  existingDiscounts: RenewalDiscount[] = [];
  includeDiscount: boolean[] = [];
  todayDateYMD: string;
  showDiscountWarning: boolean;
  currentDiscount: number;
  hasSixWeeksFree: boolean;
  lostEquipmentCost: string;
  hasMonitoringEquip: boolean;

  constructor() { }

  ngOnInit(): void {
    this.newPlanCodeAndPrice = undefined;
    this.lostEquipmentCost = undefined;
    this.daysToGo = 0;
    this.proRataWholePeriods = 0;
    this.hasSixWeeksFree = false;
    this.hardwareSetList = this.allHardwareSets
      .map((hardwareSet: HardwareSet) => {
        return {
          'label': hardwareSet.title,
          'value': hardwareSet,
        }
      });
    sortByLabel(this.hardwareSetList);
    this.hardwareList = this.allHardware
      .filter((hardware: Hardware) => 
        hardware.planSymbol != ''
      )
      .map((hardware: Hardware) => {
        return {
          'label': hardware.title,
          'value': hardware,
        }
      });
    sortByLabel(this.hardwareList);
    this.serviceList = this.allServices
      .map((service: AccountService) => {
        return {
          'label': service.title,
          'value': service,
        }
      });
    // Use plan multipliers to validate planType is one we can calculate values for
    if (PLAN_MULTIPLIERS[this.order.accountDetails.planType]) {
      const renewalDateDetails: RenewalDateDetails = getRenewalDateDetails(this.order);
      let futureRenewalDetails: FutureRenewalDetails;
      if (renewalDateDetails.crm) {
        futureRenewalDetails = renewalDateDetails.crm;
      } else {
        futureRenewalDetails = renewalDateDetails.calculated;
      }
      if (renewalDateDetails.crmStoredNextRenewal) {
        this.crmRenewalDate = renewalDateDetails.crmStoredNextRenewal.format('YYYY-MM-DD');
      }
      this.nextRenewalDate = futureRenewalDetails.firstFutureRenewal.format('YYYY-MM-DD');
      this.proRataRenewalDate = futureRenewalDetails.firstAdjustableRenewal.format('YYYY-MM-DD');
      this.daysToGo = futureRenewalDetails.firstFutureRenewal.diff(this.currentMoment, 'days');
      this.proRataWholePeriods = futureRenewalDetails.renewalsBeforeAjustable;
      this.hasSixWeeksFree = renewalDateDetails.hasSixWeeksFree;
    }
    this.newPlanType = this.order.accountDetails.planType;
    this.newVatStatus = this.order.accountDetails.vat;
    this.currentMoment = moment.tz('Europe/London').startOf('day');
    this.todayDateYMD = moment.tz('Europe/London').format('YYYY-MM-DD');
    this.currentDiscount = 0;
    this.order.renewalDiscounts.forEach((discount: RenewalDiscount) => {
      if ((!discount.discountExpiry) ||
          (discount.discountExpiry && (discount.discountExpiry > this.todayDateYMD))) {
        this.existingDiscounts.push(discount);
        this.includeDiscount.push(true);
        this.currentDiscount += discount.discount;
      }
    });
    this.dialogVisible = true;
  }

  onHideDialog(): void {
    this.closeModal.emit();
  }

  deleteHardwareSet(setIndex: number): void {
    this.hardwareSetsToQuoteFor.splice(setIndex, 1);
  }

  deleteHardware(hardwareIndex: number): void {
    this.hardwareToQuoteFor.splice(hardwareIndex, 1);
  }

  deleteService(serviceIndex: number): void {
    this.servicesToQuoteFor.splice(serviceIndex, 1);
  }

  addHardwareSet(): void {
    if (this.newHardwareSet) {
      this.hardwareSetsToQuoteFor.push(this.newHardwareSet);
    }
  }

  addHardware(): void {
    if (this.newHardware) {
      this.hardwareToQuoteFor.push(this.newHardware);
    }
  }

  addService(): void {
    if (this.newService) {
      this.servicesToQuoteFor.push(this.newService);
    }
  }

  canCalculate(): boolean {
    return ((this.hardwareSetsToQuoteFor.length > 0) || (this.hardwareToQuoteFor.length > 0)) &&
      !['', 'lifetime'].includes(this.newPlanType);
  }

  isLifetimeOrder(): boolean {
    return 'lifetime' == this.order.accountDetails.planType;
  }

  calculate(): void {
    let discountForNewPlanType: number = 0;
    const newRenewalDiscounts: RenewalDiscount[] = [];
    this.newPlanCodeAndPrice = undefined;
    this.lostEquipmentCost = undefined;
    this.priceDiffForPeriod = '';
    this.proRataOldPrice = '';
    this.proRataNewPrice = '';
    this.proRataDiff = '';
    this.showPlanTypeChangeWarning = false;
    this.showDiscountWarning = false;
    
    this.includeDiscount.forEach((include: boolean, index: number) => {
      if (include) {
        discountForNewPlanType += this.existingDiscounts[index].discount;
      }
    });
    if (this.newDiscount) {
      discountForNewPlanType += this.newDiscount;
    }
    if (discountForNewPlanType > 0) {
      newRenewalDiscounts.push({
        'discount': discountForNewPlanType,
        'reason': 'price book',
        'otherReasonText': '',
        'addedBy': 'price book',
      });
    }
    const tmpPlanCodeAndPrice: PlanCodeAndPrice = getPlanCodeAndPrice(this.newPlanType, this.newVatStatus, 
      this.order.created, this.hardwareSetsToQuoteFor, this.hardwareToQuoteFor, newRenewalDiscounts,
      this.servicesToQuoteFor);
    // There is no period renewal price for lifetime, so nothing to calculate if either the old or new is
    if ((tmpPlanCodeAndPrice.errors.length == 0) &&
        ![this.order.accountDetails.planType, this.newPlanType].includes('lifetime')) {
      // Only need to test current renewal price as the new one must be a number, if no errors
      if (PRICE_REGEX.test(this.order.renewalInformation.renewalPrice)) {
        const tmpOldPrice: number = Number(this.order.renewalInformation.renewalPrice);
        let tmpNewPrice: number = Number(tmpPlanCodeAndPrice.renewalPrice);
        this.priceDiffForPeriod = (tmpNewPrice - tmpOldPrice).toFixed(2);
        if (this.proRataRenewalDate) {
          if (this.newPlanType != this.order.accountDetails.planType) {
            if (newRenewalDiscounts.length > 0) {
              newRenewalDiscounts[0].discount *= (PLAN_MULTIPLIERS[this.order.accountDetails.planType] / PLAN_MULTIPLIERS[this.newPlanType]);
              if (this.currentDiscount != newRenewalDiscounts[0].discount) {
                this.showDiscountWarning = true;
              }
            }
            const oldPeriodNewDetailsPrice: PlanCodeAndPrice = getPlanCodeAndPrice(this.order.accountDetails.planType,
                this.newVatStatus, this.order.created, this.hardwareSetsToQuoteFor, this.hardwareToQuoteFor, newRenewalDiscounts,
                this.servicesToQuoteFor);
            tmpNewPrice = Number(oldPeriodNewDetailsPrice.renewalPrice);
            this.showPlanTypeChangeWarning = true;
          }
          let tmpOldProRataPrice: number = 0;
          let tmpNewProRataPrice: number = 0;
          // The period to pro rata is based on current plan type, not new
          switch (this.order.accountDetails.planType) {
            case 'monthly':
              tmpOldProRataPrice = tmpOldPrice * this.daysToGo / 30;
              tmpNewProRataPrice = tmpNewPrice * this.daysToGo / 30;
              break;
            case 'quarterly':
              tmpOldProRataPrice = tmpOldPrice * this.daysToGo / 91;
              tmpNewProRataPrice = tmpNewPrice * this.daysToGo / 91;
              break;
            case 'annual':
              tmpOldProRataPrice = tmpOldPrice * this.daysToGo / 365;
              tmpNewProRataPrice = tmpNewPrice * this.daysToGo / 365;
              break;
            default:
              break;
          }
          this.proRataOldPrice = ((this.proRataWholePeriods * tmpOldPrice) + tmpOldProRataPrice).toFixed(2);
          this.proRataNewPrice = ((this.proRataWholePeriods * tmpNewPrice) + tmpNewProRataPrice).toFixed(2);
          // Don't use the tmpOldProRataPrice and tmpNewProRataPrice as they aren't rounded and can give 1p diff answer
          this.proRataDiff = (Number(this.proRataNewPrice) - Number(this.proRataOldPrice)).toFixed(2);
        }
      }
    }
    this.newPlanCodeAndPrice = tmpPlanCodeAndPrice;
  }

  calculateLostEquipCost(): void {
    this.newPlanCodeAndPrice = undefined;
    this.lostEquipmentCost = undefined;
    this.hasMonitoringEquip = false;
    let tempPrice: number = 0;
    this.hardwareSetsToQuoteFor.forEach((hardwareSet: HardwareSet) => {
      tempPrice += hardwareSet.overrideReplacementPrice;
    });
    this.hardwareToQuoteFor.forEach((hardware: Hardware) => {
      if (hardware.category == 'Monitoring Only') {
        this.hasMonitoringEquip = true;
      } else {
        tempPrice += hardware.replacementPrice;
      }
    });
    if (this.newVatStatus != 'exempt') {
      tempPrice = tempPrice * 1.2;
    }
    this.lostEquipmentCost = tempPrice.toFixed(2);
  }

  canCalculateLostEquipCost(): boolean {
    return ((this.hardwareSetsToQuoteFor.length > 0) || (this.hardwareToQuoteFor.length > 0));
  }
}