import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ConfirmationService, FilterMetadata, MessageService, SelectItem} from 'primeng/api';
import {Table} from 'primeng/table';
import {Observable} from 'rxjs';
import {datedPriceSort, doPricesOverlap} from '../../helpers/hardwareHelpers';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {hardwareCategories} from '../../lookups/hardwareCategories';
import {Column} from '../../models/column.model';
import {DatedPrice} from '../../models/datedPrice.model';
import {Hardware} from '../../models/hardware.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {HardwareService} from '../hardware.service';
import {LinkableAccessory} from '../../models/linkableAccessory.model';

interface HardwareRow extends Hardware {
  'newFromDate'?: string;
  'newToDate'?: string;
  'newQuarterly'?: number;
  'newAnnual'?: number;
  'newAccessoryPlanCode'?: string;
}

@Component({
  selector: 'app-hardware',
  templateUrl: './hardware.component.html',
  styleUrls: ['./hardware.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class HardwareComponent implements OnInit {

  constructor(
    private hardwareService: HardwareService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private title: Title,
  ) { }

  @ViewChild('dt', {static: true})
  private table: Table;
  numberOnly = numberOnly;
  brandConfigs: BrandCfg;
  brands: SelectItem<string>[];
  hardwareCategories: SelectItem<string>[] = hardwareCategories;
  selectedCategories: string[];
  selectedBrand: string;
  hardwareItems: HardwareRow[] = [];
  cols: Column[] = [
    { field: 'title', header: 'Title' },
    { field: 'planSymbol', header: 'Plan Symbol' },
    { field: 'pricesExVat', header: 'Prices Ex VAT' },
    { field: 'replacementPrice', header: 'Replacement Price' },
    { field: 'websiteId', header: 'Brand' },
    { field: 'category', header: 'Category' },
    { field: 'updated', header: 'Updated' },
    { field: 'actions', header: 'Actions' },
  ];
  canAddHardware: boolean = false;

  ngOnInit(): void {
    this.title.setTitle('CRM Hardware');
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
  }

  loadHardware(): void {
    if (!this.selectedBrand || !this.brandConfigs[this.selectedBrand]) {
      return;
    }
    const websiteId: string = this.brandConfigs[this.selectedBrand]._id;
    if (!websiteId) {
      return;
    }
    this.canAddHardware = false;
    this.hardwareService.getHardwareForSite(websiteId)
      .subscribe((response: MultiRecordResponse<Hardware>) => {
        if (response.success) {
          this.hardwareItems = response.data.map((hardware: Hardware) => {
            hardware.pricesExVat.sort(datedPriceSort);
            return hardware;
          });
          this.canAddHardware = true;
        } else {
          this.showErrorPopUp('Error', response.message || 'Something went wrong try again.');
        }
      }, (err: Error) => {
        console.log('ERROR while getting hardware: ', err);
        this.showErrorPopUp('Error', 'Something went wrong try again.');
      });
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.table.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  getFilterValue(field: string): string {
    if (!this.table.filters[field]) {
      return '';
    }
    return (this.table.filters[field] as FilterMetadata).value;
  }

  deletePrice(rowData: HardwareRow, index: number): void {
    rowData.pricesExVat.splice(index, 1);
  }

  deleteLinkableAccessory(rowData: Hardware, index: number): void {
    rowData.linkableAccessories.splice(index, 1);
  }

  addPrice(rowData: HardwareRow): boolean {
    if (!rowData.newFromDate && !rowData.newToDate) {
      this.showInfoPopUp('Missing Dates', 'You must enter at least one date for the price.');
      return false;
    }
    if (!!rowData.newFromDate && !!rowData.newToDate && (rowData.newFromDate >= rowData.newToDate)) {
      this.showInfoPopUp('Invalid Date Range', 'The from date must be before the to date');
      return false;
    }
    let overlappingRange: boolean = false;
    rowData.pricesExVat.forEach((datedPrice: DatedPrice) => {
      if (!rowData.newFromDate) {
        if (!datedPrice.fromDate) {
          // New price and an existing price are open on the from date, so must overlap
          overlappingRange = true;
        } else if (datedPrice.fromDate <= rowData.newToDate) {
          overlappingRange = true;
        }
      } else if (!rowData.newToDate) {
        if (!datedPrice.toDate) {
          // New price and an existing price are open on the to date, so must overlap
          overlappingRange = true;
        } else if (datedPrice.toDate >= rowData.newFromDate) {
          overlappingRange = true;
        }
      } else {
        // Both start and end date specified on the new price
        if ((!!datedPrice.fromDate) && (datedPrice.fromDate >= rowData.newFromDate) && (datedPrice.fromDate <= rowData.newToDate) ||
            (!!datedPrice.toDate) && (datedPrice.toDate >= rowData.newFromDate) && (datedPrice.toDate <= rowData.newToDate)) {
          // And one side or other of the existing price's date range is within the new range
          overlappingRange = true;
        } else if ((!!datedPrice.fromDate && (datedPrice.fromDate <= rowData.newFromDate) && (!datedPrice.toDate || (datedPrice.toDate >= rowData.newToDate))) ||
            (!!datedPrice.toDate && (datedPrice.toDate >= rowData.newToDate) && !datedPrice.fromDate)) {
          // The new range fits entirely within an existing range
          overlappingRange = true;
        }
      }
    });
    if (overlappingRange) {
      this.showInfoPopUp('Invalid Date Range', 'The date range you have entered overlaps with an existing range.');
      return false;
    }
    // Must have both prices
    if (!rowData.newQuarterly || !rowData.newAnnual || (rowData.newQuarterly <= 0) || (rowData.newAnnual <= 0)) {
      this.showInfoPopUp('Missing Price', 'You must enter a quarterly and an annual price.');
      return false;
    }

    rowData.pricesExVat.push({
      'fromDate': rowData.newFromDate,
      'toDate': rowData.newToDate,
      'quarterly': rowData.newQuarterly,
      'annual': rowData.newAnnual,
    });
    rowData.pricesExVat.sort(datedPriceSort);
    delete rowData.newFromDate;
    delete rowData.newToDate;
    delete rowData.newQuarterly;
    delete rowData.newAnnual;
    return true;
  }

  addAccessoryPlanCode(rowData: HardwareRow): boolean {
    const accessory: LinkableAccessory = rowData.linkableAccessories
      .find((accessory: LinkableAccessory) => 
        accessory.planSymbol == rowData.newAccessoryPlanCode
      );
    if (accessory) {
      this.showInfoPopUp('Already Added', 'This plan code has already been added to this base unit.');
      return false;
    }
    rowData.linkableAccessories.push({
      'planSymbol': rowData.newAccessoryPlanCode,
      'accessoryTypes': [],
    });
    delete rowData.newAccessoryPlanCode;
    return true;
  }

  validToSave(hardware: HardwareRow) {
    const errors: string[] = [];
    if (!hardware.title) {
      errors.push('a title');
    }
    if (!hardware.category) {
      errors.push('a category');
    } else {
      if ((hardware.category == 'Monitoring Only') && !hardware.planSymbol.toLocaleLowerCase().startsWith('mon')) {
        errors.push('a plan symbol starting MON');
      } else if ((hardware.category != 'Monitoring Only') && hardware.planSymbol.toLocaleLowerCase().startsWith('mon')) {
        errors.push('a plan symbol that does not start MON');
      }
    }
    if (Object.keys(hardware.pricesExVat).length == 0) {
      errors.push('at least one date range with a price');
    } else {
      let pricesValid: boolean = true;
      let datesValid: boolean = true;
      Object.keys(hardware.pricesExVat).forEach((index: string) => {
        if ((hardware.pricesExVat[index].quarterly <= 0) || (hardware.pricesExVat[index].annual <= 0)) {
          pricesValid = false;
        }
        if (!hardware.pricesExVat[index].fromDate && !hardware.pricesExVat[index].toDate) {
          datesValid = false;
        }
      });
      if (!pricesValid) {
        errors.push('a quarterly and annual price for each date range');
      }
      if (!datesValid) {
        errors.push('a start and/or end date for each date range');
      }
      if (doPricesOverlap(hardware.pricesExVat)) {
        errors.push('date ranges that do not overlap');
      }
    }
    if (hardware.linkableAccessories) {
      hardware.linkableAccessories.forEach((accessory: LinkableAccessory) => {
        if (accessory.accessoryTypes.length == 0) {
          errors.push(`accepted accessories for Plan Code ${accessory.planSymbol}`);
        }
      });
    }
    if (errors.length > 0) {
      this.showInfoPopUp('Invalid Information', `You must enter ${errors.join(', ')} before saving.`);
      return false;
    } 
    return true;
  }

  saveHardware(rowData: HardwareRow): void {
    if (!rowData.newFromDate && !rowData.newToDate) {
      this.updateHardware(rowData);
    } else {
      this.confirmationService.confirm({
        key: 'save',
        message: 'You have entered a price you have not yet added, do you want it to be added before saving the update?',
        header: 'Price Addition',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        rejectVisible: true,
        accept: () => {
          if (this.addPrice(rowData)) {
            this.updateHardware(rowData);  
          }
        },
        reject: () => {
          this.updateHardware(rowData);
        }
      });
    }
  }

  updateHardware(rowData: HardwareRow): void {
    if (!this.validToSave(rowData)) {
      return;
    }
    // Clear fields used for adding prices
    delete rowData.newFromDate;
    delete rowData.newToDate;
    delete rowData.newQuarterly;
    delete rowData.newAnnual;
    rowData.updatedBy = localStorage.getItem('userName');
    let response: Observable<SingleRecordResponse<Hardware>>;
    if (!!rowData._id) {
      response = this.hardwareService.updateHardware(rowData._id, {'hardware': rowData});
    } else {
      response = this.hardwareService.saveNewHardware({'hardware': rowData});
      this.canAddHardware = true;
    }
    
    response.subscribe((rsp: SingleRecordResponse<Hardware>) => {
      if (!rsp.success || !rsp.data) {
        this.showErrorPopUp('Error Updating Hardware', rsp.message || 'Something went wrong when trying to update the hardware. Please try again.');
        this.handleError(rsp.error);
      } else {
        const tempFirst: number = this.table.first;
        this.showSuccess();
        this.hardwareItems = [...[rsp.data], ...this.hardwareItems.filter((hardware: Hardware) => 
          !!hardware._id && (hardware._id !== rsp.data._id)
        )];
        this.table.first = tempFirst;
      }
    }, (err: Error) => {
      this.handleError(err);
      this.showErrorPopUp('Error Updating Hardware',
          `Something went wrong when trying to update the hardware. Please try again. Error: ${err.message}`);
    });
  }

  addHardware(): void {
    this.canAddHardware = false;
    const newHardware: Hardware = {
      'title': '',
      'planSymbol': '',
      'pricesExVat': [],
      'recentQuarterlyPrice': 0,
      'recentAnnualPrice': 0,
      'replacementPrice': 0,
      'websiteId': {
        '_id': this.brandConfigs[this.selectedBrand]._id,
        'title': this.selectedBrand,
        'background': this.brandConfigs[this.selectedBrand].background,
        'color': this.brandConfigs[this.selectedBrand].color,
      },
      'category': '',
      'linkableAccessories': [],
      'updatedBy': localStorage.getItem('userName'),
      'createdAt': '',
      'updatedAt': '',
    };
    this.hardwareItems = [newHardware, ...this.hardwareItems];
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success Update!',
      detail: 'Changes Successfully Applied',
    });
  }

  private handleError(err: Error) {
    console.error(err);
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
