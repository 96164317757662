<div class="create-role">
      <p-dialog header="Add Role" (onHide)="closeModal()" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
        [draggable]="false" [resizable]="false">
        <form>
            <div class="row">
                <div class="form-group col-6 col-md-6 col-lg-6">
                    <label for="roleName">Role Name</label>
                    <input type="text" id="roleName" class="form-control text-capitalize" name="roleName" [(ngModel)]="roleName"
                        placeholder="Enter role name" #roleNameRef="ngModel" required>
                </div>
                <div class="form-group col-6 col-md-6 col-lg-6 add-role-multiselect">
                    <label for="permissions">Permissions</label>
                    <p-multiSelect id="permissions" name="selectedRolePermissions" [options]="rolePermissions" [(ngModel)]="selectedPermissions"
                        [maxSelectedLabels]=3 selectedItemsLabel="{0} permissions selected" [panelStyle]="{minWidth:'400px'}"
                        placeholder="Select Permissions" appendTo="body" [scrollHeight]="'400px'"
                        (onChange)="confirmPermissionAddition($event)">
                    </p-multiSelect>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-md-6 col-lg-6">
                    <button type="button" class="btn btn-warning" (click)="closeModal()">Cancel</button>
                    <button type="button" class="btn btn-primary ml-2" [disabled]="roleNameRef.invalid || isSubmitButtonDisabled" (click)="addRole()">Submit</button>
                </div>
            </div>
        </form>
    </p-dialog>
</div>
<p-confirmDialog>
</p-confirmDialog>