import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {Observable} from 'rxjs';
import {Lead} from '../models/lead.model';
import {PageCountResponse} from '../models/responses/pageCountResponse.model';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  
  constructor(private httpClient: HttpClient) { }
  API_URL: string = environment.protocol + environment.IPAddress +'/api';

  addLead(lead: Lead) {
    return this.httpClient.post<SingleRecordResponse<Lead>>(`${this.API_URL}/leads`, lead);
  }

  getLeads() {
    return this.httpClient.get<MultiRecordResponse<Lead>>(`${this.API_URL}/leads`);
  }

  getActiveLeads() {
    return this.httpClient.get<MultiRecordResponse<Lead>>(`${this.API_URL}/leads/activeLeads`);
  }

  getInactiveLeadPageCount(): Observable<PageCountResponse> {
    return this.httpClient.get<PageCountResponse>(`${this.API_URL}/leads/inactiveLeadPageCount`);
  }

  getInactiveLeadPage(page: number): Observable<MultiRecordResponse<Lead>> {
    return this.httpClient.get<MultiRecordResponse<Lead>>(`${this.API_URL}/leads/inactiveLeads/page/${page}`);
  }

  getLead(leadId: string) {
    return this.httpClient.get<SingleRecordResponse<Lead>>(`${this.API_URL}/leads/specificLead/${leadId}`)
  }

  updateLead(lead: Partial<Lead>): Observable<SingleRecordResponse<Lead>> {
    return this.httpClient.patch<SingleRecordResponse<Lead>>(`${this.API_URL}/leads`, lead);
  }
  
  deleteLeads(leadDeletionRequest): Observable<SimpleResponse> {
    return this.httpClient.patch<SimpleResponse>(`${this.API_URL}/leads/delete-leads`, leadDeletionRequest);
  }

  /*
    TODO swap to array when server /import-leads is updated to replace
    const leads: Lead[] = JSON.parse(req.body.leads); 
    with
    const leads: Lead[] = req.body.leads;
  */
  // importLeads(data: {leads: Lead[]}) {
  importLeads(data: any) {
    return this.httpClient.post<MultiRecordResponse<Lead>>(`${this.API_URL}/leads/import-leads`, data);
  }

  getNextNumber(sequenceName: string): Observable<SingleRecordResponse<number>> {
    return this.httpClient.get<SingleRecordResponse<number>>(`${this.API_URL}/sequence/${sequenceName}`);
  }
  
}
