<div class="formbar row no-gutters">
  <div class="col-12">
    Reviews 
    <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
      <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
      <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
    </button>
  </div>
</div>
<div class="formsect">
  <div [ngStyle]="{'display': pageSection.minimized?'none':'block'}">
    <div class="form-row" *ngFor="let review of orderReviews">
      <div class="col-6">
        <label for="reviewName">Name:</label>
        <input type="text" name="reviewName" readonly value="{{review.name}}">
      </div>
      <div class="col-4">
        <label for="reviewDate">Date:</label>
        <input type="text" name="reviewDate" readonly value="{{review.date | date:'dd/MM/y - HH:mm'}}">
      </div>
      <div class="col-2">
        <label for="reviewRating">Rating:</label>
        <input type="text" name="reviewRating" readonly value="{{review.rating}}">
      </div>
      <div class="col-12 form-group">
        <label for="reviewText">Review:</label>
        <textarea name="reviewText" readonly value="{{review.review}}" rows="3"></textarea>
      </div>
    </div>
  </div>
</div>