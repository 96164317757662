import { Pipe, PipeTransform } from '@angular/core';
import {SelectItem} from 'primeng/api';

@Pipe({
  name: 'catidToName'
})
export class CatidToNamePipe implements PipeTransform {

  transform(catId: string, categoryLookups: SelectItem<string>[]): string {
    if (!catId || !categoryLookups) {
      return '';
    }
    const catLookup: SelectItem<string> = categoryLookups.find((catLookup: SelectItem<string>) => 
      catLookup.value == catId
    );
    return catLookup? catLookup.label: '';
  }

}
